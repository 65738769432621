import React, { Component } from 'react';
import { Grid, Table } from 'semantic-ui-react';

import ClienteNew from '../components/ClienteNew';
import ClienteEdit from '../components/ClienteEdit';

export default class ListaClientes extends Component {
    constructor(props) {
        super(props);
        this.db = props.db;
        this.perfil = props.perfil;
        this.clientes = props.clientes;
    }

    componentWillReceiveProps = (nextProps) => {
        this.clientes = nextProps.clientes;
    }

    render() {
        return (
            <Grid>
                <Grid.Row centered>

                    <Table
                        celled
                        striped
                        compact
                        collapsing
                        color={"teal"}
                        valigned={"center"}
                        unstackable={true}
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='10'>
                                    <span>Clientes </span>
                                    <ClienteNew db={this.db} perfil={this.perfil} />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.clientes.map(value =>
                                <Table.Row>
                                    <Table.Cell>{value.nombre}</Table.Cell>
                                    <Table.Cell><ClienteEdit cliente={value} /></Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>

                </Grid.Row>
            </Grid>
        )
    }
}