
const consumos = (state = [], action) => {
    switch (action.type) {
        case 'ADD_CONSUMO':
           return action.consumos
        default:
            return state
    }
};

export default consumos;