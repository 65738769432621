const initialState = {
    vendidos: null,
    destino: null,
    fechaInicio: null,
    fechaFinal: null,
    fechaInicioVta: null,
    fechaFinalVta: null,
    allRodeos: true,
    listaRodeos: [],
    allClientes: true,
    listaClientes: [],
    allCategorias: true,
    listaCategorias: [],
    filtros: [],
    fechaCobro: true
};

const filtrosVentas = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_VENTAS':
            return Object.assign({}, state, {
                ...state,
                ...action
            });
        default:
            return state
    }
}

export default filtrosVentas;