import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class CorralEdit extends Component {
  constructor (props) {
    super();
    this.perfil = props.perfil;
    this.id = props.id;
    this.mixers = props.mixers;

    this.db = props.db;

    this.options = this.mixers.map((value) => { 
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
    });

    this.state = {
      modalOpen: false,
      alerta: false,
      nombre: props.corral.nombre,
      oldname: props.corral.nombre,
      mixer: props.corral.mixer,
      oldmixer: props.corral.mixer,
      activo: props.corral.activo,
      oldactivo: props.corral.activo,
    };

  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.corral) this.setState({ 
        nombre: nextProps.corral.nombre,
        oldname: nextProps.corral.nombre,
        mixer: nextProps.corral.mixer,
        oldmixer: nextProps.corral.mixer,
        activo: nextProps.corral.activo,
        oldactivo: nextProps.corral.activo,
     });
  }
  
  save = () => {
    var _this = this;
    if( this.state.nombre.trim().length > 0 && this.state.mixer.trim().length > 0) { 
      this.setState({cargando: true});
      var corralRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('corrales').doc(this.id);
      
      return corralRef.update({
        "nombre": this.state.nombre,
        "mixer": this.state.mixer,
        "activo": this.state.activo
      })
      .then(function() {
          _this.setState({ cargando: false, modalOpen: false, oldname: _this.state.nombre, oldmixer: _this.state.mixer, oldactivo: _this.state.activo });      
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error})
      })
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        >
        <Modal.Header>Editar Corral</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre del Corral" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select options={this.options} placeholder='Seleccionar Mixer' value={this.state.mixer} onChange={(e, { value }) => this.setState({ mixer: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={(e, { value }) => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, nombre: this.state.oldname, activo: this.state.oldactivo, mixer: this.state.oldmixer })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos!'
              list={[
                'Debe ingresar un nombre valido y designaler un mixer!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default CorralEdit;