import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import firebase from './firebase';
import logger from 'redux-logger'
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import thunkMiddleware from 'redux-thunk';

import personalStore from './reducers';

const rrfConfig = {
  timestampsInSnapshots: true,
  userProfile: 'usuarios',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

// Add reduxReduxFirebase enhancer when making store creator
const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig),
  reduxFirestore(firebase), compose(applyMiddleware(thunkMiddleware))
)(createStore);

// const createStoreWithFirebase = compose(
//   reduxFirestore(firebase),
//   reactReduxFirebase(firebase, rrfConfig)
// )(createStore)


// Add Firebase to reducers
const appReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    personalStore
  });

  const initialState = {};
  
  const store = createStoreWithFirebase(
    appReducer, 
    initialState,
    applyMiddleware(
        thunkMiddleware,
        // logger
    )
  );

  export default store;