import React from 'react';
import { Table } from 'semantic-ui-react';

export default (props) => (
  <Table.Row>
    <Table.Cell>{props.producto.nombre} </Table.Cell>
    <Table.Cell className="right aligned">{`${formatear(props.producto.materia === 100 ? props.ingrediente.kilos :  props.ingrediente.materiaseca)} Kg (${props.ms > 0 ? formatear((props.ingrediente.materiaseca / props.ms) * 100, 2) : 0}%)`}</Table.Cell>
    <Table.Cell className="right aligned">{formatear(props.ingrediente.kilos)} Kg</Table.Cell>
    <Table.Cell className="right aligned">$ {formatear(props.ingrediente.kilos * props.producto.precio)}</Table.Cell>
    <Table.Cell className="right aligned">$ {formatear(props.ingrediente.costo)}</Table.Cell>
  </Table.Row> 
)           

const formatear= (numero, digitos = 0) => {
  return new Intl.NumberFormat('es-IN',{ 
                  minimumFractionDigits: digitos, 
                  maximumFractionDigits: digitos  
                  }).format(numero);
}