const ingredientes = (state = [], action) => {
    switch (action.type) {
        case 'ADD_INGREDIENTES':
              return action.ingredientes
        case 'RESET_INGREDIENTES':
           return [];
        default:
            return state
    }
};

export default ingredientes;