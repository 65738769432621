import moment from 'moment'

let inicio = new Date(new Date().getTime() - (86400000));
inicio.setHours(0)
inicio.setMinutes(0)

const initialState = {  
    fechaInicio: moment(inicio),
    fechaFinal: null,
    allCorrales: true,
    listaCorrales: [],
    allDietas: true,
    listaDietas: [],
    filtros: [],
    allMixer: true,
    listaMixer: []
};

const filtrosSeguimiento = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_SEGUIMIENTO':
            return Object.assign({}, state, {
            ...state,
            ...action
            });
        default:
            return state
    }
}

export default filtrosSeguimiento;