import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class ProductoEdit extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;

    this.db = props.db;

    this.state = {
      error: "",
      nombre: props.producto.nombre,
      oldnombre: props.producto.nombre,
      prot: props.producto.prot,
      oldprot: props.producto.prot,
      calcio: props.producto.calcio,
      oldcalcio: props.producto.calcio,
      fosforo: props.producto.fosforo,
      oldfosforo: props.producto.fosforo,
      mcal: props.producto.mcal,
      oldmcal: props.producto.mcal,
      fdn: props.producto.fdn,
      oldfdn: props.producto.fdn,
      materia: props.producto.materia,
      oldmateria: props.producto.materia,
      minimo: props.producto.minimo,
      oldminimo: props.producto.minimo,
      precio: props.producto.precio,
      oldprecio: props.producto.precio,
      stock: props.producto.stock,
      oldstock: props.producto.stock,
      activo: props.producto.activo,
      oldactivo: props.producto.activo,
      modalOpen: false
    };

  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.producto) this.setState({ 
      nombre: nextProps.producto.nombre,
      oldnombre: nextProps.producto.nombre,
      materia: nextProps.producto.materia,
      oldmateria: nextProps.producto.materia,
      minimo: nextProps.producto.minimo,
      oldminimo: nextProps.producto.minimo,
      precio: nextProps.producto.precio,
      oldprecio: nextProps.producto.precio,
      stock: nextProps.producto.stock,
      oldstock: nextProps.producto.stock,
      activo: nextProps.producto.activo,
      oldactivo: nextProps.producto.activo,
    });
  }

  save = () => {

    var _this = this;
    
    if(this.state.nombre.length >0 && this.state.materia > 0){
      this.setState({cargando: true});
      var productoRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('productos').doc(this.id);
      
      // Set the "capital" field of the city 'DC'
      return productoRef.update({
        "nombre": this.state.nombre,
        "materia": Number(this.state.materia) || 0,
        // "stock": Number(this.state.stock) || 0,
        "precio": Number(this.state.precio) || 0,
        "minimo": Number(this.state.minimo) || 0,
        "mcal": Number(this.state.mcal) || 0,
        "prot": Number(this.state.prot) || 0,
        "fdn": Number(this.state.fdn) || 0,
        "calcio": Number(this.state.calcio) || 0,
        "fosforo": Number(this.state.fosforo) || 0,
        "activo": this.state.activo
      })
      .then(function() {
          _this.setState({ 
            cargando: false, 
            modalOpen: false, 
            alerta: false, 
            oldnombre: _this.state.nombre, 
            oldmateria: _this.state.materia, 
            oldprecio: _this.state.precio, 
            oldstock: _this.state.stock, 
            oldactivo: _this.state.activo, 
            oldminimo: _this.state.minimo,
            oldcalcio: _this.state.calcio,
            oldfosforo: _this.state.fosforo,
            oldfdn: _this.state.fdn,
            oldmcal: _this.state.mcal,
            oldprot: _this.state.prot
          });   
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error})
      })
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}

  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        >
        <Modal.Header>Editar Producto</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="NOMBRE" id="nombre" type="text" placeholder="Nombre del Producto" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="MATERIA SECA %" id="mixer" type="number" min="0" max="100" step="1" placeholder="% Materia Seca" value={this.state.materia} 
                    onChange={ (e, { value }) => this.setState({ materia: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="PRECIO POR KG" id="mixer" type="number" min="0" max="100" step="1" placeholder="Precio" value={this.state.precio} 
                    onChange={ (e, { value }) => this.setState({ precio: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="MEGA CALORIAS X KG DE MS" id="mcal" type="number" min="0" max="100" step="1" placeholder="MCAL" value={this.state.mcal} 
                    onChange={ (e, { value }) => this.setState({ mcal: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="PROTEINAS (%) X KG DE MS" id="prot" type="number" min="0" max="100" step="1" placeholder="PROT" value={this.state.prot} 
                    onChange={ (e, { value }) => this.setState({ prot: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="FDN (%) X KG DE MS" id="fdn" type="number" min="0" max="100" step="1" placeholder="FDN" value={this.state.fdn} 
                    onChange={ (e, { value }) => this.setState({ fdn: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="CALCIO (%) X KG DE MS" id="cal" type="number" min="0" max="100" step="1" placeholder="Calcio" value={this.state.calcio} 
                    onChange={ (e, { value }) => this.setState({ calcio: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="FOSFORO (%) X KG DE MS" id="fos" type="number" min="0" max="100" step="1" placeholder="Fosforo" value={this.state.fosforo} 
                    onChange={ (e, { value }) => this.setState({ fosforo: value })} />
                  </Form.Field>
                  {/* <Form.Field>
                    <Form.Input label="STOCK ACTUAL" id="mixer" type="number" min="0" max="100" step="1" placeholder="Stock" value={this.state.stock} 
                    onChange={ (e, { value }) => this.setState({ stock: value })} />
                  </Form.Field> */}
                  <Form.Field>
                    <Form.Input label="STOCK MINIMO" id="minimo" type="number" min="0" max="100" step="1" placeholder="Stock Minimo" value={this.state.minimo} 
                    onChange={ (e, { value }) => this.setState({ minimo: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="ACTIVO" toggle checked={this.state.activo} onChange={ () => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, 
                                                          alerta: false, nombre: 
                                                          this.state.oldnombre, 
                                                          materia: this.state.oldmateria, 
                                                          precio: this.state.oldprecio, 
                                                          stock: this.state.oldstock, 
                                                          activo: this.state.oldactivo, 
                                                          minimo: this.state.oldminimo, 
                                                          mcal: this.state.oldmcal, 
                                                          fdn: this.state.oldfdn, 
                                                          calcio: this.state.oldcalcio, 
                                                          prot: this.state.oldprot, 
                                                          fosforo: this.state.oldfosforo
                                                          })
                                  } inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Ingrese un nombre valido',
                'El porcentaje de materia seca debe ser mayor a cero'
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ProductoEdit;