import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class DetalleNew extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.productos = props.productos;

    this.id = props.id;
    this.db = props.db;

    this.options = this.productos.map((value) => { 
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
    });

    this.options = this.options.filter((element)=> {
      if (!element.activo) return false;
      return true;
    }) 


    this.state = {
      modalOpen: false,
      alerta: false,
      producto: "",
      orden: 0,
      cantidad: 0,
      error: "",
      cargando: false
    };

  }

  save = () => {
    
    if((this.state.orden >0) && (this.state.cantidad>0) && (this.state.producto.length>0) ){
      
      var _this = this;
      this.setState({cargando: true});

            this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas').doc(this.id).collection('detalle').add({
              "orden": Number(this.state.orden),
              "cantidad": Number(this.state.cantidad),
              "producto": this.state.producto
            })
            .then(function(docRef) {
                _this.setState({ cargando: false, modalOpen: false, alerta: false, producto: "", cantidad: 0, orden: 1});
            })
            .catch(function(error) {
                _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
            });
            this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}

  }

  componentWillReceiveProps = (nextProps) => {

    this.productos = nextProps.productos;

    this.options = this.productos.map((value) => { 
      var rObj = {};
      rObj["activo"] = value.activo;
      rObj["key"] = value.id;
      rObj["text"] = value.nombre;
      rObj["value"] = value.id;
      return rObj;
    });

    this.options = this.options.filter((element)=> {
      if (!element.activo) return false;
      return true;
    }) 
  }
  

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='google plus'>
                  <Icon name='plus' /> Agregar
                </Button>}
        size={'small'}
        >
        <Modal.Header>Agregar Producto</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Orden" id="orden" type="number" placeholder="Numero de paso en el que debe ser ingresado" value={this.state.orden} onChange={(e, { value }) => this.setState({ orden: e.target.value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select options={this.options} placeholder='Seleccionar Producto' value={this.state.producto} onChange={(e, { value }) => this.setState({ producto: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Cantidad en %" id="mixer" type="number" min="0" max="100" step="0,5" placeholder="%" value={this.state.cantidad} 
                      onChange={ (e, { value }) => this.setState({ cantidad: value })} />
                  </Form.Field>
                  
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, orden: 1, cantidad: 0, producto: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Debe ingresar un nombre valido y la capadidad debe ser mayor a cero!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default DetalleNew;
