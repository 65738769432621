import React, { Component } from 'react';
import firebase from '../firebase';
import { Table, Grid, Segment, Form, Label } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchDietas, fetchProductos } from '../actions';

import DietaPDF from './../pdf_dietas/DietaPDF';
import DietaEdit from '../components/DietaEdit';
import DietaNew from '../components/DietaNew';

const db = firebase.firestore()

class Dietas extends Component {

  static isPrivate = true;

  constructor(props) {
    super(props);
    this.firebase = props.firebase;
    this.perfil = props.perfil;

    this.buscarProductos = props.actions.fetchProductos;
    this.buscarDietas = props.actions.fetchDietas;
    this.activarListener = props.actions.initialFetch;

    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.state = ({
      dietas: props.dietas || [],
      productos: props.productos || [],
      listeners: props.listeners || []
    })

  }

  formatear = (numero, digitos = 0) => {
    return new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: digitos,
      maximumFractionDigits: digitos
    }).format(numero);
  }

  componentWillMount = () => {
    const item = { id: "productos" };

    var oyente = this.state.listeners.find(function (element) {
      return element.id === "productos";
    });

    if (oyente === undefined) {
      this.activarListener(this.perfil, item);
      this.buscarProductos(this.perfil);
    }
  }

  componentDidMount = () => {
    const item = { id: "dietas" };

    var oyente = this.state.listeners.find(function (element) {
      return element.id === "dietas";
    });

    if (oyente === undefined) {
      this.activarListener(this.perfil, item);
      this.buscarDietas(this.perfil);
    }
  }


  componentWillReceiveProps = (nextProps) => {
    if (nextProps.productos) this.setState({ productos: nextProps.productos });
    if (nextProps.dietas) this.setState({ dietas: nextProps.dietas });
    if (nextProps.listeners) this.setState({ listeners: nextProps.listeners });
  }


  activarBalanceo = () => {
    let balancear = true
    if (this.props.empresa && this.props.empresa.balancearDieta) {
      balancear = false
    }

    db.collection('empresas').doc(this.perfil.empresa).update({
      balancearDieta: balancear
    })

  }

  render() {

    let balancear = false
    if (this.props.empresa && this.props.empresa.balancearDieta) {
      balancear = true
    }

    if (this.state.productos.length === 0) {
      return (
        <div>
          <div className="row text-center">
            <span>Deben definirse los productos antes de dar de alta las dietas.</span>
          </div>
        </div>
      )
    }

    const lista = this.state.dietas.map(
      (value) => {
        let activo = "No";
        if (value.activo) activo = "si";
        return (
          <Table.Row key={value.id}>
            <Table.Cell>{value.nombre} </Table.Cell>
            <Table.Cell className="right aligned">{this.formatear(value.ms, 2)} %</Table.Cell>
            <Table.Cell className="right aligned">{this.formatear(value.mcal, 2)}</Table.Cell>
            <Table.Cell className="right aligned">{this.formatear(value.prot, 2)} %</Table.Cell>
            <Table.Cell className="right aligned">{this.formatear(value.calcio, 2)} %</Table.Cell>
            <Table.Cell className="right aligned">{this.formatear(value.fosforo, 2)} %</Table.Cell>
            <Table.Cell className="right aligned">{this.formatear(value.fdn, 2)} %</Table.Cell>
            <Table.Cell className="right aligned">$ {this.formatear(value.costo, 2)}</Table.Cell>
            <Table.Cell className="center aligned">{activo}</Table.Cell>
            <Table.Cell><DietaEdit db={this.db} perfil={this.perfil} id={value.id} dieta={value} productos={this.productos} /></Table.Cell>
            <Table.Cell><DietaPDF perfil={this.perfil} id={value.id} dieta={value} /></Table.Cell>
          </Table.Row>
        )
      }
    )

    return (
      <Grid>

        <Grid.Row centered>
          <Segment raised>
            <Form.Field >
              <Form.Checkbox label="Auto balancear cantidades al momento de las cargas" toggle checked={balancear} onChange={() => this.activarBalanceo()} />
            </Form.Field>
          </Segment>
        </Grid.Row>

        <Grid.Row centered>
          <Segment raised>
            <Form.Field ><Label>Activar esta opcion implica que al cargarse en el mixer el primer ingrediente,
                el porcentaje de desvio con respecto a lo que sugiere el sistema, se aplicara al resto de los ingredientes</Label>
            </Form.Field>
          </Segment>
        </Grid.Row>

        <Grid.Row centered>

          <Table
            celled
            striped
            compact
            collapsing
            color={"teal"}
            valigned={"center"}
            unstackable={true}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='10'>
                  <span>Dietas </span>
                  <DietaNew db={this.db} perfil={this.perfil} productos={this.productos} />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                <Table.HeaderCell>MS</Table.HeaderCell>
                <Table.HeaderCell>MCAL</Table.HeaderCell>
                <Table.HeaderCell>PROT</Table.HeaderCell>
                <Table.HeaderCell>CALCIO</Table.HeaderCell>
                <Table.HeaderCell>FOSFORO</Table.HeaderCell>
                <Table.HeaderCell>FDN</Table.HeaderCell>
                <Table.HeaderCell>COSTO/KG</Table.HeaderCell>
                <Table.HeaderCell>ACTIVO</Table.HeaderCell>
                <Table.HeaderCell className="center aligned">DETALLE</Table.HeaderCell>
                <Table.HeaderCell className="center aligned">PDF</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {lista}
            </Table.Body>
          </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    productos: state.personalStore.productos,
    empresa: state.personalStore.empresa,
    dietas: state.personalStore.dietas,
    listeners: state.personalStore.listeners,
  };
}

function mapDispatchToProps(dispatch, props) {

  const actions = {
    fetchProductos: bindActionCreators(fetchProductos, dispatch),
    fetchDietas: bindActionCreators(fetchDietas, dispatch),
    initialFetch: bindActionCreators(initialFetch, dispatch),
  };

  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dietas);