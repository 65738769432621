import React, { Component } from 'react';
import { Icon, Modal, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

export default class EliminarAlarmaTratamiento extends Component{

    state={
      modalEliminarAlarma: false
    }
  
    handleChange = (date)=> {
        this.setState({
            nuevaFecha: date
        });
    }

    confirmarEliminarAlarma = () =>{
        this.props.alarma.doc.ref.delete();
        this.setState({ modalEliminarAlarma: false })
    }


    componentWillUpdate() {
        this.fixBody();
      }
      
      componentDidUpdate() {
        this.fixBody();
      }
      
      fixBody() {
        const anotherModal = document.getElementsByClassName('ui page modals').length;
        if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
      }

    render(){
        return ( 
               <Modal 
                    closeOnEscape={true} 
                    closeOnDimmerClick={true} 
                    open={this.state.modalEliminarAlarma}
                    onClose={this.handleCloseTres}
                    trigger={<Button floated='right' onClick={() => this.setState({ modalEliminarAlarma: true })} color='red'>
                              <Icon name='delete' color="white " size="big"/> Borrar
                            </Button>}
                    size={'mini'}
                    >
                    <Modal.Header className="ui center aligned grid">Confirma borrar la alarma de tratamiento</Modal.Header>
                    <Modal.Content>
                      Se eliminara la alarma para este tratamiento programado.
                    </Modal.Content>
                    <Modal.Actions>
                      <Button negative onClick={() => this.setState({ modalEliminarAlarma: false } )} inverted>
                          Cancelar
                      </Button>
                      <Button color='green' onClick={()=> this.confirmarEliminarAlarma() } inverted>
                        <Icon name='checkmark' /> Confirmar
                      </Button>
                    </Modal.Actions>
              </Modal>
            )
        }


}