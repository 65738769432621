import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";

class Sumar extends Component {
  constructor(props) {
    super(props);
    this.perfil = props.perfil;

    this.db = props.db;

    this.id = props.id;

    this.state = {
      materiaSeca: props.rodeo.materiaSeca,
    };

    this.timer = null;
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ materiaSeca: nextProps.rodeo.materiaSeca });
  };

  sumar = () => {
    // this.db
    //   .collection("empresas")
    //   .doc(this.perfil.empresa)
    //   .collection("rodeos")
    //   .doc(this.id)
    //   .delete();

    var nuevoValor = Number(this.state.materiaSeca) + 0.25;

    const porcentaje = nuevoValor / this.props.pesoActual;

    this.props.rodeosAsociados.map((ra) => {
      this.db
        .collection("empresas")
        .doc(this.perfil.empresa)
        .collection("rodeos")
        .doc(ra.id)
        .update({
          materiaSeca: Number(ra.pesoActual * porcentaje),
        });
    });
  };

  addOne = () => {
    this.sumar();
    this.timer = setTimeout(this.addOne, 200);
  };

  stopTimer = () => {
    clearTimeout(this.timer);
  };

  render() {
    return (
      <Button
        style={{ maxWidth: "32px" }}
        positive
        onMouseDown={this.addOne}
        onMouseUp={this.stopTimer}
        onMouseLeave={this.stopTimer}
      >
        <Icon disabled name="plus" />
      </Button>
    );
  }
}

export default Sumar;
