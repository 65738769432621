import React, { Component } from "react";
import {
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Segment,
  Dimmer,
  Label,
  Loader,
} from "semantic-ui-react";

import NumberFormat from "react-number-format";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

class RodeoNew extends Component {
  constructor(props) {
    super(props);

    this.perfil = props.perfil;
    this.corrales = props.corrales || [];
    this.dietas = props.dietas || [];
    this.categorias = props.categorias || [];
    this.db = props.db;

    this.rodeos = props.rodeos;

    this.empresa = props.empresa;

    this.error = [];

    this.optionsCorrales = [];
    this.optionsDietas = [];
    this.optionsCategorias = [];

    if (this.corrales.length > 0) {
      let corrales = this.corrales.sort(function (corralA, corralB) {
        if (!corralA) return -1;
        if (!corralB) return 1;

        let numA = isNaN(parseInt(corralA.nombre))
          ? 0
          : parseInt(corralA.nombre);
        let numB = isNaN(parseInt(corralB.nombre))
          ? 0
          : parseInt(corralB.nombre);

        if (numA > numB && numB > 0) {
          return 1;
        }

        if (numA < numB && numA > 0) {
          return -1;
        }

        if (corralA.nombre > corralB.nombre) {
          return 1;
        }

        if (corralA.nombre < corralB.nombre) {
          return -1;
        }

        return 0;
      });

      this.optionsCorrales = corrales.map((value) => {
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
      });

      this.optionsCorrales = this.optionsCorrales.filter((element) => {
        if (!element.activo) return false;
        return true;
      });
    }

    if (this.dietas.length > 0) {
      this.optionsDietas = this.dietas.map((value) => {
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
      });

      this.optionsDietas = this.optionsDietas.filter((element) => {
        if (!element.activo) return false;
        return true;
      });
    }

    if (this.categorias.length > 0) {
      this.optionsCategorias = this.categorias.map((value) => {
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
      });

      this.optionsCategorias = this.optionsCategorias.filter((element) => {
        if (!element.activo) return false;
        return true;
      });
    }

    this.state = {
      cambioTurno: 8,
      porcentajeTurno: 1,
      modalOpen: false,
      precio: 0,
      hoteleria: 0,
      kilos: 0,
      error: "",
      alerta: false,
      nombre: "",
      propietario: "",
      corral: "",
      categoria: "",
      dieta: "",
      cabezas: 0,
      desbaste: 0,
      aumentoDiario: 0,
      materiaSeca: 0,
      pesoIngreso: 0,
      fechaAlta: new Date(),
      ultimaDescarga: new Date().getTime() - 86400000,
      primerDescarga: new Date().getTime() - 86400000,
      activo: true,
      cargando: false,
      seguir: this.empresa.rodeos === true ? true : false,

      metaKilos: 0,
      metaDiasEncierre: 0,
      consumo_estimado: 0,
      vender: true,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.rodeos = nextProps.rodeos;

    this.empresa = nextProps.empresa;

    if (nextProps.corrales) {
      if (nextProps.corrales.length > 0) {
        let corrales = nextProps.corrales.sort(function (corralA, corralB) {
          if (!corralA) return -1;
          if (!corralB) return 1;

          let numA = isNaN(parseInt(corralA.nombre))
            ? 0
            : parseInt(corralA.nombre);
          let numB = isNaN(parseInt(corralB.nombre))
            ? 0
            : parseInt(corralB.nombre);

          if (numA > numB && numB > 0) {
            return 1;
          }

          if (numA < numB && numA > 0) {
            return -1;
          }

          if (corralA.nombre > corralB.nombre) {
            return 1;
          }

          if (corralA.nombre < corralB.nombre) {
            return -1;
          }

          return 0;
        });

        this.optionsCorrales = corrales.map((value) => {
          var rObj = {};
          rObj["activo"] = value.activo;
          rObj["key"] = value.id;
          rObj["text"] = value.nombre;
          rObj["value"] = value.id;
          return rObj;
        });

        this.optionsCorrales = this.optionsCorrales.filter((element) => {
          if (!element.activo) return false;
          return true;
        });
      }
    }

    if (nextProps.dietas) {
      if (nextProps.dietas.length > 0) {
        this.optionsDietas = nextProps.dietas.map((value) => {
          var rObj = {};
          rObj["activo"] = value.activo;
          rObj["key"] = value.id;
          rObj["text"] = value.nombre;
          rObj["value"] = value.id;
          return rObj;
        });

        this.optionsDietas = this.optionsDietas.filter((element) => {
          if (!element.activo) return false;
          return true;
        });
      }
    }

    if (nextProps.categorias) {
      if (nextProps.categorias.length > 0) {
        this.optionsCategorias = nextProps.categorias.map((value) => {
          var rObj = {};
          rObj["activo"] = value.activo;
          rObj["key"] = value.id;
          rObj["text"] = value.nombre;
          rObj["value"] = value.id;
          return rObj;
        });

        this.optionsCategorias = this.optionsCategorias.filter((element) => {
          if (!element.activo) return false;
          return true;
        });
      }
    }
  };

  save = async () => {
    var _this = this;

    this.error = [];

    if (
      this.rodeos.find(
        (r) => r.nombre.toLowerCase() === this.state.nombre.toLowerCase()
      )
    )
      this.error.push("El nombre del rodeo ya existe!.");

    if (this.state.nombre.trim().length === 0)
      this.error.push("El nombre es obligatorio.");
    if (this.state.categoria.trim().length === 0)
      this.error.push("La categoria es obligatoria.");
    if (this.state.dieta.trim().length === 0)
      this.error.push("La dieta es obligatoria.");
    if (this.state.corral.trim().length === 0)
      this.error.push("El corral es obligatorio.");
    if (this.state.cabezas < 1)
      this.error.push("La cantidad de cabezas debe ser mayor a cero.");
    if (this.state.materiaSeca < 0.25)
      this.error.push("La materia seca por cabeza debe ser mayor a cero.");

    if (this.state.seguir && this.empresa.rodeos) {
      if (this.state.kilos < 1)
        this.error.push("Debe ingresar el peso por animal.");
      //if(this.state.precio < 1) this.error.push('Debe ingresar el costo por kilo.');
    }

    if (this.error.length === 0) {
      this.setState({ cargando: true });

      let dieta = this.state.dieta;

      let rprevio = this.rodeos.find(
        (r) => r.corral === this.state.corral && r.id !== this.id && r.activo
      );

      if (rprevio !== undefined && rprevio !== null) {
        dieta = rprevio.dieta;
      }

      const batch = this.db.batch();

      var newRodeo = this.db
        .collection("empresas")
        .doc(this.perfil.empresa)
        .collection("rodeos")
        .doc();

      batch.set(newRodeo, {
        cambioTurno: Number(this.state.cambioTurno) || 1,
        porcentajeTurno: Number(this.state.porcentajeTurno) || 1,
        nombre: this.state.nombre,
        propietario: this.state.propietario,
        categoria: this.state.categoria,
        dieta: dieta,
        corral: this.state.corral,
        cabezas: Number(this.state.cabezas),
        activo: this.state.activo,
        gdpv: Number(this.state.aumentoDiario),
        materiaSeca: Number(this.state.materiaSeca),
        fechaAlta: new Date(),
        ultimaDescarga: new Date().getTime() - 86400000,
        primerDescarga: new Date().getTime() - 86400000,
        ultimaPesada: Number(
          this.formatear(this.state.kilos * (1 - this.state.desbaste / 100))
        ),
        hoteleria: Number(this.state.hoteleria),
        ultimaFechaPesada: new Date(),
        ultimoPrecio: Number(this.state.precio),
        idDescarga: null,
        descargado: Number(0),
        remanente: Number(0),
        cpc: Number(0),
        seguir: true,
        cerrado: false,
        metaKilos: Number(this.state.metaKilos),
        metaDiasEncierre: Number(this.state.metaDiasEncierre),
        consumo_estimado: Number(this.state.consumo_estimado),
        vender: this.state.vender,
        incremento: rprevio && rprevio.incremento ? rprevio.incremento : 0,
        decremento: rprevio && rprevio.decremento ? rprevio.decremento : 0,
        cpcAutomatico:
          rprevio && rprevio.cpcAutomatico ? rprevio.cpcAutomatico : false,
      });

      var newMovRodeo = this.db
        .collection("empresas")
        .doc(this.perfil.empresa)
        .collection("movrodeos")
        .doc();

      batch.set(newMovRodeo, {
        fecha: new Date(),
        rodeo: newRodeo.id,
        concepto: "Entrada",
        detalle: "Alta de rodeo",
        cabezas: Number(this.state.cabezas),
        kilos: Number(
          this.formatear(this.state.kilos * (1 - this.state.desbaste / 100))
        ),
        precio: Number(this.state.precio),
        desbaste: Number(this.state.desbaste),
      });

      batch
        .commit()
        .then(function (docRef) {
          _this.setState({
            alerta: false,
            modalOpen: false,
            nombre: "",
            propietario: "",
            activo: true,
            corral: "",
            dieta: "",
            categoria: "",
            aumentoDiario: 0,
            ultimaPesada: 0,
            materiaSeca: 0,
            cabezas: 0,
            hoteleria: 0,
            cargando: false,
            seguir: _this.empresa.rodeos === true ? true : false,
          });
        })
        .catch(function (error) {
          _this.setState({
            alerta: true,
            error: `Error adding document: ${error}`,
            cargando: false,
          });
        });

      // this.setState({
      //   alerta: false,
      //   modalOpen: false,
      //   nombre: "",
      //   propietario: "",
      //   activo: true,
      //   corral: "",
      //   dieta: "",
      //   categoria: "",
      //   aumentoDiario: 0,
      //   ultimaPesada: 0,
      //   materiaSeca: 0,
      //   cabezas: 0,
      //   hoteleria: 0,
      //   cargando: false,
      //   seguir: true
      // });
      this.setState({ cargando: false, modalOpen: false });
    } else {
      this.setState({ alerta: true });
    }
  };

  formatear = (numero, digitos = 0) => {
    return new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: digitos,
      maximumFractionDigits: digitos,
    }).format(numero);
  };
  render() {
    if (this.state.cargando) {
      return (
        <Modal
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={this.state.cargando}
          size={"small"}
        >
          <Modal.Header>Espere un momento por favor...</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Segment>
                <Dimmer active inverted>
                  <Loader inverted>Loading {this.state.tiempo}</Loader>
                </Dimmer>
              </Segment>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
    }

    return (
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={"small"}
        trigger={
          <Button
            floated="right"
            onClick={(e, { value }) => this.setState({ modalOpen: true })}
            color="google plus"
          >
            <Icon name="plus" /> Nueva Tropa
          </Button>
        }
      >
        <Modal.Header>Nueva Tropa</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    label="Nombre"
                    id="nombre"
                    type="text"
                    placeholder="Nombre de la tropa"
                    value={this.state.nombre}
                    onChange={(e, { value }) =>
                      this.setState({ nombre: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="Propietario"
                    id="propietario"
                    type="text"
                    placeholder="Propietario"
                    value={this.state.propietario}
                    onChange={(e, { value }) =>
                      this.setState({ propietario: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Select
                    label="Corral"
                    options={this.optionsCorrales}
                    placeholder="Seleccionar Corral"
                    value={this.state.corral}
                    onChange={(e, { value }) =>
                      this.setState({ corral: value })
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Select
                    label="Categoria"
                    options={this.optionsCategorias}
                    placeholder="Seleccionar Categoria"
                    value={this.state.categoria}
                    onChange={(e, { value }) =>
                      this.setState({ categoria: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Select
                    label="Dieta"
                    options={this.optionsDietas}
                    placeholder="Seleccionar Dieta"
                    value={this.state.dieta}
                    onChange={(e, { value }) => this.setState({ dieta: value })}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    label="Cabezas"
                    id="cabezas"
                    type="number"
                    step="1"
                    min="0"
                    placeholder="Cabezas"
                    onChange={(e, { value }) =>
                      this.setState({ cabezas: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="Kg de materia seca"
                    id="materia"
                    type="number"
                    step="0.25"
                    min="0"
                    placeholder="Kg de materia seca"
                    onChange={(e, { value }) =>
                      this.setState({ materiaSeca: value })
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Form.Input
                      label={`Kg de entrada por cabezas`}
                      id="cabezas"
                      type="number"
                      step="1"
                      min="0"
                      placeholder="Kg por Cabeza"
                      onChange={(e, { value }) =>
                        this.setState({ kilos: value })
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      label="Desbaste %"
                      id="kilos"
                      type="number"
                      min="0"
                      max="100"
                      placeholder="Desbaste"
                      value={this.state.desbaste || 0}
                      onChange={(e, { value }) =>
                        this.setState({ desbaste: Number(value) })
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      readonly
                      label="Total"
                      id="total"
                      type="text"
                      placeholder="-"
                      value={`${this.formatear(
                        this.state.kilos * (1 - this.state.desbaste / 100)
                      )} Kg`}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <Form.Input
                      label="Aumento estimado"
                      id="gdpv"
                      type="number"
                      step="1"
                      min="0"
                      placeholder="Aumento estimado por cabeza"
                      onChange={(e, { value }) =>
                        this.setState({ aumentoDiario: value })
                      }
                    />
                  </Form.Field>
                </Form.Group>
                {/* <Label color={'blue'}>Costo total: $ {this.state.kilos * this.state.cabezas * this.state.precio}</Label> */}
              </Form>

              <Form>
                <Segment raised>
                  <Label size="medium" color="blue" ribbon>
                    Dato adicional: Objetivo para estimar fecha de finalizacion
                    de la tropa
                  </Label>
                  <Form.Group>
                    <Form.Field>
                      <Label color="red" ribbon>
                        Peso Objetivo:
                      </Label>
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        id="PesoObjetivo"
                        type="number"
                        step="0.25"
                        min="0"
                        placeholder="%"
                        value={this.state.metaKilos}
                        onChange={(e, { value }) =>
                          this.setState({ metaKilos: Number(value) })
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  {/* <Form.Group>
                      <Form.Field>
                        <Label color='red' ribbon>Dias de Encierre:</Label>
                      </Form.Field>
                      <Form.Field>
                        <Form.Input id="diasobjetivoencierre" type="number" step="1" min="0" placeholder="0" value={this.state.metaDiasEncierre} onChange={(e, { value }) => this.setState({ metaDiasEncierre: Number(value) })} />
                      </Form.Field>
                    </Form.Group> */}
                </Segment>
              </Form>

              <Form>
                <Segment>
                  <span gutterBottom variant="h6">
                    Configurar turnos
                  </span>
                </Segment>

                <div>
                  <span id="discrete-slider" gutterBottom>
                    Visualizar en la mañana: {this.state.porcentajeTurno * 100}%
                  </span>
                  <Slider
                    value={this.state.porcentajeTurno * 100}
                    onChange={(val) =>
                      this.setState({ porcentajeTurno: val / 100 })
                    }
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={0}
                    max={100}
                  />
                  {this.state.dieta !== null && this.state.dieta.length > 0 && (
                    <span id="discrete-slider-talcual" gutterBottom>
                      <NumberFormat
                        value={Math.round(
                          (this.dietas.find((d) => d.id === this.state.dieta)
                            ? this.dietas.find((d) => d.id === this.state.dieta)
                                .talcual
                            : 1) *
                            this.state.cabezas *
                            this.state.materiaSeca *
                            this.state.porcentajeTurno
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" kg"}
                      />
                    </span>
                  )}
                </div>
                <Form.Field>
                  <Form.Select
                    label="Mostrar 100% a partir de la hora:"
                    options={[
                      8,
                      9,
                      10,
                      11,
                      12,
                      13,
                      14,
                      15,
                      16,
                      17,
                      18,
                      19,
                      20,
                      21,
                      22,
                    ].map((o) => ({ key: o, text: o, value: o }))}
                    placeholder="Seleccionar hora"
                    value={this.state.cambioTurno}
                    onChange={(e, { value }) => {
                      this.setState({ cambioTurno: value });
                    }}
                  />
                </Form.Field>
              </Form>

              <Segment raised>
                <Form.Group>
                  <Form.Field>
                    <Form.Checkbox
                      label="Destino?"
                      toggle
                      checked={this.state.vender}
                      onChange={() =>
                        this.setState({ vender: !this.state.vender })
                      }
                    />
                  </Form.Field>
                  <Label
                    size="big"
                    color={this.state.vender ? "green" : "blue"}
                  >
                    {this.state.vender ? "Venta" : "Recria"}
                  </Label>
                </Form.Group>
              </Segment>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={(e, value) =>
              this.setState({
                modalOpen: false,
                nombre: "",
                activo: true,
                corral: "",
                dieta: "",
                categoria: "",
                aumentoDiario: 0,
                pesoIngreso: 0,
                materiaSeca: 0,
                consumo_estimado: 0,
                cabezas: 0,
              })
            }
            inverted
          >
            Cancelar
          </Button>
          <Button color="green" onClick={this.save} inverted>
            <Icon name="checkmark" /> Guardar
          </Button>
          {this.state.alerta && (
            <Message error header="Verificar Datos" list={this.error} />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RodeoNew;
