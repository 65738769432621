import React, { Component } from "react";
import { Form, Button, Modal, Icon, Message, Segment, Label } from 'semantic-ui-react/dist/commonjs';

import 'react-datepicker/dist/react-datepicker.css';

export default class EditarTropaActiva extends Component {
    constructor(props) {
        super(props);
        this.recria = props.recria || false;
        this.venta = props.venta;

        // this.salida = this.venta.toDate ? this.venta.fecha.toDate() : new Date(this.venta.fecha);

        this.perfil = props.perfil;
        this.db = props.db;

        this.data = this.venta;
        this.state = {
            modalOpen: false,
            gdpv: this.data.gdpv,
            precio: props.venta.precio || 0,
            plazo: props.venta.plazo || 0,
            metaDiasEncierre: this.data.metaDiasEncierre || 0,
            metaKilos: this.data.metaKilos || 0,
            consumo_estimado: this.data.consumo_estimado || 0
        }
    }


    componentWillReceiveProps = (nextProps) => {

        this.venta = nextProps.venta;
        this.recria = nextProps.recria || false;
        this.data = this.venta;

        // this.salida = this.venta.toDate ? this.venta.fecha.toDate() : new Date(this.venta.fecha);


        this.setState({
            metaDiasEncierre: this.data.metaDiasEncierre || 0,
            metaKilos: this.data.metaKilos || 0,
            gdpv: Number(this.data.gdpv) || 0,
            consumo_estimado: this.data.consumo_estimado || 0,
            precio: nextProps.venta.precio || 0,
            plazo: nextProps.venta.plazo || 0
        })
    }


    save = () => {
        this.db.collection("empresas").doc(this.perfil.empresa).collection("rodeos").doc(this.venta.rodeo).update({
            metaDiasEncierre: this.state.metaDiasEncierre,
            metaKilos: this.state.metaKilos,
            gdpv: Number(this.state.gdpv),
            precioventa: this.state.precio,
            consumo_estimado: this.state.consumo_estimado,
            plazo: this.state.plazo
        })

        var rodeoRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('gdpv').doc();
        rodeoRef.set({
            gdpv: this.state.gdpv,
            rodeo: this.venta.rodeo
        })

        this.setState({
            modalOpen: false
        })
    }

    editKilos = (value) => {
        this.setState({ metaKilos: Number(value) })
        // if (this.data.seguir) {
        //     if (this.state.gdpv > 0) {
        //         let diferencia = value - this.data.metaKilos;
        //         diferencia = Math.ceil(diferencia / this.state.gdpv);
        //         console.log(diferencia)
        //         this.salida = new Date(new Date(this.salida).getTime() + (diferencia * 86400000));
        //     }
        // }
    }

    editGdpv = (value) => {
        this.setState({ gdpv: Number(value) })
        // if (this.data.seguir) {
        //     if (value > 0) {
        //         let diferencia = value - this.data.metaKilos;
        //         diferencia = Math.ceil(diferencia / this.state.gdpv);
        //         this.salida = new Date(new Date(this.salida).getTime() + (diferencia * 86400000));
        //     }
        // }
    }
    
    editDias = (value) => {
        this.setState({ metaDiasEncierre: Number(value) })
        // if (this.data.seguir) {
        //     let diferencia = value - this.data.metaDiasEncierre;
        //     diferencia = Math.ceil(diferencia);
        //     this.salida = new Date(new Date(this.salida).getTime() + (diferencia * 86400000));
        // }
    }



    formatoNumero = (valor = 0, decimales = 0) => {
        return new Intl.NumberFormat('de-DE', { maximumFractionDigits: decimales }).format(valor);
    }
    formatear = (num, decimales = 0) => {
        var signo = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
    }

    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }
    render() {
        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size={'small'}
                trigger={<Button floated='center' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='green' >
                    <Icon name='edit' /> Editar
                      </Button>}
            >

                <Modal.Header>Editar Venta Pendiente del Rodeo: {this.data.nombreRodeo}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            {!this.recria &&
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input label="Precio neto x kilo" id="precio" type="number" min="0" step="0.5" placeholder="-" value={this.state.precio} onChange={(e, { value }) => this.setState({ precio: value })} />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.Input label="Plazo de venta (en dias)" id="precio" type="number" min="0" step="1" placeholder="-" value={this.state.plazo} onChange={(e, { value }) => this.setState({ plazo: value })} />
                                    </Form.Field>
                                </Form.Group>
                            }

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Form.Input label="Consumo estimado" id="consumo" type="number" min="0" step="0.25" placeholder="-" value={this.state.consumo_estimado} onChange={(e, { value }) => this.setState({ consumo_estimado: value })} />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input label="Gancia de peso estimada" id="gdpv" type="number" min="0" step="0.1" placeholder="-" value={this.state.gdpv} onChange={(e, { value }) => this.editGdpv(value)} />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Label size='big' color='blue' ribbon>
                                    {`Conversion estimada: ${this.formatoNumero((this.state.gdpv > 0 ? this.state.consumo_estimado / this.state.gdpv : 0), 2)}`}
                                </Label>
                            </Form.Group>

                            <Segment raised>
                                <Label size='medium' color='blue' ribbon>
                                    Objetivo para estimar fecha de finalizacion del rodeo
                                </Label>
                                <Form.Group>
                                    <Form.Field>
                                        <Label color='red' ribbon>Peso Objetivo:</Label>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input id="PesoObjetivo" type="number" step="0.25" min="0" placeholder="%" value={this.state.metaKilos} onChange={(e, { value }) => this.editKilos(value)} />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field>
                                        <Label color='red' ribbon>Dias de Encierre:</Label>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input id="diasobjetivoencierre" type="number" step="1" min="0" placeholder="0" value={this.state.metaDiasEncierre} onChange={(e, { value }) => this.editDias(Number(value))} />
                                    </Form.Field>
                                </Form.Group>
                                {/* <Label color='red' ribbon>Fecha de salida: {this.prettyDate(this.salida)}</Label> */}
                            </Segment>

                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={(e, value) => this.setState({
                        modalOpen: false,
                        precio: this.venta.precio || 0,
                        plazo: this.venta.plazo || 0,
                        consumo_estimado: this.venta.consumo_estimado || 0,
                        gdpv: this.data.gdpv,
                        metaDiasEncierre: this.data.metaDiasEncierre || 0,
                        metaKilos: this.data.metaKilos || 0
                    })} inverted>
                        Cancelar
                </Button>
                    <Button color='green' onClick={this.save} inverted>
                        <Icon name='checkmark' /> Guardar
                </Button>
                    {this.state.alerta &&
                        <Message
                            error
                            header='Verificar Datos'
                        />
                    }
                </Modal.Actions>
            </Modal>
        )
    }
}