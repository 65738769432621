import React, { Component } from "react";
import {
  Label,
  Dropdown,
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import ConfirmarSeguimiento from "./ConfirmarSeguimiento";

import NumberFormat from "react-number-format";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

class RodeoEdit extends Component {
  constructor(props) {
    super(props);

    this.empresa = props.empresa;
    this.rodeo = props.rodeo;

    this.rodeos = props.rodeos;

    this.perfil = props.perfil;
    this.corrales = props.corrales || [];
    this.dietas = props.dietas || [];
    this.categorias = props.categorias || [];
    this.db = props.db;
    this.id = props.id;

    this.error = [];

    this.optionsCorrales = [];
    this.optionsDietas = [];
    this.optionsCategorias = [];

    if (this.corrales.length > 0) {
      this.optionsCorrales = this.corrales.map((value) => {
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
      });

      this.optionsCorrales = this.optionsCorrales.filter((element) => {
        if (!element.activo) return false;
        return true;
      });
    }

    if (this.dietas.length > 0) {
      this.optionsDietas = this.dietas.map((value) => {
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
      });

      this.optionsDietas = this.optionsDietas.filter((element) => {
        if (!element.activo) return false;
        return true;
      });
    }

    if (this.categorias.length > 0) {
      this.optionsCategorias = this.categorias.map((value) => {
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
      });

      this.optionsCategorias = this.optionsCategorias.filter((element) => {
        if (!element.activo) return false;
        return true;
      });
    }

    this.state = {
      modalOpen: false,
      alerta: false,
      aumento: 0,
      cambioTurno: props.rodeo.cambioTurno || 8,
      porcentajeTurno: props.rodeo.porcentajeTurno || 1,
      ultimaPesada: props.rodeo.ultimaPesada,
      nombre: props.rodeo.nombre,
      propietario: props.rodeo.propietario || "",
      corral: props.rodeo.corral,
      categoria: props.rodeo.categoria,
      dieta: props.rodeo.dieta,
      cabezas: props.rodeo.cabezas,
      hoteleria: props.rodeo.hoteleria,
      oldhoteleria: props.rodeo.hoteleria,
      precio: props.rodeo.ultimoPrecio,
      oldprecio: props.rodeo.ultimoPrecio,
      aumentoDiario: props.rodeo.gdpv,
      materiaSeca: props.rodeo.materiaSeca,
      kilos: props.rodeo.ultimaPesada,
      activo: props.rodeo.activo,
      oldnombre: props.rodeo.nombre,
      oldcorral: props.rodeo.corral,
      oldcategoria: props.rodeo.categoria,
      olddieta: props.rodeo.dieta,
      oldcabezas: props.rodeo.cabezas,
      oldaumentoDiario: props.rodeo.gdpv,
      oldmateriaSeca: props.rodeo.materiaSeca,
      oldkilos: props.rodeo.ultimaPesada,
      oldactivo: props.rodeo.activo,
      seguir: props.rodeo.seguir,
      desbaste: 0,
      oldseguir: props.rodeo.seguir,

      vender: props.rodeo.vender || false,

      metaKilos: props.rodeo.metaKilos || 0,
      metaDiasEncierre: props.rodeo.metaDiasEncierre || 0,
      consumo_estimado: props.rodeo.consumo_estimado || 0,

      oldconsumo_estimado: props.rodeo.consumo_estimado || 0,
      oldvender: props.rodeo.vender || false,

      oldmetaKilos: props.rodeo.metaKilos || 0,
      oldmetaDiasEncierre: props.rodeo.metaDiasEncierre || 0,
    };

    this.calcularAumento();
  }

  componentWillReceiveProps = (nextProps) => {
    this.rodeo = nextProps.rodeo;

    this.rodeos = nextProps.rodeos;

    if (nextProps.rodeo) {
      this.setState({
        ultimaPesada: nextProps.rodeo.ultimaPesada,
        kilos: nextProps.rodeo.ultimaPesada,
        precio: nextProps.rodeo.ultimoPrecio,
        oldprecio: nextProps.rodeo.ultimoPrecio,
        hoteleria: nextProps.rodeo.hoteleria,
        oldhoteleria: nextProps.rodeo.hoteleria,
        nombre: nextProps.rodeo.nombre,
        corral: nextProps.rodeo.corral,
        categoria: nextProps.rodeo.categoria,
        dieta: nextProps.rodeo.dieta,
        cabezas: nextProps.rodeo.cabezas,
        aumentoDiario: nextProps.rodeo.gdpv,
        materiaSeca: nextProps.rodeo.materiaSeca,
        activo: nextProps.rodeo.activo,
        oldnombre: nextProps.rodeo.nombre,
        oldcorral: nextProps.rodeo.corral,
        oldcategoria: nextProps.rodeo.categoria,
        olddieta: nextProps.rodeo.dieta,
        oldcabezas: nextProps.rodeo.cabezas,
        oldaumentoDiario: nextProps.rodeo.gdpv,
        oldmateriaSeca: nextProps.rodeo.materiaSeca,
        oldactivo: nextProps.rodeo.activo,
        seguir: nextProps.rodeo.seguir,
        oldseguir: nextProps.rodeo.seguir,

        vender: nextProps.rodeo.vender || false,
        cpcAutomatico: nextProps.rodeo.cpcAutomatico || false,
        incremento: nextProps.rodeo.incremento || 0,
        metaKilos: nextProps.rodeo.metaKilos || 0,
        metaDiasEncierre: nextProps.rodeo.metaDiasEncierre || 0,

        oldvender: nextProps.rodeo.vender || false,
        oldcpcAutomatico: nextProps.rodeo.cpcAutomatico || false,
        oldincremento: nextProps.rodeo.incremento || 0,
        oldmetaKilos: nextProps.rodeo.metaKilos || 0,
        oldmetaDiasEncierre: nextProps.rodeo.metaDiasEncierre || 0,
      });

      this.calcularAumento();
    }

    if (nextProps.corrales) {
      if (nextProps.corrales.length > 0) {
        this.optionsCorrales = nextProps.corrales.map((value) => {
          var rObj = {};
          rObj["activo"] = value.activo;
          rObj["key"] = value.id;
          rObj["text"] = value.nombre;
          rObj["value"] = value.id;
          return rObj;
        });

        this.optionsCorrales = this.optionsCorrales.filter((element) => {
          if (!element.activo) return false;
          return true;
        });
      }
    }

    if (nextProps.dietas) {
      if (nextProps.dietas.length > 0) {
        this.optionsDietas = nextProps.dietas.map((value) => {
          var rObj = {};
          rObj["activo"] = value.activo;
          rObj["key"] = value.id;
          rObj["text"] = value.nombre;
          rObj["value"] = value.id;
          return rObj;
        });

        this.optionsDietas = this.optionsDietas.filter((element) => {
          if (!element.activo) return false;
          return true;
        });
      }
    }

    if (nextProps.categorias) {
      if (nextProps.categorias.length > 0) {
        this.optionsCategorias = nextProps.categorias.map((value) => {
          var rObj = {};
          rObj["activo"] = value.activo;
          rObj["key"] = value.id;
          rObj["text"] = value.nombre;
          rObj["value"] = value.id;
          return rObj;
        });

        this.optionsCategorias = this.optionsCategorias.filter((element) => {
          if (!element.activo) return false;
          return true;
        });
      }
    }
  };

  calcularAumento = () => {
    var f1 = new Date();
    f1.setHours(0);
    f1.setMinutes(0);
    f1.setSeconds(0);
    f1.setMilliseconds(0);

    var f2 =
      this.rodeo.ultimaFechaCalculada instanceof Object
        ? this.rodeo.ultimaFechaCalculada.toDate()
        : new Date(this.rodeo.ultimaFechaCalculada); //new Date(this.rodeo.ultimaFechaCalculada);
    f2.setHours(0);
    f2.setMinutes(0);
    f2.setSeconds(0);
    f2.setMilliseconds(0);

    const aumento =
      ((f1.getTime() - f2.getTime()) / 86400000) * this.rodeo.gdpv;

    //const pesoActual = (Number(this.rodeo.ultimaPesada) ? this.rodeo.ultimaPesada : 0) + Number(aumento);

    this.setState({ aumento });
  };
  save = async () => {
    var _this = this;

    this.error = [];

    let nombre = false;

    this.rodeos.forEach((element) => {
      if (element.nombre === this.state.nombre && this.rodeo.id !== element.id)
        nombre = true;
    });

    if (nombre) this.error.push("El nombre del rodeo ya existe!.");

    if (this.state.nombre.trim().length === 0)
      this.error.push("El nombre es obligatorio.");
    if (this.state.categoria.trim().length === 0)
      this.error.push("La categoria es obligatoria.");
    if (this.state.dieta.trim().length === 0)
      this.error.push("La dieta es obligatoria.");
    if (this.state.corral.trim().length === 0)
      this.error.push("El corral es obligatorio.");
    if (this.state.cabezas === 0)
      this.error.push("La cantidad de cabezas debe ser mayor a cero.");
    if (this.state.materiaSeca === 0)
      this.error.push("La materia seca por cabeza debe ser mayor a cero.");

    if (this.state.seguir && this.empresa.rodeos) {
      if (this.state.aumentoDiario < 0.01)
        this.error.push("Debe ingresar un aumento estimado.");
    }

    if (this.error.length === 0) {
      this.setState({ cargando: true });

      let dieta = this.state.dieta;
      let incremento = this.rodeo.incremento;
      let decremento = this.rodeo.decremento;
      let cpcAutomatico = this.rodeo.cpcAutomatico;

      if (this.state.oldcorral !== this.state.corral) {
        let rprevio = this.rodeos.find(
          (r) => r.corral === this.state.corral && r.id !== this.id
        );
        if (rprevio !== undefined && rprevio !== null) {
          (dieta = rprevio.dieta),
            (incremento =
              rprevio !== undefined
                ? rprevio.incremento
                : this.rodeo.incremento);
          decremento =
            rprevio !== undefined ? rprevio.decremento : this.rodeo.decremento;
          cpcAutomatico =
            rprevio !== undefined
              ? rprevio.cpcAutomatico
              : this.rodeo.cpcAutomatico;
        }
      } else {
        this.rodeos
          .filter(
            (r) => r.id !== this.rodeo.id && r.corral === this.state.corral
          )
          .map((res) =>
            this.db
              .collection("empresas")
              .doc(this.perfil.empresa)
              .collection("rodeos")
              .doc(res.id)
              .update({
                cambioTurno: Number(this.state.cambioTurno) || 1,
                porcentajeTurno: Number(this.state.porcentajeTurno) || 1,
                dieta,
                incremento,
                decremento,
                cpcAutomatico,
              })
          );
      }

      // if (_this.state.seguir && !_this.state.oldseguir) {
      //   var newMovRodeo = _this.db.collection("empresas").doc(_this.perfil.empresa).collection("movrodeos").doc();

      //   newMovRodeo.set({
      //     "fecha": new Date(),
      //     "rodeo": this.rodeo.id,
      //     "concepto": "Inicio Seguimiento",
      //     "detalle": "Pesada",
      //     "cabezas": Number(this.state.cabezas),
      //     "kilos": Number(formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
      //     "precio": Number(this.state.precio),
      //     "desbaste": Number(this.state.desbaste)
      //   });
      // }

      //cuando se modifica el gdpv se guarda el registro de modificacion para actualizar el peso actual.
      if (this.state.aumentoDiario !== this.state.oldaumentoDiario) {
        var rodeoRef = this.db
          .collection("empresas")
          .doc(this.perfil.empresa)
          .collection("gdpv")
          .doc();
        rodeoRef.set({
          gdpv: this.state.aumentoDiario,
          rodeo: this.rodeo.id,
        });
      }

      var rodeoRef = this.db
        .collection("empresas")
        .doc(this.perfil.empresa)
        .collection("rodeos")
        .doc(this.rodeo.id);

      // Set the "capital" field of the city 'DC'
      return rodeoRef
        .update({
          cambioTurno: Number(this.state.cambioTurno) || 1,
          porcentajeTurno: Number(this.state.porcentajeTurno) || 1,
          nombre: this.state.nombre,
          propietario: this.state.propietario,
          categoria: this.state.categoria,
          dieta: dieta,
          corral: this.state.activo !== true ? "" : this.state.corral,
          hoteleria: Number(this.state.hoteleria),
          activo: this.state.activo,
          materiaSeca: Number(this.state.materiaSeca),
          seguir: true,
          gdpv: Number(this.state.aumentoDiario),
          //"ultimaPesada": Number(this.state.kilos),
          ultimoPrecio: Number(this.state.precio),
          metaKilos: Number(this.state.metaKilos),
          metaDiasEncierre: Number(this.state.metaDiasEncierre),
          vender: this.state.vender,
          incremento,
          decremento,
        })
        .then(function () {
          _this.setState({
            alerta: false,
            cargando: false,
            modalOpen: false,
            oldnombre: _this.state.nombre,
            oldactivo: _this.state.activo,
            oldcorral: _this.state.corral,
            olddieta: _this.state.dieta,
            oldcategoria: _this.state.categoria,
            oldaumentoDiario: _this.state.aumentoDiario,
            oldprecio: _this.state.precio,
            oldmateriaSeca: _this.state.materiaSeca,
            oldcabezas: _this.state.cabezas,
            oldhoteleria: _this.state.hoteleria,
            oldseguir: _this.state.seguir,

            oldvender: _this.state.vender,
            oldmetaKilos: _this.state.metaKilos,
            oldmetaDiasEncierre: _this.state.metaDiasEncierre,
          });
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error });
        });
      this.setState({ cargando: false, modalOpen: false });
    } else {
      this.setState({ cargando: false, alerta: true });
    }
  };

  render() {
    const oldSeguir = this.state.oldseguir;

    if (this.state.cargando) {
      return (
        <Modal
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={this.state.cargando}
          size={"small"}
        >
          <Modal.Header>Espere un momento por favor...</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Segment>
                <Dimmer active inverted>
                  <Loader inverted>Loading {this.state.tiempo}</Loader>
                </Dimmer>
              </Segment>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
    }

    return (
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={"small"}
        trigger={
          <Dropdown.Item
            key={"editar"}
            text={"EDITAR"}
            icon="edit"
            onClick={() => this.setState({ modalOpen: true })}
          />
        }
      >
        <Modal.Header>
          Editar Tropa {this.state.seguir ? "  -  En Seguimiento..." : ""}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    label="Nombre"
                    id="nombre"
                    type="text"
                    placeholder="Nombre de la tropa"
                    value={this.state.nombre}
                    onChange={(e, { value }) =>
                      this.setState({ nombre: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="Propietario"
                    id="propietario"
                    type="text"
                    placeholder="Propietario"
                    value={this.state.propietario}
                    onChange={(e, { value }) =>
                      this.setState({ propietario: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Select
                    label="Corral"
                    options={this.optionsCorrales}
                    placeholder="Seleccionar Corral"
                    value={this.state.corral}
                    onChange={(e, { value }) =>
                      this.setState({ corral: value })
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Select
                    label="Categoria"
                    options={this.optionsCategorias}
                    placeholder="Seleccionar Categoria"
                    value={this.state.categoria}
                    onChange={(e, { value }) =>
                      this.setState({ categoria: value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Select
                    label="Dieta"
                    options={this.optionsDietas}
                    placeholder="Seleccionar Dieta"
                    value={this.state.dieta}
                    onChange={(e, { value }) => this.setState({ dieta: value })}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <Form.Input
                  inline
                  label="Kg de materia seca"
                  id="materia"
                  type="number"
                  step="0.25"
                  min="0"
                  placeholder="Kg de materia seca"
                  value={this.state.materiaSeca}
                  onChange={(e, { value }) =>
                    this.setState({ materiaSeca: value })
                  }
                />
              </Form.Field>

              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Form.Input
                      label="Aumento estimado"
                      id="gdpv"
                      type="number"
                      step="1"
                      min="0"
                      placeholder="Aumento estimado por cabeza"
                      value={this.state.aumentoDiario}
                      onChange={(e, { value }) =>
                        this.setState({ aumentoDiario: value })
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>

              <Form>
                <Segment raised>
                  <Label size="medium" color="blue" ribbon>
                    Dato adicional: Objetivo para estimar fecha de finalizacion
                    de la tropa
                  </Label>
                  <Form.Group>
                    <Form.Field>
                      <Label color="red" ribbon>
                        Peso Objetivo:
                      </Label>
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        id="PesoObjetivo"
                        type="number"
                        step="0.25"
                        min="0"
                        placeholder="%"
                        value={this.state.metaKilos}
                        onChange={(e, { value }) =>
                          this.setState({ metaKilos: Number(value) })
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  {/* <Form.Group>
                      <Form.Field>
                        <Label color='red' ribbon>Dias de Encierre:</Label>
                      </Form.Field>
                      <Form.Field>
                        <Form.Input id="diasobjetivoencierre" type="number" step="1" min="0" placeholder="0" value={this.state.metaDiasEncierre} onChange={(e, { value }) => this.setState({ metaDiasEncierre: Number(value) })} />
                      </Form.Field>
                    </Form.Group> */}
                </Segment>
              </Form>

              <Form>
                <Label size="medium" color="green" ribbon>
                  Dato adicional: Dias de encierre para estimar finalizacion del
                  rodeo
                </Label>
                <Form.Group>
                  <Form.Field>
                    <Label color="red" ribbon>
                      Dias de Encierre:
                    </Label>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      id="diasobjetivoencierre"
                      type="number"
                      step="1"
                      min="0"
                      placeholder="0"
                      value={this.state.metaDiasEncierre}
                      onChange={(e, { value }) =>
                        this.setState({ metaDiasEncierre: Number(value) })
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <Label color="red" ribbon>
                      Peso Estimado de salida:
                    </Label>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      id="PesoObjetivo"
                      type="number"
                      step="0.25"
                      min="0"
                      placeholder="%"
                      value={this.state.metaKilos}
                      onChange={(e, { value }) =>
                        this.setState({ metaKilos: Number(value) })
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>

              <Form>
                <Segment>
                  <span gutterBottom variant="h6">
                    Configurar turnos
                  </span>
                </Segment>

                <div>
                  <span id="discrete-slider" gutterBottom>
                    Visualizar en la mañana: {this.state.porcentajeTurno * 100}%
                  </span>
                  <Slider
                    value={this.state.porcentajeTurno * 100}
                    onChange={(val) =>
                      this.setState({ porcentajeTurno: val / 100 })
                    }
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={0}
                    max={100}
                  />
                  {this.state.dieta !== null && this.state.dieta.length > 0 && (
                    <span id="discrete-slider-talcual" gutterBottom>
                      <NumberFormat
                        value={Math.round(
                          (this.dietas.find((d) => d.id === this.state.dieta)
                            ? this.dietas.find((d) => d.id === this.state.dieta)
                                .talcual
                            : 1) *
                            this.state.cabezas *
                            this.state.materiaSeca *
                            this.state.porcentajeTurno
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" kg"}
                      />
                    </span>
                  )}
                </div>
                <Form.Field>
                  <Form.Select
                    label="Mostrar 100% a partir de la hora:"
                    options={[
                      8,
                      9,
                      10,
                      11,
                      12,
                      13,
                      14,
                      15,
                      16,
                      17,
                      18,
                      19,
                      20,
                      21,
                      22,
                    ].map((o) => ({ key: o, text: o, value: o }))}
                    placeholder="Seleccionar hora"
                    value={this.state.cambioTurno}
                    onChange={(e, { value }) => {
                      this.setState({ cambioTurno: value });
                    }}
                  />
                </Form.Field>
              </Form>

              <Segment raised>
                <Form.Group>
                  <Form.Field>
                    <Form.Checkbox
                      label="Destino?"
                      toggle
                      checked={this.state.vender}
                      onChange={() =>
                        this.setState({ vender: !this.state.vender })
                      }
                    />
                  </Form.Field>
                  <Label
                    size="big"
                    color={this.state.vender ? "green" : "blue"}
                  >
                    {this.state.vender ? "Venta" : "Recria"}
                  </Label>
                </Form.Group>
              </Segment>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={(e, value) =>
              this.setState({
                modalOpen: false,
                nombre: this.state.oldnombre,
                activo: this.state.oldactivo,
                corral: this.state.oldcorral,
                dieta: this.state.olddieta,
                categoria: this.state.oldcategoria,
                aumentoDiario: this.state.aumentoDiario,
                materiaSeca: this.state.oldmateriaSeca,
                cabezas: this.state.oldcabezas,
                hoteleria: this.state.oldhoteleria,
                seguir: this.state.oldseguir,
                precio: this.state.oldprecio,

                vender: this.state.oldvender,

                metaKilos: this.state.oldmetaKilos,
                metaDiasEncierre: this.state.oldmetaDiasEncierre,
                consumo_estimado: this.state.oldconsumo_estimado,
              })
            }
            inverted
          >
            Cancelar
          </Button>
          <Button color="green" onClick={this.save} inverted>
            <Icon name="checkmark" /> Guardar
          </Button>
          {this.state.alerta && (
            <Message error header="Verificar Datos" list={this.error} />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RodeoEdit;
