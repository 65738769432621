import React, { Component } from 'react'
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchProductosSanitarios, fetchCategoriasSanitarias } from '../actions';

import ProductoSanitarioNew from '../components/ProductoSanitarioNew';
import ProductoSanitarioEdit from '../components/ProductoSanitarioEdit';

class Productos extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.db = firebase.firestore();
    
    this.buscarProductosSanitarios = props.actions.fetchProductosSanitarios;
    this.buscarCategoriasSanitarias = props.actions.fetchCategoriasSanitarias;
    this.activarListener = props.actions.initialFetch;

    this.perfil = props.perfil;

    this.state = { 
      productosSanitarios: props.productosSanitarios || [],
      categoriasSanitarias: props.categoriasSanitarias || [],
      listeners: props.listeners || []
    };

  }

  componentWillMount = () => {
    
    
    const itemCat = { id: "categoriasSanitarias" };
    
    var oyenteCat = this.state.listeners.find(function(element) {
      return element.id === "categoriasSanitarias";
    });

    if(oyenteCat === undefined){
        this.activarListener(this.perfil, itemCat);
        this.buscarCategoriasSanitarias(this.perfil);
    }

    const item = { id: "productosSanitarios" };
    
    var oyente = this.state.listeners.find(function(element) {
        return element.id === "productosSanitarios";
      });

    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarProductosSanitarios(this.perfil);
    }

  }
  
  componentWillReceiveProps = (nextProps) => {
    this.setState({
        productosSanitarios: nextProps.productosSanitarios,
        categoriasSanitarias: nextProps.categoriasSanitarias
    });
  }
  
  render() {

    var _this = this;

    this.state.productosSanitarios.sort(function (a, b) {
      
      let catA = _this.state.categoriasSanitarias.find((c)=> c.id === a.categoria);
      if (catA === undefined || catA === null) {catA = {}; catA.nombre = "Sin Categoria";}
      let catB = _this.state.categoriasSanitarias.find((c)=> c.id === b.categoria);
      if (catB === undefined || catB === null) {catB = {}; catB.nombre = "Sin Categoria";}
        if (catA.nombre > catB.nombre) {
            return 1;
        }
        if (catA.nombre < catB.nombre) {
            return -1;
        }
        return 0;
      });

    const lista = this.state.productosSanitarios.map(
            (value) => {
                    let categoria = this.state.categoriasSanitarias.find((c)=> c.id === value.categoria);
                    if (categoria === undefined || categoria === null) {categoria = {}; categoria.nombre = "Sin Categoria";}
                    let activo = "No";
                    if (value.activo) activo = "si";
                    return (
                      <Table.Row key={value.id}>
                        <Table.Cell>{categoria.nombre} </Table.Cell>
                        <Table.Cell>{value.nombre} </Table.Cell>
                        <Table.Cell className="right aligned">$ {value.costo}</Table.Cell>
                        <Table.Cell className="center aligned">{value.medida}</Table.Cell>
                        <Table.Cell className="center aligned">{activo}</Table.Cell>
                        <Table.Cell><ProductoSanitarioEdit categorias={this.state.categoriasSanitarias} db={this.db} perfil={this.perfil} id={value.id} producto={value} /></Table.Cell>
                      </Table.Row>   
                    )
                }, this);
          
        
    
    return (
      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='5'>
                    <span>Productos Sanitarios</span>
                    <ProductoSanitarioNew categorias={this.state.categoriasSanitarias} db={this.db} perfil={this.perfil}/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Categoria</Table.HeaderCell>
                  <Table.HeaderCell>Producto</Table.HeaderCell>
                  <Table.HeaderCell>Costo</Table.HeaderCell>
                  <Table.HeaderCell>Medida</Table.HeaderCell>
                  <Table.HeaderCell>Activo</Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    productosSanitarios: state.personalStore.productosSanitarios,
    categoriasSanitarias: state.personalStore.categoriasSanitarias,
    listeners: state.personalStore.listeners,
  };
}

function mapDispatchToProps(dispatch, props) {

  const actions = {
    fetchProductosSanitarios: bindActionCreators(fetchProductosSanitarios, dispatch),
    fetchCategoriasSanitarias: bindActionCreators(fetchCategoriasSanitarias, dispatch),
    initialFetch: bindActionCreators(initialFetch, dispatch),
  };
  
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Productos);