import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAOvHhegzNLlQsl2x-jco2KRgETMv4tGjo",
  authDomain: "control-ganadero-ee861.firebaseapp.com",
  databaseURL: "https://control-ganadero-ee861.firebaseio.com",
  projectId: "control-ganadero-ee861",
  storageBucket: "control-ganadero-ee861.appspot.com",
  messagingSenderId: "254597437620",
};

firebase.initializeApp(firebaseConfig);
// firebase.firestore().collection("saf")
// firebase.firestore().settings({ timestampsInSnapshots: true })
// firebase.firestore().enablePersistence()
/*
firebase.firestore().enablePersistence()
.then(function() {
    // Initialize Cloud Firestore through firebase
    var db = firebase.firestore();
})
.catch(function(err) {
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});
*/
export default firebase;
