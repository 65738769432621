import React, { useState, useEffect } from "react";
import {
  Grid,
  Dropdown,
  Button,
  Modal,
  Segment,
  Form,
  Label,
  List,
  Icon,
} from "semantic-ui-react";
import Calendar from "react-calendar";

import moment from "moment";

const AutomatismosProgramados = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [incremento, setIncremento] = useState(0);
  const [decremento, setDecremento] = useState(0);
  const [fecha, setFecha] = useState(new Date());

  const [programas, setProgramas] = useState([]);

  const buscarProgramas = () => {
    props.db
      .collection("empresas")
      .doc(props.perfil.empresa)
      .collection("automatismos_programados")
      .where("rodeo", "==", props.rodeo.id)
      .orderBy("fecha")
      .onSnapshot((snaps) => {
        let collection = [];
        snaps.docs.map((d) => {
          let obj = { ...d.data(), id: d.id };
          console.log(obj);
          collection.push(obj);
        });
        setProgramas(collection);
      });
  };

  useEffect(() => {
    buscarProgramas();
  }, [props.rodeo.id, modalOpen]);

  const agregar = () => {
    if (!isNaN(incremento) && !isNaN(decremento)) {
      console.log("guardando");

      props.rodeos
        .filter((r) => r.corral === props.rodeo.corral)
        .map((ref) => {
          props.db
            .collection("empresas")
            .doc(props.perfil.empresa)
            .collection("automatismos_programados")
            .add({
              rodeo: ref.id,
              incremento: incremento,
              decremento: decremento,
              fecha: fecha,
            });
        });
    }
  };
  const borrar = (id) => {
    props.db
      .collection("empresas")
      .doc(props.perfil.empresa)
      .collection("automatismos_programados")
      .doc(id)
      .delete();
  };

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      size={"large"}
      trigger={
        <Dropdown.Item
          key={"automatismos-automaticas"}
          text={"PROGRAMAR AUTOMATISMOS"}
          icon="percent"
          onClick={() => setModalOpen(true)}
        />
      }
    >
      <Modal.Header>
        Programar cambios de porcentajes de automatismos de la tropa:{" "}
        {props.rodeo.nombre}
      </Modal.Header>
      <Modal.Content>
        <Grid centered column={2} divided>
          <Grid.Row centered>
            <Grid.Column centered width={8}>
              <Grid.Row centered textAlign="center">
                <Segment raised>
                  <Grid style={{ margin: 4 }}>
                    <Grid.Row centered textAlign="center">
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "1.4em",
                          fontWeight: "bold",
                        }}
                      >
                        Seleccionar Fecha
                      </span>
                    </Grid.Row>
                  </Grid>

                  <Grid style={{ margin: 4 }}>
                    <Grid.Row centered textAlign="center">
                      <Calendar
                        className="shadow"
                        onChange={(f) => setFecha(f)}
                        value={fecha}
                        minDate={new Date()}
                      />
                    </Grid.Row>
                  </Grid>

                  <Grid style={{ margin: 4 }}>
                    <Grid.Row centered textAlign="center">
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "1.4em",
                          fontWeight: "bold",
                        }}
                      >
                        Indicar porcentajes
                      </span>
                    </Grid.Row>
                    <Grid.Row centered>
                      <Segment raised>
                        <Form.Group>
                          <Form.Field>
                            <Label size="large" color="red" ribbon>
                              Aumentar consumo con lecturas de remanente 0
                            </Label>
                          </Form.Field>
                          <Form.Field>
                            <Form.Input
                              id="incremento"
                              type="number"
                              step="0.25"
                              min="0"
                              placeholder="%"
                              //   value={incremento}
                              onChange={(e, { value }) =>
                                setIncremento(Number(value))
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field>
                            <Label size="large" color="#866810" ribbon>
                              {`Bajar consumo con lecturas de remanente mayor a ${props.empresa.tolerancia_remanente}%`}
                              :
                            </Label>
                          </Form.Field>
                          <Form.Field>
                            <Form.Input
                              id="decremento"
                              type="number"
                              step="0.25"
                              min="0"
                              placeholder="%"
                              //   value={decremento}
                              onChange={(e, { value }) =>
                                setDecremento(Number(value))
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                      </Segment>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Row>
              <Grid style={{ margin: 10 }}>
                <Grid.Row centered textAlign="center">
                  <Button primary onClick={() => agregar()}>
                    Programar cambio
                  </Button>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column width={8}>
              <Segment raised>
                {programas.map((p) => (
                  <List.Item key={p.id}>
                    <List.Content>
                      <List.Description>
                        <Button icon={"close"} onClick={() => borrar(p.id)} />
                        {moment(p.fecha.toDate()).format("DD-MM-YYYY")}{" "}
                        <Icon name="time" />
                        Incremento: {p.incremento} Decremento: {p.decremento}{" "}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => setModalOpen(false)} inverted>
          SALIR
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AutomatismosProgramados;
