import React, { Component } from "react";
import { Loader, Segment, Dimmer, Image, Grid } from "semantic-ui-react";
import ReactTable from "react-table";

import { connect } from "react-redux";
import { setFilterCargas } from "../actions";

import ListaConsumosCarga from "./ListaConsumosCarga";

import FiltroFechas from "./filtros/ModalFechas";
import ModalMultiple from "./filtros/ModalMultiple";

const CustomLoader = () => (
  <Segment>
    <Dimmer active>
      <Loader>Loading</Loader>
    </Dimmer>
    <Image src="./assets/paragraph.png" />
  </Segment>
);

const formatoNumero = (valor = 0, decimales = 0) => {
  return new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: decimales,
  }).format(valor);
};

const formatoMoneda = (valor) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(valor);
};

const fechaCorta = (date) => {
  var months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  return (
    date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
  );
};

const dia = (date) => {
  var weekday = new Array(7);
  weekday[0] = "Domingo";
  weekday[1] = "Lunes";
  weekday[2] = "Martes";
  weekday[3] = "Miercoles";
  weekday[4] = "Jueves";
  weekday[5] = "Viernes";
  weekday[6] = "Sabado";

  return weekday[date.getDay()];
};

class ListaCargas extends Component {
  constructor(props) {
    super(props);

    this.productos = props.productos;
    this.setFilterCargas = props.setFilterCargas;

    this.filtered = {
      fechaInicio: props.filtrosCargas.fechaInicio,
      fechaFinal: props.filtrosCargas.fechaFinal,
      allDays: props.filtrosCargas.allDays,
      listaDias: props.filtrosCargas.listaDias,
      allDietas: props.filtrosCargas.allDietas,
      listaDietas: props.filtrosCargas.listaDietas,
      filtros: props.filtrosCargas.filtros,
      allMixer: props.filtrosCargas.allMixer,
      listaMixer: props.filtrosCargas.listaMixer,
    };

    this.filtrosFijos = ["dias", "dietas", "mixers"];

    this.diasF = [];
    this.dietasF = [];
    this.mixerF = [];
  }

  componentWillReceiveProps = (nextProps) => {
    this.filtered = {
      fechaInicio: nextProps.filtrosCargas.fechaInicio,
      fechaFinal: nextProps.filtrosCargas.fechaFinal,
      allDays: nextProps.filtrosCargas.allDays,
      listaDias: nextProps.filtrosCargas.listaDias,
      allDietas: nextProps.filtrosCargas.allDietas,
      listaDietas: nextProps.filtrosCargas.listaDietas,
      filtros: nextProps.filtrosCargas.filtros,
      allMixer: nextProps.filtrosCargas.allMixer,
      listaMixer: nextProps.filtrosCargas.listaMixer,
    };

    this.productos = nextProps.productos;
  };

  onChangeFechaInicio = (fechaInicio) => {
    this.filtered.fechaInicio = fechaInicio;
    this.setFilterCargas(this.filtered);
  };
  onChangeFechaFinal = (fechaFinal) => {
    this.filtered.fechaFinal = fechaFinal;
    this.setFilterCargas(this.filtered);
  };

  borrarFechaInicio = () => {
    this.filtered.fechaInicio = null;
    this.setFilterCargas(this.filtered);
  };

  borrarFechaFinal = () => {
    this.filtered.fechaFinal = null;
    this.setFilterCargas(this.filtered);
  };

  onSelectedAllDias = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "dias");
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "dias"
        );
      }
    }

    let dias = [];
    if (!this.filtered.allDays) {
      Object.keys(opciones).map((opcion) => {
        dias.push(opciones[opcion].id);
      });

      this.filtered.listaDias = dias;
      this.filtered.allDays = true;
    } else {
      this.filtered.filtros.push({ nombre: "dias", hora: new Date() });
      this.filtered.allDays = false;
      this.filtered.listaDias = [];
    }
    this.setFilterCargas(this.filtered);
  };

  onSelectedDay = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "dias", hora: new Date() });

    let index = this.filtered.listaDias.indexOf(id);

    if (index > -1) {
      this.filtered.listaDias = this.filtered.listaDias.filter((e) => e !== id);
      this.filtered.allDays = false;
    } else {
      let dias = [];

      if (this.filtered.allDays) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) dias.push(opciones[opcion].id);
        });

        this.filtered.listaDias = dias;
        this.filtered.allDays = false;
      } else {
        this.filtered.listaDias = this.filtered.listaDias.concat(id);
        this.filtered.allDays = false;
      }
    }
    this.setFilterCargas(this.filtered);
  };

  onSelectedAllDietas = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "dietas");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "dietas"
        );
      }
    }

    let dietas = [];
    if (!this.filtered.allDietas) {
      Object.keys(opciones).map((opcion) => {
        dietas.push(opciones[opcion].id);
      });

      this.filtered.listaDietas = dietas;
      this.filtered.allDietas = true;
    } else {
      this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });
      this.filtered.allDietas = false;
      this.filtered.listaDietas = [];
    }
    this.setFilterCargas(this.filtered);
  };

  onSelectedDieta = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });

    let index = this.filtered.listaDietas.indexOf(id);

    if (index > -1) {
      this.filtered.listaDietas = this.filtered.listaDietas.filter(
        (e) => e !== id
      );
      this.filtered.allDietas = false;
    } else {
      let dietas = [];

      if (this.filtered.allDietas) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) dietas.push(opciones[opcion].id);
        });

        this.filtered.listaDietas = dietas;
        this.filtered.allDietas = false;
      } else {
        this.filtered.listaDietas = this.filtered.listaDietas.concat(id);
        this.filtered.allDietas = false;
      }
    }
    this.setFilterCargas(this.filtered);
  };

  aplicarFiltroDias = (listaDescarga) => {
    this.diasF = [];
    var weekday = new Array(7);
    weekday[0] = "Domingo";
    weekday[1] = "Lunes";
    weekday[2] = "Martes";
    weekday[3] = "Miercoles";
    weekday[4] = "Jueves";
    weekday[5] = "Viernes";
    weekday[6] = "Sabado";

    //filtrando por dia
    listaDescarga = listaDescarga.filter((d) => {
      var fecha =
        d[1].fecha instanceof Object
          ? d[1].fecha.toDate()
          : new Date(d[1].fecha); //new Date(d.fecha);

      let day = {};
      day.id = fecha.getDay(); //new Date(d.fecha).getDay();
      day.label = weekday[day.id];
      this.diasF[day.id] = day;

      let bandera = true;
      if (!this.filtered.allDays) {
        let index = this.filtered.listaDias.indexOf(fecha.getDay());
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  aplicarFiltroDietas = (listaDescarga) => {
    this.dietasF = [];
    //filtrando por dietas
    let lista = listaDescarga.filter((d) => {
      let dieta = {};
      dieta.id = d[1].dieta;
      dieta.label = this.props.dietas[d[1].dieta]
        ? this.props.dietas[d[1].dieta].nombre
        : "";

      this.dietasF[dieta.id] = dieta;

      let bandera = true;
      if (!this.filtered.allDietas) {
        let index = this.filtered.listaDietas.indexOf(d[1].dieta);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };

  onSelectedAllMixer = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "mixers");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "mixers"
        );
      }
    }

    let mixers = [];
    if (!this.filtered.allMixer) {
      Object.keys(opciones).map((opcion) => {
        mixers.push(opciones[opcion].id);
      });

      this.filtered.listaMixer = mixers;
      this.filtered.allMixer = true;
    } else {
      this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });
      this.filtered.allMixer = false;
      this.filtered.listaMixer = [];
    }
    this.setFilterCargas(this.filtered);
  };

  onSelectedMixer = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });

    let index = this.filtered.listaMixer.indexOf(id);

    if (index > -1) {
      this.filtered.listaMixer = this.filtered.listaMixer.filter(
        (e) => e !== id
      );
      this.filtered.allMixer = false;
    } else {
      let mixers = [];

      if (this.filtered.allMixer) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) mixers.push(opciones[opcion].id);
        });

        this.filtered.listaMixer = mixers;
        this.filtered.allMixer = false;
      } else {
        this.filtered.listaMixer = this.filtered.listaMixer.concat(id);
        this.filtered.allMixer = false;
      }
    }
    this.setFilterCargas(this.filtered);
  };

  aplicarFiltroMixers = (listaDescarga) => {
    this.mixerF = [];
    //filtrando por categorias
    listaDescarga = listaDescarga.filter((d) => {
      const usuario = this.props.usuarios[d[1].usuario];
      let mixer = {};

      mixer.id = usuario ? usuario.mixer : "-";
      mixer.label = this.props.mixers[usuario.mixer]
        ? this.props.mixers[usuario.mixer].nombre
        : "-";

      this.mixerF[mixer.id] = mixer;

      let bandera = true;
      if (!this.filtered.allMixer) {
        let index = this.filtered.listaMixer.indexOf(mixer ? mixer.id : "-");
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };
  render() {
    let listaCargas = Object.entries(this.props.cargas).sort(function (a, b) {
      const fa = a[1].fecha.toDate ? a[1].fecha.toDate() : new Date(a[1].fecha);
      const fb = b[1].fecha.toDate ? b[1].fecha.toDate() : new Date(b[1].fecha);
      if (fa > fb) return -1;
      if (fa < fb) return 1;
      return 0;
    });

    listaCargas = listaCargas.filter((d) => {
      var fecha = d[1].fecha.toDate
        ? d[1].fecha.toDate()
        : new Date(d[1].fecha); //new Date(d.fecha);

      let banderaFechaInicio = true;
      if (this.filtered.fechaInicio !== null) {
        var fechaInicio = new Date(this.filtered.fechaInicio);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filtered.fechaFinal !== null) {
        var fechaFin = new Date(this.filtered.fechaFinal);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    let listaFiltros = this.filtered.filtros.reduce((filtro, item) => {
      let val = item.nombre;
      filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
      return filtro;
    }, {});

    listaFiltros = Object.keys(listaFiltros).sort((a, b) => {
      if (listaFiltros[a].hora > listaFiltros[b].hora) {
        return 1;
      }

      if (listaFiltros[a].hora < listaFiltros[b].hora) {
        return -1;
      }
      return 0;
    });

    this.filtrosFijos.forEach((fijo) => {
      let index = listaFiltros.indexOf(fijo);
      if (index < 0) listaFiltros.push(fijo);
    });

    var _this = this;
    listaFiltros.forEach((f) => {
      if (f === "dietas") listaCargas = _this.aplicarFiltroDietas(listaCargas);
      if (f === "mixers") listaCargas = _this.aplicarFiltroMixers(listaCargas);
      if (f === "dias") listaCargas = _this.aplicarFiltroDias(listaCargas);
    });

    let ingredientes = [];

    // listaCargas.forEach((d) => {
    //   ingredientes = ingredientes.concat(d[1].ingredientes);
    // });

    listaCargas.forEach((d) => {
      ingredientes = ingredientes.concat(
        d[1].ingredientes.map((ing) => ({
          ...ing,
          totalcarga: d[1].totalcarga,
        }))
      );
    });
    console.log(ingredientes);

    const listaIngredientes = ingredientes.reduce((ingrediente, item) => {
      let val = item.producto;
      ingrediente[val] = ingrediente[val] || {
        kilos: 0,
        costo: 0,
        materiaseca: 0,
        sugerido: 0,
      };
      ingrediente[val].materiaseca += item.materiaseca;
      let sugerido = 0;

      if (item.sugerido && item.totalcarga) {
        sugerido = item.totalcarga * (item.sugerido / 100); //totalcarga es total en ms
      } else {
        sugerido = item.kilos;
      }
      ingrediente[val].sugerido += sugerido;
      ingrediente[val].kilos += item.kilos;
      ingrediente[val].costo +=
        item.costo !== null && item.costo !== undefined ? item.costo : 0;
      return ingrediente;
    }, {});

    let totalCarga = 0;
    let totalCosto = 0;

    listaCargas.map((d, i) => {
      totalCarga += Number(d[1].totalcarga + d[1].agua);
      totalCosto += Number(d[1].costo);
    });

    const columns = [
      {
        id: "dia",
        Header: "Dia",
        accessor: (d) =>
          dia(d[1].fecha.toDate ? d[1].fecha.toDate() : new Date(d[1].fecha)), // String-based value accessors!
        maxWidth: 70,
        Filter: (
          <ModalMultiple
            opciones={this.diasF}
            label={"Dia"}
            onSelected={this.onSelectedDay}
            onSelectedAll={this.onSelectedAllDias}
            todo={this.filtered.allDays}
            seleccion={this.filtered.listaDias}
          />
        ),
      },
      {
        id: "fecha",
        Header: "Fecha",
        accessor: (d) =>
          d[1].fecha.toDate ? d[1].fecha.toDate() : new Date(d[1].fecha),
        Cell: (props) => <span>{fechaCorta(props.value)}</span>, // Custom cell components!
        maxWidth: 100,
        Filter: (
          <FiltroFechas
            borrarFechaInicio={this.borrarFechaInicio}
            borrarFechaFinal={this.borrarFechaFinal}
            fechaInicio={this.filtered.fechaInicio}
            fechaFinal={this.filtered.fechaFinal}
            onChangeFechaInicio={this.onChangeFechaInicio}
            onChangeFechaFinal={this.onChangeFechaFinal}
          />
        ),
      },
      {
        id: "mixer",
        Header: "Mixer",
        accessor: (d) => this.props.usuarios[d[1].usuario].mixer, // Custom value accessors!
        Cell: (props) => (
          <span>
            {this.props.mixers[props.value]
              ? this.props.mixers[props.value].nombre
              : ""}
          </span>
        ),
        maxWidth: 100,
        Filter: (
          <ModalMultiple
            opciones={this.mixerF}
            label={"Mixer"}
            onSelected={this.onSelectedMixer}
            onSelectedAll={this.onSelectedAllMixer}
            todo={this.filtered.allMixer}
            seleccion={this.filtered.listaMixer}
          />
        ),
      },
      {
        id: "dieta",
        Header: "Dieta",
        accessor: (d) => d[1].dieta,
        Cell: (props) => (
          <span>
            {this.props.dietas[props.value]
              ? this.props.dietas[props.value].nombre
              : ""}
          </span>
        ),
        maxWidth: 140,
        Filter: (
          <ModalMultiple
            opciones={this.dietasF}
            label={"Dieta"}
            onSelected={this.onSelectedDieta}
            onSelectedAll={this.onSelectedAllDietas}
            todo={this.filtered.allDietas}
            seleccion={this.filtered.listaDietas}
          />
        ),
      },
      {
        id: "kilos",
        Header: "Cargado",
        accessor: (d) => d[1].totalcarga + d[1].agua,
        Cell: (props) => <span>{formatoNumero(props.value)} kg</span>,
        maxWidth: 200,
        style: {
          textAlign: "right",
        },
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {formatoNumero(totalCarga)}
          </span>
        ),
      },
      //   {
      //     id: "costo",
      //     Header: "Met",
      //     accessor: (d) => d[1].costo,
      //     Cell: (props) => <span>{formatoMoneda(props.value)}</span>,
      //     style: {
      //       textAlign: "right",
      //     },
      //     maxWidth: 200,
      //     Filter: (
      //       <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
      //         {formatoNumero(totalCosto)}
      //       </span>
      //     ),
      //   },
    ];

    return (
      <Grid style={{ padding: 0, margin: 2 }}>
        <Grid.Row centered>
          <ListaConsumosCarga listaIngredientes={listaIngredientes} />
        </Grid.Row>

        <Grid.Row centered>
          <ReactTable
            data={listaCargas}
            noDataText={!this.props.cargando ? "No hay cargas registradas" : ""}
            columns={columns}
            loading={this.props.cargando}
            LoadingComponent={({
              className,
              loading,
              loadingText,
              ...rest
            }) => {
              if (loading)
                return (
                  <div
                    style={{
                      position: "fixed",
                      zIndex: 1000,
                      margin: "5% auto",
                      left: 0,
                      right: 0,
                    }}
                  >
                    <CustomLoader />
                  </div>
                );
              return <div></div>;
            }}
            defaultPageSize={50}
            showPaginationTop
            showPaginationBottom
            filterable
            className="-striped -highlight"
            previousText="Previo"
            nextText="Siguiente"
            loadingText="Cargando"
            pageText="Pagina"
            ofText="de"
            rowsText="Filas"
            getTdProps={(state, rowInfo, column) => {
              return {
                style: {
                  backgroundColor: "white",
                },
              };
            }}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

function mapStateToProps(state, props) {
  return {
    filtrosCargas: state.personalStore.filtrosCargas,
  };
}

export default connect(mapStateToProps, { setFilterCargas })(ListaCargas);
