import React from "react";
import PropTypes from "prop-types";
import { Menu, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import Login from "./Login";
import NombreEmpresa from "./NombreEmpresa";

import { fetchEmpresa, initialFetch } from "../actions";
import { connect } from "react-redux";

class Template extends React.Component {
  constructor(props) {
    super(props);

    this.perfil = props.perfil;
    this.fechaCargaInicio = props.fechaCargaInicio;
    this.fetchEmpresa = props.fetchEmpresa;
    this.initialFetch = props.initialFetch;

    this.state = {
      empresa: props.empresa,
      listeners: props.listeners || [],
      activeItem: "rodeos",
    };
  }

  componentWillMount = () => {
    const item = { id: "empresa" };

    var oyente = this.state.listeners.find(function (element) {
      return element.id === "empresa";
    });

    if (oyente === undefined) {
      this.initialFetch(this.perfil, item);
      this.fetchEmpresa(this.perfil);
    }
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  componentWillReceiveProps = (nextProps) => {
    let { perfil } = nextProps;

    if (perfil) {
      this.perfil = perfil;
    }

    if (perfil == null) {
      this.perfil = null;
    }

    this.setState({
      empresa: nextProps.empresa,
      listeners: nextProps.listeners || [],
    });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <div>
        <Menu
          stackable
          tabular={"right"}
          color={"teal"}
          inverted
          size={"huge"}
          style={{ marginBottom: 0 }}
        >
          {this.perfil === undefined ||
            this.perfil === null ||
            (this.perfil.rol !== "admin" && (
              <Menu.Item>
                <img
                  className="logo"
                  src="/assets/logo.png"
                  width="auto"
                  alt="Mis Corrales"
                />
              </Menu.Item>
            ))}
          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item>
              <img
                className="logo"
                src="/assets/logo.png"
                width="auto"
                alt="Mis Corrales"
              />
            </Menu.Item>
          )}

          {this.perfil.isEmpty ||
            (this.perfil.rol !== "admin" && (
              <Menu.Item>
                <span style={{ fontSize: "2em" }}>Mis Corrales </span>
              </Menu.Item>
            ))}

          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item>
              <span style={{ fontSize: "2em" }}>
                Mis Corrales - <NombreEmpresa perfil={this.perfil} /> -{" "}
                {this.perfil.nombre}
              </span>
            </Menu.Item>
          )}

          {this.state.empresa && this.state.empresa.patrocinador && (
            <Menu.Item>
              <a href={`http://${this.state.empresa.url}`} target="_blank">
                <img
                  className="logo"
                  src={this.state.empresa.logo}
                  width="auto"
                  alt="Mis Corrales"
                />
              </a>
            </Menu.Item>
          )}

          {/* reloj con produccion de carne */}

          {/* {!this.perfil.isEmpty && this.perfil.rol === 'admin' &&
             <Tablero perfil={this.perfil} fechaCargaInicio={this.props.fechaCargaInicio} />
          } */}
        </Menu>

        <Menu
          style={{ marginTop: 0, paddingLeft: 20 }}
          stackable
          tabular={"right"}
          color={"teal"}
          inverted
          size={"huge"}
        >
          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/rodeos"
              name="rodeos"
              active={activeItem === "rodeos"}
              onClick={this.handleItemClick}
            >
              Tropas Activas
            </Menu.Item>
          )}

          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/rodeos_cerrados"
              name="rodeos_cerrados"
              active={activeItem === "rodeos_cerrados"}
              onClick={this.handleItemClick}
            >
              Tropas Cerradas
            </Menu.Item>
          )}

          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/descargas"
              name="descargas"
              active={activeItem === "descargas"}
              onClick={this.handleItemClick}
            >
              Descargas
            </Menu.Item>
          )}

          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/cargas"
              name="cargas"
              active={activeItem === "cargas"}
              onClick={this.handleItemClick}
            >
              Cargas
            </Menu.Item>
          )}

          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/seguimiento"
              name="seguimiento"
              active={activeItem === "seguimiento"}
              onClick={this.handleItemClick}
            >
              Seguimiento
            </Menu.Item>
          )}

          {!this.perfil.isEmpty && this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/sanidad"
              name="sanidad"
              active={activeItem === "sanidad"}
              onClick={this.handleItemClick}
            >
              Programa Sanitario
            </Menu.Item>
          )}

          {this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/stock"
              name="stock"
              active={activeItem === "stock"}
              onClick={this.handleItemClick}
            >
              Stock
            </Menu.Item>
          )}
          {/* 
          {!this.perfil.isEmpty && this.perfil.rol === 'admin' &&

            <Menu.Item as={Link}
              to='/clima'
              name='clima'
              active={activeItem === 'clima'}
              onClick={this.handleItemClick}
            >
              Clima
            </Menu.Item>

          } */}

          {this.perfil.rol === "admin" && (
            <Menu.Item
              as={Link}
              to="/reportes"
              name="reportes"
              active={activeItem === "reportes"}
              onClick={this.handleItemClick}
            >
              Reportes
            </Menu.Item>
          )}

          {this.perfil.rol === "admin" && (
            <Menu.Item>
              <a
                href="https://dashboard.miscorrales.com"
                target={"_blank"}
                style={{color: "rgb(181 76 237)", background: "white", padding: "4px"}}
              >
                Dashboard
              </a>
            </Menu.Item>
          )}

          {!this.perfil.isEmpty &&
            this.perfil.rol === "admin" &&
            this.perfil.empresa === "prueba" && (
              <Menu.Item
                as={Link}
                to="/empresas"
                name="empresas"
                active={activeItem === "empresas"}
                onClick={this.handleItemClick}
              >
                Empresas
              </Menu.Item>
            )}

          <div className="right item">
            {!this.perfil.isEmpty && (
              <Menu.Menu position="right">
                {this.perfil.rol === "admin" && (
                  <Menu.Item
                    as={Link}
                    to="/configuracion/dietas"
                    name="configuracion"
                    active={activeItem === "configuracion"}
                    onClick={this.handleItemClick}
                  >
                    Configuracion
                  </Menu.Item>
                )}

                {/* <Menu.Item as={Link}
                  to='/demo'
                  name='demo'
                  active={activeItem === 'demo'}
                  onClick={this.handleItemClick}
                >
                Demo
                </Menu.Item>  */}

                <Menu.Item
                  as={Link}
                  to="/logout"
                  name="logout"
                  active={activeItem === "logout"}
                  onClick={this.handleItemClick}
                >
                  Salir
                </Menu.Item>
              </Menu.Menu>
            )}

            {/* {this.perfil.isEmpty &&
              <Login />
            } */}
          </div>
        </Menu>
      </div>
    );
  }
}

Template.propTypes = {
  children: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    empresa: state.personalStore.empresa,
    listeners: state.personalStore.listeners,
  };
}

export default connect(mapStateToProps, { fetchEmpresa, initialFetch })(
  Template
);
