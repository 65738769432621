import React, { Component } from 'react';

import {Grid, Label, Divider, Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react';

import DetalleDieta from '../containers/DetalleDieta';

class DietaNew extends Component {

  constructor (props) {
    super(props);
    this.perfil = props.perfil;

    this.db = props.db;

    this.productos = props.productos;

    this.state = {
      id: null,
      nombre: "",
      agua: 0,
      activo: true,
      modalOpen: false,
      cargando: false
    };

    this.timer = null;
    
      }
    
      addOne = () => {
        this.setState({agua: Number(this.state.agua + 1)})
        this.timer = setTimeout(this.addOne, 200);
      }
    
      delOne = () => {
        if (this.state.agua - 1 >= 0){
          this.setState({agua: Number(this.state.agua - 1)})
        }
        this.timer = setTimeout(this.delOne, 200);
      }
    
      stopTimer = () => {
        clearTimeout(this.timer);
      }
    
      formatear = (numero, digitos = 0) => {
        return new Intl.NumberFormat('es-ES',{ 
                        minimumFractionDigits: digitos, 
                        maximumFractionDigits: digitos  
                        }).format(numero);
      }

  save = () => {
    if( this.state.nombre.trim().length> 0 ){

      var _this = this;
      this.setState({cargando: true});

      if(this.state.id){

        var dietaRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas').doc(this.state.id);
        
        return dietaRef.update({
          "nombre": this.state.nombre,
          "agua": Number(this.state.agua),
          "activo": this.state.activo
        })
        .then(function() {
            _this.setState({ cargando: false, id: null, modalOpen: false, alerta: false, nombre: "", activo: true});      
        })
        .catch(function(error) {
            // The document probably doesn't exist.
            _this.setState({ cargando: false, alerta: true, error: error})
        })

      }else{

        this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas').add({
          "nombre": this.state.nombre,
          "agua": Number(this.state.agua),
          "activo": this.state.activo,
          "talcual": 0,
          "costo": 0
        })
        .then(function(docRef) {
            _this.setState({ id: docRef.id, cargando: false });
        })
        .catch(function(error) {
            _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
        });

      }
      //this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}

  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'large'}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='google plus'  >
                  <Icon name='plus' /> Agregar
                </Button>}
        >
        <Modal.Header>Nueva Dieta</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre de la Dieta" value={this.state.nombre} onChange={(e, { value }) => { if(value.length < 16 ) this.setState({ nombre: value })} } />
                  </Form.Field>

                  {this.state.id &&
                    <DetalleDieta perfil={this.perfil} agua={this.state.agua} productos={this.productos} id={this.state.id} />
                  }
                  
                  {this.state.id &&
                    <Grid centered style={{marginTop: 15}}>
                    
                        <Grid.Row style={{marginBottom: 0, paddingBottom: 0}}>
                            <Label basic color='blue' pointing='below' size={"big"}>% de Agua</Label>
                        </Grid.Row>
    
                        <Grid.Row style={{marginTop: 0, paddingTop: 0}}>
                          <Button.Group>
                            <Button negative onMouseDown={this.delOne} onMouseUp={this.stopTimer} onMouseLeave={this.stopTimer}><Icon disabled name='minus' /></Button>
                            <Button.Or text={this.formatear(this.state.agua, 2)} />
                            <Button positive onMouseDown={this.addOne} onMouseUp={this.stopTimer} onMouseLeave={this.stopTimer}><Icon disabled name='plus' /></Button>
                          </Button.Group>
                        </Grid.Row>
    
                      </Grid>
                  }
                  <Divider />
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={ () => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, agua: 0, alerta: false, nombre: "", activo: true, id: null })} inverted>
              Cancelar
          </Button>
          
            {this.state.id &&
                <Button color='green' onClick={this.save} inverted>
                    <Icon name='checkmark' /> Guardar 
                </Button>
            }
            {!this.state.id &&
                <Button color='green' onClick={this.save} inverted>
                    <Icon name='checkmark' /> Generar 
                </Button>
            }
            
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Ingrese un nombre valido',
                'El detalle no puede estar vacio'
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default DietaNew;