import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Icon, Dimmer, Loader, Segment, Table, Grid } from 'semantic-ui-react'

import FiltroFechas from './filtros/ModalFechas';

import moment from 'moment'

import Api from './../api'
const api = new Api();


const ENTRADA_AJUSTE_POSTITIVO = "Entrada Ajuste Positivo"
const ENTRADA_POR_AJUSTE = "Entrada por ajuste"
const ENTRADA_POR_COMPRA = "Entrada por compra"
const ENTRADA_POR_COSECHA = "Entrada por cosecha"
const ENTRADA_POR_TRASLADO = "Entrada por traslado"
const SALIDA_AJUSTE_NEGATIVO = "Salida por Ajuste Negativo"
const SALIDA_POR_AJUSTE =  "Salida por ajuste"
const SALIDA_POR_TRASLADO = "Salida por traslado"
const SALIDA_CONSUMOS = "Consumos"


const formatear= (numero, digitos = 0) => {
  return new Intl.NumberFormat('es-ES',{ 
                  minimumFractionDigits: digitos, 
                  maximumFractionDigits: digitos  
                  }).format(numero);
}
class StockEntrePeriodos extends Component {

  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount = () => {
    let inicio = new Date(new Date().getTime() - (2592000000));
    inicio.setHours(0)
    inicio.setMinutes(0)

    api.getStockByDateInicial(this.perfil.empresa, inicio, this.props.dispatch);
    api.getStockByDateFinal(this.perfil.empresa, new Date(), this.props.dispatch);
  }
  

  handleClose = () => this.props.dispatch({
    type: "CLOSE_STOCK_ENTRE_PERIODOS"
  })
  
  handleOpen = () => this.props.dispatch({
    type: "OPEN_STOCK_ENTRE_PERIODOS"
  })

  onChangeFechaInicio = (fechaInicio) => {
    this.props.dispatch({
      type: "SELECT_FECHA_INICIAL",
      payload: fechaInicio
    })
    api.getStockByDateInicial(this.perfil.empresa, fechaInicio, this.props.dispatch);
  }
  onChangeFechaFinal = (fechaFinal) => {
    this.props.dispatch({
      type: "SELECT_FECHA_FINAL",
      payload: fechaFinal
    })
    api.getStockByDateFinal(this.perfil.empresa, fechaFinal, this.props.dispatch);
  }
 
  borrarFechaInicio = () => {
      this.filtered.fechaInicio = null;
      this.props.dispatch({
        type: "SELECT_FECHA_INICIAL",
        payload: moment(new Date())
      })
      api.getStockByDateInicial(this.perfil.empresa, new Date(), this.props.dispatch);
  }

  borrarFechaFinal = () => {
    this.props.dispatch({
      type: "SELECT_FECHA_FINAL",
      payload: moment(new Date())
    })
    api.getStockByDateFinal(this.perfil.empresa, new Date(), this.props.dispatch);
  }

  render () {
    if(this.props.stockPorPeriodos.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.props.stockPorPeriodos.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    
     let listaProductosInicial = []
     let listaProductosFinal = []

     let movimientosInicial
     let movimientosFinal

     if( this.props.stockPorPeriodos.stockInicial && this.props.stockPorPeriodos.stockFinal ){

      movimientosInicial = this.props.stockPorPeriodos.stockInicial.movimientos.reduce((mov, item) => {
          let val = item.concepto + item.producto;
          mov[val] = mov[val] || { entrada: 0, salida: 0 };
          mov[val].concepto = item.concepto;
          mov[val].producto = item.producto;
          mov[val].entrada += item.entrada;
          mov[val].salida += item.salida;
          return mov;
      }, {});
      
      
      movimientosFinal = this.props.stockPorPeriodos.stockFinal.movimientos.reduce((mov, item) => {
          let val = item.concepto + item.producto;
          mov[val] = mov[val] || { entrada: 0, salida: 0 };
          mov[val].concepto = item.concepto;
          mov[val].producto = item.producto;
          mov[val].entrada += item.entrada;
          mov[val].salida += item.salida;
          return mov;
      }, {});
      
      
      listaProductosInicial = this.props.stockPorPeriodos.stockInicial.productos.map(
      (value) => {
          const id = value.id
          const producto = value.nombre
          const stockInicial = value.stock
          let ajustePositivo = movimientosFinal[ENTRADA_POR_AJUSTE+id] ? movimientosFinal[ENTRADA_POR_AJUSTE+id].entrada : 0;
          ajustePositivo += movimientosFinal[ENTRADA_AJUSTE_POSTITIVO+id] ? movimientosFinal[ENTRADA_AJUSTE_POSTITIVO+id].entrada : 0;
          ajustePositivo -= movimientosInicial[ENTRADA_POR_AJUSTE+id] ? movimientosInicial[ENTRADA_POR_AJUSTE+id].entrada : 0;
          ajustePositivo -= movimientosInicial[ENTRADA_AJUSTE_POSTITIVO+id] ? movimientosInicial[ENTRADA_AJUSTE_POSTITIVO+id].entrada : 0;

          let entradaPorCompras = movimientosFinal[ENTRADA_POR_COMPRA+id] ? movimientosFinal[ENTRADA_POR_COMPRA+id].entrada : 0;
          entradaPorCompras -= movimientosInicial[ENTRADA_POR_COMPRA+id] ? movimientosInicial[ENTRADA_POR_COMPRA+id].entrada : 0;

          let entradaPorCosecha = movimientosFinal[ENTRADA_POR_COSECHA+id] ? movimientosFinal[ENTRADA_POR_COSECHA+id].entrada : 0;
          entradaPorCosecha -= movimientosInicial[ENTRADA_POR_COSECHA+id] ? movimientosInicial[ENTRADA_POR_COSECHA+id].entrada : 0

          let entradaPorTraslados = movimientosFinal[ENTRADA_POR_TRASLADO+id] ? movimientosFinal[ENTRADA_POR_TRASLADO+id].entrada : 0;
          entradaPorTraslados -= movimientosInicial[ENTRADA_POR_TRASLADO+id] ? movimientosInicial[ENTRADA_POR_TRASLADO+id].entrada : 0;


          let ajusteNegativo = movimientosFinal[SALIDA_POR_AJUSTE+id] ? movimientosFinal[SALIDA_POR_AJUSTE+id].salida : 0;
          ajusteNegativo += movimientosFinal[SALIDA_AJUSTE_NEGATIVO+id] ? movimientosFinal[SALIDA_AJUSTE_NEGATIVO+id].salida : 0;
          ajusteNegativo -= movimientosInicial[SALIDA_POR_AJUSTE+id] ? movimientosInicial[SALIDA_POR_AJUSTE+id].salida : 0;
          ajusteNegativo -= movimientosInicial[SALIDA_AJUSTE_NEGATIVO+id] ? movimientosInicial[SALIDA_AJUSTE_NEGATIVO+id].salida : 0;

          let salidaPorTraslados = movimientosFinal[SALIDA_POR_TRASLADO+id] ? movimientosFinal[SALIDA_POR_TRASLADO+id].salida : 0;
          salidaPorTraslados -= movimientosInicial[SALIDA_POR_TRASLADO+id] ? movimientosInicial[SALIDA_POR_TRASLADO+id].salida : 0;

          let consumos = movimientosFinal[SALIDA_CONSUMOS+id] ? movimientosFinal[SALIDA_CONSUMOS+id].salida : 0;
          consumos -= movimientosInicial[SALIDA_CONSUMOS+id] ? movimientosInicial[SALIDA_CONSUMOS+id].salida : 0;

          let stockFinal = stockInicial + ajustePositivo + entradaPorCompras + entradaPorCosecha + entradaPorTraslados - ajusteNegativo - salidaPorTraslados - consumos;

          let productoSistema = this.props.stockPorPeriodos.stockFinal.productos.find(p => p.id === id)
          let sistema = 0
          if(productoSistema){
            sistema = productoSistema.stock
          }
          return (
            <Table.Row key={id}>
              <Table.Cell className="right aligned">{producto}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(stockInicial, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(ajustePositivo, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(entradaPorCompras, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(entradaPorCosecha, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(entradaPorTraslados, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(-ajusteNegativo, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(-salidaPorTraslados, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(-consumos, 2)}</Table.Cell>
              <Table.Cell className="right aligned">{formatear(stockFinal, 2)}</Table.Cell>
               {/* <Table.Cell className="right aligned">{formatear(sistema, 2)}</Table.Cell> */}
            </Table.Row>   
          )
      });
    }

    return(
      <Modal 
        closeOnEscape={true} 
        closeOnDimmerClick={true} 
        open={this.props.stockPorPeriodos.modalOpen}
        onClose={this.handleClose}
        size="large"
        trigger={<Button floated='right' onClick={()=> this.handleOpen()} color='blue' >
                  <Icon name='plus' /> Stock entre periodos
                </Button>}
        >

        <Modal.Header>
          Stock entre periodos {' '}
          <FiltroFechas 
              stock={true}
              titulo={"Seleccionar fechas"}
              borrarFechaInicio={this.borrarFechaInicio} 
              borrarFechaFinal={this.borrarFechaFinal} 
              fechaInicio={this.props.stockPorPeriodos.fechaInicial} 
              fechaFinal={this.props.stockPorPeriodos.fechaFinal} 
              onChangeFechaInicio={this.onChangeFechaInicio} 
              onChangeFechaFinal={this.onChangeFechaFinal} />
          </Modal.Header>
        <Modal.Content>

          <Grid celled style={{padding: 20, margin: 2}}>
            <Grid.Row centered>

              <Grid.Column width={16}>
                <Table 
                    celled 
                    striped 
                    compact 
                    collapsing 
                    color={"blue"} 
                    valigned={"center"}
                    unstackable={true}
                    >
                    <Table.Header>
                      <Table.Row>
                          <Table.HeaderCell>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <span>{moment(this.props.stockPorPeriodos.fechaInicial).format("DD-MM-YY")}</span>
                          </Table.HeaderCell>
                          <Table.HeaderCell className="center aligned" colSpan='4'>
                            ENTRADAS
                          </Table.HeaderCell>
                          <Table.HeaderCell className="center aligned" colSpan='3'>
                            SALIDAS
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <span>{moment(this.props.stockPorPeriodos.fechaFinal).format("DD-MM-YY")}</span>
                          </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Producto</Table.HeaderCell>
                        <Table.HeaderCell>Stock Inicial</Table.HeaderCell>
                        <Table.HeaderCell>Ajuste +</Table.HeaderCell>
                        <Table.HeaderCell>Compra +</Table.HeaderCell>
                        <Table.HeaderCell>Cosecha +</Table.HeaderCell>
                        <Table.HeaderCell>Traslados +</Table.HeaderCell>
                        <Table.HeaderCell>Ajuste -</Table.HeaderCell>
                        <Table.HeaderCell>Traslados -</Table.HeaderCell>
                        <Table.HeaderCell>Consumos -</Table.HeaderCell>
                        <Table.HeaderCell>Stock Final</Table.HeaderCell>
                        {/* <Table.HeaderCell>Stock Final Sistema</Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body> 
                      {listaProductosInicial}
                    </Table.Body>
                  </Table>
              </Grid.Column>

            </Grid.Row>
          </Grid>

        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={()=> this.handleClose()} inverted>
              Cerrar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    stockPorPeriodos: state.personalStore.stockPorPeriodos
  }
}

export default connect(mapStateToProps)(StockEntrePeriodos);