import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Loader,
  Segment,
  Dimmer,
  Image,
  Icon,
  Grid,
  Form,
  Label,
  Button,
} from "semantic-ui-react";
import ReactTable from "react-table";
import firebase from "../firebase";

import { withFirestore } from "react-redux-firebase";
import { compose } from "redux";

import EditarVenta from "./../components/EditarVenta";
import EditarTropaActiva from "./../components/EditarTropaActiva";
import FiltroFechas from "./../components/filtros/ModalFechas";
import ModalMultiple from "./../components/filtros/ModalMultiple";
import { setFilterVentas } from "./../actions";

import { ExelReporteVentas } from "./../utils/excel/exportarVentas";

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

let clienteCache = {};
const getCliente = (clientes = [], cliente = "") => {
  if (!clienteCache)
    clienteCache = { "Sin definir": { nombre: "Sin definir" } };

  if (clienteCache[cliente]) return clienteCache[cliente].nombre;

  clienteCache[cliente] = clientes.find((c) => c.id === cliente);

  return clienteCache[cliente]?.nombre || "-";
};

let rodeoCache = {};
const getRodeo = (rodeos = [], rodeo = "") => {
  if (!rodeoCache) rodeoCache = { "Sin definir": { nombre: "Sin definir" } };

  if (rodeoCache[rodeo]) return rodeoCache[rodeo];

  rodeoCache[rodeo] = rodeos.find((c) => c.id === rodeo);

  return rodeoCache[rodeo];
};

let categoriaCache = {};
const getCategoria = (categorias = [], categoria = "") => {
  if (!categoriaCache)
    categoriaCache = { "Sin definir": { nombre: "Sin definir" } };

  if (categoriaCache[categoria]) return categoriaCache[categoria];

  categoriaCache[categoria] = categorias.find((c) => c.id === categoria);

  return categoriaCache[categoria];
};

const CustomLoader = () => (
  <Segment>
    <Dimmer active>
      <Loader>Loading</Loader>
    </Dimmer>
    <Image src="./assets/paragraph.png" />
  </Segment>
);

class ListaVentas extends Component {
  constructor(props) {
    super(props);

    this.firestore = props.firestore;

    this.db = firebase.firestore();
    this.perfil = props.perfil;
    this.empresa = props.empresa;

    this.seleccionVendidos = ["Cerrados", "Pendientes"];
    this.seleccionDestinos = ["Recria", "Venta"];

    this.vendidosF = {};
    this.vendidosF["Cerrados"] = { id: "Cerrados", label: "Cerrados" };
    this.vendidosF["Pendientes"] = { id: "Pendientes", label: "Pendientes" };

    this.destinosF = {};
    this.destinosF["Recria"] = { id: "Recria", label: "Recria" };
    this.destinosF["Venta"] = { id: "Venta", label: "Venta" };

    this.filteredVentas = {
      vendidos: props.filtrosVentas.vendidos,
      destino: props.filtrosVentas.destino,
      fechaInicio: props.filtrosVentas.fechaInicio,
      fechaFinal: props.filtrosVentas.fechaFinal,
      fechaInicioVta: props.filtrosVentas.fechaInicioVta,
      fechaFinalVta: props.filtrosVentas.fechaFinalVta,
      allRodeos: props.filtrosVentas.allRodeos,
      listaRodeos: props.filtrosVentas.listaRodeos,
      allCategorias: props.filtrosVentas.allCategorias,
      listaCategorias: props.filtrosVentas.listaCategorias,
      allClientes: props.filtrosVentas.allClientes,
      listaClientes: props.filtrosVentas.listaClientes,
      filtros: props.filtrosVentas.filtros,
      fechaCobro: props.filtrosVentas.fechaCobro,
    };

    this.filtrosFijos = [
      "destino",
      "vendidos",
      "clientes",
      "rodeos",
      "categorias",
    ];
    this.clientesFventas = [];
    this.rodeosFventas = [];
    this.categoriasFventas = [];

    this.state = {
      pdfventas: props.pdfventas || null,
      activeItem: "datos",
      iva: props.empresa.iva || 10.5,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.filteredVentas = {
      vendidos: nextProps.filtrosVentas.vendidos,
      destino: nextProps.filtrosVentas.destino,
      fechaInicio: nextProps.filtrosVentas.fechaInicio,
      fechaFinal: nextProps.filtrosVentas.fechaFinal,
      fechaInicioVta: nextProps.filtrosVentas.fechaInicioVta,
      fechaFinalVta: nextProps.filtrosVentas.fechaFinalVta,
      allRodeos: nextProps.filtrosVentas.allRodeos,
      listaRodeos: nextProps.filtrosVentas.listaRodeos,
      allCategorias: nextProps.filtrosVentas.allCategorias,
      listaCategorias: nextProps.filtrosVentas.listaCategorias,
      allClientes: nextProps.filtrosVentas.allClientes,
      listaClientes: nextProps.filtrosVentas.listaClientes,
      filtros: nextProps.filtrosVentas.filtros,
      fechaCobro: nextProps.filtrosVentas.fechaCobro,
    };

    this.setState({
      iva: nextProps.empresa.iva,
      pdfventas: nextProps.pdfventas,
    });
  };

  selectedFechaCobro = () => {
    this.filteredVentas.fechaCobro = true;
    this.props.setFilterVentas(this.filteredVentas);
  };

  selectedFechaVenta = () => {
    this.filteredVentas.fechaCobro = false;
    this.props.setFilterVentas(this.filteredVentas);
  };

  generarPDF() {
    this.db
      .collection("empresas")
      .doc(this.props.perfil.empresa)
      .collection("reportes_stock")
      .add({
        procesado: false,
        descripcion: "",
        fecha: new Date(),
      });
  }

  actualizarIva = (iva) => {
    this.db.collection("empresas").doc(this.perfil.empresa).update({
      iva,
    });
  };

  formatoMoneda = (valor) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(valor);
  };
  formatoNumero = (valor = 0, decimales = 0) => {
    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: decimales,
    }).format(valor);
  };
  prettyDate = (date) => {
    var months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return (
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
    );
  };

  /////////////FECHAS//////////
  onChangeFechaInicio = (fechaInicio) => {
    this.filteredVentas.fechaInicio = fechaInicio;
    this.props.setFilterVentas(this.filteredVentas);
  };
  onChangeFechaFinal = (fechaFinal) => {
    this.filteredVentas.fechaFinal = fechaFinal;
    this.props.setFilterVentas(this.filteredVentas);
  };
  borrarFechaInicio = () => {
    this.filteredVentas.fechaInicio = null;
    this.props.setFilterVentas(this.filteredVentas);
  };
  borrarFechaFinal = () => {
    this.filteredVentas.fechaFinal = null;
    this.props.setFilterVentas(this.filteredVentas);
  };

  //fecha vta

  onChangeFechaInicioVta = (fechaInicio) => {
    this.filteredVentas.fechaInicioVta = fechaInicio;
    this.props.setFilterVentas(this.filteredVentas);
  };
  onChangeFechaFinalVta = (fechaFinal) => {
    this.filteredVentas.fechaFinalVta = fechaFinal;
    this.props.setFilterVentas(this.filteredVentas);
  };
  borrarFechaInicioVta = () => {
    this.filteredVentas.fechaInicioVta = null;
    this.props.setFilterVentas(this.filteredVentas);
  };
  borrarFechaFinalVta = () => {
    this.filteredVentas.fechaFinalVta = null;
    this.props.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllRodeos = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "rodeos"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "rodeos"
        );
      }
    }

    let rodeos = [];
    if (!this.filteredVentas.allRodeos) {
      Object.keys(opciones).map((opcion) => {
        rodeos.push(opciones[opcion].id);
      });

      this.filteredVentas.listaRodeos = rodeos;
      this.filteredVentas.allRodeos = true;
    } else {
      this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });
      this.filteredVentas.allRodeos = false;
      this.filteredVentas.listaRodeos = [];
    }
    this.props.setFilterVentas(this.filteredVentas);
  };
  onSelectedRodeo = (id, opciones) => {
    this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });

    let index = this.filteredVentas.listaRodeos.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaRodeos = this.filteredVentas.listaRodeos.filter(
        (e) => e !== id
      );
      this.filteredVentas.allRodeos = false;
    } else {
      let rodeos = [];

      if (this.filteredVentas.allRodeos) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) rodeos.push(opciones[opcion].id);
        });

        this.filteredVentas.listaRodeos = rodeos;
        this.filteredVentas.allRodeos = false;
      } else {
        this.filteredVentas.listaRodeos =
          this.filteredVentas.listaRodeos.concat(id);
        this.filteredVentas.allRodeos = false;
      }
    }
    this.props.setFilterVentas(this.filteredVentas);
  };
  onSelectedAllCategorias = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "categorias"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "categorias"
        );
      }
    }

    let categorias = [];
    if (!this.filteredVentas.allCategorias) {
      Object.keys(opciones).map((opcion) => {
        categorias.push(opciones[opcion].id);
      });

      this.filteredVentas.listaCategorias = categorias;
      this.filteredVentas.allCategorias = true;
    } else {
      this.filteredVentas.filtros.push({
        nombre: "categorias",
        hora: new Date(),
      });
      this.filteredVentas.allCategorias = false;
      this.filteredVentas.listaCategorias = [];
    }
    this.props.setFilterVentas(this.filteredVentas);
  };
  onSelectedCategoria = (id, opciones) => {
    this.filteredVentas.filtros.push({
      nombre: "categorias",
      hora: new Date(),
    });

    let index = this.filteredVentas.listaCategorias.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaCategorias =
        this.filteredVentas.listaCategorias.filter((e) => e !== id);
      this.filteredVentas.allCategorias = false;
    } else {
      let categorias = [];

      if (this.filteredVentas.allCategorias) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) categorias.push(opciones[opcion].id);
        });

        this.filteredVentas.listaCategorias = categorias;
        this.filteredVentas.allCategorias = false;
      } else {
        this.filteredVentas.listaCategorias =
          this.filteredVentas.listaCategorias.concat(id);
        this.filteredVentas.allCategorias = false;
      }
    }
    this.props.setFilterVentas(this.filteredVentas);
  };
  onSelectedAllClientes = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "clientes"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "clientes"
        );
      }
    }

    let clientes = [];
    if (!this.filteredVentas.allClientes) {
      Object.keys(opciones).map((opcion) => {
        clientes.push(opciones[opcion].id);
      });

      this.filteredVentas.listaClientes = clientes;
      this.filteredVentas.allClientes = true;
    } else {
      this.filteredVentas.filtros.push({
        nombre: "clientes",
        hora: new Date(),
      });
      this.filteredVentas.allClientes = false;
      this.filteredVentas.listaClientes = [];
    }
    this.props.setFilterVentas(this.filteredVentas);
  };
  onSelectedClientes = (id, opciones) => {
    this.filteredVentas.filtros.push({ nombre: "clientes", hora: new Date() });

    let index = this.filteredVentas.listaClientes.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaClientes =
        this.filteredVentas.listaClientes.filter((e) => e !== id);
      this.filteredVentas.allClientes = false;
    } else {
      let clientes = [];

      if (this.filteredVentas.allClientes) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) clientes.push(opciones[opcion].id);
        });

        this.filteredVentas.listaClientes = clientes;
        this.filteredVentas.allClientes = false;
      } else {
        this.filteredVentas.listaClientes =
          this.filteredVentas.listaClientes.concat(id);
        this.filteredVentas.allClientes = false;
      }
    }
    this.props.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllVendidosPendientes = () => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "vendidos"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "vendidos"
        );
      }
    }

    this.seleccionVendidos = ["Cerrados", "Pendientes"];

    this.filteredVentas.vendidos = null;

    this.props.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllDestinos = () => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "destino"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "destino"
        );
      }
    }

    this.seleccionDestinos = ["Recria", "Venta"];

    this.filteredVentas.destino = null;

    this.props.setFilterVentas(this.filteredVentas);
  };

  onSelectedVendidosPendientes = (id, opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "vendidos"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "vendidos"
        );
      }
    }

    if (id === "Cerrados") {
      this.seleccionVendidos = ["Cerrados"];

      this.filteredVentas.filtros.push({
        nombre: "vendidos",
        hora: new Date(),
      });
      this.filteredVentas.vendidos = true;
      this.props.setFilterVentas(this.filteredVentas);
    }

    if (id === "Pendientes") {
      this.seleccionVendidos = ["Pendientes"];

      this.filteredVentas.filtros.push({
        nombre: "vendidos",
        hora: new Date(),
      });
      this.filteredVentas.vendidos = false;
      this.props.setFilterVentas(this.filteredVentas);
    }
  };

  onSelectedDestino = (id, opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "destino"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "destino"
        );
      }
    }

    if (id === "Recria") {
      this.seleccionDestinos = ["Recria"];

      this.filteredVentas.filtros.push({ nombre: "destino", hora: new Date() });
      this.filteredVentas.destino = true;
      this.props.setFilterVentas(this.filteredVentas);
    }

    if (id === "Venta") {
      this.seleccionDestinos = ["Venta"];

      this.filteredVentas.filtros.push({ nombre: "destino", hora: new Date() });
      this.filteredVentas.destino = false;
      this.props.setFilterVentas(this.filteredVentas);
    }
  };

  aplicarFiltroClientes = (lista) => {
    this.clientesFventas = [];
    //filtrando por categorias
    lista = lista.filter((d) => {
      const objCliente = this.props.clientes.find((c) => c.id === d.cliente);

      let cliente = {};
      cliente.id = d.cliente;
      cliente.label = objCliente ? objCliente.nombre : "Sin definir";
      this.clientesFventas[cliente.id] = cliente;

      let bandera = true;
      if (!this.filteredVentas.allClientes) {
        let index = this.filteredVentas.listaClientes.indexOf(d.cliente);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  aplicarFiltroVendidos = (lista) => {
    lista = lista.filter((d) => {
      if (this.filteredVentas.vendidos === null) {
        return true;
      }

      if (this.filteredVentas.vendidos === true) {
        return d.vendido === true;
      }

      if (this.filteredVentas.vendidos === false) {
        return d.vendido === false;
      }
    });

    return lista;
  };

  aplicarFiltroDestino = (lista) => {
    lista = lista.filter((d) => {
      if (this.filteredVentas.destino === null) {
        return true;
      }

      if (this.filteredVentas.destino === false) {
        return d.vender === true;
      }

      if (this.filteredVentas.destino === true) {
        return d.vender === false;
      }
    });

    return lista;
  };

  aplicarFiltroCategorias = (lista) => {
    this.categoriasFventas = [];
    //filtrando por categorias
    lista = lista.filter((d) => {
      let categoria = {};
      categoria.id = d.categoria;
      categoria.label = d.categoria;
      this.categoriasFventas[categoria.id] = categoria;

      let bandera = true;
      if (!this.filteredVentas.allCategorias) {
        let index = this.filteredVentas.listaCategorias.indexOf(d.categoria);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  aplicarFiltroRodeos = (lista) => {
    this.rodeosFventas = [];
    //filtrando por rodeo
    lista = lista.filter((d) => {
      let rodeo = {};
      rodeo.id = d.rodeo;
      rodeo.label = d.nombreRodeo;
      this.rodeosFventas[rodeo.id] = rodeo;

      let bandera = true;
      if (!this.filteredVentas.allRodeos) {
        let index = this.filteredVentas.listaRodeos.indexOf(d.rodeo);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };

  generarInforme = (ventas) => {
    let vtasInforme = [];

    ventas.map((value) => {
      const cliente = this.props.clientes.find((c) => c.id === value.cliente);

      vtasInforme.push({
        empresa: this.empresa.nombre,
        estado: value.vendido ? "Vendido" : "Pendiente",
        destino: value.vender ? "Venta" : "Recria",
        cliente: cliente ? cliente.nombre : "Sin definir",
        fecha: value.fecha
          ? value.fecha.toDate
            ? value.fecha.toDate()
            : new Date(value.fecha)
          : "-",
        mesVenta:
          new Date(
            value.fecha
              ? value.fecha.toDate
                ? value.fecha.toDate()
                : new Date(value.fecha)
              : new Date()
          ).getMonth() + 1,
        anoVenta: new Date(
          value.fecha
            ? value.fecha.toDate
              ? value.fecha.toDate()
              : new Date(value.fecha)
            : new Date()
        ).getFullYear(),
        plazo: Number(value.plazo),
        fechaCobro: value.fechaCobro,
        mesCobro: new Date(value.fechaCobro).getMonth() + 1,
        anoCobro: new Date(value.fechaCobro).getFullYear(),
        rodeo: value.nombreRodeo,
        categoria: value.categoria,
        cabezas: Number(value.cabezas),
        kilos: Number(value.kilos),
        totalKilos: Number(value.kilos * value.cabezas),
        precio: Number(value.precio),
        total: Number(value.cabezas * value.precio * value.kilos),
        totalMasIva: Number(
          value.cabezas *
            value.precio *
            value.kilos *
            (this.state.iva / 100 + 1)
        ),
        consumoEstimado: Number(value.consumo_estimado),
      });
    });

    return <ExelReporteVentas ventas={vtasInforme} />;
  };

  render() {
    const {
      perfil,
      movrodeos,
      reportesVentas,
      categorias,
      rodeosInactivos,
      rodeosActivos,
      clientes,
      empresa,
    } = this.props;

    const rodeos = rodeosActivos.concat(rodeosInactivos);
    let listaVentas = movrodeos.filter(
      (m) =>
        m.detalle.toLowerCase() === "venta" ||
        m.detalle.toLowerCase() === "salida a campo"
    );

    let optionsClientes = [];

    clientes.map((value) => {
      optionsClientes.push({
        key: value.id,
        text: value.nombre,
        value: value.id,
      });
    });

    let tropas = rodeosActivos.map((value) => {
      var f1 = new Date();
      f1.setHours(0);
      f1.setMinutes(0);
      f1.setSeconds(0);
      f1.setMilliseconds(0);

      var f2 = value.ultimaFechaCalculada
        ? value.ultimaFechaCalculada.toDate
          ? value.ultimaFechaCalculada.toDate()
          : new Date(value.ultimaFechaCalculada)
        : new Date();
      f2.setHours(0);
      f2.setMinutes(0);
      f2.setSeconds(0);
      f2.setMilliseconds(0);

      const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * value.gdpv;
      const pesoActual =
        (Number(value.ultimaPesada) ? value.ultimaPesada : 0) + Number(aumento);

      //value.metaDiasEncierre
      //fecha de salida
      let fechaEstimada = new Date();
      let diasEncierre =
        new Date().getTime() -
        (value.fechaAlta.toDate
          ? value.fechaAlta.toDate().getTime()
          : new Date(value.fechaAlta).getTime());
      diasEncierre = Number(this.formatoNumero(diasEncierre / 86400000, 0));

      //kilos
      // const metaDias = Number(value.metaDiasEncierre) || 0;
      const metaKilos = Number(value.metaKilos) || 0;
      let kilos = metaKilos;
      // if (value.seguir) {
      if (pesoActual >= metaKilos && metaKilos > 0) {
        kilos = pesoActual;
        fechaEstimada = new Date();
        // } else if (diasEncierre >= metaDias && metaDias > 0) {
        //     fechaEstimada = new Date();
      } else {
        // if ( metaKilos > 0) {
        let faltantePorKilos = 0;
        let faltantePorDias = 0;
        if (metaKilos > 0) {
          faltantePorKilos = (metaKilos - pesoActual) / value.gdpv;
        }
        // if (metaDias > 0) {
        //     faltantePorDias = metaDias - diasEncierre;
        // }

        // if (metaDias === 0) {
        //     fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(faltantePorKilos) * 86400000));
        // }

        if (metaKilos === 0) {
          fechaEstimada = new Date(
            fechaEstimada.getTime() + Math.ceil(faltantePorDias) * 86400000
          );
        }

        // if (faltantePorKilos <= faltantePorDias && metaKilos > 0) {
        fechaEstimada = new Date(
          fechaEstimada.getTime() + Math.ceil(faltantePorKilos) * 86400000
        );
        // }

        // if (faltantePorDias <= faltantePorKilos && metaDias > 0) {
        //     fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(faltantePorDias) * 86400000));
        // }
      }
      // }
      // } else {
      //     if (diasEncierre >= metaDias) {
      //         fechaEstimada = new Date();
      //     } else {
      //         fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(metaDias - diasEncierre) * 86400000));
      //     }
      // }

      let categoria = getCategoria(categorias, value.categoria); //categoria = categorias.find(c => c.id === value.categoria);

      let fechaCobro = fechaEstimada;

      if (value.plazo)
        fechaCobro = new Date(fechaCobro.getTime() + value.plazo * 86400000);

      return {
        movId: value.id,
        id: value.id + fechaEstimada,
        rodeo: value.id,
        fecha: fechaEstimada,
        gdpv: value.gdpv,
        metaDiasEncierre: value.metaDiasEncierre,
        metaKilos: value.metaKilos,
        fechaCobro,
        cliente: "Sin definir",
        dieta: value.dieta,
        nombreRodeo: value.nombre,
        categoria: categoria ? categoria.nombre : "-",
        cabezas: value.cabezas,
        kilos,
        plazo: value.plazo || 0,
        consumo_estimado: value.consumo_estimado || 0,
        precio: value.vender ? value.precioventa || 0 : 0,
        neto: value.cabezas * categoria.venta * kilos,
        total:
          value.cabezas * categoria.venta * kilos * (this.state.iva / 100 + 1),
        vender: value.vender ? value.vender : false,
        iva: Number(this.state.iva),
        vendido: false,
      };
    });

    // tropas = tropas.filter(t => t.vender)

    let listaVentaReporte = [];

    listaVentas.map((value) => {
      let rodeo = getRodeo(rodeos, value.rodeo);
      let categoria = null;

      if (rodeo) {
        categoria = getCategoria(categorias, rodeo.categoria);
      }

      let fechaCobro = value.fecha.toDate
        ? value.fecha.toDate()
        : new Date(value.fecha);
      if (value.plazo) {
        fechaCobro = new Date(fechaCobro.getTime() + value.plazo * 86400000);
      }

      listaVentaReporte.push({
        vender: value.detalle.toLowerCase() === "venta", // value.cliente ? true : ( rodeo.vender ? rodeo.vender : false ),
        movId: value.id,
        id: value.fecha + (rodeo ? rodeo.nombre : "-"),
        rodeo: value.rodeo,
        gdpv: rodeo.gdpv,
        vendido: true,
        dieta: rodeo ? rodeo.dieta : null,
        cliente: value.cliente ? value.cliente : "Sin definir",
        fecha: value.fecha,
        consumo_estimado: 0,
        plazo: value.plazo ? value.plazo : 0,
        fechaCobro: fechaCobro,
        nombreRodeo: rodeo ? rodeo.nombre : "-",
        categoria: categoria ? categoria.nombre : "-",
        cabezas: value.cabezas,
        kilos: value.kilos,
        precio: value.detalle.toLowerCase() === "venta" ? value.precio : 0,
        iva: Number(this.state.iva),
      });
    });

    listaVentaReporte = listaVentaReporte.concat(tropas);

    let listaFiltrosV = this.filteredVentas.filtros.reduce((filtro, item) => {
      let val = item.nombre;
      filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
      return filtro;
    }, {});

    listaFiltrosV = Object.keys(listaFiltrosV).sort((a, b) => {
      if (listaFiltrosV[a].hora > listaFiltrosV[b].hora) {
        return 1;
      }

      if (listaFiltrosV[a].hora < listaFiltrosV[b].hora) {
        return -1;
      }
      return 0;
    });

    this.filtrosFijos.forEach((fijo) => {
      let index = listaFiltrosV.indexOf(fijo);
      if (index < 0) listaFiltrosV.push(fijo);
    });

    listaVentaReporte = listaVentaReporte.filter((d) => {
      var fecha = d.fechaCobro.toDate
        ? d.fechaCobro.toDate()
        : new Date(d.fechaCobro); //new Date(d.detalle.fecha);

      let banderaFechaInicio = true;
      if (this.filteredVentas.fechaInicio !== null) {
        var fechaInicio = new Date(this.filteredVentas.fechaInicio);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filteredVentas.fechaFinal !== null) {
        var fechaFin = new Date(this.filteredVentas.fechaFinal);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    listaVentaReporte = listaVentaReporte.filter((d) => {
      var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha); //new Date(d.detalle.fecha);

      let banderaFechaInicio = true;
      if (this.filteredVentas.fechaInicioVta !== null) {
        var fechaInicio = new Date(this.filteredVentas.fechaInicioVta);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filteredVentas.fechaFinalVta !== null) {
        var fechaFin = new Date(this.filteredVentas.fechaFinalVta);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    const _this = this;
    listaFiltrosV.forEach((f) => {
      if (f === "vendidos")
        listaVentaReporte = _this.aplicarFiltroVendidos(listaVentaReporte);
      if (f === "destino")
        listaVentaReporte = _this.aplicarFiltroDestino(listaVentaReporte);
      if (f === "rodeos")
        listaVentaReporte = _this.aplicarFiltroRodeos(listaVentaReporte);
      if (f === "categorias")
        listaVentaReporte = _this.aplicarFiltroCategorias(listaVentaReporte);
      if (f === "clientes")
        listaVentaReporte = _this.aplicarFiltroClientes(listaVentaReporte);
    });

    let totalKilos = 0;
    let totalNeto = 0;
    let totalNetoIva = 0;
    let totalCabezas = 0;

    let totalVendido = 0;
    let totalCobrado = 0;
    let totalPendiente = 0;
    let totalPrecio = 0;

    listaVentaReporte.map((value) => {
      totalKilos += Number(value.kilos * value.cabezas);
      totalNeto += value.cabezas * value.precio * value.kilos;
      totalNetoIva +=
        value.cabezas * value.precio * value.kilos * (this.state.iva / 100 + 1);
      totalCabezas += value.cabezas;
      totalPrecio += value.precio;

      if (value.vendido) {
        totalVendido +=
          value.cabezas *
          value.precio *
          value.kilos *
          (this.state.iva / 100 + 1);
        if (new Date().getTime() - value.fechaCobro.getTime() >= 0) {
          totalCobrado +=
            value.cabezas *
            value.precio *
            value.kilos *
            (this.state.iva / 100 + 1);
        }
      }
      if (!value.vendido) {
        totalPendiente +=
          value.cabezas *
          value.precio *
          value.kilos *
          (this.state.iva / 100 + 1);
      }
    });

    const columns = [
      {
        id: "estado",
        Header: "Estado",
        accessor: (d) => (d.vendido ? "Cerrado" : "Pendiente"), // String-based value accessors!
        maxWidth: 100,
        Filter: (
          <ModalMultiple
            opciones={this.vendidosF}
            label={"Estado"}
            onSelected={this.onSelectedVendidosPendientes}
            onSelectedAll={this.onSelectedAllVendidosPendientes}
            todo={this.filteredVentas.vendidos === null ? true : false}
            seleccion={this.seleccionVendidos}
          />
        ),
      },
      {
        id: "destino",
        Header: "Destino",
        accessor: (d) => (d.vender ? "Venta" : "Recria"), // String-based value accessors!
        maxWidth: 100,
        Filter: (
          <ModalMultiple
            opciones={this.destinosF}
            label={"Destino"}
            onSelected={this.onSelectedDestino}
            onSelectedAll={this.onSelectedAllDestinos}
            todo={this.filteredVentas.destino === null ? true : false}
            seleccion={this.seleccionDestinos}
          />
        ),
      },
      {
        id: "cliente",
        Header: "Cliente",
        accessor: (d) => getCliente(clientes, d.cliente),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        maxWidth: 250,
        Filter: (
          <ModalMultiple
            opciones={this.clientesFventas}
            label={"Cliente"}
            onSelected={this.onSelectedClientes}
            onSelectedAll={this.onSelectedAllClientes}
            todo={this.filteredVentas.allClientes}
            seleccion={this.filteredVentas.listaClientes}
          />
        ),
      },
      {
        id: "fechavta",
        Header: "Fecha Vta",
        accessor: (d) =>
          d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha),
        Cell: (props) => <span>{this.prettyDate(props.value)}</span>,
        Filter: (
          <FiltroFechas
            sin_limite={true}
            titulo={"Fecha Vta"}
            borrarFechaInicio={this.borrarFechaInicioVta}
            borrarFechaFinal={this.borrarFechaFinalVta}
            fechaInicio={this.filteredVentas.fechaInicioVta}
            fechaFinal={this.filteredVentas.fechaFinalVta}
            onChangeFechaInicio={this.onChangeFechaInicioVta}
            onChangeFechaFinal={this.onChangeFechaFinalVta}
          />
        ),
      },
      {
        id: "fechacobro",
        Header: "Fecha Cobro",
        accessor: (d) =>
          d.fechaCobro.toDate ? d.fechaCobro.toDate() : new Date(d.fechaCobro),
        Cell: (props) => <span>{this.prettyDate(props.value)}</span>,
        Filter: (
          <FiltroFechas
            sin_limite={true}
            titulo={"Fecha Vta"}
            borrarFechaInicio={this.borrarFechaInicio}
            borrarFechaFinal={this.borrarFechaFinal}
            fechaInicio={this.filteredVentas.fechaInicio}
            fechaFinal={this.filteredVentas.fechaFinal}
            onChangeFechaInicio={this.onChangeFechaInicio}
            onChangeFechaFinal={this.onChangeFechaFinal}
          />
        ),
      },
      {
        id: "rodeo",
        Header: "Rodeo",
        accessor: (d) => d.nombreRodeo,
        Cell: (props) => <span>{props.value}</span>,
        maxWidth: 100,
        Filter: (
          <ModalMultiple
            opciones={this.rodeosFventas}
            label={"Rodeo"}
            onSelected={this.onSelectedRodeo}
            onSelectedAll={this.onSelectedAllRodeos}
            todo={this.filteredVentas.allRodeos}
            seleccion={this.filteredVentas.listaRodeos}
          />
        ),
      },
      {
        id: "categoria",
        Header: "Categoria",
        accessor: (d) => d.categoria,
        Cell: (props) => <span>{props.value}</span>,
        Filter: (
          <ModalMultiple
            opciones={this.categoriasFventas}
            label={"Categoria"}
            onSelected={this.onSelectedCategoria}
            onSelectedAll={this.onSelectedAllCategorias}
            todo={this.filteredVentas.allCategorias}
            seleccion={this.filteredVentas.listaCategorias}
          />
        ),
      },
      {
        id: "cabezas",
        Header: "Cab",
        accessor: (d) => d.cabezas,
        Cell: (props) => <span>{props.value}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {this.formatoNumero(totalCabezas, 2)}
          </span>
        ),
      },
      {
        id: "kilos",
        Header: "Kilos",
        accessor: (d) => d.kilos,
        Cell: (props) => <span>{props.value}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {this.formatoNumero(
              totalCabezas > 0 ? totalKilos / totalCabezas : 0,
              2
            )}
          </span>
        ),
      },
      {
        id: "kiloscab",
        Header: "Total Kilos",
        accessor: (d) => this.formatoNumero(d.kilos * d.cabezas, 2),
        Cell: (props) => <span>{props.value}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {this.formatoNumero(totalKilos, 2)}
          </span>
        ),
      },
      {
        id: "precio",
        Header: "$ x kg",
        accessor: (d) => this.formatoMoneda(d.precio),
        Cell: (props) => <span>{props.value}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalKilos > 0 ? this.formatoMoneda(totalNeto / totalKilos) : 0}
          </span>
        ),
      },
      {
        id: "precioiva",
        Header: "$ x kg + iva",
        accessor: (d) =>
          this.formatoMoneda(d.precio * (this.state.iva / 100 + 1)),
        Cell: (props) => <span>{props.value}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalKilos > 0 ? this.formatoMoneda(totalNetoIva / totalKilos) : 0}
          </span>
        ),
      },
      {
        id: "consumo",
        Header: "Consumo",
        accessor: (d) => this.formatoNumero(d.consumo_estimado),
        Cell: (props) => <span>{props.value}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>-</span>
        ),
      },
      {
        id: "editar",
        Header: "Edicion",
        accessor: (d) => d,
        Cell: (props) =>
          props.value.vendido ? (
            <EditarVenta
              recria={!props.value.vender}
              optionsClientes={optionsClientes}
              venta={props.value}
              db={this.db}
              perfil={this.perfil}
            />
          ) : (
            <EditarTropaActiva
              recria={!props.value.vender}
              perfil={this.perfil}
              db={this.db}
              venta={props.value}
            />
          ),
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>-</span>
        ),
      },
    ];

    // style: {
    //     cursor: "pointer",
    //     fontSize: 25,
    //     padding: "0",
    //     textAlign: "right",
    //     userSelect: "none"
    //   }

    return (
      <Grid>
        <Grid.Row centered>
          <Form>
            <Form.Group>
              <Form.Field>
                <Label color="red" ribbon>
                  Iva Venta:
                </Label>
              </Form.Field>
              <Form.Field>
                <Form.Input
                  id="ivaventa"
                  type="number"
                  step="0.1"
                  min="0"
                  placeholder="%"
                  value={this.state.iva}
                  onChange={(e, { value }) => this.setState({ iva: value })}
                />
                <Button onClick={() => this.actualizarIva(this.state.iva)}>
                  Guardar
                </Button>
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Label color="blue" ribbon size="large">
                {`Total Vendido: ${this.formatoMoneda(totalVendido)}`}
              </Label>
            </Form.Field>
            <Form.Field>
              <Label color="blue" ribbon size="large">
                {`Total Cobrado: ${this.formatoMoneda(totalCobrado)}`}
              </Label>
            </Form.Field>
            <Form.Field>
              <Label color="green" ribbon size="large">
                {`Faltante por Cobrar: ${this.formatoMoneda(
                  totalVendido - totalCobrado
                )} `}
              </Label>
            </Form.Field>
            <Form.Field>
              <Label color="#CEF6F5" ribbon size="large">
                {`Faltante por Vender: ${this.formatoMoneda(totalPendiente)} `}
              </Label>
            </Form.Field>
            <Form.Field>
              <Label color="#CEF6F5" ribbon size="large">
                {this.generarInforme(listaVentaReporte)}
                {this.state.pdfventas !== undefined &&
                  this.state.pdfventas !== null &&
                  this.state.pdfventas.procesado === false && (
                    <Loader active inline />
                  )}
                {this.state.pdfventas !== undefined &&
                  this.state.pdfventas !== null &&
                  this.state.pdfventas.procesado === true && (
                    <a target="_blank" href={this.state.pdfventas.url}>
                      <Icon
                        name="file pdf outline"
                        size="big"
                        link
                        color={"red"}
                      />
                    </a>
                  )}
              </Label>
            </Form.Field>
          </Form>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
              <ReactTable
                data={listaVentaReporte}
                noDataText={!this.cargando ? "No hay ventas registradas" : ""}
                columns={columns}
                loading={this.cargando}
                LoadingComponent={({
                  className,
                  loading,
                  loadingText,
                  ...rest
                }) => {
                  if (loading)
                    return (
                      <div
                        style={{
                          position: "fixed",
                          zIndex: 1000,
                          margin: "5% auto",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <CustomLoader />
                      </div>
                    );
                  return <div></div>;
                }}
                defaultPageSize={25}
                showPaginationTop
                showPaginationBottom
                filterable
                defaultFilterMethod={(filter, row) =>
                  filterCaseInsensitive(filter, row)
                }
                className="-striped -highlight"
                previousText="Previo"
                nextText="Siguiente"
                loadingText="Cargando"
                pageText="Pagina"
                ofText="de"
                rowsText="Filas"
                getTdProps={(state, rowInfo, column) => {
                  // if (rowInfo && rowInfo.row && rowInfo.row.estado && rowInfo.row.estado === "Vendido") {
                  //     return {
                  //         style: {
                  //             backgroundColor: '#4ded4d'
                  //         }
                  //     }
                  // }
                  if (
                    column &&
                    column.id === "estado" &&
                    rowInfo &&
                    rowInfo.row &&
                    rowInfo.row.estado &&
                    rowInfo.row.estado === "Cerrado"
                  ) {
                    return {
                      style: {
                        backgroundColor: "#4ded4d",
                      },
                    };
                  }
                  return {
                    style: {
                      backgroundColor: "white",
                    },
                  };
                }}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filtrosVentas: state.personalStore.filtrosVentas,
    filtrosTropasActivas: state.personalStore.filtrosTropasActivas,
  };
};

const withComponse = connect(mapStateToProps, { setFilterVentas })(ListaVentas);

export default compose(
  withFirestore,
  connect((state, props) => ({
    pdfventas: state.firestore.data.pdfventas,
  }))
)(withComponse);

// export default connect(mapStateToProps, { setFilterVentas } )(ListaVentas)
