import React, { Component } from "react";
import {
  Loader,
  Segment,
  Dimmer,
  Image,
  Icon,
  Grid,
  Accordion,
} from "semantic-ui-react";
import ReactTable from "react-table";

import { connect } from "react-redux";
import { setFilterDescargas } from "../actions";

import ListaConsumos from "./ListaConsumos";

import FiltroFechas from "./filtros/ModalFechas";
import ModalMultiple from "./filtros/ModalMultiple";

import moment from "moment";

import SetupColumns from "./../components/SetupColumns";

import firebase from "../firebase";

import Api from "./../api";

const api = new Api();

const CustomLoader = () => (
  <Segment>
    <Dimmer active>
      <Loader>Loading</Loader>
    </Dimmer>
    <Image src="./assets/paragraph.png" />
  </Segment>
);

const formatoMoneda = (valor) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(valor);
};
const formatoNumero = (valor = 0, decimales = 0) => {
  return new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: decimales,
  }).format(valor);
};

const fechaCorta = (date) => {
  var months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  return (
    date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
  );
};

const dia = (date) => {
  var weekday = new Array(7);
  weekday[0] = "Domingo";
  weekday[1] = "Lunes";
  weekday[2] = "Martes";
  weekday[3] = "Miercoles";
  weekday[4] = "Jueves";
  weekday[5] = "Viernes";
  weekday[6] = "Sabado";

  return weekday[date.getDay()];
};

const semaforoRemanente = (data, empresa) => {
  let tolerancia_remanente = empresa.tolerancia_remanente || 5;
  let semaforo = {
    backgroundColor: "white",
    color: "black",
  };

  if (data.meta > 0) {
    let res = (Number(data.remanentehoy) / Number(data.meta)) * 100;
    semaforo.backgroundColor = "green";
    semaforo.color = "white";
    if (res === 0) {
      semaforo.backgroundColor = "#FF5151";
      semaforo.color = "black";
    }
    if (res > tolerancia_remanente) {
      semaforo.backgroundColor = "yellow";
      semaforo.color = "black";
    }
  }

  return semaforo;
};

const semaforoFaltante = (data, empresa) => {
  let semaforo = {
    backgroundColor: "white",
    color: "black",
  };

  if (data.meta > 0) {
    let res =
      (Number(data.meta - data.kilos - data.remanentehoy) / Number(data.meta)) *
      100;
    semaforo.backgroundColor = "green";
    if (res < empresa.tolerancia * Number(-1)) {
      semaforo.backgroundColor = "yellow";
      semaforo.color = "black";
    }
    if (res > 0 && res < empresa.tolerancia) {
      semaforo.backgroundColor = "green";
      semaforo.color = "white";
    }
    if (res > empresa.tolerancia) {
      semaforo.backgroundColor = "#FF5151";
      semaforo.color = "black";
    }
  }

  return semaforo;
};

const verificarCol = (columna = "") => {
  const item = localStorage.getItem(columna);
  if (!item) return true;

  if (item === "false") return false;

  return true;
};

class ListaDescargas extends Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.perfil = props.perfil;
    this.cargando = props.cargando;
    this.state = {
      col_cabezas: verificarCol("col_cabezas"),
      col_categoria: verificarCol("col_categoria"),
      col_corral: verificarCol("col_corral"),
      col_costocabeza: verificarCol("col_costocabeza"),
      col_cpcms: verificarCol("col_cpcms"),
      col_cpctc: verificarCol("col_cpctc"),
      col_descargado: verificarCol("col_descargado"),
      col_dia: verificarCol("col_dia"),
      col_dieta: verificarCol("col_dieta"),
      col_diferencia: verificarCol("col_diferencia"),
      col_fecha: verificarCol("col_fecha"),
      col_mcal: verificarCol("col_mcal"),
      col_meta: verificarCol("col_meta"),
      col_prot: verificarCol("col_prot"),
      col_remanente: verificarCol("col_remanente"),
      col_rodeo: verificarCol("col_rodeo"),
      col_mixer: verificarCol("col_mixer"),
      activeIndex: -1,
    };

    this.descargas = props.descargas || [];

    this.dietas = props.dietas;
    this.productos = props.productos;
    this.corrales = props.corrales;
    this.empresa = props.empresa;
    this.rodeos = props.rodeos;
    this.categorias = props.categorias;
    this.usuarios = props.usuarios;
    this.mixers = props.mixers;

    this.setFilterDescargas = props.setFilterDescargas;

    this.filtered = {
      fechaInicio: props.filtrosDescargas.fechaInicio,
      fechaFinal: props.filtrosDescargas.fechaFinal,
      allDays: props.filtrosDescargas.allDays,
      listaDias: props.filtrosDescargas.listaDias,
      allCorrales: props.filtrosDescargas.allCorrales,
      listaCorrales: props.filtrosDescargas.listaCorrales,
      allRodeos: props.filtrosDescargas.allRodeos,
      listaRodeos: props.filtrosDescargas.listaRodeos,
      allCategorias: props.filtrosDescargas.allCategorias,
      listaCategorias: props.filtrosDescargas.listaCategorias,
      allDietas: props.filtrosDescargas.allDietas,
      listaDietas: props.filtrosDescargas.listaDietas,
      filtros: props.filtrosDescargas.filtros,
      allMixer: props.filtrosDescargas.allMixer,
      listaMixer: props.filtrosDescargas.listaMixer,
    };

    this.filtrosFijos = [
      "dias",
      "corrales",
      "rodeos",
      "categorias",
      "dietas",
      "mixers",
    ];

    this.diasF = [];
    this.corralesF = [];
    this.rodeosF = [];
    this.categoriasF = [];
    this.dietasF = [];
    this.mixerF = [];
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  componentWillReceiveProps = (nextProps) => {
    this.cargando = nextProps.cargando;
    this.rodeos = nextProps.rodeos;
    this.empresa = nextProps.empresa;
    this.productos = nextProps.productos;
    this.descargas = nextProps.descargas;
    this.dietas = nextProps.dietas;
    this.corrales = nextProps.corrales;
    this.categorias = nextProps.categorias;
    this.usuarios = nextProps.usuarios;
    this.mixers = nextProps.mixers;

    this.filtered = {
      fechaInicio: nextProps.filtrosDescargas.fechaInicio,
      fechaFinal: nextProps.filtrosDescargas.fechaFinal,
      allDays: nextProps.filtrosDescargas.allDays,
      listaDias: nextProps.filtrosDescargas.listaDias,
      allCorrales: nextProps.filtrosDescargas.allCorrales,
      listaCorrales: nextProps.filtrosDescargas.listaCorrales,
      allRodeos: nextProps.filtrosDescargas.allRodeos,
      listaRodeos: nextProps.filtrosDescargas.listaRodeos,
      allCategorias: nextProps.filtrosDescargas.allCategorias,
      listaCategorias: nextProps.filtrosDescargas.listaCategorias,
      allDietas: nextProps.filtrosDescargas.allDietas,
      listaDietas: nextProps.filtrosDescargas.listaDietas,
      filtros: nextProps.filtrosDescargas.filtros,
      allMixer: nextProps.filtrosDescargas.allMixer,
      listaMixer: nextProps.filtrosDescargas.listaMixer,
    };
  };

  onChangeFechaInicio = (fechaInicio) => {
    const { fechaFinal } = this.props.filtrosDescargas;
    let inicio = new Date(fechaInicio);
    inicio.setHours(0);
    inicio.setMinutes(0);
    this.filtered.fechaInicio = moment(inicio);
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
    this.descargasByRodeo(this.rodeos, inicio, fechaFinal);
  };
  onChangeFechaFinal = (fechaFinal) => {
    const { fechaInicio } = this.props.filtrosDescargas;
    let final = new Date(fechaFinal);
    final.setHours(23);
    final.setMinutes(59);
    this.filtered.fechaFinal = moment(final);
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
    this.descargasByRodeo(this.rodeos, fechaInicio, final);
  };

  descargasByRodeo = (rodeos, fechaInicio, fechaFinal) => {
    const { dispatch } = this.props;
    api.fetchDescargas(
      this.props.perfil.empresa,
      rodeos,
      dispatch,
      fechaInicio,
      fechaFinal
    );
  };

  borrarFechaInicio = () => {
    const { fechaFinal } = this.props.filtrosDescargas;
    this.filtered.fechaInicio = null;
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
    this.descargasByRodeo(this.rodeos, null, fechaFinal);
  };

  borrarFechaFinal = () => {
    const { fechaInicio } = this.props.filtrosDescargas;
    this.filtered.fechaFinal = null;
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
    this.descargasByRodeo(this.rodeos, fechaInicio, null);
  };

  onSelectedAllDias = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "dias");
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "dias"
        );
      }
    }

    let dias = [];
    if (!this.filtered.allDays) {
      Object.keys(opciones).map((opcion) => {
        dias.push(opciones[opcion].id);
      });

      this.filtered.listaDias = dias;
      this.filtered.allDays = true;
    } else {
      this.filtered.filtros.push({ nombre: "dias", hora: new Date() });
      this.filtered.allDays = false;
      this.filtered.listaDias = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedDay = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "dias", hora: new Date() });

    let index = this.filtered.listaDias.indexOf(id);

    if (index > -1) {
      this.filtered.listaDias = this.filtered.listaDias.filter((e) => e !== id);
      this.filtered.allDays = false;
    } else {
      let dias = [];

      if (this.filtered.allDays) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) dias.push(opciones[opcion].id);
        });

        this.filtered.listaDias = dias;
        this.filtered.allDays = false;
      } else {
        this.filtered.listaDias = this.filtered.listaDias.concat(id);
        this.filtered.allDays = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedAllCorrales = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "corrales");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "corrales"
        );
      }
    }

    let corrales = [];
    if (!this.filtered.allCorrales) {
      Object.keys(opciones).map((opcion) => {
        corrales.push(opciones[opcion].id);
      });
      this.filtered.listaCorrales = corrales;
      this.filtered.allCorrales = true;
    } else {
      this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });
      this.filtered.allCorrales = false;
      this.filtered.listaCorrales = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedCorral = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });

    let index = this.filtered.listaCorrales.indexOf(id);

    if (index > -1) {
      this.filtered.listaCorrales = this.filtered.listaCorrales.filter(
        (e) => e !== id
      );
      this.filtered.allCorrales = false;
    } else {
      let corrales = [];

      if (this.filtered.allCorrales) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) corrales.push(opciones[opcion].id);
        });

        this.filtered.listaCorrales = corrales;
        this.filtered.allCorrales = false;
      } else {
        this.filtered.listaCorrales = this.filtered.listaCorrales.concat(id);
        this.filtered.allCorrales = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedAllRodeos = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "rodeos");
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "rodeos"
        );
      }
    }

    let rodeos = [];
    if (!this.filtered.allRodeos) {
      Object.keys(opciones).map((opcion) => {
        rodeos.push(opciones[opcion].id);
      });

      this.filtered.listaRodeos = rodeos;
      this.filtered.allRodeos = true;
    } else {
      this.filtered.filtros.push({ nombre: "rodeos", hora: new Date() });
      this.filtered.allRodeos = false;
      this.filtered.listaRodeos = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedRodeo = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "rodeos", hora: new Date() });

    let index = this.filtered.listaRodeos.indexOf(id);

    if (index > -1) {
      this.filtered.listaRodeos = this.filtered.listaRodeos.filter(
        (e) => e !== id
      );
      this.filtered.allRodeos = false;
    } else {
      let rodeos = [];

      if (this.filtered.allRodeos) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) rodeos.push(opciones[opcion].id);
        });

        this.filtered.listaRodeos = rodeos;
        this.filtered.allRodeos = false;
      } else {
        this.filtered.listaRodeos = this.filtered.listaRodeos.concat(id);
        this.filtered.allRodeos = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedAllCategorias = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "categorias");
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "categorias"
        );
      }
    }

    let categorias = [];
    if (!this.filtered.allCategorias) {
      Object.keys(opciones).map((opcion) => {
        categorias.push(opciones[opcion].id);
      });

      this.filtered.listaCategorias = categorias;
      this.filtered.allCategorias = true;
    } else {
      this.filtered.filtros.push({ nombre: "categorias", hora: new Date() });
      this.filtered.allCategorias = false;
      this.filtered.listaCategorias = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedCategoria = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "categorias", hora: new Date() });

    let index = this.filtered.listaCategorias.indexOf(id);

    if (index > -1) {
      this.filtered.listaCategorias = this.filtered.listaCategorias.filter(
        (e) => e !== id
      );
      this.filtered.allCategorias = false;
    } else {
      let categorias = [];

      if (this.filtered.allCategorias) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) categorias.push(opciones[opcion].id);
        });

        this.filtered.listaCategorias = categorias;
        this.filtered.allCategorias = false;
      } else {
        this.filtered.listaCategorias = this.filtered.listaCategorias.concat(
          id
        );
        this.filtered.allCategorias = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedAllDietas = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "dietas");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "dietas"
        );
      }
    }

    let dietas = [];
    if (!this.filtered.allDietas) {
      Object.keys(opciones).map((opcion) => {
        dietas.push(opciones[opcion].id);
      });

      this.filtered.listaDietas = dietas;
      this.filtered.allDietas = true;
    } else {
      this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });
      this.filtered.allDietas = false;
      this.filtered.listaDietas = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedDieta = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });

    let index = this.filtered.listaDietas.indexOf(id);

    if (index > -1) {
      this.filtered.listaDietas = this.filtered.listaDietas.filter(
        (e) => e !== id
      );
      this.filtered.allDietas = false;
    } else {
      let dietas = [];

      if (this.filtered.allDietas) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) dietas.push(opciones[opcion].id);
        });

        this.filtered.listaDietas = dietas;
        this.filtered.allDietas = false;
      } else {
        this.filtered.listaDietas = this.filtered.listaDietas.concat(id);
        this.filtered.allDietas = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  //APLICANDO FILTROS

  aplicarFiltroDias = (listaDescarga) => {
    this.diasF = [];
    var weekday = new Array(7);
    weekday[0] = "Domingo";
    weekday[1] = "Lunes";
    weekday[2] = "Martes";
    weekday[3] = "Miercoles";
    weekday[4] = "Jueves";
    weekday[5] = "Viernes";
    weekday[6] = "Sabado";

    //filtrando por dia
    listaDescarga = listaDescarga.filter((d) => {
      var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha);

      let day = {};
      day.id = fecha.getDay();
      day.label = weekday[day.id];
      this.diasF[day.id] = day;

      let bandera = true;
      if (!this.filtered.allDays) {
        let index = this.filtered.listaDias.indexOf(fecha.getDay());
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  aplicarFiltroCorrales = (listaDescarga) => {
    this.corralesF = [];
    //filtrando por corral
    listaDescarga = listaDescarga.filter((d) => {
      let corral = {};
      corral.id = d.corral;

      corral.label = this.corrales[d.corral]
        ? this.corrales[d.corral].nombre
        : "";
      this.corralesF[corral.id] = corral;

      let bandera = true;
      if (!this.filtered.allCorrales) {
        let index = this.filtered.listaCorrales.indexOf(d.corral);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  aplicarFiltroRodeos = (listaDescarga) => {
    this.rodeosF = [];
    //filtrando por rodeo
    listaDescarga = listaDescarga.filter((d) => {
      let rodeo = {};
      rodeo.id = d.rodeo;
      let r = this.rodeos[d.rodeo];
      if (r !== undefined && r !== null) {
        rodeo.label = r.nombre;
      } else {
        rodeo.label = "n/n";
      }
      this.rodeosF[rodeo.id] = rodeo;

      let bandera = true;
      if (!this.filtered.allRodeos) {
        let index = this.filtered.listaRodeos.indexOf(d.rodeo);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  aplicarFiltroCategorias = (listaDescarga) => {
    this.categoriasF = [];
    //filtrando por categorias
    listaDescarga = listaDescarga.filter((d) => {
      let categoria = {};
      categoria.id = d.categoria;
      categoria.label = this.categorias[d.categoria]
        ? this.categorias[d.categoria].nombre
        : "";
      this.categoriasF[categoria.id] = categoria;

      let bandera = true;
      if (!this.filtered.allCategorias) {
        let index = this.filtered.listaCategorias.indexOf(d.categoria);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  aplicarFiltroDietas = (listaDescarga) => {
    this.dietasF = [];
    //filtrando por dietas
    listaDescarga = listaDescarga.filter((d) => {
      let dieta = {};
      dieta.id = d.dieta;
      dieta.label = this.dietas[d.dieta] ? this.dietas[d.dieta].nombre : "";
      this.dietasF[dieta.id] = dieta;

      let bandera = true;
      if (!this.filtered.allDietas) {
        let index = this.filtered.listaDietas.indexOf(d.dieta);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  setColumns = (columna) => {
    if (columna === "col_cabezas") {
      localStorage.setItem("col_cabezas", !this.state.col_cabezas);
      this.setState({
        col_cabezas: !this.state.col_cabezas,
      });
    }

    if (columna === "col_mixer") {
      localStorage.setItem("col_mixer", !this.state.col_mixer);
      this.setState({
        col_mixer: !this.state.col_mixer,
      });
    }

    if (columna === "col_categoria") {
      localStorage.setItem("col_categoria", !this.state.col_categoria);
      this.setState({
        col_categoria: !this.state.col_categoria,
      });
    }

    if (columna === "col_corral") {
      localStorage.setItem("col_corral", !this.state.col_corral);
      this.setState({
        col_corral: !this.state.col_corral,
      });
    }

    if (columna === "col_costocabeza") {
      localStorage.setItem("col_costocabeza", !this.state.col_costocabeza);
      this.setState({
        col_costocabeza: !this.state.col_costocabeza,
      });
    }

    if (columna === "col_cpcms") {
      localStorage.setItem("col_cpcms", !this.state.col_cpcms);
      this.setState({
        col_cpcms: !this.state.col_cpcms,
      });
    }

    if (columna === "col_cpctc") {
      localStorage.setItem("col_cpctc", !this.state.col_cpctc);
      this.setState({
        col_cpctc: !this.state.col_cpctc,
      });
    }

    if (columna === "col_descargado") {
      localStorage.setItem("col_descargado", !this.state.col_descargado);
      this.setState({
        col_descargado: !this.state.col_descargado,
      });
    }

    if (columna === "col_dia") {
      localStorage.setItem("col_dia", !this.state.col_dia);
      this.setState({
        col_dia: !this.state.col_dia,
      });
    }

    if (columna === "col_dieta") {
      localStorage.setItem("col_dieta", !this.state.col_dieta);
      this.setState({
        col_dieta: !this.state.col_dieta,
      });
    }

    if (columna === "col_diferencia") {
      localStorage.setItem("col_diferencia", !this.state.col_diferencia);
      this.setState({
        col_diferencia: !this.state.col_diferencia,
      });
    }

    if (columna === "col_fecha") {
      localStorage.setItem("col_fecha", !this.state.col_fecha);
      this.setState({
        col_fecha: !this.state.col_fecha,
      });
    }

    if (columna === "col_mcal") {
      localStorage.setItem("col_mcal", !this.state.col_mcal);
      this.setState({
        col_mcal: !this.state.col_mcal,
      });
    }

    if (columna === "col_meta") {
      localStorage.setItem("col_meta", !this.state.col_meta);
      this.setState({
        col_meta: !this.state.col_meta,
      });
    }

    if (columna === "col_prot") {
      localStorage.setItem("col_prot", !this.state.col_prot);
      this.setState({
        col_prot: !this.state.col_prot,
      });
    }

    if (columna === "col_remanente") {
      localStorage.setItem("col_remanente", !this.state.col_remanente);
      this.setState({
        col_remanente: !this.state.col_remanente,
      });
    }

    if (columna === "col_rodeo") {
      localStorage.setItem("col_rodeo", !this.state.col_rodeo);
      this.setState({
        col_rodeo: !this.state.col_rodeo,
      });
    }
  };

  onSelectedAllMixer = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "mixers");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "mixers"
        );
      }
    }

    let mixers = [];
    if (!this.filtered.allMixer) {
      Object.keys(opciones).map((opcion) => {
        mixers.push(opciones[opcion].id);
      });

      this.filtered.listaMixer = mixers;
      this.filtered.allMixer = true;
    } else {
      this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });
      this.filtered.allMixer = false;
      this.filtered.listaMixer = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  onSelectedMixer = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });

    let index = this.filtered.listaMixer.indexOf(id);

    if (index > -1) {
      this.filtered.listaMixer = this.filtered.listaMixer.filter(
        (e) => e !== id
      );
      this.filtered.allMixer = false;
    } else {
      let mixers = [];

      if (this.filtered.allMixer) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) mixers.push(opciones[opcion].id);
        });

        this.filtered.listaMixer = mixers;
        this.filtered.allMixer = false;
      } else {
        this.filtered.listaMixer = this.filtered.listaMixer.concat(id);
        this.filtered.allMixer = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_DESCARGAS",
      ...this.filtered,
    });
  };

  aplicarFiltroUsuarios = (listaDescarga) => {
    this.mixerF = [];
    //filtrando por categorias
    listaDescarga = listaDescarga.filter((d) => {
      let mixer = {};
      mixer.id = d.usuario;
      mixer.label = this.usuarios[d.usuario]
        ? this.usuarios[d.usuario].nombre
        : "";

      this.mixerF[mixer.id] = mixer;

      let bandera = true;
      if (!this.filtered.allMixer) {
        let index = this.filtered.listaMixer.indexOf(mixer ? mixer.id : "-");
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  render() {
    let listaDescarga = this.descargas.sort(function (a, b) {
      const fa = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
      const fb = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return -1;
      if (fa < fb) return 1;
      return 0;
    });

    listaDescarga = listaDescarga.filter((d) => {
      var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha); //new Date(d.fecha);

      let banderaFechaInicio = true;
      if (this.filtered.fechaInicio !== null) {
        var fechaInicio = new Date(this.filtered.fechaInicio);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filtered.fechaFinal !== null) {
        var fechaFin = new Date(this.filtered.fechaFinal);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    let listaFiltros = this.filtered.filtros.reduce((filtro, item) => {
      let val = item.nombre;
      filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
      return filtro;
    }, {});

    listaFiltros = Object.keys(listaFiltros).sort((a, b) => {
      if (listaFiltros[a].hora > listaFiltros[b].hora) {
        return 1;
      }

      if (listaFiltros[a].hora < listaFiltros[b].hora) {
        return -1;
      }
      return 0;
    });

    this.filtrosFijos.forEach((fijo) => {
      let index = listaFiltros.indexOf(fijo);
      if (index < 0) listaFiltros.push(fijo);
    });

    var _this = this;
    listaFiltros.forEach((f) => {
      if (f === "dias") listaDescarga = _this.aplicarFiltroDias(listaDescarga);
      if (f === "corrales")
        listaDescarga = _this.aplicarFiltroCorrales(listaDescarga);
      if (f === "rodeos")
        listaDescarga = _this.aplicarFiltroRodeos(listaDescarga);
      if (f === "categorias")
        listaDescarga = _this.aplicarFiltroCategorias(listaDescarga);
      if (f === "dietas")
        listaDescarga = _this.aplicarFiltroDietas(listaDescarga);
      if (f === "mixers")
        listaDescarga = _this.aplicarFiltroUsuarios(listaDescarga);
    });

    let ingredientes = [];

    listaDescarga.map((d) => {
      ingredientes = ingredientes.concat(d.ingredientes);
    });

    const listaIngredientes = ingredientes.reduce((ingrediente, item) => {
      let val = item.producto;
      ingrediente[val] = ingrediente[val] || {
        kilos: 0,
        costo: 0,
        materiaseca: 0,
      };
      ingrediente[val].materiaseca += item.materiaseca;
      ingrediente[val].kilos += item.kilos;
      ingrediente[val].costo +=
        item.costo !== null && item.costo !== undefined ? item.costo : 0;
      return ingrediente;
    }, {});

    let totalCab = 0;
    let totalDesc = 0;
    let totalMeta = 0;

    let totalcpcms = 0;
    let totalcpctc = 0;

    let pesosXcab = 0;
    let megaXcab = 0;
    let protXcab = 0;

    let totalRemanente = 0;

    listaDescarga.map((d, i) => {
      // if (i > 0) {
      d.cpcms =
        (Number(d.remanentehoy / d.talcual) +
          Number(d.materiaseca) -
          Number(d.remanentefuturo / d.talcual)) /
        d.cabezas;
      d.cpctc =
        (Number(d.remanentehoy) + Number(d.kilos) - Number(d.remanentefuturo)) /
        d.cabezas;
      // } else {
      //     d.cpctc = ((Number(d.remanentehoy) + Number(d.kilos)) / d.cabezas)
      //     d.cpcms = (Number(d.remanentehoy / d.talcual) + Number(d.materiaseca)) / d.cabezas
      // }

      totalCab += Number(d.cabezas);
      totalDesc += Number(d.kilos);
      totalMeta += Number(d.meta);
      totalRemanente += Number(d.remanentehoy);

      totalcpcms += d.cpcms * d.cabezas;

      totalcpctc += d.cpctc * d.cabezas;

      pesosXcab += d.cpctc * d.cabezas * (d.costo / d.kilos);

      megaXcab += d.cpcms * d.mcal * d.cabezas;

      protXcab += (d.cpcms * d.prot * d.cabezas) / 100;
    });

    //console.log(lista)

    const columns = [
      {
        id: "dia",
        Header: "Dia",
        show: this.state.col_dia,
        accessor: (d) =>
          dia(d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha)), // String-based value accessors!
        maxWidth: 70,
        Filter: (
          <ModalMultiple
            opciones={this.diasF}
            label={"Dia"}
            onSelected={this.onSelectedDay}
            onSelectedAll={this.onSelectedAllDias}
            todo={this.filtered.allDays}
            seleccion={this.filtered.listaDias}
          />
        ),
      },
      {
        id: "fecha",
        Header: "Fecha",
        show: this.state.col_fecha,
        accessor: (d) =>
          d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha),
        Cell: (props) => <span>{fechaCorta(props.value)}</span>, // Custom cell components!
        maxWidth: 100,
        Filter: (
          <FiltroFechas
            borrarFechaInicio={this.borrarFechaInicio}
            borrarFechaFinal={this.borrarFechaFinal}
            fechaInicio={this.filtered.fechaInicio}
            fechaFinal={this.filtered.fechaFinal}
            onChangeFechaInicio={this.onChangeFechaInicio}
            onChangeFechaFinal={this.onChangeFechaFinal}
          />
        ),
      },
      {
        id: "mixer",
        Header: "Usuario",
        show: this.state.col_mixer,
        accessor: (d) => d.usuario,
        Cell: (props) => (
          <span>
            {this.usuarios[props.value]
              ? this.usuarios[props.value].nombre
              : ""}
          </span>
        ), //<span>{ props.value.nombre }</span>,
        maxWidth: 100,
        Filter: (
          <ModalMultiple
            opciones={this.mixerF}
            label={"Usuario"}
            onSelected={this.onSelectedMixer}
            onSelectedAll={this.onSelectedAllMixer}
            todo={this.filtered.allMixer}
            seleccion={this.filtered.listaMixer}
          />
        ),
      },
      {
        id: "corral",
        Header: "Corral",
        show: this.state.col_corral,
        accessor: (d) => d.corral, // Custom value accessors!
        Cell: (props) => (
          <span>
            {this.corrales[props.value]
              ? this.corrales[props.value].nombre
              : ""}
          </span>
        ),
        Filter: (
          <ModalMultiple
            opciones={this.corralesF}
            label={"Corral"}
            onSelected={this.onSelectedCorral}
            onSelectedAll={this.onSelectedAllCorrales}
            todo={this.filtered.allCorrales}
            seleccion={this.filtered.listaCorrales}
          />
        ),
      },
      {
        id: "rodeo",
        Header: "Rodeo",
        show: this.state.col_rodeo,
        accessor: (d) => d.rodeo,
        // accessor: d => d,
        // Cell: props => <span onClick={()=> this.db.collection("empresas").doc(this.perfil.empresa).collection("descargas").doc(props.value.id).delete()}>borrar</span>,
        Cell: (props) => (
          <span>
            {this.rodeos[props.value] ? this.rodeos[props.value].nombre : ""}
          </span>
        ),
        maxWidth: 100,
        Filter: (
          <ModalMultiple
            opciones={this.rodeosF}
            label={"Rodeo"}
            onSelected={this.onSelectedRodeo}
            onSelectedAll={this.onSelectedAllRodeos}
            todo={this.filtered.allRodeos}
            seleccion={this.filtered.listaRodeos}
          />
        ),
      },
      {
        id: "categoria",
        Header: "Categoria",
        show: this.state.col_categoria,
        accessor: (d) => d.categoria,
        Cell: (props) => (
          <span>
            {this.categorias[props.value]
              ? this.categorias[props.value].nombre
              : ""}
          </span>
        ),
        Filter: (
          <ModalMultiple
            opciones={this.categoriasF}
            label={"Categoria"}
            onSelected={this.onSelectedCategoria}
            onSelectedAll={this.onSelectedAllCategorias}
            todo={this.filtered.allCategorias}
            seleccion={this.filtered.listaCategorias}
          />
        ),
      },
      {
        id: "dieta",
        Header: "Dieta",
        show: this.state.col_dieta,
        accessor: (d) => d.dieta,
        Cell: (props) => (
          <span>
            {this.dietas[props.value] ? this.dietas[props.value].nombre : ""}
          </span>
        ),
        maxWidth: 140,
        Filter: (
          <ModalMultiple
            opciones={this.dietasF}
            label={"Dieta"}
            onSelected={this.onSelectedDieta}
            onSelectedAll={this.onSelectedAllDietas}
            todo={this.filtered.allDietas}
            seleccion={this.filtered.listaDietas}
          />
        ),
      },
      {
        id: "cabezas",
        Header: "Cab",
        show: this.state.col_cabezas,
        accessor: (d) => d.cabezas,
        style: {
          textAlign: "right",
        },
        maxWidth: 80,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {formatoNumero(totalCab)}
          </span>
        ),
      },
      {
        id: "remanente",
        Header: "Rem",
        show: this.state.col_remanente,
        accessor: (d) => d,
        Cell: (props) => (
          <span>{formatoNumero(props.value.remanentehoy)} kg</span>
        ),
        style: {
          textAlign: "right",
        },
        maxWidth: 70,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalDesc > 0
              ? formatoNumero((totalRemanente / totalDesc) * 100, 2)
              : 0}
            %
          </span>
        ),
      },
      {
        id: "descargado",
        Header: "Desc",
        show: this.state.col_descargado,
        accessor: (d) => d.kilos,
        Cell: (props) => <span>{formatoNumero(props.value)} kg</span>,
        maxWidth: 100,
        style: {
          textAlign: "right",
        },
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {formatoNumero(totalDesc)}
          </span>
        ),
      },
      {
        id: "meta",
        Header: "Met",
        show: this.state.col_meta,
        accessor: (d) => d.meta,
        Cell: (props) => <span>{formatoNumero(props.value)} kg</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 100,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {formatoNumero(totalMeta)}
          </span>
        ),
      },
      {
        id: "diferencia",
        Header: "Dif",
        show: this.state.col_diferencia,
        accessor: (d) => d,
        Cell: (props) => (
          <span>
            {formatoNumero(
              props.value.kilos + props.value.remanentehoy - props.value.meta
            )}{" "}
            kg
          </span>
        ),
        style: {
          textAlign: "right",
        },
        maxWidth: 70,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {formatoNumero(totalMeta - totalDesc)}
          </span>
        ),
      },
      {
        id: "cpcms",
        Header: "CPC MS",
        show: this.state.col_cpcms,
        accessor: (d) => Number(d.cpcms), //((Number(d.remanentehoy / d.talcual) + Number(d.materiaseca) - Number(d.remanentefuturo / d.talcual)) / d.cabezas)
        Cell: (props) => <span>{formatoNumero(props.value, 2)} kg</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 80,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalCab > 0 ? formatoNumero(totalcpcms / totalCab, 2) : 0}
          </span>
        ),
      },
      {
        id: "cpctc",
        Header: "CPC TC",
        show: this.state.col_cpctc,
        accessor: (d) => Number(d.cpctc), //((Number(d.remanentehoy) + Number(d.kilos) - Number(d.remanentefuturo)) / d.cabezas),
        Cell: (props) => <span>{formatoNumero(props.value, 2)} kg</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 80,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalCab > 0 ? formatoNumero(totalcpctc / totalCab, 2) : 0}
          </span>
        ),
      },
      {
        id: "costoporcabeza",
        Header: "$/Cab",
        show: this.state.col_costocabeza,
        accessor: (
          d //((Number(d.remanentehoy / d.talcual) + Number(d.materiaseca) - Number(d.remanentefuturo / d.talcual)) / d.cabezas) * (d.costo / d.kilos) * d.talcual,
        ) =>
          ((Number(d.remanentehoy) +
            Number(d.kilos) -
            Number(d.remanentefuturo)) /
            d.cabezas) *
          (d.costo / d.kilos),
        Cell: (props) => <span>{formatoMoneda(props.value)}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 80,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalCab > 0 ? formatoNumero(pesosXcab / totalCab, 2) : 0}
          </span>
        ),
      },
      {
        id: "mcal",
        Header: "MCal/cab",
        show: this.state.col_mcal,
        accessor: (d) =>
          Number(
            ((d.remanentehoy / d.talcual +
              Number(d.materiaseca) -
              Number(d.remanentefuturo / d.talcual)) /
              d.cabezas) *
              d.mcal
          ),
        Cell: (props) => <span>{formatoNumero(props.value, 2)}</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 80,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalCab > 0 ? formatoNumero(megaXcab / totalCab, 2) : 0}
          </span>
        ),
      },
      {
        id: "prot",
        Header: "Prot/cab",
        show: this.state.col_prot,
        accessor: (d) =>
          Number(
            (((d.remanentehoy / d.talcual +
              Number(d.materiaseca) -
              Number(d.remanentefuturo / d.talcual)) /
              d.cabezas) *
              d.prot) /
              100
          ),
        Cell: (props) => <span>{formatoNumero(props.value, 2)} kg</span>,
        style: {
          textAlign: "right",
        },
        maxWidth: 80,
        Filter: (
          <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            {totalCab > 0 ? formatoNumero(protXcab / totalCab, 2) : 0}
          </span>
        ),
      },
    ];

    // style: {
    //     cursor: "pointer",
    //     fontSize: 25,
    //     padding: "0",
    //     textAlign: "right",
    //     userSelect: "none"
    //   }

    const { activeIndex } = this.state;

    return (
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                  style={{
                    backgroundColor: "white",
                    fontWeight: "bold",
                    fontSize: "1.4em",
                    padding: "4px",
                  }}
                >
                  <Icon name="dropdown" />
                  <span>Ver lista de productos consumidos</span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <ListaConsumos
                    productos={this.productos}
                    listaIngredientes={listaIngredientes}
                  />
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row style={{ textAlign: "right" }}>
                <SetupColumns
                  setColumns={this.setColumns}
                  columnas={this.state}
                />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <ReactTable
              data={listaDescarga}
              noDataText={!this.cargando ? "No hay descargas registradas" : ""}
              columns={columns}
              loading={this.cargando}
              LoadingComponent={({
                className,
                loading,
                loadingText,
                ...rest
              }) => {
                if (loading)
                  return (
                    <div
                      style={{
                        position: "fixed",
                        zIndex: 1000,
                        margin: "5% auto",
                        left: 0,
                        right: 0,
                      }}
                    >
                      <CustomLoader />
                    </div>
                  );
                return <div></div>;
              }}
              defaultPageSize={25}
              showPaginationTop
              showPaginationBottom
              filterable
              className="-striped -highlight"
              previousText="Previo"
              nextText="Siguiente"
              loadingText="Cargando"
              pageText="Pagina"
              ofText="de"
              rowsText="Filas"
              getTdProps={(state, rowInfo, column) => {
                if (
                  column &&
                  column.id === "remanente" &&
                  rowInfo &&
                  rowInfo.row &&
                  rowInfo.row.remanente
                ) {
                  return {
                    style: semaforoRemanente(
                      rowInfo.row.remanente,
                      this.empresa
                    ),
                  };
                }
                if (
                  column &&
                  column.id === "diferencia" &&
                  rowInfo &&
                  rowInfo.row &&
                  rowInfo.row.remanente
                ) {
                  return {
                    style: semaforoFaltante(
                      rowInfo.row.remanente,
                      this.empresa
                    ),
                  };
                }
                return {
                  style: {
                    backgroundColor: "white",
                  },
                };
              }}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    filtrosDescargas: state.personalStore.filtrosDescargas,
    ingredientes: state.personalStore.ingredientes,
  };
}

export default connect(mapStateToProps)(ListaDescargas);
