import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Button, Modal, Icon, Message, Dimmer, Loader, Segment } from 'semantic-ui-react'

import Api from './../api'

const api = new Api();

/**
 * Entrada por cosecha
 * Entrada por compra
 * Entrada por traslado
 * Entrada Ajuste Positivo
 * Salida por traslado
 * Salida por Ajuste Negativo
 */
const conceptos = [
  { key: 'cosecha', text: 'Entrada por cosecha', value: 'Entrada por cosecha' },
  { key: 'compra', text: 'Entrada por compra', value: 'Entrada por compra' },
  { key: 'trasladop', text: 'Entrada por traslado', value: 'Entrada por traslado' },
  { key: 'apositivo', text: 'Entrada Ajuste Positivo', value: 'Entrada Ajuste Positivo' },
  { key: 'trasladon', text: 'Salida por traslado', value: 'Salida por traslado' },
  { key: 'anegativo', text: 'Salida por Ajuste Negativo', value: 'Salida por Ajuste Negativo' }
]

class StockNew extends Component {

  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.productos = props.productos;
    this.db = props.db;
    this.error = [];

    this.options = this.productos.map((value) => { 
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
    });

    this.options = this.options.filter((element)=> {
      if (!element.activo) return false;
      return true;
    }) 

    this.state = {
      cargando: false,
      modalOpen: false,
      alerta: false,
      concepto: "",
      detalle: "",
      producto: "",
      entrada: 0,
      salida: 0,
    };

  }

  selectedConcepto = (value) => {

    if (value === 'Salida por traslado' || value === 'Salida por Ajuste Negativo' ){
      this.setState({ concepto: value, disabledEntrada: true, entrada: 0 })
    }else{
      this.setState({ concepto: value, disabledEntrada: false, salida: 0 })
    }
  }  
  
  save = () => {
    this.error = [];
    var _this = this;
    
    if(this.state.concepto.trim().length === 0) this.error.push('El concepto es obligatorio.');
    if(this.state.producto.trim().length === 0) this.error.push('El producto es obligatorio.');
    if(this.state.entrada === 0 && this.state.salida === 0) this.error.push('Debe ingresar una cantidad de entrada o de salida.');
    if(this.state.entrada > 0 && this.state.salida > 0) this.error.push('Debe ingresar una cantidad de entrada o de salida, no ambos!.');
    if(!this.state.entrada && !this.state.salida) this.error.push('Debe ingresar una cantidad de entrada o de salida.');
    if(this.state.entrada < 0 || this.state.salida < 0) this.error.push('Las entradas y salidas no pueden incluir valores negativos.');

    if(this.error.length === 0){
      this.setState({cargando: true})
      this.db.collection("empresas").doc(this.perfil.empresa).collection('stock').add({
        "concepto": this.state.concepto,
        "detalle": this.state.detalle,
        "producto": this.state.producto,
        "entrada": Number(this.state.entrada) || 0,
        "salida": Number(this.state.salida) || 0,
        "fecha": new Date()
      })
      .then(function(docRef) {
          api.getStock(_this.props.perfil.empresa, _this.props.dispatch)
          api.getStockByDateFinal(_this.perfil.empresa, new Date(), _this.props.dispatch);
          _this.setState({cargando: false, alerta: false, modalOpen: false, concepto: "", entrada: 0, salida: 0, producto: "" }); 
          _this.error = [];
          _this.props.getStock();
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true });
          _this.error.push( `Error adding document: ${error}`);
      });
      //
      // this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }

  render () {
    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                  <Icon name='plus' /> Nuevo movimiento de stock
                </Button>}
        >

        <Modal.Header>Registrar Movimiento</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Select fluid label='Concepto' options={conceptos} placeholder='Concepto' value={this.state.concepto} onChange={(e, { value }) => this.selectedConcepto(value) }/>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Detalle" id="detalle" type="text" placeholder="Detalle" value={this.state.detalle} onChange={(e, { value }) => this.setState({ detalle: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Producto" options={this.options} placeholder='Seleccionar Producto' value={this.state.producto} onChange={(e, { value }) => this.setState({ producto: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input disabled={this.state.disabledEntrada} label="Entrada" id="entrada" type="number" min="0" step="1" placeholder="Entrada" value={this.state.entrada} onChange={(e, { value }) => this.setState({ entrada: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input disabled={!this.state.disabledEntrada} label="Salida" id="salida" type="number" min="0" step="1" placeholder="Salida" value={this.state.salida} onChange={(e, { value }) => this.setState({ salida: value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, concepto: "", entrada: 0, salida: 0, producto: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header="Verifique los errores..."
              list={this.error}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default connect()(StockNew);