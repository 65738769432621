import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Table } from "semantic-ui-react";
import ResumenMovimientos from "./components/ResumenMovimientos";

import moment from "moment";

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-IN", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

class Reporte extends Component {
  render() {
    const produccion =
      this.props.resultados.salidas - this.props.resultados.entradas;
    const ventas = this.props.resultados.ventas;
    const compras = this.props.resultados.compras;
    const hoteleria = this.props.hoteleria;
    const sanidad = this.props.sanidad;
    // const raciones = this.props.raciones;
    const raciones = this.props.resultados.raciones;
    const ms = this.props.ms;
    const conversion = produccion > 0 ? ms / produccion : 0;
    const entradaAbsoluta = this.props.resultados.cabAcumuladas;
    const mcal = this.props.mcal;
    const prot = this.props.prot;
    const { movimientos, rodeos } = this.props;

    let fechaInicial = movimientos[0].fecha;
    let fechaFinal = movimientos[movimientos.length - 1].fecha;

    fechaInicial = fechaInicial.toDate
      ? fechaInicial.toDate()
      : new Date(fechaInicial);
    fechaFinal = fechaFinal.toDate ? fechaFinal.toDate() : new Date(fechaFinal);

    const diasTotales =
      moment(fechaFinal).diff(moment(fechaInicial), "days") + 1;

    let costoAlimentacion = 0;
    Object.keys(this.props.productos).map((c) => {
      costoAlimentacion +=
        this.props.productos[c].kilos * this.props.productos[c].precio;
    });

    let adpv = 0;
    if (raciones > 0) {
      adpv = produccion / raciones;
    }

    let conversioncalc = 0;
    if (produccion > 0) conversioncalc = ms / produccion;

    let diasEnc = 0;
    if (entradaAbsoluta > 0) {
      diasEnc = Number(raciones) / entradaAbsoluta;
    }

    let existenciaPromedio = 0;
    if (diasTotales > 0) {
      existenciaPromedio = raciones / diasTotales;
    }

    let consumo = 0;
    if (raciones > 0) {
      consumo = ms / raciones;
    }
    let mcalgdpv = 0;
    if (adpv > 0) {
      mcalgdpv = mcal / adpv;
    }
    let protgdpv = 0;
    if (adpv > 0) {
      protgdpv = prot / adpv;
    }

    let costokiloms = 0;
    if (consumo > 0 && raciones > 0) {
      costokiloms = costoAlimentacion / raciones / consumo;
    }

    let ingreso_neto_por_kg = 0;
    if (produccion > 0) {
      ingreso_neto_por_kg = (ventas - compras) / produccion;
    }

    let ingreso_por_dia_por_cabeza = ingreso_neto_por_kg * adpv;

    let gast_alim_por_racion = 0;
    if (raciones > 0) {
      gast_alim_por_racion = costoAlimentacion / raciones;
    }

    let sanitario_por_cabeza_por_dia = 0;
    if (diasEnc > 0) {
      sanitario_por_cabeza_por_dia = sanidad / diasEnc;
    }

    let alimimentacion_x_kg = 0;
    if (produccion > 0) {
      alimimentacion_x_kg = costoAlimentacion / produccion;
    }

    let sanitario_x_kg = 0;
    if (adpv > 0) {
      sanitario_x_kg = sanitario_por_cabeza_por_dia / adpv;
    }

    let hoteleria_x_kg = 0;
    if (adpv > 0) {
      hoteleria_x_kg = hoteleria / adpv;
    }

    return (
      <Grid.Row columns={2}>
        <Grid.Column floated="right">
          <Table celled color="black">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  RESUMEN DE MOVIMIENTOS
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Concepto</Table.HeaderCell>
                <Table.HeaderCell>Cabezas</Table.HeaderCell>
                <Table.HeaderCell>Kilos promedios</Table.HeaderCell>
                <Table.HeaderCell>Kilos totales</Table.HeaderCell>
                <Table.HeaderCell>Precio promedio</Table.HeaderCell>
                <Table.HeaderCell>Monetizacion total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <ResumenMovimientos movimientos={movimientos} />
          </Table>
        </Grid.Column>

        <br />

        <Grid.Column>
          <Table celled color="brown">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  RESULTADOS GENERALES
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Raciones suministradas</Table.Cell>
                <Table.Cell textAlign="right">
                  {/* Autoconsumo: {formatear(this.props.resultados.raciones)} / Por
                  descargas: */}
                  {formatear(raciones)}{" "}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Total de cabezas ingresadas</Table.Cell>
                <Table.Cell textAlign="right">{entradaAbsoluta}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Dias de encierre promedio</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(raciones / entradaAbsoluta, 1)}
                  {" dias"}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Dias del periodo seleccionado</Table.Cell>
                <Table.Cell textAlign="right">{diasTotales}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  Existencia promedio (durante los {diasTotales} dias)
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(existenciaPromedio)}{" "}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Produccion de Carne</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(produccion)} Kg
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Consumo de Materia Seca</Table.Cell>
                <Table.Cell textAlign="right">{formatear(ms)} Kg</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>ADPV</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(adpv, 2)} kg
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Conversion</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(conversion, 2)} a 1
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              {/* {rodeos.length === 1 && (
                <Table.Row>
                  <Table.Cell>Dias de permanencia</Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatear(diasEnc)}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )} */}
              <Table.Row>
                <Table.Cell>Ingreso Neto por Produccion</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(ventas - compras)}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Gasto de Alimentacion</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(costoAlimentacion)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {ventas - compras > 0
                    ? formatear(
                        (costoAlimentacion / (ventas - compras)) * 100,
                        2
                      )
                    : 0}{" "}
                  %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Gasto de Hoteleria</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(hoteleria * raciones)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {ventas - compras > 0
                    ? formatear(
                        ((hoteleria * raciones) / (ventas - compras)) * 100,
                        2
                      )
                    : 0}{" "}
                  %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Gasto Sanitario</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(sanidad * entradaAbsoluta)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {ventas - compras > 0
                    ? formatear(
                        ((sanidad * entradaAbsoluta) / (ventas - compras)) *
                          100,
                        2
                      )
                    : 0}{" "}
                  %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Resultado por Produccion</Table.Cell>
                <Table.Cell textAlign="right">
                  ${" "}
                  {formatear(
                    ventas -
                      compras -
                      costoAlimentacion -
                      hoteleria * raciones -
                      sanidad * entradaAbsoluta
                  )}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {ventas - compras > 0
                    ? formatear(
                        ((ventas -
                          compras -
                          costoAlimentacion -
                          hoteleria * raciones -
                          sanidad * entradaAbsoluta) /
                          (ventas - compras)) *
                          100,
                        2
                      )
                    : 0}{" "}
                  %
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Concentracion Energia de la Dieta</Table.Cell>
                <Table.Cell textAlign="right">{formatear(mcal, 2)}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Proteina de la Dieta</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(prot, 2)} %
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Consumo ms/cab/dia</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(consumo, 2)} kg
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Consumo mega calorias diarias</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(mcal * consumo, 2)} Mcal
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Consumo proteinas diarias</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear((consumo * prot) / 100, 2)} / kg
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Resultado/dia/cab</Table.Cell>
                <Table.Cell textAlign="right">
                  ${" "}
                  {formatear(
                    ingreso_por_dia_por_cabeza -
                      gast_alim_por_racion -
                      sanitario_por_cabeza_por_dia -
                      hoteleria,
                    2
                  )}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>

        <br />

        <Grid.Column>
          <Table celled color="brown">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  RESULTADOS POR KG PRODUCIDO
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>MCal/kg ganancia de peso</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear((mcal * consumo) / adpv, 2)}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Prot/kg ganancia de peso</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear((consumo * prot) / 100 / adpv, 2)} / kg
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Costo/kg MS de alimento</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(costokiloms, 2)}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Ingreso Neto/kg Producido</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(ingreso_neto_por_kg, 2)}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Gasto Alim x kg</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(alimimentacion_x_kg, 2)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(
                    ingreso_neto_por_kg > 0
                      ? (alimimentacion_x_kg / ingreso_neto_por_kg) * 100
                      : 0,
                    2
                  )}{" "}
                  %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Gasto Hoteleria x kg</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(hoteleria_x_kg, 2)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(
                    ingreso_neto_por_kg > 0
                      ? (hoteleria_x_kg / ingreso_neto_por_kg) * 100
                      : 0,
                    2
                  )}{" "}
                  %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Gasto Sanitario x kg</Table.Cell>
                <Table.Cell textAlign="right">
                  $ {formatear(sanitario_x_kg, 2)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(
                    ingreso_neto_por_kg > 0
                      ? (sanitario_x_kg / ingreso_neto_por_kg) * 100
                      : 0,
                    2
                  )}{" "}
                  %
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Resultado x kg</Table.Cell>
                <Table.Cell textAlign="right">
                  ${" "}
                  {formatear(
                    ingreso_neto_por_kg -
                      alimimentacion_x_kg -
                      sanitario_x_kg -
                      hoteleria_x_kg,
                    2
                  )}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {formatear(
                    ingreso_neto_por_kg > 0
                      ? ((ingreso_neto_por_kg -
                          alimimentacion_x_kg -
                          sanitario_x_kg -
                          hoteleria_x_kg) /
                          ingreso_neto_por_kg) *
                          100
                      : 0,
                    2
                  )}{" "}
                  %
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.personalStore.nuevoReporte,
  };
};

export default connect(mapStateToProps)(Reporte);
