import React, { Component } from "react";
import firebase from "../firebase";
import PropTypes from "prop-types";
import { Segment, Image } from "semantic-ui-react";

import { connect } from "react-redux";

import {
  initialFetch,
  fetchClientes,
  fetchEmpresa,
  fetchRodeosInactivos,
  fetchRodeosActivos,
  fetchReporteVentas,
  fetchCategorias,
  fetchMovRodeos,
  fetchProductos,
  fetchDetalleDietas,
  fetchDietas,
  setDemanda,
} from "../actions";

export default function (ComposedComponent) {
  class RequiereDemanda extends Component {
    static propTypes = {
      perfil: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.db = firebase.firestore();
      this.perfil = props.perfil;

      this.activarListener = props.initialFetch;
      this.fetchReporteVentas = props.fetchReporteVentas;
      this.fetchEmpresa = props.fetchEmpresa;
      this.fetchRodeosInactivos = props.fetchRodeosInactivos;
      this.fetchRodeosActivos = props.fetchRodeosActivos;
      this.fetchCategorias = props.fetchCategorias;
      this.fetchMovRodeos = props.fetchMovRodeos;
      this.fetchClientes = props.fetchClientes;
      this.fetchDietas = props.fetchDietas;

      this.fetchDetalleDietas = props.fetchDetalleDietas;
      this.fetchProductos = props.fetchProductos;
      this.setDemanda = props.setDemanda;

      this.state = {
        width: 450,
        height: 304,
        dietas: props.dietas || [],
        detalledietas: props.detalledietas || [],
        productos: props.productos || [],
        descripcion: "",
        listeners: props.listeners || [],
        clientes: props.clientes || [],
        reportes_ventas: props.reportes_ventas || [],
        rodeos_activos: props.rodeosActivos || [],
        rodeos_inactivos: props.rodeosInactivos || [],
        categorias: props.categorias || [],
        movrodeos: props.movrodeos || [],
        empresa: props.empresa,
        iva: props.empresa.iva || 10.5,
      };
    }

    componentWillMount = () => {
      const empresa = { id: "empresa" };

      const oyenteEmpresa = this.state.listeners.find(function (element) {
        return element.id === "empresa";
      });

      if (oyenteEmpresa === undefined) {
        this.activarListener(this.perfil, empresa);
        this.fetchEmpresa(this.perfil);
      }

      const cliente = { id: "clientes" };

      const oyenteCliente = this.state.listeners.find(function (element) {
        return element.id === "clientes";
      });

      if (oyenteCliente === undefined) {
        this.activarListener(this.perfil, cliente);
        this.fetchClientes(this.perfil);
      }

      const categorias = { id: "categorias" };

      const oyenteCategorias = this.state.listeners.find(function (element) {
        return element.id === "categorias";
      });

      if (oyenteCategorias === undefined) {
        this.activarListener(this.perfil, categorias);
        this.fetchCategorias(this.perfil);
      }

      const itemReportes = { id: "reporteVentas" };

      const oyenteReportes = this.state.listeners.find(function (element) {
        return element.id === "reporteVentas";
      });

      if (oyenteReportes === undefined) {
        this.activarListener(this.perfil, itemReportes);
        this.fetchReporteVentas(this.perfil);
      }
    };
    componentDidMount = () => {
      const rodeosActivos = { id: "rodeos_activos" };
      const rodeosInactivos = { id: "rodeos_inactivos" };

      const oyenteActivos = this.state.listeners.find(function (element) {
        return element.id === "rodeos_activos";
      });

      if (oyenteActivos === undefined) {
        this.activarListener(this.perfil, rodeosActivos);
        this.fetchRodeosActivos(this.perfil);
      }

      const dietas = "dietas";
      const oyenteDietas = this.state.listeners.find(function (element) {
        return element.id === "dietas";
      });

      if (oyenteDietas === undefined) {
        this.activarListener(this.perfil, dietas);
        this.fetchDietas(this.perfil);
      }

      const oyenteInactivos = this.state.listeners.find(function (element) {
        return element.id === "rodeos_inactivos";
      });

      if (oyenteInactivos === undefined) {
        this.activarListener(this.perfil, rodeosInactivos);
        this.fetchRodeosInactivos(this.perfil);
      }

      const movimientos = { id: "movrodeos" };

      const oyenteMovimientos = this.state.listeners.find(function (element) {
        return element.id === "movrodeos";
      });

      if (oyenteMovimientos === undefined) {
        this.activarListener(this.perfil, movimientos);
        this.fetchMovRodeos(this.perfil);
      }

      const productos = { id: "productos" };

      const oyenteProductos = this.state.listeners.find(function (element) {
        return element.id === "productos";
      });

      if (oyenteProductos === undefined) {
        this.activarListener(this.perfil, productos);
        this.fetchProductos(this.perfil);
      }
    };

    componentWillReceiveProps = (nextProps) => {
      this.setState({
        dietas: nextProps.dietas || [],
        detalledietas: nextProps.detalledietas || [],
        dietas: nextProps.dietas || [],
        productos: nextProps.productos || [],
        listeners: nextProps.listeners || [],
        clientes: nextProps.clientes || [],
        movrodeos: nextProps.movrodeos || [],
        reportes_ventas: nextProps.reportes_ventas || [],
        rodeos_activos: nextProps.rodeosActivos || [],
        rodeos_inactivos: nextProps.rodeosInactivos || [],
        categorias: nextProps.categorias || [],
        empresa: nextProps.empresa,
        iva: nextProps.empresa.iva || 10.5,
      });
    };

    formatoMoneda = (valor) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(valor);
    };

    formatoNumero = (valor, decimales) => {
      return new Intl.NumberFormat("de-DE", {
        maximumFractionDigits: decimales,
      }).format(valor);
    };

    prettyDate = (date) => {
      var months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];

      return (
        date.getDate() +
        " " +
        months[date.getMonth()] +
        " " +
        date.getFullYear()
      );
    };

    buscarDietas = (dietas) => {
      Object.keys(dietas).map((value) => {
        this.buscarDetalleDieta(value);
      });
    };

    buscarDetalleDieta = (id) => {
      if (id) {
        const item = { id: id };

        var oyente = this.state.listeners.find(function (element) {
          return element.id === id;
        });

        if (oyente === undefined) {
          this.activarListener(this.perfil, item);
          this.fetchDetalleDietas(this.perfil, id);
        }
      }
    };

    setearDemanda = (productos) => {
      this.setDemanda(productos);
    };
    render() {
      //tropas activas
      let listaVentaReporte = this.state.rodeos_activos.map((value) => {
        var f1 = new Date();
        f1.setHours(0);
        f1.setMinutes(0);
        f1.setSeconds(0);
        f1.setMilliseconds(0);

        var f2 = value.ultimaFechaCalculada
          ? value.ultimaFechaCalculada.toDate
            ? value.ultimaFechaCalculada.toDate()
            : new Date(value.ultimaFechaCalculada)
          : new Date();
        f2.setHours(0);
        f2.setMinutes(0);
        f2.setSeconds(0);
        f2.setMilliseconds(0);

        const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * value.gdpv;
        const pesoActual =
          (Number(value.ultimaPesada) ? value.ultimaPesada : 0) +
          Number(aumento);

        //value.metaDiasEncierre
        //fecha de salida
        let fechaEstimada = new Date();
        let diasEncierre =
          new Date().getTime() -
          (value.fechaAlta.toDate
            ? value.fechaAlta.toDate().getTime()
            : new Date(value.fechaAlta).getTime());
        diasEncierre = Number(this.formatoNumero(diasEncierre / 86400000, 0));

        let dias = 0;
        //kilos
        const metaDias = Number(value.metaDiasEncierre) || 0;
        const metaKilos = Number(value.metaKilos) || 0;
        let kilos = metaKilos;
        if (value.seguir) {
          if (pesoActual >= metaKilos && metaKilos > 0) {
            kilos = pesoActual;
            fechaEstimada = new Date();
          } else if (diasEncierre >= metaDias && metaDias > 0) {
            fechaEstimada = new Date();
          } else {
            if (metaDias > 0 || metaKilos > 0) {
              let faltantePorKilos = 0;
              let faltantePorDias = 0;
              if (metaKilos > 0) {
                faltantePorKilos = (metaKilos - pesoActual) / value.gdpv;
              }
              if (metaDias > 0) {
                faltantePorDias = metaDias - diasEncierre;
              }

              if (metaDias === 0) {
                dias = Math.ceil(faltantePorKilos);
                fechaEstimada = new Date(
                  fechaEstimada.getTime() +
                    Math.ceil(faltantePorKilos) * 86400000
                );
              }

              if (metaKilos === 0) {
                dias = Math.ceil(faltantePorDias);
                fechaEstimada = new Date(
                  fechaEstimada.getTime() +
                    Math.ceil(faltantePorDias) * 86400000
                );
              }

              if (faltantePorKilos <= faltantePorDias && metaKilos > 0) {
                dias = Math.ceil(faltantePorKilos);
                fechaEstimada = new Date(
                  fechaEstimada.getTime() +
                    Math.ceil(faltantePorKilos) * 86400000
                );
              }

              if (faltantePorDias <= faltantePorKilos && metaDias > 0) {
                dias = Math.ceil(faltantePorDias);
                fechaEstimada = new Date(
                  fechaEstimada.getTime() +
                    Math.ceil(faltantePorDias) * 86400000
                );
              }
            }
          }
        } else {
          if (diasEncierre >= metaDias) {
            fechaEstimada = new Date();
          } else {
            dias = metaDias - diasEncierre;
            fechaEstimada = new Date(
              fechaEstimada.getTime() +
                Math.ceil(metaDias - diasEncierre) * 86400000
            );
          }
        }

        let categoria = this.state.categorias.find(
          (c) => c.id === value.categoria
        );

        let fechaCobro = fechaEstimada;

        if (value.plazo)
          fechaCobro = new Date(fechaCobro.getTime() + value.plazo * 86400000);

        return {
          dias,
          doc: value.doc,
          id: value.id + fechaEstimada,
          fecha: fechaEstimada,
          fechaCobro,
          cliente: "Sin definir",
          dieta: value.dieta,
          rodeo: value.nombre,
          categoria: categoria ? categoria.nombre : "-",
          cabezas: value.cabezas,
          kilos,
          plazo: value.plazo || 0,
          consumo_estimado: value.consumo_estimado || 0,
          precio: value.precioventa || 0,
          neto: value.cabezas * categoria.venta * kilos,
          total:
            value.cabezas *
            categoria.venta *
            kilos *
            (this.state.iva / 100 + 1),
          vender: value.vender ? value.vender : false,
          iva: Number(this.state.iva),
          vendido: false,
        };
      });

      const listaReduced = listaVentaReporte.reduce((dieta, item) => {
        const diet = this.state.dietas.find((d) => d.id === item.dieta);
        let nombre = "Sin definir";
        if (diet) {
          nombre = diet.nombre;
        }
        let val = item.dieta;
        dieta[val] = dieta[val] || { dieta: val, nombre, consumo: 0 };
        dieta[val].consumo += Number(
          Number(item.cabezas) * Number(item.consumo_estimado) * item.dias
        );
        return dieta;
      }, {});

      this.buscarDietas(listaReduced);

      let lista_productos = [];

      if (
        Object.keys(listaReduced).length > 0 &&
        this.state.detalledietas.length > 0 &&
        this.state.productos.length > 0
      ) {
        let productos = [];

        this.state.detalledietas.map((dieta) => {
          dieta.detalle.map((producto) => {
            productos.push({
              dieta: dieta.id,
              producto: producto.producto,
              cantidad: producto.cantidad,
            });
          });
        });

        this.state.productos.map((producto) => {
          let detalle = [];
          let total = 0;
          let totalms = 0;
          const coeficiente =
            producto.materia > 0 ? 1 / (producto.materia / 100) : 0;

          Object.keys(listaReduced).map((dieta) => {
            const pd = productos.find(
              (p) => p.dieta === dieta && p.producto === producto.id
            );
            let cantidad = pd ? pd.cantidad : 0;
            let subtotal =
              cantidad > 0 ? listaReduced[dieta].consumo / (100 / cantidad) : 0;
            detalle.push({ dieta, subtotal: subtotal * coeficiente });
            totalms += subtotal;
            total += subtotal * coeficiente;
          });

          let p = {
            precio: producto.precio,
            detalle,
            totalms,
            total,
            id: producto.id,
            nombre: producto.nombre,
            stock: producto.stock > 0 ? producto.stock : 0,
            diferencia: producto.stock - total,
          };
          lista_productos.push(p);
        });
      }

      if (
        lista_productos.length === 0 &&
        this.state.rodeos_activos.length > 0
      ) {
        return (
          <Segment loading>
            <Image src="/assets/paragraph.png" />
          </Segment>
        );
      }

      this.setearDemanda(lista_productos);

      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state, props) {
    return {
      dietas: state.personalStore.dietas,
      productos: state.personalStore.productos,
      detalledietas: state.personalStore.detalledietas,
      clientes: state.personalStore.clientes,
      movrodeos: state.personalStore.movrodeos,
      empresa: state.personalStore.empresa,
      categorias: state.personalStore.categorias,
      reportes_ventas: state.personalStore.reportes_ventas,
      rodeosActivos: state.personalStore.rodeosActivos,
      rodeosInactivos: state.personalStore.rodeosInactivos,
      listeners: state.personalStore.listeners,
    };
  }

  return connect(mapStateToProps, {
    initialFetch,
    fetchClientes,
    fetchEmpresa,
    fetchRodeosInactivos,
    fetchRodeosActivos,
    fetchReporteVentas,
    fetchCategorias,
    fetchMovRodeos,
    fetchProductos,
    fetchDetalleDietas,
    fetchDietas,
    setDemanda,
  })(RequiereDemanda);
}
