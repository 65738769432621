const descargas = (state = [], action) => {
    switch (action.type) {
        case 'ADD_DESCARGAS':
                if (state.length === 0){
                    let descargas = [];
                   Object.keys(action.payload).forEach((d)=>{
                        if (!state.map(m => m.id).includes(d)){
                      descargas.push({ ...action.payload[d], id: d });
                        }
                    })
                    return descargas
                }else{
                    let descargas = [];
                    Object.keys(action.payload).forEach((d)=>{
                        if (!state.map(m => m.id).includes(d)){
                            descargas.push({ ...action.payload[d], id: d });
                        }
                    })
                    return state.concat(descargas);
                }
            //}
        case 'EDIT_DESCARGA':
            return state.map( ( item ) => {

                if(item.id !== action.id) {
                    return item;
                }

                return {
                    ...item,
                    ...action
                };    
            });
        case 'DELETE_DESCARGA':
           return state.filter( (item) => item.id !== action.id);
        case 'RESET_DESCARGAS':
           return [];
        default:
            return state
    }
};

export default descargas;