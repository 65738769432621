
const usuarios = (state = [], action) => {
    switch (action.type) {
        case 'ADD_USUARIO':
            return action.usuarios
        default:
            return state
    }
};

export default usuarios;