
const rodeos_activos = (state = [], action) => {
    switch (action.type) {
        case 'ADD_RODEO_ACTIVO':
           return action.rodeosActivos
        default:
            return state
    }
};

export default rodeos_activos;