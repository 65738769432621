import React, { Component } from 'react';
import { Icon, Modal, Button } from 'semantic-ui-react';

export default class BorrarReporte extends Component {

    constructor(props) {
      super(props);
      this.doc = props.doc;
      this.state = {
        open: false
      }
    }
  
    closeModal = () => {
      this.setState({ open: false })
    }
    render() {
  
      return (
        <Modal
          onClose={this.closeModal}
          open={this.state.open}
          trigger={<Icon size="big" color="red" style={{ cursor: "pointer" }} name="delete" onClick={() => this.setState({ open: true })} />}
          size={'tiny'}
        >
          <Modal.Header>Confirma eliminar el reporte?</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Si confirma se eliminara el documento pdf.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => this.closeModal()} inverted>
              Cancelar
            </Button>
            <Button color='green' onClick={() => this.doc.doc.ref.delete()} inverted>
              <Icon name='checkmark' /> Confirmar
            </Button>
          </Modal.Actions>
        </Modal>
      )
    }
  }