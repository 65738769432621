import React from 'react';
import { firebaseConnect, isEmpty  } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom';

class Home extends React.Component {

  static isPrivate = false;

  constructor(props){
    super(props);

    this.state = ({perfil: props.perfil || null});
  }

  render() {
    if(!isEmpty(this.state.perfil)){
      return <Redirect to="../rodeos" push={true} />
    }

    return <div className="home">  </div>;
  }
}

const wrappedHome = firebaseConnect()(Home)

export default wrappedHome;