import firebase from "../firebase";
import { isEmpty } from "react-redux-firebase";
const db = firebase.firestore();

export const fetchEmpresas = () => {
  return function (dispatch) {
    db.collection("empresas").onSnapshot(function (querySnapshot) {
      let items = [];
      querySnapshot.forEach(function (doc) {
        let item = doc.data();
        item.doc = doc;
        item.id = doc.id;
        items.push(item);
      });
      dispatch(add(items));
    });
  };
};

const add = (empresas) => ({
  type: "ADD_EMPRESAS",
  empresas,
});
