import React, { Component } from 'react';
import  { baseUrl } from '../commons/constants'

export default class PdfDieta extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }
 
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
 
  render() {
    const { pageNumber, numPages } = this.state;
    const { file } = this.props
    return (
      <div style={{height: "90vh"}}>
        <iframe src={`${baseUrl}/archivo/${file}`} title="Detalle de dieta" width={"100%"} height={"100%"}>
            Click aqui: <a href={`${baseUrl}/archivo/${file}`}>Descargar PDF</a>
        </iframe>
      </div>
    );
  }
}