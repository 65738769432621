import firebase from "../firebase";
import { isEmpty } from "react-redux-firebase";
const db = firebase.firestore();

export const fetchRodeosActivos = (perfil) => {
  return function (dispatch) {
    if (!isEmpty(perfil)) {
      db.collection("empresas")
        .doc(perfil.empresa)
        .collection("rodeos")
        .where("activo", "==", true)
        .orderBy("nombre", "asc")
        .get()
        .then((querySnapshot) => {
          let items = [];
          querySnapshot.forEach(function (doc) {
            let item = doc.data();
            item.doc = doc;
            item.id = doc.id;
            items.push(item);
          });
          dispatch(add(items));
        });
      // .onSnapshot(function (querySnapshot) {
      //   let items = [];
      //   querySnapshot.forEach(function (doc) {
      //     let item = doc.data();
      //     item.doc = doc;
      //     item.id = doc.id;
      //     items.push(item);
      //   });
      //   dispatch(add(items));
      // });
    }
  };
};

const add = (rodeosActivos) => ({
  type: "ADD_RODEO_ACTIVO",
  rodeosActivos,
});
