
const consumos_sin_seguimiento = (state = [], action) => {
    switch (action.type) {
        case 'ADD_CONSUMO_SIN_SEGUIMIENTO':
            return action.consumos
        default:
            return state
    }
};

export default consumos_sin_seguimiento;