import firebase from "../firebase";
import { isEmpty } from "react-redux-firebase";
const db = firebase.firestore();

export const fetchCategorias = (perfil) => {
  return function (dispatch) {
    if (!isEmpty(perfil)) {
      db.collection("empresas")
        .doc(perfil.empresa)
        .collection("categorias")
        .orderBy("nombre", "asc")
        .onSnapshot(function (querySnapshot) {
          let items = [];
          querySnapshot.forEach(function (doc) {
            let cat = doc.data();
            cat.id = doc.id;
            items.push(cat);
          });
          dispatch(addCategoria(items));
        });
    }
  };
};

const addCategoria = (categorias) => ({
  type: "ADD_CATEGORIA",
  categorias,
});
