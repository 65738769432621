const initialState = {  
    activos: null,
    allCategorias: true,
    listaCategorias: [],
    allDietas: true,
    listaDietas: [],
    filtros: []
};

const filtrosReportes = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_REPORTES':
            return Object.assign({}, state, {
                ...state,
                ...action
            });
        default:
            return state
    }
}

export default filtrosReportes;