import React from 'react';

import { Grid, Divider, Image, Segment } from 'semantic-ui-react'

export default class TooltipMessage extends React.Component {

    formatoNumero = (valor = 0, decimales = 0) => {
        return new Intl.NumberFormat('de-DE', { maximumFractionDigits: decimales }).format(valor);
    }
    render() {

        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;
            if (payload && payload.length > 0) {


                const datos = payload[0].payload;

                return (
                    <Grid className="custom-tooltip">
                        <Grid.Row centered>
                            <span className="texto-etiquetas">{label}</span>
                        </Grid.Row>
                        <Grid.Row centered>

                            <Divider />
                            {datos.clima &&
                                // ITH = (1,8 x T° + 32) – (0,55 – 0,55 x HR / 100) x (1,8 x T° – 26)
                                <div className="content-box-ith">
                                    <span className="texto-etiquetas">{`ITH: ${this.formatoNumero(datos.ITH, 2)}`}</span>
                                </div>
                            }
                            <div className="content-box-ms">
                                <span className="texto-etiquetas">{`Materia Seca: ${this.formatoNumero(datos.Materia_Seca, 2)} kg`}</span>
                            </div>
                            <div className="content-box-mc">
                                <span className="texto-etiquetas">{`Megacalorias: ${this.formatoNumero(datos.Megacalorias, 2)}`}</span>
                            </div>
                            <Divider />
                            {datos.clima &&
                                <Segment>
                                    <Image src={`../assets/day/${datos.icon}`} size='tiny' floated='left' />
                                    <span >{`Temp: ${datos.temp_c}C°`}</span>
                                </Segment>
                            }
                            {datos.clima &&
                                <p >{`${datos.condition}`}</p>
                            }
                            {datos.clima &&
                                <span>{`Precipitación: ${datos.precipitacion}mm`}</span>
                            }
                            {datos.clima &&
                                <span >{`Humedad: ${datos.humidity}%`}</span>
                            }

                            <Divider />

                            <span >{`Cabezas: ${datos.Cabezas}`}</span>
                            <span >{`Dieta: ${datos.dieta}`}</span>
                            <span >{`Energia de dieta: ${this.formatoNumero(datos.calidad, 2)}`}</span>
                        </Grid.Row>
                    </Grid >
                )
            }
        }

        return (
            <p>Sin Datos</p>
        )
    }
}