import moment from "moment";

let inicio = new Date(new Date().getTime() - 604800000);
inicio.setHours(0);
inicio.setMinutes(0);

let final = new Date();
final.setHours(23);
final.setMinutes(59);

const initialState = {
  fechaInicio: moment(inicio),
  fechaFinal: moment(final),
  allDays: true,
  listaDias: [],
  allCorrales: true,
  listaCorrales: [],
  allRodeos: true,
  listaRodeos: [],
  allCategorias: true,
  listaCategorias: [],
  allDietas: true,
  listaDietas: [],
  filtros: [],
  allMixer: true,
  listaMixer: [],
};

const filtrosDescargas = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTROS_DESCARGAS":
      return Object.assign({}, state, {
        ...state,
        ...action,
      });
    default:
      return state;
  }
};

export default filtrosDescargas;
