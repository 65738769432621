import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';


export default class BorrarDescarga extends Component {


    state = { open: false }
  
    show = size => () => this.setState({ size, open: true })
    close = () => this.setState({ open: false })
  

    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
                      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
        
        var minutos = date.getMinutes();
        if (minutos < 10) minutos = '0' + minutos;
      
        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getHours() + ':' + minutos;
    }
    
    render() {
      const { open, size } = this.state
  
      return (
            
          <Modal size={size} open={open} onClose={this.close}
                trigger={ <Button circular color={this.props.color} icon="delete" className="ui floated right" onClick={this.show('small')}/>}
                >

            <Modal.Header>
              Eliminar {this.props.accion}
            </Modal.Header>
            <Modal.Content>
              <p>Esta seguro de eliminar la {this.props.accion} del dia { this.prettyDate( (this.props.descarga.fecha instanceof Object) ? this.props.descarga.fecha.toDate() : new Date(this.props.descarga.fecha) ) }</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={()=> this.setState({open: false})}>
                No
              </Button>
              <Button positive icon='checkmark' labelPosition='right' content='Si' onClick={()=> {  this.props.descarga.doc.ref.delete();  this.setState({open: false});  } }/>
            </Modal.Actions>
          </Modal>


      )
    }
  }