import React, { Component } from 'react'
import { connect } from 'react-redux';
import { EDITAR_MOVIMIENTO } from './actions'
import { Input } from 'semantic-ui-react'

class InputKilos extends Component {

    handleChange = (value) => {
        const { dispatch, id } = this.props
        if (!isNaN(value)){
            dispatch({
                type: EDITAR_MOVIMIENTO,
                payload: {
                    id,
                    value: Number(value)
                }
            })
        }
    }

    render() {
        return (
            <Input fluid value={this.props.value} icon='balance' placeholder='Kilos...' onChange={(val, {value}) => this.handleChange(value)}/>
        )
    }
}

export default connect()(InputKilos)