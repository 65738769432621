
const rodeos_inactivos = (state = [], action) => {
    switch (action.type) {
        case 'ADD_RODEO_INACTIVO':
            return action.rodeosInactivos
        default:
            return state
    }
};

export default rodeos_inactivos;