const initialState = { };

const empresa = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_EMPRESA':
            return Object.assign({}, state, {
                ...state,
                 ...action
            });
        default:
            return state
    }
}

export default empresa;