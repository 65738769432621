import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
// import { Loader, Dimmer } from 'semantic-ui-react'
import ListaDescargas from '../components/ListaDescargas';

import firebase from './../firebase';

import Api from './../api'

const api = new Api();
const db = firebase.firestore();

class Descargas extends Component {

  static isPrivate = true;

  constructor(props) {
    super(props);

    this.perfil = props.perfil;

    this.state = ({
      descargas: props.descargas || [],
      empresa: props.empresa,
      corrales: props.corrales,
      productos: props.productos,
      dietas: props.dietas,
      categorias: props.categorias,
      usuarios: props.usuarios,
      mixers: props.mixers,
      rodeos: props.rodeos
    })

    this.borrando = false

  }



  descargasByRodeo = (rodeos) => {
    const { filtrosDescargas } = this.props;
    const { fechaInicio, fechaFinal } = filtrosDescargas;
    api.fetchDescargas(this.props.perfil.empresa, rodeos, this.props.dispatch, fechaInicio, fechaFinal);
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.empresa && nextProps.rodeos && nextProps.rodeos !== this.state.rodeos){
      this.descargasByRodeo(nextProps.rodeos);
    }
    this.setState({
      descargas: nextProps.descargas,
      empresa: nextProps.empresa,
      corrales: nextProps.corrales,
      productos: nextProps.productos,
      dietas: nextProps.dietas,
      categorias: nextProps.categorias,
      rodeos: nextProps.rodeos,
      mixers: nextProps.mixers,
      usuarios: nextProps.usuarios,
    })
  }

  fechaCorta = (date) => {
    var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
  }

  render() {
    if (this.state.descargas === undefined ||
      this.state.categorias === undefined ||
      this.state.empresa === undefined ||
      this.state.corrales === undefined ||
      this.state.dietas === undefined ||
      this.state.productos === undefined ||
      this.state.usuarios === undefined ||
      this.state.mixers === undefined ||
      this.state.rodeos === undefined)
      return (<ListaDescargas perfil={this.props.perfil} cargando={true} descargas={[]} usuarios={[]} mixers={[]} />)

    // return (<Dimmer active><Loader /></Dimmer>)

    if (this.state.descargas === null ||
      this.state.categorias === null ||
      this.state.empresa === null ||
      this.state.corrales === null ||
      this.state.dietas === null ||
      this.state.productos === null ||
      this.state.usuarios === null ||
      this.state.mixers === null ||
      this.state.rodeos === null)
      return (<ListaDescargas cargando={false} perfil={this.props.perfil} corrales={this.state.corrales} productos={this.state.productos} empresa={this.state.empresa} rodeos={this.state.rodeos} dietas={this.state.dietas} categorias={this.state.categorias} descargas={[]} usuarios={this.state.usuarios} mixer={this.state.mixers} />)

    return (
      <ListaDescargas perfil={this.props.perfil} cargando={false} corrales={this.state.corrales} productos={this.state.productos} empresa={this.state.empresa} rodeos={this.state.rodeos} dietas={this.state.dietas} categorias={this.state.categorias} descargas={this.state.descargas} usuarios={this.state.usuarios} mixers={this.state.mixers} />
    )
  }
}


export default compose(
  firestoreConnect((props, state) => {

    return [
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        storeAs: 'empresa'
      },
      // {
      //   collection: 'empresas',
      //   doc: props.perfil.empresa,
      //   subcollections: [{ collection: 'descargas' }],
      //   orderBy: [['fecha', 'desc']],
      //   where: filtrosDescarga,
      //   storeAs: 'descargas'
      // },
      {
        collection: 'usuarios',
        storeAs: 'usuarios'
      },
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        subcollections: [{ collection: 'mixers' }],
        storeAs: 'mixers'
      },
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        subcollections: [{ collection: 'corrales' }],
        orderBy: [['nombre', 'asc']],
        storeAs: 'corrales'
      },
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        subcollections: [{ collection: 'productos' }],
        orderBy: [['nombre', 'asc']],
        storeAs: 'productos'
      },
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        subcollections: [{ collection: 'dietas' }],
        orderBy: [['nombre', 'asc']],
        storeAs: 'dietas'
      },
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        subcollections: [{ collection: 'categorias' }],
        orderBy: [['nombre', 'asc']],
        storeAs: 'categorias'
      },
      {
        collection: 'empresas',
        doc: props.perfil.empresa,
        subcollections: [{ collection: 'rodeos' }],
        orderBy: [['nombre', 'asc']],
        storeAs: 'rodeos'
      }
    ]
  }), // sync todos collection from Firestore into redux
  connect((state, props) => ({
    filtrosDescargas: state.personalStore.filtrosDescargas,
    descargas: state.personalStore.descargas,
    empresa: state.firestore.data.empresa,
    usuarios: state.firestore.data.usuarios,
    mixers: state.firestore.data.mixers,
    corrales: state.firestore.data.corrales,
    productos: state.firestore.data.productos,
    dietas: state.firestore.data.dietas,
    categorias: state.firestore.data.categorias,
    rodeos: state.firestore.data.rodeos
  }))
)(Descargas)