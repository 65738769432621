import React from 'react';
import { connect } from 'react-redux';

import { Grid, Form, Label, Button, Icon, Segment, Divider, Table } from 'semantic-ui-react';

import { initialFetch, fetchDietas, fetchDetalleDietas, fetchProductos } from '../actions'

import $ from "jquery";

import Api from './../api'

const api = new Api();

class Simulador extends React.Component {
    constructor(props) {
        super(props);

        this.perfil = props.perfil;

        this.fetchDietas = props.fetchDietas;
        this.activarListener = props.initialFetch;
        this.fetchDietas = props.fetchDietas;
        this.fetchDetalleDietas = props.fetchDetalleDietas;
        this.fetchProductos = props.fetchProductos;

        this.state = {
            listeners: props.listeners || [],
            demanda: props.demanda || [],
            productos: props.productos || [],
            detalledietas: props.detalledietas || [],
            cabezas: 0,
            kg_entrada: 0,
            precio_compra: 0,
            precio_venta: 0,
            sanidad: 0,
            hoteleria: 0,
            gdpv: 0,
            dias_dieta: 0,
            kg_objetivo: 0,
            consumo_estimado: 0,
            mortandad: 0,
            dietas: props.dietas || [],
            dias: 0,
            dieta: "",
            dietas_seleccionadas: []
        }

    }

    getStock = () =>  api.getStock(this.props.perfil.empresa, this.props.dispatch)

    componentDidMount = async () => {
        this.getStock()
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            listeners: nextProps.listeners || [],
            demanda: nextProps.demanda || [],
            productos: nextProps.productos || [],
            dietas: nextProps.dietas || [],
            detalledietas: nextProps.detalledietas || []
        })
    }

    componentWillMount = () => {
        const dietas = "dietas";
        const oyenteDietas = this.state.listeners.find(function (element) {
            return element.id === "dietas";
        });

        if (oyenteDietas === undefined) {
            this.activarListener(this.perfil, dietas);
            this.fetchDietas(this.perfil);
        }

        const productos = "productos";
        const oyenteproductos = this.state.listeners.find(function (element) {
            return element.id === "productos";
        });

        if (oyenteproductos === undefined) {
            this.activarListener(this.perfil, productos);
            this.fetchProductos(this.perfil);
        }
    }

    obtenerDetalles = () => {
        this.state.dietas.map(dieta => {
            const item = { id: dieta.id };

            var oyente = this.state.listeners.find(function (element) {
                return element.id === dieta.id;
            });

            if (oyente === undefined) {
                this.activarListener(this.perfil, item);
                this.fetchDetalleDietas(this.perfil, dieta.id);
            }
        })
    }


    formatoMoneda = (valor) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(valor)
    }
    formatoNumero = (valor = 0, decimales = 0) => {
        return new Intl.NumberFormat('de-DE', { maximumFractionDigits: decimales }).format(valor);
    }
    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }

    ActualizarDiasGdpv = (gdpv) => {
        let dias = 0;
        if (Number(gdpv) > 0) {
            dias = (this.state.kg_objetivo - this.state.kg_entrada) / gdpv;
        }
        this.setState({
            gdpv: gdpv,
            dias_dieta: dias,
            dietas_seleccionadas: []
        });

    }

    ActualizarDiasKilosObjetivo = (kilosObjetivo) => {
        let dias = 0;
        if (Number(this.state.gdpv) > 0) {
            dias = (kilosObjetivo - this.state.kg_entrada) / this.state.gdpv;
        }
        this.setState({
            kg_objetivo: kilosObjetivo,
            dias_dieta: dias,
            dietas_seleccionadas: []
        });
    }

    ActualizarDiasKilosInicial = (kilosInicial) => {
        let dias = 0;

        if (Number(this.state.gdpv) > 0) {
            dias = (this.state.kg_objetivo - kilosInicial) / this.state.gdpv;
        }
        this.setState({
            kg_entrada: kilosInicial,
            dias_dieta: dias,
            dietas_seleccionadas: []
        });
    }

    agregarDieta = () => {
        if (this.state.dieta.length > 0) {
            let dias = Math.ceil(this.state.dias_dieta);

            this.state.dietas_seleccionadas.map(value => {
                dias -= value.dias;
            })

            if (this.state.dias > 0) {
                dias = this.state.dias
            }

            let dietas = this.state.dietas_seleccionadas;

            const d = this.state.dietas.find(diet => diet.id === this.state.dieta)
            dietas.push({ dieta: this.state.dieta, dias, nombre: d.nombre, consumo: 0 });

            this.setState({
                dias: 0,
                dieta: "",
                dietas_seleccionadas: dietas
            })
        }
    }

    eliminarDietaSeleccionada(i) {
        let dietas = this.state.dietas_seleccionadas;
        dietas.splice(i, 1);
        this.setState({
            dietas_seleccionadas: dietas
        })
    }

    render() {

        const { stock } = this.props

        if (!stock){
          return <h2>Cargando...</h2>
        }
    

        let cabezas = this.state.cabezas * (1 - (this.state.mortandad / 100));

        let produccion_carne = (this.state.kg_objetivo - this.state.kg_entrada) * cabezas;

        this.obtenerDetalles();

        let dias_faltantes = this.state.dias_dieta;

        this.state.dietas_seleccionadas.map(value => {
            dias_faltantes -= value.dias;
        })

        let dietas = [];
        var rObj = {};
        rObj["key"] = "-";
        rObj["text"] = "";
        rObj["value"] = "";

        dietas.push({ rObj })




        const lista_dietas = this.state.dietas_seleccionadas.map((value, i) => {
            const dieta = this.state.dietas.find(d => d.id === value.dieta);
            return (
                <Form.Field key={`${value.dieta}${value.dias}`}>
                    <Label size="big" basic color='blue' pointing>{`${dieta.nombre} ${value.dias} ${value.dias > 1 ? 'dias' : 'dia'}`}
                        <Icon color="red" name='delete' onClick={() => this.eliminarDietaSeleccionada(i)} />
                    </Label>
                </Form.Field>
            )
        })

        this.state.dietas.map((value) => {
            var rObj = {};
            rObj["key"] = value.id;
            rObj["text"] = value.nombre;
            rObj["value"] = value.id;
            dietas.push(rObj);
        });



        //calculando productos etc...

        let lista_productos = [];

        let supertotal_ms = 0;
        let supertotal_tc = 0;
        let supertotal_pesos = 0;

        if (this.state.productos.length > 0) {
            let productos = [];

            this.state.dietas_seleccionadas.map(d => {
                const dieta = this.state.detalledietas.find(item => item.id === d.dieta);
                if (dieta) {
                    dieta.detalle.map((producto) => {
                        productos.push({ dieta: dieta.id, producto: producto.producto, cantidad: producto.cantidad })
                    })
                }
            })

            this.state.productos.map(producto => {

                let detalle = [];
                let total = 0;
                let totalms = 0;
                const coeficiente = producto.materia > 0 ? (1 / (producto.materia / 100)) : 0;

                this.state.dietas_seleccionadas.map((dieta) => {
                    const pd = productos.find(p => p.dieta === dieta.dieta && p.producto === producto.id)
                    let cantidad = pd ? pd.cantidad : 0;
                    let subtotal = cantidad > 0 ? (dieta.dias * this.state.consumo_estimado * this.state.cabezas) / (100 / cantidad) : 0;
                    detalle.push({ dieta, subtotal: subtotal * coeficiente })
                    totalms += subtotal;
                    total += (subtotal * coeficiente);
                })

                supertotal_ms += totalms;
                supertotal_tc += total;
                supertotal_pesos += total * producto.precio;

                const producto_stock = stock.productos.find(p => p.id === producto.id)

                let p = { precio: producto.precio, detalle, totalms, total, id: producto.id, nombre: producto.nombre, stock: producto_stock.stock > 0 ? producto_stock.stock : 0, diferencia: producto_stock.stock - total }
                lista_productos.push(p);
            })

        }


        lista_productos = lista_productos.filter(p => p.total > 0);

        // let data = [];

        // lista_productos.map(p => {
        //     if (p.total > p.stock) {
        //         data.push({ name: p.nombre, Stock: (p.stock / 1000), Demanda: (p.total / 1000) })
        //     }
        // })

        let renderProductos = lista_productos.map(producto => {
            const demanda = this.state.demanda.find(d => d.id === producto.id)

            let diferencia = 0;

            if (demanda) {
                diferencia = producto.stock - demanda.total - producto.total;
            }

            return (<Table.Row key={producto.id}>
                <Table.Cell textAlign="left">{producto.nombre}</Table.Cell>
                {producto.detalle.map(d =>
                    <Table.Cell textAlign="right">{this.formatoNumero((d.subtotal / 1000), 3)}</Table.Cell>
                )}
                <Table.Cell textAlign="right">{this.formatoNumero((producto.total / 1000), 3)}</Table.Cell>
                <Table.Cell textAlign="right">{this.formatoNumero((producto.totalms / 1000), 3)}</Table.Cell>
                <Table.Cell textAlign="right">{this.formatoMoneda(producto.total * producto.precio)}</Table.Cell>
                <Table.Cell textAlign="right">{demanda ? this.formatoNumero((demanda.total / 1000), 3) : 0}</Table.Cell>
                <Table.Cell textAlign="right">{this.formatoNumero((producto.stock / 1000), 3)}</Table.Cell>
                <Table.Cell style={{ backgroundColor: diferencia < 0 ? "#FF4000" : "white" }} textAlign="right">{this.formatoNumero((diferencia / 1000), 3)}</Table.Cell>
            </Table.Row>)
        })


        const listaDemanda = this.state.demanda.filter(d => {
            
            const p = lista_productos.find(p => p.id === d.id);
            if (p)
                return false;

            return true;
        })

        let renderOtrosProductos = listaDemanda.map(d => {
            const producto_stock = stock.productos.find(p => p.id === d.id)
            const diferencia = producto_stock.stock - d.total
            return (<Table.Row key={d.id}>
                <Table.Cell textAlign="left">{d.nombre}</Table.Cell>
                {this.state.dietas_seleccionadas.map(d =>
                    <Table.Cell textAlign="right">0</Table.Cell>
                )}
                <Table.Cell textAlign="right">0</Table.Cell>
                <Table.Cell textAlign="right">0</Table.Cell>
                <Table.Cell textAlign="right">0</Table.Cell>
                <Table.Cell textAlign="right">{this.formatoNumero((d.total))}</Table.Cell>
                <Table.Cell textAlign="right">{this.formatoNumero((producto_stock.stock))}</Table.Cell>
                <Table.Cell style={{ backgroundColor: diferencia < 0 ? "#FF4000" : "white" }} textAlign="right">{this.formatoNumero(diferencia)}</Table.Cell>
            </Table.Row>)
        })

        //fin calculos


        let diasEncierre = this.state.gdpv > 0 ? Math.ceil((this.state.kg_objetivo - this.state.kg_entrada) / this.state.gdpv) : 0;

        let resultadoPorProduccion = this.state.gdpv > 0 ? ((((produccion_carne + (cabezas * this.state.kg_entrada)) * this.state.precio_venta) - (this.state.kg_entrada * this.state.precio_compra * this.state.cabezas)) - supertotal_pesos - (this.state.cabezas * Math.ceil((this.state.kg_objetivo - this.state.kg_entrada) / this.state.gdpv) * this.state.hoteleria) - (this.state.sanidad * this.state.cabezas)) : 0;
        let gastoSanitario = this.state.sanidad * this.state.cabezas;
        let gastoHoteleria = this.state.gdpv > 0 ? (this.state.cabezas * Math.ceil((this.state.kg_objetivo - this.state.kg_entrada) / this.state.gdpv) * this.state.hoteleria) : 0;
        let ingresoNetoPorProduccion = ((produccion_carne + (cabezas * this.state.kg_entrada)) * this.state.precio_venta) - (this.state.kg_entrada * this.state.precio_compra * this.state.cabezas);
        let egresoPorCompra = this.state.kg_entrada * this.state.precio_compra * this.state.cabezas;

        let resultadoSobreInversion = 0;
        if ((gastoHoteleria + gastoSanitario + supertotal_pesos + egresoPorCompra) > 0)
            resultadoSobreInversion = resultadoPorProduccion / (gastoHoteleria + gastoSanitario + supertotal_pesos + egresoPorCompra)

        let ted = 0;

        if (diasEncierre > 0)
            ted = (Math.pow(1 + resultadoSobreInversion, (1 / diasEncierre)) - 1);

        let tna = 0;

        if (diasEncierre > 0)
            tna = Math.pow(1 + ted, 365) - 1;



        return (
            <Grid style={{ minHeight: "800px" }} textAlign='center' container>

                <Segment textAlign='center'>
                    <Form>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Cabezas</Label>
                            <Form.Input value={this.state.cabezas} id="input-cabezas" onSelect={() => $("#input-cabezas").select()} type="number" step="1" min="0" onChange={(e, { value }) => this.setState({ cabezas: value })} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Peso de entrada</Label>
                            <Form.Input value={this.state.kg_entrada} id="input-kg_entrada" onSelect={() => $("#input-kg_entrada").select()} type="number" step="1" min="0" onChange={(e, { value }) => this.ActualizarDiasKilosInicial(value)} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Precio neto Compra</Label>
                            <Form.Input value={this.state.precio_compra} id="input-precio_compra" onSelect={() => $("#input-precio_compra").select()} type="number" step="1" min="0" onChange={(e, { value }) => this.setState({ precio_compra: value })} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Peso de salida</Label>
                            <Form.Input value={this.state.kg_objetivo} id="input-kg_objetivo" onSelect={() => $("#input-kg_objetivo").select()} type="number" step="1" min="0" onChange={(e, { value }) => this.ActualizarDiasKilosObjetivo(value)} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Precio neto Venta</Label>
                            <Form.Input value={this.state.precio_venta} id="input-precio_venta" onSelect={() => $("#input-precio_venta").select()} type="number" step="1" min="0" onChange={(e, { value }) => this.setState({ precio_venta: value })} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Consumo Estimado</Label>
                            <Form.Input value={this.state.consumo_estimado} id="input-consumo_estimado" onSelect={() => $("#input-consumo_estimado").select()} type="number" step="1" min="0" onChange={(e, { value }) => this.setState({ consumo_estimado: value })} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>GDPV Estimado</Label>
                            <Form.Input value={this.state.gdpv} type="number" id="input-gdpv" onSelect={() => $("#input-gdpv").select()} step="0.1" min="0" onChange={(e, { value }) => this.ActualizarDiasGdpv(value)} />
                        </Form.Group>

                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Mortandand (%)</Label>
                            <Form.Input id="input-mortandad" size="medium" onSelect={() => $("#input-mortandad").select()} value={this.state.mortandad} type="number" max={100} step="0.5" min="0" onChange={(e, { value }) => this.setState({ mortandad: value })} />
                        </Form.Group>

                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Gasto Sanitario por Cabeza</Label>
                            <Form.Input value={this.state.sanidad} type="number" id="input-sanidad" onSelect={() => $("#input-sanidad").select()} step="1" min="0" onChange={(e, { value }) => this.setState({ sanidad: value })} />
                        </Form.Group>
                        <Form.Group>
                            <Label pointing="right" size="medium" basic>Gasto Hoteleria por dia</Label>
                            <Form.Input value={this.state.hoteleria} type="number" id="input-hoteleria" onSelect={() => $("#input-hoteleria").select()} step="1" min="0" onChange={(e, { value }) => this.setState({ hoteleria: value })} />
                        </Form.Group>
                    </Form>
                </Segment>

                {/* {this.state.gdpv > 0 && this.state.kg_objetivo > this.state.kg_entrada && */}
                <Segment textAlign='center' style={{ minWidth: "20%" }}>
                    <Form>
                        {Number(this.state.gdpv) > 0 && Number(this.state.kg_objetivo) > Number(this.state.kg_entrada) &&
                            <Form.Field>
                                <Label basic size="large">{`Conversion: ${this.formatoNumero((this.state.consumo_estimado / this.state.gdpv), 2)} a 1`}</Label>
                            </Form.Field>
                        }
                        {Number(this.state.gdpv) > 0 && Number(this.state.kg_objetivo) > Number(this.state.kg_entrada) &&
                            <Form.Field>
                                <Label basic size="large">{`Dias de encierre: ${Math.ceil((this.state.kg_objetivo - this.state.kg_entrada) / this.state.gdpv)}`}</Label>
                            </Form.Field>
                        }
                    </Form>

                    <Divider />

                    {dias_faltantes > 0 &&
                        <Form textAlign='center'>
                            <Form.Group>
                                <Form.Field>
                                    <Form.Select label="Dieta" options={dietas} placeholder='Seleccionar Dieta' value={this.state.dieta} onChange={(e, { value }) => this.setState({ dieta: value })} />
                                </Form.Field>
                                <Form.Field >
                                    <Form.Input id="input-dias" onSelect={() => $("#input-dias").select()} label="Dias" value={this.state.dias > 0 ? this.state.dias : Math.ceil(dias_faltantes)} type="number" max={Math.ceil(this.state.dias_dieta)} step="1" min="1" onChange={(e, { value }) => { if (Number(value) <= Math.ceil(dias_faltantes)) this.setState({ dias: value }) }} />
                                </Form.Field>
                            </Form.Group>
                            {this.state.dieta &&
                                <Button primary icon labelPosition='right' onClick={() => this.agregarDieta()}>
                                    Agregar
                                            <Icon name='right arrow' />
                                </Button>
                            }
                        </Form>
                    }

                    {this.state.dietas_seleccionadas.length > 0 &&
                        <Form>
                            {lista_dietas}
                        </Form>
                    }

                </Segment>

                <Segment textAlign="left">
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Produccion de Carne {this.formatoNumero(produccion_carne, 0)}Kg</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Ingreso por Venta {this.formatoMoneda((produccion_carne + (cabezas * this.state.kg_entrada)) * this.state.precio_venta)}</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Egreso por Compras {this.formatoMoneda(egresoPorCompra)}</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Ingreso Neto por Produccion {this.formatoMoneda(ingresoNetoPorProduccion)}</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Gastos Alimentacion {this.formatoMoneda(supertotal_pesos)} ({ingresoNetoPorProduccion > 0 ? this.formatoNumero((supertotal_pesos / ingresoNetoPorProduccion) * 100, 2) : 0}%)</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Gastos Hoteleria {this.formatoMoneda(gastoHoteleria)} ({ingresoNetoPorProduccion > 0 ? this.formatoNumero((gastoHoteleria / ingresoNetoPorProduccion) * 100, 2) : 0}%)</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Gastos Sanitario {this.formatoMoneda(gastoSanitario)} ({ingresoNetoPorProduccion > 0 ? this.formatoNumero((gastoSanitario / ingresoNetoPorProduccion) * 100, 2) : 0}%)</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Resultado por Produccion {this.formatoMoneda(resultadoPorProduccion)} ({ingresoNetoPorProduccion > 0 ? this.formatoNumero((resultadoPorProduccion / ingresoNetoPorProduccion) * 100, 2) : 0}%)</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">Resultado Sobre Inversion {this.formatoNumero(resultadoSobreInversion * 100, 2)} %</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">TED {diasEncierre > 0 ? this.formatoNumero(ted * 100, 2) : 0} %</Label>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row textAlign="left">
                        <Label basic size="large" color="blue">TNA {this.formatoNumero(tna * 100, 2)} %</Label>
                    </Grid.Row>
                </Segment>

                <Segment>
                    <Grid>

                        <Grid.Row centered>

                            <Label color="blue" ribbon size="large" >El stock y la demanda estan expresados en toneladas</Label>

                            <Table
                                celled
                                striped
                                color={"blue"}
                                valigned={"center"}
                            >
                                <Table.Header>

                                    <Table.Row>
                                        <Table.HeaderCell>
                                        </Table.HeaderCell>
                                        {this.state.dietas_seleccionadas.map(dieta =>
                                            <Table.HeaderCell>
                                            </Table.HeaderCell>
                                        )
                                        }
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            {this.formatoNumero((supertotal_tc / 1000), 3)} tn
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            {this.formatoNumero((supertotal_ms / 1000), 3)} tn
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            {this.formatoMoneda(supertotal_pesos)}
                                        </Table.HeaderCell>

                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>

                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>

                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>

                                        </Table.HeaderCell>

                                    </Table.Row>

                                    <Table.Row>

                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Producto
                                            </Table.HeaderCell>
                                        {this.state.dietas_seleccionadas.map(dieta =>
                                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                                {dieta.nombre}
                                            </Table.HeaderCell>
                                        )
                                        }
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Total TC
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Total MS
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Total $
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Demanda Actual
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Stock
                                            </Table.HeaderCell>
                                        <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                            Diferencia
                                            </Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {renderProductos}
                                    {renderOtrosProductos}
                                </Table.Body>
                            </Table>

                        </Grid.Row>

                    </Grid>
                </Segment>

            </Grid>

        )
    }
}


function mapStateToProps(state, props) {
    return {
        stock: state.personalStore.stock,
        dietas: state.personalStore.dietas,
        demanda: state.personalStore.demanda,
        productos: state.personalStore.productos,
        detalledietas: state.personalStore.detalledietas,
        listeners: state.personalStore.listeners,
    };
}

export default connect(
    mapStateToProps,
    { initialFetch, fetchDietas, fetchDetalleDietas, fetchProductos }
)(Simulador);