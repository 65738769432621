import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class ConfNotificacionesNew extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    
    this.db = props.db;
    
    this.state = {
      nombre: "",
      email: "",
      modalOpen: false,
      cargando: false
    };

  }

  validateMail = (email) => {
      var resultado = false;
    
      var patron=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
      if(email.search(patron)===0)
      {
          resultado = true;
      }
      
      return resultado;
  }


  save = () => {
    if((this.state.nombre.trim().length>0) &&(this.validateMail(this.state.email))){
      var _this = this;

      this.setState({cargando: true});

      this.db.collection("empresas").doc(this.perfil.empresa).collection('confnotificaciones').add({
        "nombre": this.state.nombre,
        "email": this.state.email
      })
      .then(function(docRef) {
          _this.setState({ cargando: false, modalOpen: false, alerta: false, nombre: "", email: ""});
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
      });

      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='google plus'>
                  <Icon name='plus' /> Nuevo
                </Button>}
        size={'small'}
        >
        <Modal.Header>Nuevo Correo a Notificar</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: e.target.value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Email" id="email" type="email" placeholder="Email" value={this.state.email} onChange={(e, { value }) => this.setState({ email: e.target.value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, nombre: "", email: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos'
              list={[
                'Debe ingresar un nombre y el email debe ser valido',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ConfNotificacionesNew;