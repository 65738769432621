import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class ProductoNew extends Component {

  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    
    this.db = props.db;

    this.state = {
      nombre: "",
      materia: 0,
      precio: 0,
      stock: 0,
      minimo: 0,
      calcio: 0,
      fosforo: 0,
      fdn: 0,
      mcal: 0,
      prot: 0,
      activo: true,
      modalOpen: false,
      cargando: false
    };

  }

  save = () => {

    if(this.state.nombre.trim().length>0 && this.state.materia > 0){
        var _this = this;

        this.setState({ cargando: true });

        this.db.collection("empresas").doc(this.perfil.empresa).collection('productos').add({
          "nombre": this.state.nombre,
          "materia": Number(this.state.materia) || 0,
          "stock": Number(this.state.stock) || 0,
          "precio": Number(this.state.precio) || 0,
          "minimo": Number(this.state.minimo) || 0,
          "mcal": Number(this.state.mcal) || 0,
          "prot": Number(this.state.prot) || 0,
          "fdn": Number(this.state.fdn) || 0,
          "calcio": Number(this.state.calcio) || 0,
          "fosforo": Number(this.state.fosforo) || 0,
          "activo": this.state.activo
        })
        .then(function(docRef) {
            _this.setState({ cargando: false, modalOpen: false, alerta: false, nombre: "", materia: 0, precio: 0, stock: 0, fdn: 0, calcio: 0, fosforo: 0, mcal: 0, prot: 0, activo: true});
        })
        .catch(function(error) {
            _this.setState({alerta: true, error:`Error adding document: ${error}`, cargando: false});
        });
        this.setState({ cargando: false, modalOpen: false })
      }else{this.setState({ alerta: true });}

  }

  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='google plus'>
                  <Icon name='plus' /> Nuevo
                </Button>}
        size={'small'}
        >
        <Modal.Header>Nuevo Producto</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="NOMBRE" id="nombre" type="text" placeholder="Nombre del Producto" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="MATERIA SECA %" id="materia" type="number" min="0" max="100" step="1" placeholder="% Materia Seca" value={this.state.materia} 
                    onChange={ (e, { value }) => this.setState({ materia: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="PRECIO X KG" id="precio" type="number" min="0" max="100" step="1" placeholder="Precio" value={this.state.precio} 
                    onChange={ (e, { value }) => this.setState({ precio: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="MEGA CALORIAS X KG DE MS" id="mcal" type="number" min="0" max="100" step="1" placeholder="MCAL" value={this.state.mcal} 
                    onChange={ (e, { value }) => this.setState({ mcal: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="PROTEINAS (%) X KG DE MS" id="prot" type="number" min="0" max="100" step="1" placeholder="PROT" value={this.state.prot} 
                    onChange={ (e, { value }) => this.setState({ prot: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="FDN (%) X KG DE MS" id="fdn" type="number" min="0" max="100" step="1" placeholder="FDN" value={this.state.fdn} 
                    onChange={ (e, { value }) => this.setState({ fdn: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="CALCIO (%) X KG DE MS" id="cal" type="number" min="0" max="100" step="1" placeholder="Calcio" value={this.state.calcio} 
                    onChange={ (e, { value }) => this.setState({ calcio: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="FOSFORO (%) X KG DE MS" id="fos" type="number" min="0" max="100" step="1" placeholder="Fosforo" value={this.state.fosforo} 
                    onChange={ (e, { value }) => this.setState({ fosforo: value })} />
                  </Form.Field>
                  {/* <Form.Field>
                    <Form.Input label="STOCK ACTUAL" id="stock" type="number" min="0" max="100" step="1" placeholder="Stock" value={this.state.stock} 
                    onChange={ (e, { value }) => this.setState({ stock: value })} />
                  </Form.Field> */}
                  <Form.Field>
                    <Form.Input label="STOCK MINIMO" id="minimo" type="number" min="0" max="100" step="1" placeholder="Stock Minimo" value={this.state.minimo} 
                    onChange={ (e, { value }) => this.setState({ minimo: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="ACTIVO" toggle checked={this.state.activo} onChange={ () => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, nombre: "", materia: 0, precio: 0, stock: 0, fnd: 0, calcio: 0, fosforo: 0, mcal: 0, prot: 0, activo: true })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Ingrese un nombre valido',
                'El porcentaje de materia seca debe ser mayor a cero',
                'El minimo de stock debe ser mayor a cero'
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ProductoNew;
