import React, { Component } from 'react';
import firebase from '../firebase';
import { Table, Grid, Container, Icon, Menu, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

// import BorrarReporte from '../components/BorrarReporte';

import {
  NUEVO_REPORTE
 } from './actions'

class Reportes extends Component {

  static isPrivate = true;

  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.perfil = props.perfil;
    // this.state = ({
    //   reportes:  [],
    // })
  }
  prettyDate = (date) => {
    var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

    var minutos = date.getMinutes();

    if (minutos < 10) minutos = "0" + minutos;

    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + date.getHours() + ':' + minutos;
  }


  // componentDidMount = async () => {
  //   let reportes = []
  //   const resReportes = await this.db.collection('empresas').doc(this.perfil.empresa).collection('reportes').get();
   
  //   resReportes.forEach( (doc) => {
  //     let reporte = doc.data();
  //     reporte.id = doc.id;
  //     reportes.push(reporte)
      
  //   })
  //   this.setState({
  //     reportes
  //   })
  // }
  

  render() {

    const { reportes, rodeos } = this.props

    if (reportes === undefined || rodeos === undefined)
      return (<Loader active />)


    const lista = reportes.map(
        (value) => {
          let nombres_rodeos = [];

          value.rodeos.map(r =>{
            const rod = rodeos.find( rodeo => rodeo.id === r)
           
            if (rod){
              nombres_rodeos.push(rod.nombre)
            }
          });
          
          nombres_rodeos = nombres_rodeos.join(', ');

          let procesado = "En proceso";
          if (value.procesado) procesado = <a target='_blank' href={value.url}>
            <Icon name="file pdf outline" size='big' link />
          </a>
          return (
            <Table.Row key={value.id}>
              <Table.Cell textAlign="center">{this.prettyDate(value.fecha.toDate())} </Table.Cell>
              {/* <Table.Cell textAlign="center">{value.periodo ? value.periodo : '-' }</Table.Cell> */}
              <Table.Cell textAlign="center">{nombres_rodeos} </Table.Cell>
              <Table.Cell textAlign="center">{procesado}</Table.Cell>
              {/* <Table.Cell textAlign="center"><BorrarReporte doc={value} /></Table.Cell> */}
            </Table.Row>
          )
        }
      )
    

    return (
      <Container>
        <Grid>
          <Grid.Row centered>

            <Table
              celled
              striped
              color={"teal"}
              valigned={"center"}
              unstackable={true}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='6'>
                    <Menu>
                      <Menu.Item>
                        Lista de reportes
                      </Menu.Item>
                    <Menu.Menu position='right'>
                      <Menu.Item as={Link}
                        to='/reportes/nuevo_reporte'
                        name='nuevo_reporte'
                        style={{backgroundColor: "red", color: 'white', fontWeight: 'bold'}}
                        onClick={()=> this.props.dispatch({ type: NUEVO_REPORTE }) }
                      >
                        Nuevo +
                      </Menu.Item>
                    </Menu.Menu>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">Fecha</Table.HeaderCell>
                  {/* <Table.HeaderCell textAlign="center">Periodo</Table.HeaderCell> */}
                  <Table.HeaderCell textAlign="center">Rodeos</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Descargar</Table.HeaderCell>
                  {/* <Table.HeaderCell textAlign="center">Borrar</Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {lista}
              </Table.Body>
            </Table>

          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}


const WrappedComponent = connect()(Reportes);

export default compose(
  firestoreConnect((props, state) => {
      return [
          {
              collection: 'empresas',
              doc: props.perfil.empresa,
              subcollections: [{ collection: 'reportes' }],
              orderBy: [['fecha', 'desc']],
              storeAs: 'reportes'
          },
          {
              collection: 'empresas',
              doc: props.perfil.empresa,
              subcollections: [{ collection: 'rodeos' }],
              storeAs: 'rodeos_reporte'
          }
      ]
  }), // sync todos collection from Firestore into redux
  connect((state, props) => {
      let reportes, rodeos

      if (state.firestore.data.reportes === null) reportes = []
      if (state.firestore.data.rodeos_reporte === null) rodeos = []
      
      if (state.firestore.data.reportes){
          reportes = []
          Object.entries(state.firestore.data.reportes).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              reportes.push( obj)
          })
      }
      if (state.firestore.data.rodeos_reporte){
        rodeos = []
          Object.entries(state.firestore.data.rodeos_reporte).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              rodeos.push( obj)
          })
      }

      return ({
          reportes,
          rodeos,
      })
  }
  )
)(WrappedComponent)