import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../firebase';
import { Grid, Segment } from 'semantic-ui-react';
import { initialFetch, fetchClientes } from '../actions';

import ListaClientes from './ListaClientes';
class Clientes extends Component {
    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.perfil = props.perfil;

        this.activarListener = props.initialFetch;
        this.fetchClientes = props.fetchClientes;

        this.state = ({
            clientes: props.clientes || [],
            listeners: props.listeners || []
        })
    }

    componentWillReceiveProps = (nextProps) => {
      this.setState({
        clientes: nextProps.clientes || [],
        listeners: nextProps.listeners || []
      })
    }
    
    componentWillMount = () => {
        const item = { id: "clientes" };

        var oyente = this.state.listeners.find(function (element) {
            return element.id === "clientes";
        });

        if (oyente === undefined) {
            this.activarListener(this.perfil, item);
            this.fetchClientes(this.perfil);
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Row centered>
                    <Segment raised>
                        <ListaClientes perfil={this.perfil} db={this.db} clientes={this.state.clientes} />
                    </Segment>
                </Grid.Row>
            </Grid>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        clientes: state.personalStore.clientes,
        listeners: state.personalStore.listeners
    };
}

export default connect(
    mapStateToProps,
    { initialFetch, fetchClientes }
)(Clientes);