import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Container, Grid } from 'semantic-ui-react'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { NUEVO_REPORTE_SET_RODEOS, NUEVO_REPORTE_SET_ALL_RODEOS, NUEVO_REPORTE_SET_FILTROS } from './actions'

import TableTropas from './components/tableTropas'
import RowSiguiente from './components/rowSiguiente'

class SeleccionarRodeos extends Component {

    constructor(props) {
      super(props);

      this.seleccionActivos = ["Activos", "Cerrados"];

      this.activosF = {};
      this.activosF["Cerrados"] = { id: "Cerrados", label: "Cerrados" };
      this.activosF["Activos"] = { id: "Activos", label: "Activos" };

      this.filtrosFijos = ["propietarios", "tropas", "activos", "categorias"]

      this.propietariosF = [];
      this.tropasF = [];
      this.categoriasF = [];
    }

    renderCategory = (cat) => {
        if (this.props.categorias && this.props.categorias[cat]){
            return this.props.categorias[cat].nombre
        }else{
            return null
        }
    }

    select = async (id) => {
        const { dispatch } = this.props
        dispatch({
            type: NUEVO_REPORTE_SET_RODEOS,
            payload: id
        })
    }

    selectAll = async (rodeos) => {
        const { dispatch } = this.props
        dispatch({
            type: NUEVO_REPORTE_SET_ALL_RODEOS,
            payload: rodeos
        })
    }

    handleActionFilter = async (filtros) => {
        const { dispatch } = this.props
        dispatch({
            type: NUEVO_REPORTE_SET_FILTROS,
            payload: filtros
        })
    }

    onChangeFechaInicio = (fechaInicio) => this.handleActionFilter({...this.props.reporte.filtros, fechaInicio })

    borrarFechaInicio = () => this.handleActionFilter({...this.props.reporte.filtros, fechaInicio: null })

    onSelectedAllActivosCerrados = () => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        let busqueda = filtros.filtros.find((f) => f.nombre === "activos");

        if (busqueda !== null && busqueda !== undefined) {
            let index = filtros.filtros.indexOf(busqueda);
            if (index > -1) {
                filtros.filtros = filtros.filtros.filter((e) => e.nombre !== "activos");
            }
        }

        this.seleccionActivos = ["Activos", "Cerrados"];

        filtros.activos = null;

        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }

    onSelectedActivosCerrados = (id, opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        let busqueda = filtros.filtros.find((f) => f.nombre === "activos");

        if (busqueda !== null && busqueda !== undefined) {
            let index = filtros.filtros.indexOf(busqueda);
            if (index > -1) {
                filtros.filtros = filtros.filtros.filter((e) => e.nombre !== "activos");
            }
        }

        if (id === "Cerrados") {
            this.seleccionActivos = ["Cerrados"];

            filtros.filtros.push({ nombre: "activos", hora: new Date() });
            filtros.activos = false;
        }

        if (id === "Activos") {
            this.seleccionActivos = ["Activos"];

            filtros.filtros.push({ nombre: "activos", hora: new Date() });
            filtros.activos = true;
        }

        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }

    onSelectedAllTropas = (opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        let busqueda = filtros.filtros.find((f) => f.nombre === "tropas");
        if (busqueda !== null && busqueda !== undefined) {

            let index = filtros.filtros.indexOf(busqueda);


            if (index > -1) {
                filtros.filtros = filtros.filtros.filter((e) => e.nombre !== "tropas");
            }

        }

        let rodeos = [];
        if (!filtros.allTropas) {
            Object.keys(opciones).map(opcion => {
                rodeos.push(opciones[opcion].id);
            })

            filtros.listaTropas = rodeos;
            filtros.allTropas = true;
        } else {
            filtros.filtros.push({ nombre: "tropas", hora: new Date() });
            filtros.allTropas = false;
            filtros.listaTropas = [];
        }
        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }
    onSelectedTropas = (id, opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        filtros.filtros.push({ nombre: "tropas", hora: new Date() });

        let index = filtros.listaTropas.indexOf(id);

        if (index > -1) {
            filtros.listaTropas = filtros.listaTropas.filter((e) => e !== id);
            filtros.allTropas = false;
        } else {

            let rodeos = [];

            if (filtros.allTropas) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        rodeos.push(opciones[opcion].id);
                })

                filtros.listaTropas = rodeos;
                filtros.allTropas = false;
            } else {

                filtros.listaTropas = filtros.listaTropas.concat(id);
                filtros.allTropas = false;
            }
        }
        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }

    onSelectedAllCategorias = (opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        let busqueda = filtros.filtros.find((f) => f.nombre === "categorias");
        if (busqueda !== null && busqueda !== undefined) {

            let index = filtros.filtros.indexOf(busqueda);


            if (index > -1) {
                filtros.filtros = filtros.filtros.filter((e) => e.nombre !== "categorias");
            }

        }

        let rodeos = [];
        if (!filtros.allCategorias) {
            Object.keys(opciones).map(opcion => {
                rodeos.push(opciones[opcion].id);
            })

            filtros.listaCategorias = rodeos;
            filtros.allCategorias = true;
        } else {
            filtros.filtros.push({ nombre: "categorias", hora: new Date() });
            filtros.allCategorias = false;
            filtros.listaCategorias = [];
        }
        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }
    onSelectedCategoria = (id, opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        filtros.filtros.push({ nombre: "categorias", hora: new Date() });

        let index = filtros.listaCategorias.indexOf(id);

        if (index > -1) {
            filtros.listaCategorias = filtros.listaCategorias.filter((e) => e !== id);
            filtros.allCategorias = false;
        } else {

            let rodeos = [];

            if (filtros.allCategorias) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        rodeos.push(opciones[opcion].id);
                })

                filtros.listaCategorias = rodeos;
                filtros.allCategorias = false;
            } else {

                filtros.listaCategorias = filtros.listaCategorias.concat(id);
                filtros.allCategorias = false;
            }
        }
        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }

    onSelectedAllPropietarios = (opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        let busqueda = filtros.filtros.find((f) => f.nombre === "propietarios");
        if (busqueda !== null && busqueda !== undefined) {

            let index = filtros.filtros.indexOf(busqueda);


            if (index > -1) {
                filtros.filtros = filtros.filtros.filter((e) => e.nombre !== "propietarios");
            }

        }

        let rodeos = [];
        if (!filtros.allPropietarios) {
            Object.keys(opciones).map(opcion => {
                rodeos.push(opciones[opcion].id);
            })

            filtros.listaPropietarios = rodeos;
            filtros.allPropietarios = true;
        } else {
            filtros.filtros.push({ nombre: "propietarios", hora: new Date() });
            filtros.allPropietarios = false;
            filtros.listaPropietarios = [];
        }
        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }
    
    onSelectedPropietario = (id, opciones) => {
        let { reporte } = this.props;
        let  filtros  = JSON.parse(JSON.stringify(reporte.filtros))

        filtros.filtros.push({ nombre: "propietarios", hora: new Date() });

        let index = filtros.listaPropietarios.indexOf(id);

        if (index > -1) {
            filtros.listaPropietarios = filtros.listaPropietarios.filter((e) => e !== id);
            filtros.allPropietarios = false;
        } else {

            let rodeos = [];

            if (filtros.allPropietarios) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        rodeos.push(opciones[opcion].id);
                })

                filtros.listaPropietarios = rodeos;
                filtros.allPropietarios = false;
            } else {

                filtros.listaPropietarios = filtros.listaPropietarios.concat(id);
                filtros.allPropietarios = false;
            }
        }
        this.handleActionFilter({...this.props.reporte.filtros, ...filtros })
    }


    aplicarFiltroCategorias = (lista) => {
        let { reporte } = this.props;
        let { filtros } = reporte
        this.categoriasF = [];
        //filtrando por categorias
        lista = lista.filter((d) => {
          let cliente = {};
          cliente.id = d.categoria;
          cliente.label = this.renderCategory(d.categoria) || "Sin definir";
          
          this.categoriasF[cliente.id] = cliente;

          let bandera = true;
          if (!filtros.allCategorias) {
              let index = filtros.listaCategorias.indexOf(d.categoria);
              if (index < 0) bandera = false;
          }

          return bandera;
        });

        return lista;
    }

    aplicarFiltroPropietarios = (lista) => {
        let { reporte } = this.props;
        let { filtros } = reporte
        this.propietariosF = [];
        //filtrando por categorias
        lista = lista.filter((d, index) => {

          let cliente = {};
          cliente.id = d.propietario;
          cliente.label = d.propietario || "s/n";
          this.propietariosF[cliente.id] = cliente;

          let bandera = true;
          if (!filtros.allPropietarios) {
              let index = filtros.listaPropietarios.indexOf(d.propietario);
              if (index < 0) bandera = false;
          }

          return bandera;
        });

        return lista;
    }

    aplicarFiltroTropas = (lista) => {
        let { reporte } = this.props;
        let { filtros } = reporte
        this.tropasF = [];
        //filtrando por categorias
        lista = lista.filter((d) => {

          let cliente = {};
          cliente.id = d.id;
          cliente.label = d.nombre;
          this.tropasF[cliente.id] = cliente;

          let bandera = true;
          if (!filtros.allTropas) {
              let index = filtros.listaTropas.indexOf(d.id);
              if (index < 0) bandera = false;
          }

          return bandera;
        });

        return lista;
    }
    aplicarFiltroActivos = (lista) => {
        let { reporte } = this.props;
        let { filtros } = reporte
        lista = lista.filter((d) => {
            if (filtros.activos === null) {
                return true;
            }

            if (filtros.activos === true) {
                return d.activo === true;
            }

            if (filtros.activos === false) {
                return d.activo === false;
            }
        });

        return lista;
    }

    render(){
        let { rodeos, categorias, reporte } = this.props;
        let { filtros } = reporte
        let tropas = rodeos
    
        let listaFiltrosV = filtros.filtros.reduce((filtro, item) => {
          let val = item.nombre;
          filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
          return filtro;
        }, {});

        listaFiltrosV = Object.keys(listaFiltrosV).sort((a, b) => {

            if (listaFiltrosV[a].hora > listaFiltrosV[b].hora) {
                return 1;
            }

            if (listaFiltrosV[a].hora < listaFiltrosV[b].hora) {
                return -1;
            }
            return 0;
        })

        this.filtrosFijos.forEach((fijo) => {
            let index = listaFiltrosV.indexOf(fijo);
            if (index < 0) listaFiltrosV.push(fijo);
        });

        tropas = tropas.filter((d) => {

            var fecha = d.fechaAlta.toDate ? d.fechaAlta.toDate() : new Date(d.fechaAlta); //new Date(d.detalle.fecha);

            let banderaFechaInicio = true;
            if (filtros.fechaInicio !== null) {
                var fechaInicio = new Date(filtros.fechaInicio);
                fechaInicio.setHours(0);
                banderaFechaInicio = fecha > fechaInicio
            }

            return banderaFechaInicio;
        });

        const _this = this
        listaFiltrosV.forEach((f) => {
            if (f === "activos") tropas = _this.aplicarFiltroActivos(tropas);
            if (f === "tropas") tropas = _this.aplicarFiltroTropas(tropas);
            if (f === "categorias") tropas = _this.aplicarFiltroCategorias(tropas);
            if (f === "propietarios") tropas = _this.aplicarFiltroPropietarios(tropas);
        })

        return (
            <Container style={{paddingBottom: 40}}>
                <Grid centered >
                  <Grid.Row centered>
                      <h2>Paso 1: Seleccionar Tropas</h2>
                  </Grid.Row>
                
                <TableTropas 
                    rodeosSeleccionados={reporte.rodeos}
                    select={this.select}
                    selectAll={this.selectAll}

                    tropas={tropas} 
                    categorias={categorias} 

                    filtros={reporte.filtros}
                    activosF={this.activosF}
                    tropasF={this.tropasF}
                    categoriasF={this.categoriasF}
                    propietariosF={this.propietariosF}
                    seleccionActivos={this.seleccionActivos}

                    onSelectedActivosCerrados={this.onSelectedActivosCerrados}
                    onSelectedAllActivosCerrados={this.onSelectedAllActivosCerrados}
                    borrarFechaInicio={this.borrarFechaInicio}
                    onChangeFechaInicio={this.onChangeFechaInicio}
                    onSelectedTropa={this.onSelectedTropas}
                    onSelectedAllTropas={this.onSelectedAllTropas}
                    onSelectedCategoria={this.onSelectedCategoria}
                    onSelectedAllCategorias={this.onSelectedAllCategorias}
                    onSelectedPropietario={this.onSelectedPropietario}
                    onSelectedAllPropietarios={this.onSelectedAllPropietarios}
                    />

                <RowSiguiente 
                    toNext={"/reportes/seleccionar_fecha"} 
                    toBack={"/reportes/"} 
                    disabled={this.props.reporte.rodeos.length === 0}
                    negative
                    />

                </Grid>   
            </Container>
        )
    }
}


export default compose(
    firestoreConnect((props, state) => {
      return [
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          subcollections: [{ collection: 'categorias' }],
          orderBy: [['nombre', 'asc']],
          storeAs: 'categorias'
        },
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          subcollections: [{ collection: 'rodeos' }],
          orderBy: [['nombre', 'asc']],
          storeAs: 'rodeos'
        }
      ]
    }), 
    connect((state, props) => {
       let rodeos = []

       if (state.firestore.data.rodeos){
          rodeos = Object.keys(state.firestore.data.rodeos).map( r => ({ ...state.firestore.data.rodeos[r], id: r }) )
       }
      
       return {
          categorias: state.firestore.data.categorias,
          rodeos,
          reporte: state.personalStore.nuevoReporte
        }
    })
  )(SeleccionarRodeos)