import React from "react";
import { firebaseConnect } from "react-redux-firebase";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../actions";

const Logout = (props) => {
  props.firebase
    .auth()
    .signOut()
    .then(() => {
      props.actions.logout();
      window.location.assign("/");
    })
    .catch((error) => console.log(`Error ${error.code}: ${error.message}`));

  return <div></div>;
};

const wrappedLogout = firebaseConnect()(Logout);

function mapStateToProps(state, props) {
  // armamos un objeto solo con los
  // datos del store que nos interesan
  // y lo devolvemos
  return {
    user: state.personalStore.user,
  };
}

function mapDispatchToProps(dispatch, props) {
  // creamos un objeto con un método para crear
  // y despachar acciones fácilmente y en
  // una sola línea
  const actions = {
    logout: bindActionCreators(logout, dispatch),
  };

  // devolvemos nuestras funciones dispatch
  // y los props normales del componente
  return { actions };
}

export default connect(mapStateToProps, mapDispatchToProps)(wrappedLogout);
