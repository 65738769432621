import React from "react";
import { Grid, Segment, Form, Checkbox, Table } from "semantic-ui-react";

import firebase from "../firebase";

import { connect } from "react-redux";
import {
  initialFetch,
  fetchClientes,
  fetchEmpresa,
  fetchRodeosInactivos,
  fetchRodeosActivos,
  fetchCategorias,
  fetchReporteVentas,
  fetchMovRodeos,
  setFilterVentas,
  setFilterTropas,
} from "../actions";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import ModalMultiple from "../components/filtros/ModalMultiple";
import FiltroFechas from "../components/filtros/ModalFechas";

class GraficoVentas extends React.Component {
  constructor(props) {
    super(props);

    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.activarListener = props.initialFetch;
    this.fetchReporteVentas = props.fetchReporteVentas;
    this.fetchEmpresa = props.fetchEmpresa;
    this.fetchRodeosInactivos = props.fetchRodeosInactivos;
    this.fetchRodeosActivos = props.fetchRodeosActivos;
    this.fetchCategorias = props.fetchCategorias;
    this.fetchMovRodeos = props.fetchMovRodeos;
    this.fetchClientes = props.fetchClientes;

    this.setFilterTropas = props.setFilterTropas;
    this.setFilterVentas = props.setFilterVentas;

    this.seleccionVendidos = ["Vendidos", "Pendientes"];

    this.vendidosF = {};
    this.vendidosF["Vendidos"] = { id: "Vendidos", label: "Vendidos" };
    this.vendidosF["Pendientes"] = { id: "Pendientes", label: "Pendientes" };

    this.filteredVentas = {
      vendidos: props.filtrosVentas.vendidos,
      fechaInicio: props.filtrosVentas.fechaInicio,
      fechaFinal: props.filtrosVentas.fechaFinal,
      fechaInicioVta: props.filtrosVentas.fechaInicioVta,
      fechaFinalVta: props.filtrosVentas.fechaFinalVta,
      allRodeos: props.filtrosVentas.allRodeos,
      listaRodeos: props.filtrosVentas.listaRodeos,
      allCategorias: props.filtrosVentas.allCategorias,
      listaCategorias: props.filtrosVentas.listaCategorias,
      allClientes: props.filtrosVentas.allClientes,
      listaClientes: props.filtrosVentas.listaClientes,
      filtros: props.filtrosVentas.filtros,
      fechaCobro: props.filtrosVentas.fechaCobro,
    };

    this.filtrosFijos = ["vendidos", "clientes", "rodeos", "categorias"];
    this.clientesFventas = [];
    this.rodeosFventas = [];
    this.categoriasFventas = [];

    this.state = {
      width: 450,
      height: 304,
      activeItem: "datos",
      descripcion: "",
      listeners: props.listeners || [],
      clientes: props.clientes || [],
      reportes_ventas: props.reportes_ventas || [],
      rodeos_activos: props.rodeosActivos || [],
      rodeos_inactivos: props.rodeosInactivos || [],
      categorias: props.categorias || [],
      movrodeos: props.movrodeos || [],
      empresa: props.empresa,
      iva: props.empresa.iva || 10.5,
    };
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if (window.innerWidth < 500) {
      this.setState({ width: 450, height: 302 });
    } else {
      let update_width = window.innerWidth - 100;
      let update_height = Math.round(update_width / 3);
      this.setState({ width: update_width, height: update_height });
    }
  }
  /**
   * Add event listener
   */

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      listeners: nextProps.listeners || [],
      clientes: nextProps.clientes || [],
      movrodeos: nextProps.movrodeos || [],
      reportes_ventas: nextProps.reportes_ventas || [],
      rodeos_activos: nextProps.rodeosActivos || [],
      rodeos_inactivos: nextProps.rodeosInactivos || [],
      categorias: nextProps.categorias || [],
      empresa: nextProps.empresa,
      iva: nextProps.empresa.iva || 10.5,
    });

    this.filteredVentas = {
      vendidos: nextProps.filtrosVentas.vendidos,
      fechaInicioVta: nextProps.filtrosVentas.fechaInicioVta,
      fechaFinalVta: nextProps.filtrosVentas.fechaFinalVta,
      fechaInicio: nextProps.filtrosVentas.fechaInicio,
      fechaFinal: nextProps.filtrosVentas.fechaFinal,
      allRodeos: nextProps.filtrosVentas.allRodeos,
      listaRodeos: nextProps.filtrosVentas.listaRodeos,
      allCategorias: nextProps.filtrosVentas.allCategorias,
      listaCategorias: nextProps.filtrosVentas.listaCategorias,
      allClientes: nextProps.filtrosVentas.allClientes,
      listaClientes: nextProps.filtrosVentas.listaClientes,
      filtros: nextProps.filtrosVentas.filtros,
      fechaCobro: nextProps.filtrosVentas.fechaCobro,
    };
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();

    const rodeosActivos = { id: "rodeos_activos" };
    const rodeosInactivos = { id: "rodeos_inactivos" };

    const oyenteActivos = this.state.listeners.find(function (element) {
      return element.id === "rodeos_activos";
    });

    if (oyenteActivos === undefined) {
      this.activarListener(this.perfil, rodeosActivos);
      this.fetchRodeosActivos(this.perfil);
    }

    const oyenteInactivos = this.state.listeners.find(function (element) {
      return element.id === "rodeos_inactivos";
    });

    if (oyenteInactivos === undefined) {
      this.activarListener(this.perfil, rodeosInactivos);
      this.fetchRodeosInactivos(this.perfil);
    }

    const movimientos = { id: "movrodeos" };

    const oyenteMovimientos = this.state.listeners.find(function (element) {
      return element.id === "movrodeos";
    });

    if (oyenteMovimientos === undefined) {
      this.activarListener(this.perfil, movimientos);
      this.fetchMovRodeos(this.perfil);
    }
  }

  componentWillMount = () => {
    const empresa = { id: "empresa" };

    const oyenteEmpresa = this.state.listeners.find(function (element) {
      return element.id === "empresa";
    });

    if (oyenteEmpresa === undefined) {
      this.activarListener(this.perfil, empresa);
      this.fetchEmpresa(this.perfil);
    }

    const cliente = { id: "clientes" };

    const oyenteCliente = this.state.listeners.find(function (element) {
      return element.id === "clientes";
    });

    if (oyenteCliente === undefined) {
      this.activarListener(this.perfil, cliente);
      this.fetchClientes(this.perfil);
    }

    const categorias = { id: "categorias" };

    const oyenteCategorias = this.state.listeners.find(function (element) {
      return element.id === "categorias";
    });

    if (oyenteCategorias === undefined) {
      this.activarListener(this.perfil, categorias);
      this.fetchCategorias(this.perfil);
    }

    const itemReportes = { id: "reporteVentas" };

    const oyenteReportes = this.state.listeners.find(function (element) {
      return element.id === "reporteVentas";
    });

    if (oyenteReportes === undefined) {
      this.activarListener(this.perfil, itemReportes);
      this.fetchReporteVentas(this.perfil);
    }
  };
  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  formatoMoneda = (valor) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(valor);
  };

  formatoNumero = (valor, decimales) => {
    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: decimales,
    }).format(valor);
  };

  prettyDate = (date) => {
    var months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return months[date.getMonth()] + " " + date.getFullYear();
  };

  selectedFechaCobro = () => {
    this.filteredVentas.fechaCobro = true;
    this.setFilterVentas(this.filteredVentas);
  };

  selectedFechaVenta = () => {
    this.filteredVentas.fechaCobro = false;
    this.setFilterVentas(this.filteredVentas);
  };

  generarPDF() {
    this.db
      .collection("empresas")
      .doc(this.perfil.empresa)
      .collection("reportes_stock")
      .add({
        procesado: false,
        descripcion: this.state.descripcion,
        fecha: new Date(),
      });
  }

  actualizarIva = (iva) => {
    this.state.empresa.doc.ref.update({
      iva,
    });
  };

  /////////////FECHAS//////////
  onChangeFechaInicio = (fechaInicio) => {
    this.filteredVentas.fechaInicio = fechaInicio;
    this.setFilterVentas(this.filteredVentas);
  };
  onChangeFechaFinal = (fechaFinal) => {
    this.filteredVentas.fechaFinal = fechaFinal;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaInicio = () => {
    this.filteredVentas.fechaInicio = null;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaFinal = () => {
    this.filteredVentas.fechaFinal = null;
    this.setFilterVentas(this.filteredVentas);
  };

  //fecha vta

  onChangeFechaInicioVta = (fechaInicio) => {
    this.filteredVentas.fechaInicioVta = fechaInicio;
    this.setFilterVentas(this.filteredVentas);
  };
  onChangeFechaFinalVta = (fechaFinal) => {
    this.filteredVentas.fechaFinalVta = fechaFinal;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaInicioVta = () => {
    this.filteredVentas.fechaInicioVta = null;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaFinalVta = () => {
    this.filteredVentas.fechaFinalVta = null;
    this.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllRodeos = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "rodeos"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "rodeos"
        );
      }
    }

    let rodeos = [];
    if (!this.filteredVentas.allRodeos) {
      Object.keys(opciones).map((opcion) => {
        rodeos.push(opciones[opcion].id);
      });

      this.filteredVentas.listaRodeos = rodeos;
      this.filteredVentas.allRodeos = true;
    } else {
      this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });
      this.filteredVentas.allRodeos = false;
      this.filteredVentas.listaRodeos = [];
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedRodeo = (id, opciones) => {
    this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });

    let index = this.filteredVentas.listaRodeos.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaRodeos = this.filteredVentas.listaRodeos.filter(
        (e) => e !== id
      );
      this.filteredVentas.allRodeos = false;
    } else {
      let rodeos = [];

      if (this.filteredVentas.allRodeos) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) rodeos.push(opciones[opcion].id);
        });

        this.filteredVentas.listaRodeos = rodeos;
        this.filteredVentas.allRodeos = false;
      } else {
        this.filteredVentas.listaRodeos =
          this.filteredVentas.listaRodeos.concat(id);
        this.filteredVentas.allRodeos = false;
      }
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedAllCategorias = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "categorias"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "categorias"
        );
      }
    }

    let categorias = [];
    if (!this.filteredVentas.allCategorias) {
      Object.keys(opciones).map((opcion) => {
        categorias.push(opciones[opcion].id);
      });

      this.filteredVentas.listaCategorias = categorias;
      this.filteredVentas.allCategorias = true;
    } else {
      this.filteredVentas.filtros.push({
        nombre: "categorias",
        hora: new Date(),
      });
      this.filteredVentas.allCategorias = false;
      this.filteredVentas.listaCategorias = [];
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedCategoria = (id, opciones) => {
    this.filteredVentas.filtros.push({
      nombre: "categorias",
      hora: new Date(),
    });

    let index = this.filteredVentas.listaCategorias.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaCategorias =
        this.filteredVentas.listaCategorias.filter((e) => e !== id);
      this.filteredVentas.allCategorias = false;
    } else {
      let categorias = [];

      if (this.filteredVentas.allCategorias) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) categorias.push(opciones[opcion].id);
        });

        this.filteredVentas.listaCategorias = categorias;
        this.filteredVentas.allCategorias = false;
      } else {
        this.filteredVentas.listaCategorias =
          this.filteredVentas.listaCategorias.concat(id);
        this.filteredVentas.allCategorias = false;
      }
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedAllClientes = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "clientes"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "clientes"
        );
      }
    }

    let clientes = [];
    if (!this.filteredVentas.allClientes) {
      Object.keys(opciones).map((opcion) => {
        clientes.push(opciones[opcion].id);
      });

      this.filteredVentas.listaClientes = clientes;
      this.filteredVentas.allClientes = true;
    } else {
      this.filteredVentas.filtros.push({
        nombre: "clientes",
        hora: new Date(),
      });
      this.filteredVentas.allClientes = false;
      this.filteredVentas.listaClientes = [];
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedClientes = (id, opciones) => {
    this.filteredVentas.filtros.push({ nombre: "clientes", hora: new Date() });

    let index = this.filteredVentas.listaClientes.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaClientes =
        this.filteredVentas.listaClientes.filter((e) => e !== id);
      this.filteredVentas.allClientes = false;
    } else {
      let clientes = [];

      if (this.filteredVentas.allClientes) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) clientes.push(opciones[opcion].id);
        });

        this.filteredVentas.listaClientes = clientes;
        this.filteredVentas.allClientes = false;
      } else {
        this.filteredVentas.listaClientes =
          this.filteredVentas.listaClientes.concat(id);
        this.filteredVentas.allClientes = false;
      }
    }
    this.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllVendidosPendientes = () => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "vendidos"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "vendidos"
        );
      }
    }

    this.seleccionVendidos = ["Vendidos", "Pendientes"];

    this.filteredVentas.vendidos = null;

    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedVendidosPendientes = (id, opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "vendidos"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "vendidos"
        );
      }
    }

    if (id === "Vendidos") {
      this.seleccionVendidos = ["Vendidos"];

      this.filteredVentas.filtros.push({
        nombre: "vendidos",
        hora: new Date(),
      });
      this.filteredVentas.vendidos = true;
      this.setFilterVentas(this.filteredVentas);
    }

    if (id === "Pendientes") {
      this.seleccionVendidos = ["Pendientes"];

      this.filteredVentas.filtros.push({
        nombre: "vendidos",
        hora: new Date(),
      });
      this.filteredVentas.vendidos = false;
      this.setFilterVentas(this.filteredVentas);
    }
  };

  aplicarFiltroClientes = (lista) => {
    this.clientesFventas = [];
    //filtrando por categorias
    lista = lista.filter((d) => {
      const objCliente = this.state.clientes.find((c) => c.id === d.cliente);

      let cliente = {};
      cliente.id = d.cliente;
      cliente.label = objCliente ? objCliente.nombre : "Sin definir";
      this.clientesFventas[cliente.id] = cliente;

      let bandera = true;
      if (!this.filteredVentas.allClientes) {
        let index = this.filteredVentas.listaClientes.indexOf(d.cliente);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  aplicarFiltroVendidos = (lista) => {
    //filtrando por dietas

    lista = lista.filter((d) => {
      if (this.filteredVentas.vendidos === null) {
        return true;
      }

      if (this.filteredVentas.vendidos === true) {
        return d.vendido === true;
      }

      if (this.filteredVentas.vendidos === false) {
        return d.vendido === false;
      }
    });

    return lista;
  };
  aplicarFiltroCategorias = (lista) => {
    this.categoriasFventas = [];
    //filtrando por categorias
    lista = lista.filter((d) => {
      let categoria = {};
      categoria.id = d.categoria;
      categoria.label = d.categoria;
      this.categoriasFventas[categoria.id] = categoria;

      let bandera = true;
      if (!this.filteredVentas.allCategorias) {
        let index = this.filteredVentas.listaCategorias.indexOf(d.categoria);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  aplicarFiltroRodeos = (lista) => {
    this.rodeosFventas = [];
    //filtrando por rodeo
    lista = lista.filter((d) => {
      let rodeo = {};
      rodeo.id = d.rodeo;
      rodeo.label = d.rodeo;
      this.rodeosFventas[rodeo.id] = rodeo;

      let bandera = true;
      if (!this.filteredVentas.allRodeos) {
        let index = this.filteredVentas.listaRodeos.indexOf(d.rodeo);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  render() {
    let optionsClientes = [];

    this.state.clientes.map((value) => {
      optionsClientes.push({
        key: value.id,
        text: value.nombre,
        value: value.id,
      });
    });

    var _this = this;

    //tropas activas
    let tropas = this.state.rodeos_activos.map((value) => {
      var f1 = new Date();
      f1.setHours(0);
      f1.setMinutes(0);
      f1.setSeconds(0);
      f1.setMilliseconds(0);

      var f2 = value.ultimaFechaCalculada
        ? value.ultimaFechaCalculada.toDate
          ? value.ultimaFechaCalculada.toDate()
          : new Date(value.ultimaFechaCalculada)
        : new Date();
      f2.setHours(0);
      f2.setMinutes(0);
      f2.setSeconds(0);
      f2.setMilliseconds(0);

      const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * value.gdpv;
      const pesoActual =
        (Number(value.ultimaPesada) ? value.ultimaPesada : 0) + Number(aumento);

      //value.metaDiasEncierre
      //fecha de salida
      let fechaEstimada = new Date();
      let diasEncierre =
        new Date().getTime() -
        (value.fechaAlta.toDate
          ? value.fechaAlta.toDate().getTime()
          : new Date(value.fechaAlta).getTime());
      diasEncierre = Number(this.formatoNumero(diasEncierre / 86400000, 0));

      //kilos
      const metaDias = Number(value.metaDiasEncierre) || 0;
      const metaKilos = Number(value.metaKilos) || 0;
      let kilos = metaKilos;
      if (value.seguir) {
        if (pesoActual >= metaKilos && metaKilos > 0) {
          kilos = pesoActual;
          fechaEstimada = new Date();
        } else if (diasEncierre >= metaDias && metaDias > 0) {
          fechaEstimada = new Date();
        } else {
          if (metaDias > 0 || metaKilos > 0) {
            let faltantePorKilos = 0;
            let faltantePorDias = 0;
            if (metaKilos > 0) {
              faltantePorKilos = (metaKilos - pesoActual) / value.gdpv;
            }
            if (metaDias > 0) {
              faltantePorDias = metaDias - diasEncierre;
            }

            if (metaDias === 0) {
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorKilos) * 86400000
              );
            }

            if (metaKilos === 0) {
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorDias) * 86400000
              );
            }

            if (faltantePorKilos <= faltantePorDias && metaKilos > 0) {
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorKilos) * 86400000
              );
            }

            if (faltantePorDias <= faltantePorKilos && metaDias > 0) {
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorDias) * 86400000
              );
            }
          }
        }
      } else {
        if (diasEncierre >= metaDias) {
          fechaEstimada = new Date();
        } else {
          fechaEstimada = new Date(
            fechaEstimada.getTime() +
              Math.ceil(metaDias - diasEncierre) * 86400000
          );
        }
      }

      let categoria = this.state.categorias.find(
        (c) => c.id === value.categoria
      );

      let fechaCobro = fechaEstimada;

      if (value.plazo)
        fechaCobro = new Date(fechaCobro.getTime() + value.plazo * 86400000);

      return {
        doc: value.doc,
        id: value.id + fechaEstimada,
        fecha: fechaEstimada,
        fechaCobro,
        cliente: "Sin definir",
        dieta: value.dieta,
        rodeo: value.nombre,
        categoria: categoria ? categoria.nombre : "-",
        cabezas: value.cabezas,
        kilos,
        plazo: value.plazo || 0,
        consumo_estimado: value.consumo_estimado || 0,
        precio: value.precioventa || 0,
        neto: value.cabezas * categoria.venta * kilos,
        total:
          value.cabezas * categoria.venta * kilos * (this.state.iva / 100 + 1),
        vender: value.vender ? value.vender : false,
        iva: Number(this.state.iva),
        vendido: false,
      };
    });

    tropas = tropas.filter((t) => t.vender);

    let listaVentas = this.state.movrodeos.filter(
      (m) => m.detalle.toLowerCase() === "venta"
    );

    let listaVentaReporte = [];

    listaVentas.map((value) => {
      let rodeo = this.state.rodeos_inactivos.find((r) => r.id === value.rodeo);

      if (!rodeo) {
        rodeo = this.state.rodeos_activos.find((r) => r.id === value.rodeo);
      }

      let categoria = null;

      if (rodeo) {
        categoria = this.state.categorias.find((c) => c.id === rodeo.categoria);
      }

      let fechaCobro = value.fecha.toDate
        ? value.fecha.toDate()
        : new Date(value.fecha);
      if (value.plazo) {
        fechaCobro = new Date(fechaCobro.getTime() + value.plazo * 86400000);
      }

      listaVentaReporte.push({
        id: value.fecha + (rodeo ? rodeo.nombre : "-"),
        vendido: true,
        dieta: rodeo ? rodeo.dieta : null,
        doc: value.doc,
        cliente: value.cliente ? value.cliente : "Sin definir",
        fecha: value.fecha,
        consumo_estimado: 0,
        plazo: value.plazo ? value.plazo : 0,
        fechaCobro: fechaCobro,
        rodeo: rodeo ? rodeo.nombre : "-",
        categoria: categoria ? categoria.nombre : "-",
        cabezas: value.cabezas,
        kilos: value.kilos * (1 - value.desbaste / 100),
        precio: value.precio,
        iva: Number(this.state.iva),
      });
    });

    listaVentaReporte = listaVentaReporte.concat(tropas);

    let listaFiltrosV = this.filteredVentas.filtros.reduce((filtro, item) => {
      let val = item.nombre;
      filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
      return filtro;
    }, {});

    listaFiltrosV = Object.keys(listaFiltrosV).sort((a, b) => {
      if (listaFiltrosV[a].hora > listaFiltrosV[b].hora) {
        return 1;
      }

      if (listaFiltrosV[a].hora < listaFiltrosV[b].hora) {
        return -1;
      }
      return 0;
    });

    this.filtrosFijos.forEach((fijo) => {
      let index = listaFiltrosV.indexOf(fijo);
      if (index < 0) listaFiltrosV.push(fijo);
    });

    listaVentaReporte = listaVentaReporte.filter((d) => {
      var fecha = d.fechaCobro.toDate
        ? d.fechaCobro.toDate()
        : new Date(d.fechaCobro); //new Date(d.detalle.fecha);

      let banderaFechaInicio = true;
      if (this.filteredVentas.fechaInicio !== null) {
        var fechaInicio = new Date(this.filteredVentas.fechaInicio);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filteredVentas.fechaFinal !== null) {
        var fechaFin = new Date(this.filteredVentas.fechaFinal);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    //por fecha de venta

    listaVentaReporte = listaVentaReporte.filter((d) => {
      var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha); //new Date(d.detalle.fecha);

      let banderaFechaInicio = true;
      if (this.filteredVentas.fechaInicioVta !== null) {
        var fechaInicio = new Date(this.filteredVentas.fechaInicioVta);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filteredVentas.fechaFinalVta !== null) {
        var fechaFin = new Date(this.filteredVentas.fechaFinalVta);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    listaFiltrosV.forEach((f) => {
      if (f === "vendidos")
        listaVentaReporte = _this.aplicarFiltroVendidos(listaVentaReporte);
      if (f === "rodeos")
        listaVentaReporte = _this.aplicarFiltroRodeos(listaVentaReporte);
      if (f === "categorias")
        listaVentaReporte = _this.aplicarFiltroCategorias(listaVentaReporte);
      if (f === "clientes")
        listaVentaReporte = _this.aplicarFiltroClientes(listaVentaReporte);
    });

    //LO QUE IMPORTA

    let listaReduced;
    let data = [];
    if (this.filteredVentas.fechaCobro) {
      listaVentaReporte.sort((a, b) => {
        if (a.fechaCobro > b.fechaCobro) return 1;
        if (a.fechaCobro < b.fechaCobro) return -1;
        return 0;
      });

      listaReduced = listaVentaReporte.reduce((mes, item) => {
        let val = this.prettyDate(
          item.fechaCobro.toDate
            ? item.fechaCobro.toDate()
            : new Date(item.fechaCobro)
        );
        mes[val] = mes[val] || { name: val, Pendiente: 0, Vendido: 0 };
        if (item.vendido) {
          mes[val].Vendido += Number(
            Number(item.cabezas) *
              Number(item.precio) *
              Number(item.kilos) *
              (Number(item.iva) / 100 + 1)
          );
        } else {
          mes[val].Pendiente += Number(
            Number(item.cabezas) *
              Number(item.precio) *
              Number(item.kilos) *
              (Number(item.iva) / 100 + 1)
          );
        }
        return mes;
      }, {});
    } else {
      listaVentaReporte.sort((a, b) => {
        if (
          (a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha)).getTime() >
          (b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha)).getTime()
        )
          return 1;
        if (
          (a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha)).getTime() <
          (b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha)).getTime()
        )
          return -1;
        return 0;
      });

      listaReduced = listaVentaReporte.reduce((mes, item) => {
        let val = this.prettyDate(
          item.fecha.toDate ? item.fecha.toDate() : new Date(item.fecha)
        );
        mes[val] = mes[val] || { name: val, Pendiente: 0, Vendido: 0 };
        if (item.vendido) {
          mes[val].Vendido += Number(
            Number(item.cabezas) *
              Number(item.precio) *
              Number(item.kilos) *
              (Number(item.iva) / 100 + 1)
          );
        } else {
          mes[val].Pendiente += Number(
            Number(item.cabezas) *
              Number(item.precio) *
              Number(item.kilos) *
              (Number(item.iva) / 100 + 1)
          );
        }
        return mes;
      }, {});
    }

    Object.keys(listaReduced).map((value) => {
      data.push(listaReduced[value]);
    });

    let totalplata = 0;
    const listaResumen = Object.keys(listaReduced).map((value) => {
      totalplata +=
        Number(listaReduced[value].Pendiente) +
        Number(listaReduced[value].Vendido);
      return (
        <Table.Row>
          <Table.Cell textAlign="left">{listaReduced[value].name}</Table.Cell>
          <Table.Cell textAlign="right">
            {this.formatoMoneda(listaReduced[value].Vendido)}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {this.formatoMoneda(listaReduced[value].Pendiente)}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {this.formatoMoneda(
              Number(listaReduced[value].Pendiente) +
                Number(listaReduced[value].Vendido)
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Grid>
        <Grid.Row centered>
          <Segment raised>
            <Form.Group>
              <Form.Field>
                <Checkbox
                  label="Graficar por fecha de Venta"
                  name="checkboxRadioGroup"
                  value="consumos"
                  checked={!this.filteredVentas.fechaCobro}
                  onClick={this.selectedFechaVenta}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Graficar por fecha de Cobro"
                  name="checkboxRadioGroup"
                  value="resumen"
                  checked={this.filteredVentas.fechaCobro}
                  onClick={this.selectedFechaCobro}
                />
              </Form.Field>
            </Form.Group>

            <Table celled striped color={"blue"} valigned={"center"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <ModalMultiple
                      opciones={this.vendidosF}
                      label={"Estado"}
                      onSelected={this.onSelectedVendidosPendientes}
                      onSelectedAll={this.onSelectedAllVendidosPendientes}
                      todo={
                        this.filteredVentas.vendidos === null ? true : false
                      }
                      seleccion={this.seleccionVendidos}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <ModalMultiple
                      opciones={this.clientesFventas}
                      label={"Cliente"}
                      onSelected={this.onSelectedClientes}
                      onSelectedAll={this.onSelectedAllClientes}
                      todo={this.filteredVentas.allClientes}
                      seleccion={this.filteredVentas.listaClientes}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <FiltroFechas
                      sin_limite={true}
                      titulo={"Fecha Vta"}
                      borrarFechaInicio={this.borrarFechaInicioVta}
                      borrarFechaFinal={this.borrarFechaFinalVta}
                      fechaInicio={this.filteredVentas.fechaInicioVta}
                      fechaFinal={this.filteredVentas.fechaFinalVta}
                      onChangeFechaInicio={this.onChangeFechaInicioVta}
                      onChangeFechaFinal={this.onChangeFechaFinalVta}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <FiltroFechas
                      sin_limite={true}
                      titulo={"Fecha Cobro"}
                      borrarFechaInicio={this.borrarFechaInicio}
                      borrarFechaFinal={this.borrarFechaFinal}
                      fechaInicio={this.filteredVentas.fechaInicio}
                      fechaFinal={this.filteredVentas.fechaFinal}
                      onChangeFechaInicio={this.onChangeFechaInicio}
                      onChangeFechaFinal={this.onChangeFechaFinal}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <ModalMultiple
                      opciones={this.rodeosFventas}
                      label={"Rodeo"}
                      onSelected={this.onSelectedRodeo}
                      onSelectedAll={this.onSelectedAllRodeos}
                      todo={this.filteredVentas.allRodeos}
                      seleccion={this.filteredVentas.listaRodeos}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <ModalMultiple
                      opciones={this.categoriasFventas}
                      label={"Categoria"}
                      onSelected={this.onSelectedCategoria}
                      onSelectedAll={this.onSelectedAllCategorias}
                      todo={this.filteredVentas.allCategorias}
                      seleccion={this.filteredVentas.listaCategorias}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </Segment>
        </Grid.Row>

        <Grid.Row centered>
          <div
            style={{
              overflow: "scroll",
              height: `${this.state.height + 60}px;`,
              width: `${this.state.width}px;`,
            }}
          >
            <ComposedChart
              layout="horizontal"
              width={90 * data.length > 450 ? 90 * data.length : 450}
              height={this.state.height}
              data={data}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" type="category" />
              <YAxis type="number" />
              <Tooltip
                formatter={(value, name, props) => this.formatoMoneda(value)}
              />
              <Legend verticalAlign="top" iconType={"diamond"} />
              {/* <Area dataKey='amt' fill='#8884d8' stroke='#8884d8' /> */}
              <Bar dataKey="Vendido" stackId="a" fill="#04B431" />
              <Bar dataKey="Pendiente" stackId="a" fill="#01A9DB" />
              {/* <Line dataKey='uv' stroke='#ff7300' /> */}
            </ComposedChart>
          </div>
        </Grid.Row>

        <Grid.Row centered>
          <Segment raised>
            <Table celled striped color={"blue"} valigned={"center"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                  <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                  <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    {this.formatoMoneda(totalplata)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell className="center aligned">
                    Mes
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Vendido
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Pendiente
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Total
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{listaResumen}</Table.Body>
            </Table>
          </Segment>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    clientes: state.personalStore.clientes,
    movrodeos: state.personalStore.movrodeos,
    empresa: state.personalStore.empresa,
    categorias: state.personalStore.categorias,
    reportes_ventas: state.personalStore.reportes_ventas,
    rodeosActivos: state.personalStore.rodeosActivos,
    rodeosInactivos: state.personalStore.rodeosInactivos,
    filtrosVentas: state.personalStore.filtrosVentas,
    filtrosTropasActivas: state.personalStore.filtrosTropasActivas,
    listeners: state.personalStore.listeners,
  };
}

export default connect(mapStateToProps, {
  initialFetch,
  fetchClientes,
  fetchEmpresa,
  fetchRodeosInactivos,
  fetchRodeosActivos,
  fetchReporteVentas,
  fetchCategorias,
  fetchMovRodeos,
  setFilterVentas,
  setFilterTropas,
})(GraficoVentas);
