import React from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react/dist/commonjs'

import Calendario from '../common/Calendario';

export default class FiltroFechas extends React.Component {

  constructor(props){
    super(props);

    this.titulo = props.titulo;

    this.fechaInicio = props.fechaInicio;
    this.fechaFinal = props.fechaFinal;

    this.onChangeFechaInicio = props.onChangeFechaInicio;
    this.onChangeFechaFinal = props.onChangeFechaFinal;

    this.borrarFechaInicio = props.borrarFechaInicio;
    this.borrarFechaFinal = props.borrarFechaFinal;

    this.sin_limite = props.sin_limite || false;

    this.state = {
        modalOpen: false
    }

  }

  componentWillReceiveProps = (nextProps) => {
    this.fechaInicio = nextProps.fechaInicio;
    this.fechaFinal = nextProps.fechaFinal;
  }
  
  close = ()=>{
    this.setState({ modalOpen: false })
  }
  render() {
    return (
        <Modal dimmer={"inverted"}
            closeOnEscape={false} 
            closeOnDimmerClick={false} 
            onClose={this.close}
            open={this.state.modalOpen}
            size={'tiny'}
            trigger={
                      <Button compact inverted={this.fechaInicio === null && this.fechaFinal === null} color={"green"} onClick={()=>this.setState({modalOpen: true})}>{this.titulo ? this.titulo : "Fecha"}</Button>
                    }
            >

            <Modal.Header>
                        <Grid columns={2} >
                            <Grid.Row> 
                                <Grid.Column textAlign={"left"} >
                                    Seleccionar Rango de Fechas 
                                </Grid.Column>
                                <Grid.Column textAlign={"right"} >
                                    <Button color={"green"} inverted onClick={
                                        ()=>{ 
                                            this.setState({
                                                modalOpen: false,
                                            });
                                            if (this.props.stock){
                                                this.onChangeFechaFinal(this.fechaFinal)
                                            }
                                        }
                                     } icon={"external"} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
            </Modal.Header>
            <Modal.Content>
            <Modal.Description>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                Desde...
                                <Calendario minDate={this.props.minDate} sin_limite={this.sin_limite} borrar={this.borrarFechaInicio} style={{backGroundColor: "red"}} fecha={this.fechaInicio} onChangeFecha={this.onChangeFechaInicio}/>
                            </Grid.Column>
                            <Grid.Column>
                                Hasta...
                                <Calendario minDate={this.props.minDate} sin_limite={this.sin_limite} borrar={this.borrarFechaFinal} fecha={this.fechaFinal} onChangeFecha={this.onChangeFechaFinal}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            </Modal.Description>
            </Modal.Content>
        </Modal>
    );
  }

}