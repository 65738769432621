import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Loader,
  Segment,
  Dimmer,
  Image,
  Grid,
  Button,
  Input,
  Label,
} from "semantic-ui-react";

import ListaMovimientos from "./ListaMovimientos";

import {
  FETCHED,
  NUEVO_REPORTE_SET_MOVIMIENTOS,
  NUEVO_REPORTE_SET_PRECIOS_GLOBAL,
} from "./actions";
import Api from "./../api";

const api = new Api();

class SetearMovimientos extends Component {
  state = {
    precioCompra: 0,
    precioVenta: 0,
    error: false,
  };

  componentWillMount = async () => {
    const { dispatch, rodeos, fechaInicio, fechaFinal } = this.props;
    try {
      dispatch({
        type: FETCHED,
      });
      let final = new Date(new Date(fechaFinal).getTime()); //+ 86400000)
      // let final = new Date("06/30/2020");

      const data = await api.getMovimientos(
        this.props.perfil.empresa,
        rodeos,
        fechaInicio,
        final
      );
      console.log({ data });

      dispatch({
        type: NUEVO_REPORTE_SET_MOVIMIENTOS,
        payload: data,
      });
    } catch (err) {
      // console.log({err})
    }
  };

  changePrecios = () => {
    if (!isNaN(this.state.precioCompra) && !isNaN(this.state.precioVenta)) {
      this.setState({
        error: false,
      });

      const { dispatch } = this.props;
      try {
        dispatch({
          type: NUEVO_REPORTE_SET_PRECIOS_GLOBAL,
          payload: {
            precioCompra: this.state.precioCompra,
            precioVenta: this.state.precioVenta,
          },
        });
      } catch (err) {
        // console.log({err})
      }
    } else {
      this.setState({
        error: true,
      });
    }
  };

  render() {
    const { fetched, rodeos, movimientos } = this.props;

    if (fetched) {
      return (
        <Segment>
          <Dimmer active>
            <Loader />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      );
    }
    if (rodeos.length === 0) {
      return <Redirect to="/reportes" push={true} />;
    }

    return (
      <Container style={{ paddingBottom: 40 }}>
        <Grid centered>
          <Grid.Row centered>
            <h2>Paso 2: Configurar movimientos</h2>
          </Grid.Row>

          <Grid.Row centered>
            <Grid columns={3} centered>
              <Grid.Row centered>
                <h4>
                  ¿Autocompletar precios de venta y compra con los siguientes
                  valores?
                </h4>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column style={{ width: "280px" }}>
                  <Input
                    label="Precio compra"
                    style={{ width: "100px" }}
                    value={this.state.precioCompra}
                    placeholder="Precio compra"
                    onChange={(val, { value }) =>
                      this.setState({ precioCompra: value })
                    }
                  />
                </Grid.Column>
                <Grid.Column style={{ width: "280px" }}>
                  <Input
                    label="Precio venta"
                    style={{ width: "100px" }}
                    value={this.state.precioVenta}
                    placeholder="Precio venta"
                    onChange={(val, { value }) =>
                      this.setState({ precioVenta: value })
                    }
                  />
                </Grid.Column>
                <Grid.Column style={{ width: "280px" }}>
                  <Button
                    positive
                    primary
                    size="small"
                    style={{ marginLeft: 20 }}
                    disabled={movimientos.length === 0}
                    onClick={this.changePrecios}
                  >
                    Autocompletar
                  </Button>
                </Grid.Column>
              </Grid.Row>

              {this.state.error && (
                <Grid.Row centered>
                  <Label basic color="red" pointing>
                    Solo se admiten valores numericos, por favor verifique.
                  </Label>
                </Grid.Row>
              )}
            </Grid>
          </Grid.Row>

          <Grid.Row centered>
            <Button
              as={Link}
              info
              floated="right"
              primary
              size="small"
              to="/reportes/nuevo_reporte"
              style={{ marginRigth: 20 }}
            >
              Anterior
            </Button>
            <Button
              as={Link}
              positive
              floated="right"
              primary
              size="small"
              to="/reportes/setear_productos"
              style={{ marginLeft: 20 }}
              disabled={movimientos.length === 0}
            >
              Siguiente
            </Button>
          </Grid.Row>

          <Grid.Row>
            <ListaMovimientos tropas={this.props.tropas} />
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.personalStore.nuevoReporte,
    tropas: state.firestore.data.rodeos,
  };
};

export default connect(mapStateToProps)(SetearMovimientos);
