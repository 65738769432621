import React, { Component } from 'react';
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchUsuarios, fetchMixers } from '../actions';

import UsuarioNew from '../components/UsuarioNew';
import UsuarioEdit from '../components/UsuarioEdit';

class Usuarios extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.buscarUsuarios= props.actions.fetchUsuarios;
    this.buscarMixers = props.actions.fetchMixers;
    this.activarListener = props.actions.initialFetch;
    
    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.state = ({
      mixers: props.mixers || [],
      usuarios: props.usuarios || [],
      listeners: props.listeners || []
    })

  }

  componentWillMount = () => {
    const itemM = { id: "mixers" };
    
    var oyenteM = this.state.listeners.find(function(element) {
      return element.id === "mixers";
    });
    
    if(oyenteM === undefined){
        this.activarListener(this.perfil, itemM);
        this.buscarMixers(this.perfil);
    }

    const item = { id: "usuarios" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "usuarios";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarUsuarios(this.perfil); 
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      mixers: nextProps.mixers || [],
      usuarios: nextProps.usuarios || [],
      listeners: nextProps.listeners || []
    })
  }

  render() {

    if (this.state.usuarios.length === 0){
      return ( 
        <div>
          <div className="row pull-right">
            <UsuarioNew mixers={this.state.mixers} db={this.db} perfil={this.perfil} />
          </div>
          <div className="row text-center">
            <span>No hay usuarios dados de alta.</span>
          </div>
        </div>
      )
    }

    const lista = this.state.usuarios.map(
            (value) => {
                let mixer = this.state.mixers.find((m) => m.id === value.mixer);
                if ( mixer === undefined || mixer === null ) { mixer = {}; mixer.nombre = "Sin mixer"; }

                let activo = "No";
                if (value.activo) activo = "si";
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre} </Table.Cell>
                    <Table.Cell>{value.apellido} </Table.Cell>
                    <Table.Cell>{value.rol} </Table.Cell>
                    <Table.Cell>{mixer.nombre} </Table.Cell>
                    <Table.Cell className="center aligned">{activo}</Table.Cell>
                    <Table.Cell className="center aligned">{value.email}</Table.Cell>
                    <Table.Cell className="center aligned"><UsuarioEdit mixers={this.state.mixers} db={this.db} perfil={this.perfil} id={value.id} usuario={value} /></Table.Cell>
                  </Table.Row>   
                )
          }
        )
    
    return (

      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='7'>
                      <span>Usuarios </span>
                      <UsuarioNew mixers={this.state.mixers} db={this.db} perfil={this.perfil} />
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Apellido</Table.HeaderCell>
                  <Table.HeaderCell>Rol</Table.HeaderCell>
                  <Table.HeaderCell>Mixer</Table.HeaderCell>
                  <Table.HeaderCell>Activo</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )

  }

}

function mapStateToProps(state, props) {
  return {
    usuarios: state.personalStore.usuarios,
    mixers: state.personalStore.mixers,
    listeners: state.personalStore.listeners
  };
}

function mapDispatchToProps(dispatch, props) {
  
    const actions = {
        fetchUsuarios: bindActionCreators(fetchUsuarios, dispatch),
        fetchMixers: bindActionCreators(fetchMixers, dispatch),
        initialFetch: bindActionCreators(initialFetch, dispatch),
    };
    
    return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Usuarios);