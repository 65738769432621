import React from "react";
import {
  Table,
  Button,
  Label,
  Grid,
  Icon,
  Pagination,
  Form,
} from "semantic-ui-react";

// import GraficoConsumo from "../containers/GraficoConsumo";
// import ResumenSeguimiento from "./ResumenSeguimiento";
import RodeoSanidad from "./RodeoSanidad";
import RodeoMov from "./RodeoMov";
import RodeoEdit from "./RodeoEdit";
import RodeoNew from "./RodeoNew";
import Automatismo from "./Automatismo";

import CambioDieta from "./../dietas_automaticas/CambioDieta";
import ProgramarAutomatismo from "./../ProgramarAutomatismo";

import Sumar from "./Sumar";
import ButtonMenu from "./common/ButtonMenu";
import Restar from "./Restar";

import ModalMultiple from "./filtros/ModalMultiple";

import firebase from "../firebase";

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-IN", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

const prettyDate = (date) => {
  var months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  var minutos = date.getMinutes();
  if (minutos < 10) minutos = "0" + minutos;

  return (
    date.getDate() +
    " " +
    months[date.getMonth()] +
    " " +
    date.getHours() +
    ":" +
    minutos
  );
};

const diaMes = (date) => {
  var months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  var minutos = date.getMinutes();
  if (minutos < 10) minutos = "0" + minutos;

  return date.getDate() + " " + months[date.getMonth()];
};

export default class ListaRodeos extends React.PureComponent {
  constructor(props) {
    super(props);
    this.perfil = props.perfil;

    this.db = firebase.firestore();
  }

  componentWillReceiveProps = (nextProps) => {
    this.perfil = nextProps.perfil;
  };

  render() {
    let fecha = new Date();
    fecha.setHours(0);
    fecha.setMinutes(0);

    let listaActivos = [];

    let totalCabezas = 0;
    let totalMeta = 0;
    let totalDescargado = 0;
    let totalFalta = 0;
    let totalRemanente = 0;
    let totalKilos = 0;
    let cabezasSeguimiento = 0;
    let cpcacumulado = 0;

    let diasEncierresTotales = 0;
    let fechasPesadaAcumulada = 0;

    var f1 = new Date();
    f1.setHours(0);
    f1.setMinutes(0);
    f1.setSeconds(0);
    f1.setMilliseconds(0);

    let rodeosReduce = this.props.rodeosactivos.reduce((lista, item) => {
      let val = item.corral;

      let f2 = item.ultimaFechaCalculada
        ? item.ultimaFechaCalculada.toDate
          ? item.ultimaFechaCalculada.toDate()
          : new Date(item.ultimaFechaCalculada)
        : new Date();
      f2.setHours(0);
      f2.setMinutes(0);
      f2.setSeconds(0);
      f2.setMilliseconds(0);

      const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * item.gdpv;
      const pesoActual =
        (Number(item.ultimaPesada) ? item.ultimaPesada : 0) + Number(aumento);

      lista[val] = lista[val] || { rodeos: [] };

      lista[val].rodeos.push({ ...item, pesoActual });
      return lista;
    }, {});

    listaActivos = this.props.rodeosactivos.map((value) => {
      let isLectura = value.isLectura || false;

      if (value && value.ultima_lectura) {
        let ultimaLectura = new Date(value.ultima_lectura);
        if (f1.getTime() > ultimaLectura.getTime()) {
          isLectura = false;
        }
      } else {
        isLectura = false;
      }

      var f2 = value.ultimaFechaCalculada
        ? value.ultimaFechaCalculada.toDate
          ? value.ultimaFechaCalculada.toDate()
          : new Date(value.ultimaFechaCalculada)
        : new Date();
      f2.setHours(0);
      f2.setMinutes(0);
      f2.setSeconds(0);
      f2.setMilliseconds(0);

      const aumentoRaciones =
        ((f1.getTime() - f2.getTime()) / 86400000) * value.cabezas;
      const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * value.gdpv;

      // let diasEnc = (Number(value.racionesTotales) + ((f1.getTime() - f2.getTime()) / 86400000) * value.cabezas) / value.cabAcumuladaTotal;
      // let diasSeg = (Number(value.raciones) + ((f1.getTime() - f2.getTime()) / 86400000) * value.cabezas) / value.cabAcumuladas;

      let gananciaDiaria =
        value.racionesTotales > 0
          ? ((value.ultimaPesada + Number(aumento)) * value.cabezas -
              Number(value.entradas) +
              Number(value.salidas)) /
            (value.racionesTotales + Number(aumentoRaciones))
          : value.gdpv;

      const pesoActual =
        (Number(value.ultimaPesada) ? value.ultimaPesada : 0) + Number(aumento);

      const corral = this.props.corrales.find((item) => {
        return item.id === value.corral;
      });

      const dieta = this.props.dietas.find((item) => {
        return item.id === value.dieta;
      });

      value.meta = value.materiaSeca * value.cabezas * dieta.talcual;

      const categoria = this.props.categorias.find((item) => {
        return item.id === value.categoria;
      });

      if (value.ultimaDescarga < fecha.getTime()) {
        value.descargado = 0;
      }
      if (value.ultimaDescarga < fecha.getTime() && !isLectura) {
        value.remanente = 0;
      }

      if (value.seguir) {
        totalKilos += pesoActual * value.cabezas;
        cabezasSeguimiento += value.cabezas;
        cpcacumulado += value.cpc * value.cabezas;
      }

      totalRemanente += value.remanente;
      totalCabezas += value.cabezas;
      totalMeta += value.materiaSeca * value.cabezas * dieta.talcual;
      totalDescargado += value.descargado;

      if (
        value.materiaSeca * value.cabezas * dieta.talcual -
          value.descargado -
          value.remanente >
        0
      )
        totalFalta +=
          value.materiaSeca * value.cabezas * dieta.talcual -
          value.descargado -
          value.remanente;

      const primerMovimiento = this.props.movrodeos.filter(
        (m) => m.rodeo === value.id
      );

      let fecha_alta = value.fechaAlta.toDate
        ? value.fechaAlta.toDate()
        : new Date(value.fechaAlta); //new Date();
      // if (primerMovimiento.length > 0)
      //     fecha_alta = primerMovimiento ? (primerMovimiento[0].fecha.toDate ? primerMovimiento[0].fecha.toDate() : new Date(primerMovimiento[0].fecha)) : new Date();

      // fecha_alta.setHours(0);
      // fecha_alta.setMinutes(0);
      // fecha_alta.setSeconds(0);
      // fecha_alta.setMilliseconds(0);

      let hoy = new Date();
      hoy.setHours(0);
      hoy.setMinutes(0);
      hoy.setSeconds(0);
      hoy.setMilliseconds(0);

      let permanencia = (hoy.getTime() - fecha_alta.getTime()) / 86400000;

      permanencia += value.diasAcumulados ? value.diasAcumulados : 0;

      diasEncierresTotales += permanencia * value.cabezas;
      fechasPesadaAcumulada +=
        new Date(value.ultimaFechaPesada.toDate()).getTime() * value.cabezas;

      // console.log(this.props.perfil)
      // if (this.props.perfil !== undefined) {

      let rodeosAsociados = rodeosReduce[value.corral].rodeos;

      return (
        <Table.Row key={value.id}>
          <Table.Cell className="center aligned">
            <ButtonMenu
              rodeo={value}
              edit={
                <RodeoEdit
                  empresa={this.props.empresa}
                  rodeos={this.props.rodeosactivos}
                  id={value.id}
                  rodeo={value}
                  db={this.props.db}
                  perfil={this.props.perfil}
                  corrales={this.props.corrales}
                  dietas={this.props.dietas}
                  categorias={this.props.categorias}
                />
              }
              movimientos={
                <RodeoMov
                  clientes={this.props.clientes}
                  rodeos_inactivos={this.props.rodeosInactivos}
                  corral={corral}
                  dieta={dieta}
                  categoria={categoria}
                  rodeos={this.props.rodeosactivos}
                  corrales={this.props.corrales}
                  empresa={this.props.empresa}
                  rodeo={value}
                  movrodeos={this.props.movrodeos}
                  db={this.props.db}
                  perfil={this.props.perfil}
                />
              }
              sanidad={
                <RodeoSanidad
                  empresa={this.props.empresa}
                  rodeo={value}
                  db={this.props.db}
                  perfil={this.props.perfil}
                />
              }
              automatismo={
                <Automatismo
                  db={this.props.db}
                  perfil={this.props.perfil}
                  empresa={this.props.empresa}
                  rodeo={value}
                />
              }
              dietas={
                <CambioDieta
                  db={this.props.db}
                  perfil={this.props.perfil}
                  empresa={this.props.empresa}
                  rodeo={value}
                  dietas={this.props.dietas}
                  rodeos={this.props.rodeosactivos}
                />
              }
              programarAutomatismo={
                <ProgramarAutomatismo
                  db={this.props.db}
                  perfil={this.props.perfil}
                  empresa={this.props.empresa}
                  rodeo={value}
                  rodeos={this.props.rodeosactivos}
                />
              }
            />
          </Table.Cell>
          {/* <Table.Cell className="center aligned"> */}
          {/* <GraficoConsumo perfil={this.perfil} clima={this.props.clima} dietas={this.props.dietas} categoria={categoria.nombre} tropa={value.nombre} rodeo={value.id} /> */}
          {/* </Table.Cell> */}
          {corral ? (
            <Table.Cell>{corral.nombre} </Table.Cell>
          ) : (
            <Table.Cell style={{ backgroundColor: "red" }}>
              Sin Corral{" "}
            </Table.Cell>
          )}
          {corral ? (
            <Table.Cell>
              {this.props.mixers.find((m) => m.id === corral.mixer).nombre}{" "}
            </Table.Cell>
          ) : (
            <Table.Cell style={{ backgroundColor: "red" }}> - </Table.Cell>
          )}

          <Table.Cell>{value.propietario} </Table.Cell>
          <Table.Cell>{value.nombre} </Table.Cell>

          <Table.Cell className="right aligned">{value.cabezas}</Table.Cell>
          <Table.Cell>{categoria.nombre} </Table.Cell>
          <Table.Cell>{dieta.nombre} </Table.Cell>
          <Table.Cell className="right aligned">
            {formatear(value.materiaSeca * value.cabezas * dieta.talcual)} kg
          </Table.Cell>
          {/* style={this.semaforoRemanente(value)}
                    style={this.semaforoFaltante(value)} */}
          <Table.Cell className="right aligned">
            {value.descargado > 0 || isLectura
              ? formatear(
                  (Number(value.remanente) / Number(value.meta)) * 100,
                  2
                )
              : 0}{" "}
            %
          </Table.Cell>
          <Table.Cell className="right aligned">
            {formatear(value.descargado)} kg
          </Table.Cell>
          <Table.Cell className="right aligned">
            {formatear(
              value.materiaSeca * value.cabezas * dieta.talcual -
                value.descargado -
                value.remanente
            )}{" "}
            kg
          </Table.Cell>

          <Table.Cell
            className="right aligned"
            style={this.props.semaforo(value)}
          >
            {formatear(value.cpc, 2)} kg
          </Table.Cell>
          <Table.Cell className="right aligned">
            <Button.Group>
              <Restar
                pesoActual={pesoActual}
                rodeosAsociados={rodeosAsociados}
                rodeo={value}
                id={value.id}
                db={this.props.db}
                perfil={this.props.perfil}
              />
              <Button.Or text={formatear(value.materiaSeca, 2)} />
              <Sumar
                pesoActual={pesoActual}
                rodeosAsociados={rodeosAsociados}
                rodeo={value}
                id={value.id}
                db={this.props.db}
                perfil={this.props.perfil}
              />
            </Button.Group>
          </Table.Cell>
          {this.props.empresa.rodeos && value.seguir ? (
            <Table.Cell className="right aligned">
              {formatear(
                (value.materiaSeca /
                  value.cabezas /
                  (pesoActual / value.cabezas)) *
                  100,
                2
              )}
            </Table.Cell>
          ) : (
            <Table.Cell />
          )}
          <Table.Cell className="right aligned">
            {formatear(permanencia)}
          </Table.Cell>
          {this.props.empresa.rodeos && value.seguir && (
            <Table.Cell>{diaMes(value.ultimaFechaPesada.toDate())}</Table.Cell>
          )}

          {this.props.empresa.rodeos && value.seguir && (
            <Table.Cell className="right aligned">
              {pesoActual ? formatear(pesoActual) : 0} Kg
            </Table.Cell>
          )}
          {this.props.empresa.rodeos && value.seguir && (
            <Table.Cell textAlign="center">
              {formatear(gananciaDiaria, 2)}
            </Table.Cell>
          )}
          {/* {this.props.empresa.rodeos && value.seguir &&
                            <Table.Cell className="right aligned"> <ResumenSeguimiento rodeo={value} cabezas={value.cabezas} ventas={value.ventas} compras={value.compras} ultimoPrecio={value.ultimoPrecio} raciones={(value.raciones + Number(aumentoRaciones))} consumos={this.props.consumos.find(c => c.id === value.id)} consumos_sin_seguimiento={this.props.consumos_sin_seguimiento.find(c => c.id === value.id)} productos={this.props.productos} dieta={dieta} cpc={value.cpc} peso={pesoActual} dias={diasSeg} gdpv={gananciaDiaria} /> </Table.Cell>
                        } */}
          {!this.props.empresa.rodeos ||
            (!value.seguir && <Table.Cell></Table.Cell>)}
          {!this.props.empresa.rodeos ||
            (!value.seguir && <Table.Cell></Table.Cell>)}
          {!this.props.empresa.rodeos ||
            (!value.seguir && <Table.Cell> </Table.Cell>)}
          {/* <Table.Cell className="right aligned"><Button icon="add" onClick={()=> console.log(value)}/></Table.Cell> */}
          {/* <Table.Cell className="right aligned"><Button icon="add" 
                                onClick={
                                    ()=> this.db.collection("empresas").doc(this.perfil.empresa).collection('rodeos').doc(value.id).update({ultimaPesada: 463})
                                }
                            />
                        </Table.Cell>  */}
          {/* <Table.Cell className="right aligned">
            <Button
              icon="add"
              onClick={
                () =>
                  this.db
                    .collection("empresas")
                    .doc(this.perfil.empresa)
                    .collection("rodeos")
                    .doc(value.id)
                    .update({ descargado: 0 })
                //() => console.log({ value })
              }
            />
            VER
          </Table.Cell> */}
          {/* ines@lacarreta.com */}
        </Table.Row>
      );

      // }
      // return (<div key={value.id}>a</div>)
    });

    let porcentajePesoVivoPromedio =
      cabezasSeguimiento > 0
        ? (cpcacumulado /
            cabezasSeguimiento /
            (totalKilos / cabezasSeguimiento)) *
          100
        : 0;
    // console.log(listaActivos)
    return (
      <Grid
        style={{
          paddingBottom: 100,
          marginTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Grid.Row centered>
          {/* {this.props.clima.map( (dia) => 
            <img src={`../assets/day/${dia.icon}`} />
        )} */}
          <Table
            celled
            striped
            compact
            collapsing
            color={"teal"}
            valigned={"center"}
            unstackable={true}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="20">
                  <span>Tropas al {prettyDate(new Date())}</span>

                  <Button.Group floated="right">
                    <Label>Establecer % de Tolerancia: </Label>
                    <Button negative onClick={this.props.bajarTolerancia}>
                      <Icon disabled name="minus" />
                    </Button>
                    <Button.Or text={this.props.empresa.tolerancia} />
                    <Button positive onClick={this.props.subirTolerancia}>
                      <Icon disabled name="plus" />
                    </Button>
                  </Button.Group>
                  <RodeoNew
                    db={this.props.db}
                    rodeos={this.props.rodeosactivos.concat(
                      this.props.rodeosInactivos
                    )}
                    empresa={this.props.empresa}
                    perfil={this.props.perfil}
                    corrales={this.props.corrales}
                    dietas={this.props.dietas}
                    categorias={this.props.categorias}
                  />
                </Table.HeaderCell>
              </Table.Row>

              <Table.Row textAlign={"center"}>
                <Table.HeaderCell colSpan="5" />
                <Table.HeaderCell>{totalCabezas}</Table.HeaderCell>
                <Table.HeaderCell colSpan="2" />
                <Table.HeaderCell>{formatear(totalMeta)} Kg</Table.HeaderCell>
                <Table.HeaderCell>
                  {formatear(totalRemanente)} Kg
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {formatear(totalDescargado)} Kg
                </Table.HeaderCell>
                <Table.HeaderCell>{formatear(totalFalta)} Kg</Table.HeaderCell>
                <Table.HeaderCell>
                  {cabezasSeguimiento > 0
                    ? formatear(cpcacumulado / cabezasSeguimiento, 2)
                    : formatear(0)}{" "}
                  Kg
                </Table.HeaderCell>

                <Table.HeaderCell colSpan="2" />
                <Table.HeaderCell>
                  {cabezasSeguimiento > 0
                    ? Number(diasEncierresTotales / cabezasSeguimiento).toFixed(
                        0
                      )
                    : formatear(0)}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {cabezasSeguimiento > 0
                    ? diaMes(
                        new Date(fechasPesadaAcumulada / cabezasSeguimiento)
                      )
                    : "-"}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {cabezasSeguimiento > 0
                    ? formatear(totalKilos / cabezasSeguimiento)
                    : formatear(0)}{" "}
                  Kg
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="2">
                  <Label as="a" basic color="red" pointing>
                    {formatear(porcentajePesoVivoPromedio, 2)} % P.V.
                  </Label>
                </Table.HeaderCell>
                {/* <Table.HeaderCell colSpan='1' /> */}
              </Table.Row>

              <Table.Row textAlign={"center"}>
                <Table.HeaderCell className="center aligned">
                  Menu
                </Table.HeaderCell>
                {/* <Table.HeaderCell className="center aligned">
                  Consumo
                </Table.HeaderCell> */}
                <Table.HeaderCell>Corral</Table.HeaderCell>

                <Table.HeaderCell>
                  <ModalMultiple
                    opciones={this.props.mixerF}
                    label={"Mixer"}
                    onSelected={this.props.onSelectedMixer}
                    onSelectedAll={this.props.onSelectedAllMixer}
                    todo={this.props.filtered.allMixer}
                    seleccion={this.props.filtered.listaMixer}
                  />
                </Table.HeaderCell>

                <Table.HeaderCell>Propietario</Table.HeaderCell>
                <Table.HeaderCell>Tropa</Table.HeaderCell>
                <Table.HeaderCell>Cab</Table.HeaderCell>
                <Table.HeaderCell>
                  <ModalMultiple
                    opciones={this.props.categoriasF}
                    label={"Categoria"}
                    onSelected={this.props.onSelectedCategoria}
                    onSelectedAll={this.props.onSelectedAllCategorias}
                    todo={this.props.filtered.allCategorias}
                    seleccion={this.props.filtered.listaCategorias}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <ModalMultiple
                    opciones={this.props.dietasF}
                    label={"Dieta"}
                    onSelected={this.props.onSelectedDieta}
                    onSelectedAll={this.props.onSelectedAllDietas}
                    todo={this.props.filtered.allDietas}
                    seleccion={this.props.filtered.listaDietas}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Meta</Table.HeaderCell>
                <Table.HeaderCell>Rema</Table.HeaderCell>
                <Table.HeaderCell>Desc</Table.HeaderCell>
                <Table.HeaderCell>Falt</Table.HeaderCell>
                <Table.HeaderCell style={{ maxWidth: "22px" }}>
                  CPC
                </Table.HeaderCell>
                <Table.HeaderCell>MS</Table.HeaderCell>
                <Table.HeaderCell>% P.V</Table.HeaderCell>
                <Table.HeaderCell>D Enc.</Table.HeaderCell>
                <Table.HeaderCell>F. U. P.</Table.HeaderCell>
                <Table.HeaderCell>Peso</Table.HeaderCell>
                <Table.HeaderCell>GDPV</Table.HeaderCell>
                {/* {this.props.empresa.rodeos &&
                                    <Table.HeaderCell></Table.HeaderCell>
                                } */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listaActivos.slice(
                (this.props.page - 1) * this.props.rowsPerPage,
                (this.props.page - 1) * this.props.rowsPerPage +
                  this.props.rowsPerPage
              )}
            </Table.Body>
          </Table>
        </Grid.Row>
        <Grid.Row centered>
          {listaActivos.length > this.props.rowsPerPage && (
            <div style={{ display: "flex", gap: "10px", maginBottom: "10px" }}>
              <Form.Field>
                <Form.Select
                  label="Filas por pagina"
                  options={[25, 50, 75, 100, 150].map((e) => ({
                    key: e,
                    text: e,
                    value: e,
                  }))}
                  placeholder="Filas por pagina"
                  value={this.props.rowsPerPage}
                  onChange={(e, { value }) => {
                    this.props.setPage(1);
                    this.props.setRows(value);
                  }}
                />
              </Form.Field>
              <Pagination
                defaultActivePage={this.props.page}
                activePage={this.props.page}
                totalPages={
                  Math.floor(listaActivos.length / this.props.rowsPerPage) + 1
                }
                onPageChange={(e, { activePage }) => {
                  window.scrollTo(0, 0);
                  this.props.setPage(activePage);
                }}
              />
            </div>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}
