import firebase from '../firebase';
import { isEmpty } from 'react-redux-firebase';
const db = firebase.firestore();

export const fetchEmpresa = (perfil) => {
    return function (dispatch) {
        if (!isEmpty(perfil)){
            db.collection("empresas").doc(perfil.empresa)
                .onSnapshot(function(doc) {
                    let empresa = doc.data()
                   empresa.doc = doc;
                    dispatch(setEmpresa(empresa));
                });
        }

    }
}

const setEmpresa = (empresa) => ({
    type: "SET_EMPRESA",
    ...empresa
});