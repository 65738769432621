
const reportes_programas_sanitarios = (state = [], action) => {
    switch (action.type) {
        case 'ADD_REPORTE_PROGRAMA_SANITARIO':
            return action.programasSanitarios
        default:
            return state
    }
};

export default reportes_programas_sanitarios;