import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import format from "date-format";
import numeral from "numeral";

import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const calcularMovimientos = (movimientos) => {
  let existencia = {
    etiqueta: "Existencias",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let altas = {
    etiqueta: "Altas de tropa",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let compra = {
    etiqueta: "Entrada por compras",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let encierre = {
    etiqueta: "Entrada por encierre",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let venta = {
    etiqueta: "Salida por ventas",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let transferenciasOutput = {
    etiqueta: "Salida por transferencias",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let transferenciasInput = {
    etiqueta: "Entrada por transferencias",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let salidaCampo = {
    etiqueta: "Salida a campo",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let ajustePositivo = {
    etiqueta: "Ajuste positivo",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let ajusteNegativo = {
    etiqueta: "Ajuste negativo",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let muertes = {
    etiqueta: "Muertes",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let entradas = {
    etiqueta: "Total Entradas",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let salidas = {
    etiqueta: "Total Salidas",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };

  let resultado = [];

  movimientos.forEach((movimiento) => {
    if (movimiento.concepto.toLowerCase() === "entrada") {
      if (movimiento.detalle === "Alta de rodeo") {
        altas.cantidad += movimiento.cabezas;
        altas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        altas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Transferencia") {
        transferenciasInput.cantidad += movimiento.cabezas;
        transferenciasInput.kilosTotales +=
          movimiento.cabezas * movimiento.kilos;
        transferenciasInput.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Encierre") {
        encierre.cantidad += movimiento.cabezas;
        encierre.kilosTotales += movimiento.cabezas * movimiento.kilos;
        encierre.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Entrada por Recuento") {
        ajustePositivo.cantidad += movimiento.cabezas;
        // ajustePositivo.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // ajustePositivo.monetizacion -=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        // entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // entradas.monetizacion -=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Compra") {
        compra.cantidad += movimiento.cabezas;
        compra.kilosTotales += movimiento.cabezas * movimiento.kilos;
        compra.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
    }

    if (movimiento.concepto.toLowerCase() === "salida") {
      if (movimiento.detalle === "A fecha final de reporte") {
        existencia.cantidad += movimiento.cabezas;
        existencia.kilosTotales += movimiento.cabezas * movimiento.kilos;
        existencia.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Generar Rodeo") {
        transferenciasOutput.cantidad += movimiento.cabezas;
        transferenciasOutput.kilosTotales +=
          movimiento.cabezas * movimiento.kilos;
        transferenciasOutput.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Transferencia") {
        transferenciasOutput.cantidad += movimiento.cabezas;
        transferenciasOutput.kilosTotales +=
          movimiento.cabezas * movimiento.kilos;
        transferenciasOutput.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Salida a Campo") {
        salidaCampo.cantidad += movimiento.cabezas;
        salidaCampo.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidaCampo.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Salida por Recuento") {
        ajusteNegativo.cantidad += movimiento.cabezas;
        // ajusteNegativo.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // ajusteNegativo.monetizacion +=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        // salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // salidas.monetizacion +=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Venta") {
        venta.cantidad += movimiento.cabezas;
        venta.kilosTotales += movimiento.cabezas * movimiento.kilos;
        venta.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
    }

    if (movimiento.concepto.toLowerCase() === "muerte") {
      muertes.cantidad += movimiento.cabezas;
    }
  });

  resultado.push(altas);
  resultado.push(compra);
  resultado.push(encierre);
  resultado.push(transferenciasInput);
  resultado.push(ajustePositivo);
  resultado.push(venta);
  resultado.push(salidaCampo);
  resultado.push(transferenciasOutput);
  resultado.push(ajusteNegativo);
  resultado.push(muertes);
  resultado.push(existencia);
  resultado.push(entradas);
  resultado.push(salidas);

  return resultado;
};

export const pdfReporteTropas = async (data) => {
  let logo = await getBase64Image("/assets/logo.png");

  const docDefinition = declareDoc(data, logo);
  pdfMake
    .createPdf(docDefinition)
    .download(`reporte-${new Date().toISOString()}.pdf`);
};

const getBase64Image = async (url) => {
  const response = await fetch(url, { mode: "no-cors" });
  const blob = await response.blob();
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  return reader.result;
};

const declareDoc = (data, logo) => {
  let resumen = calcularMovimientos(data.movimientos);

  const produccion = data.resultados.salidas - data.resultados.entradas;
  const ventas = data.resultados.ventas;
  const compras = data.resultados.compras;
  const hoteleria = data.hoteleria;
  const sanidad = data.sanidad;
  const raciones = data.raciones;
  const ms = data.ms;
  const conversion = produccion > 0 ? ms / produccion : 0;
  const entradaAbsoluta = data.resultados.cabAcumuladas;
  const mcal = data.mcal;
  const prot = data.prot;
  const { movimientos, rodeos } = data;

  let fechaInicial = movimientos[0].fecha;
  let fechaFinal = movimientos[movimientos.length - 1].fecha;

  fechaInicial = fechaInicial.toDate
    ? fechaInicial.toDate()
    : new Date(fechaInicial);
  fechaFinal = fechaFinal.toDate ? fechaFinal.toDate() : new Date(fechaFinal);

  const diasTotales = moment(fechaFinal).diff(moment(fechaInicial), "days") + 1;

  let costoAlimentacion = 0;
  Object.keys(data.productos).map((c) => {
    costoAlimentacion += data.productos[c].kilos * data.productos[c].precio;
  });

  let adpv = 0;
  if (raciones > 0) {
    adpv = produccion / raciones;
  }

  let conversioncalc = 0;
  if (produccion > 0) conversioncalc = ms / produccion;

  let diasEnc = 0;
  if (entradaAbsoluta > 0) {
    diasEnc = Number(raciones) / entradaAbsoluta;
  }

  let existenciaPromedio = 0;
  if (diasTotales > 0) {
    existenciaPromedio = raciones / diasTotales;
  }

  let consumo = 0;
  if (raciones > 0) {
    consumo = ms / raciones;
  }
  let mcalgdpv = 0;
  if (adpv > 0) {
    mcalgdpv = mcal / adpv;
  }
  let protgdpv = 0;
  if (adpv > 0) {
    protgdpv = prot / adpv;
  }

  let costokiloms = 0;
  if (consumo > 0 && raciones > 0) {
    costokiloms = costoAlimentacion / raciones / consumo;
  }

  let ingreso_neto_por_kg = 0;
  if (produccion > 0) {
    ingreso_neto_por_kg = (ventas - compras) / produccion;
  }

  let ingreso_por_dia_por_cabeza = ingreso_neto_por_kg * adpv;

  let gast_alim_por_racion = 0;
  if (raciones > 0) {
    gast_alim_por_racion = costoAlimentacion / raciones;
  }

  let sanitario_por_cabeza_por_dia = 0;
  if (diasEnc > 0) {
    sanitario_por_cabeza_por_dia = sanidad / diasEnc;
  }

  let alimimentacion_x_kg = 0;
  if (produccion > 0) {
    alimimentacion_x_kg = costoAlimentacion / produccion;
  }

  let sanitario_x_kg = 0;
  if (adpv > 0) {
    sanitario_x_kg = sanitario_por_cabeza_por_dia / adpv;
  }

  let hoteleria_x_kg = 0;
  if (adpv > 0) {
    hoteleria_x_kg = hoteleria / adpv;
  }

  let pdf = {
    pageMargins: [30, 30, 30, 30],
    // header: function (currentPage, pageCount, pageSize) {
    //   return [
    //     {
    //       columns: [
    //         {
    //           image: logo,
    //           width: 80,
    //           height: 80,
    //           margin: -5,
    //         },
    //         {
    //           stack: [
    //             {
    //               text: "Mis Corrales",
    //               fontSize: 18,
    //             },
    //             "Reporte de tropa/s",
    //             `Periodo ${data.periodo}`,
    //           ],
    //           width: "*",
    //         },
    //         {
    //           stack: [
    //             {
    //               text: format("dd/MM/yyyy hh:mm", new Date()),
    //             },
    //           ],
    //           width: "*",
    //           alignment: "right",
    //         },
    //       ],
    //       margin: [15, 15],
    //     },
    //   ];
    // },

    footer: function (currentPage, pageCount, pageSize) {
      return [
        {
          text: currentPage + "/" + pageCount,
          alignment: "center",
          margin: [8, 8],
        },
        {
          canvas: [
            { type: "rect", x: 170, y: 32, w: pageSize.width - 170, h: 40 },
          ],
        },
      ];
    },

    content: [],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [8, 6, 8, 6],
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: "center",
      },
      tabla: {
        margin: [10, 10, 10, 0],
        fontSize: 12,
      },
      tableHeader: {
        bold: true,
        fontSize: 14,
        color: "black",
      },
      contado: {
        bold: true,
        fontSize: 16,
        color: "green",
      },
      subcontado: {
        bold: true,
        fontSize: 14,
        color: "black",
      },
      texto: {
        bold: true,
        fontSize: 14,
        color: "black",
      },
      credito: {
        bold: true,
        fontSize: 16,
        color: "black",
      },
    },
    defaultStyle: {
      alignment: "justify",
    },
  };

  pdf.content.push({
    columns: [
      {
        image: logo,
        width: 80,
        height: 80,
        margin: -5,
      },
      {
        stack: [
          {
            text: "Mis Corrales",
            fontSize: 18,
          },
          "Reporte de tropa/s",
          `Periodo ${data.periodo}`,
        ],
        width: "*",
      },
      {
        stack: [
          {
            text: format("dd/MM/yyyy hh:mm", new Date()),
          },
        ],
        width: "*",
        alignment: "right",
      },
    ],
    margin: [15, 15],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 10, x2: 550 - 10, y2: 10, lineWidth: 2 },
    ],
  });

  pdf.content.push({
    text: "Resumen de Movimientos",
    style: "header",
    alignment: "left",
  });

  let tableResumenMovimientos = {
    style: "tabla",
    table: {
      headerRows: 1,
      body: [],
    },
    layout: "lightHorizontalLines",
  };
  tableResumenMovimientos.table.body.push([
    { text: "Concepto", style: "subheader" },
    { text: "Cabezas", style: "subheader" },
    { text: "Kilos promedios", style: "subheader" },
    { text: "Kilos totales", style: "subheader" },
    { text: "Precio promedio", style: "subheader" },
    { text: "Monetizacion total", style: "subheader" },
  ]);

  resumen.map((m) => {
    tableResumenMovimientos.table.body.push([
      {
        text: m.etiqueta,
        alignment: "left",
        width: 200,
        bold: m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas",
        color:
          m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
            ? "blue"
            : "black",
      },
      {
        text: m.cantidad,
        alignment: "right",
        bold: m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas",
        color:
          m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
            ? "blue"
            : "black",
      },
      {
        text: numeral(m.cantidad > 0 ? m.kilosTotales / m.cantidad : 0).format(
          "0,0.00"
        ),
        alignment: "right",
        bold: m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas",
        color:
          m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
            ? "blue"
            : "black",
      },
      {
        text: numeral(m.kilosTotales).format("0,0.00"),
        alignment: "right",
        bold: m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas",
        color:
          m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
            ? "blue"
            : "black",
      },
      {
        text: numeral(
          Math.abs(m.kilosTotales > 0 ? m.monetizacion / m.kilosTotales : 0)
        ).format("$0,0.00"),
        alignment: "right",
        bold: m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas",
        color:
          m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
            ? "blue"
            : "black",
      },
      {
        text: numeral(m.monetizacion).format("$0,0.00"),
        alignment: "right",
        bold: m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas",
        color:
          m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
            ? "blue"
            : "black",
      },
    ]);
  });

  pdf.content.push(tableResumenMovimientos);

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 10, x2: 550 - 10, y2: 10, lineWidth: 2 },
    ],
  });

  pdf.content.push({
    text: "Resultados Generales",
    style: "header",
    alignment: "left",
  });

  pdf.content.push({
    columns: [
      {
        text: "Raciones suministradas",
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(data.raciones).format("0"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: "Total de cabezas ingresadas",
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(entradaAbsoluta).format("0"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: "Dias de encierre promedio",
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(data.raciones / entradaAbsoluta).format("0.0"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: "Dias del periodo seleccionado",
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(diasTotales).format("0"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Existencia promedio (durante los ${diasTotales} dias)`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(existenciaPromedio).format("0"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Produccion de Carne`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(produccion).format("0,0.00"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Consumo de Materia Seca`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(ms).format("0,0"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `ADPV`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(adpv).format("0.00"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Conversion`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(conversion).format("0,0.00")} a 1`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Ingreso Neto por Produccion`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(ventas - compras).format("$0,0.00"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Gasto de Alimentacion`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(costoAlimentacion).format("$0,0.00")} (${
          ventas - compras > 0
            ? numeral((costoAlimentacion / (ventas - compras)) * 100).format(
                "0,0.00"
              )
            : 0
        } %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Gasto de Hoteleria`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(hoteleria * raciones).format("$0,0.00")} (${
          ventas - compras > 0
            ? numeral(
                ((hoteleria * raciones) / (ventas - compras)) * 100
              ).format("0,0.00")
            : 0
        } %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Gasto Sanitario`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(sanidad * entradaAbsoluta).format("$0,0.00")} (${
          ventas - compras > 0
            ? numeral(
                ((sanidad * entradaAbsoluta) / (ventas - compras)) * 100
              ).format("0,0.00")
            : 0
        } %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Resultado por Produccion`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(
          ventas -
            compras -
            costoAlimentacion -
            hoteleria * raciones -
            sanidad * entradaAbsoluta
        ).format("$0,0.00")} ( ${
          ventas - compras > 0
            ? numeral(
                ((ventas -
                  compras -
                  costoAlimentacion -
                  hoteleria * raciones -
                  sanidad * entradaAbsoluta) /
                  (ventas - compras)) *
                  100
              ).format("0,0.00")
            : 0
        } %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Concentracion Energia de la Dieta`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: numeral(mcal).format("0,0.00"),
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Proteina de la Dieta`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(prot).format("0,0.00")} %`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Consumo ms/cab/dia`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(consumo).format("0,0.00")} kg`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Consumo mega calorias diarias`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(mcal * consumo).format("0,0.00")} Mcal`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Consumo proteinas diarias`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral((consumo * prot) / 100).format("0,0.00")}/kg`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Resultado/dia/cab`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(
          ingreso_por_dia_por_cabeza -
            gast_alim_por_racion -
            sanitario_por_cabeza_por_dia -
            hoteleria
        ).format("$0,0.00")}`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 10, x2: 550 - 10, y2: 10, lineWidth: 2 },
    ],
  });

  pdf.content.push({
    text: "Resultados por KG producido",
    style: "header",
    alignment: "left",
  });

  pdf.content.push({
    columns: [
      {
        text: `MCal/kg ganancia de peso`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral((mcal * consumo) / adpv).format("0,0.00")}`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Prot/kg ganancia de peso`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral((consumo * prot) / 100 / adpv).format("0,0.00")}/kg`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Costo/kg MS de alimento`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(costokiloms).format("$0,0.00")}`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Ingreso Neto/kg Producido`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(ingreso_neto_por_kg).format("$0,0.00")}`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Gasto Alim x kg`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(alimimentacion_x_kg).format("$0,0.00")} (${numeral(
          ingreso_neto_por_kg > 0
            ? (alimimentacion_x_kg / ingreso_neto_por_kg) * 100
            : 0
        ).format("0,0.00")} %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Gasto Hoteleria x kg`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(hoteleria_x_kg).format("$0,0.00")} (${numeral(
          ingreso_neto_por_kg > 0
            ? (hoteleria_x_kg / ingreso_neto_por_kg) * 100
            : 0
        ).format("0,0.00")} %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Gasto Sanitario x kg`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(sanitario_x_kg).format("$0,0.00")} (${numeral(
          ingreso_neto_por_kg > 0
            ? (sanitario_x_kg / ingreso_neto_por_kg) * 100
            : 0
        ).format("0,0.00")} %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 1 },
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: `Resultado x kg`,
        fontSize: 14,
        width: "*",
        alignment: "left",
      },
      {
        text: `${numeral(
          ingreso_neto_por_kg -
            alimimentacion_x_kg -
            sanitario_x_kg -
            hoteleria_x_kg
        ).format("$0,0.00")} (${numeral(
          ingreso_neto_por_kg > 0
            ? ((ingreso_neto_por_kg -
                alimimentacion_x_kg -
                sanitario_x_kg -
                hoteleria_x_kg) /
                ingreso_neto_por_kg) *
                100
            : 0
        ).format("0,0.00")} %)`,
        width: "*",
        alignment: "right",
      },
    ],
  });

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 3, x2: 550 - 10, y2: 3, lineWidth: 2 },
    ],
  });

  pdf.content.push({
    text: "Productos consumidos",
    style: "header",
    alignment: "left",
  });

  let tablaProductos = {
    style: "tabla",
    table: {
      headerRows: 1,
      body: [],
    },
    layout: "lightHorizontalLines",
  };
  tablaProductos.table.body.push([
    { text: "Producto", style: "subheader" },
    { text: "Kilos totales", style: "subheader" },
    { text: "Materia Seca", style: "subheader" },
    { text: "Valor", style: "subheader" },
  ]);

  data.productos.map((m) => {
    tablaProductos.table.body.push([
      {
        text: m.nombre,
        alignment: "left",
        width: 200,
        bold: true,
      },
      {
        text: `${numeral(m.kilos).format("0,0")} kg`,
        alignment: "right",
      },
      {
        text: `${numeral(m.materiaseca).format("0,0")} kg`,
        alignment: "right",
      },
      {
        text: numeral(m.costo).format("$0,0"),
        alignment: "right",
      },
    ]);
  });

  pdf.content.push(tablaProductos);

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 10, x2: 550 - 10, y2: 10, lineWidth: 2 },
    ],
  });

  pdf.content.push({
    text: "Lista de Movimientos",
    style: "header",
    alignment: "left",
  });

  let tablaMovimientos = {
    style: "tabla",
    table: {
      headerRows: 1,
      body: [],
    },
    layout: "lightHorizontalLines",
  };
  tablaMovimientos.table.body.push([
    { text: "Fecha", style: "subheader" },
    { text: "Tropa", style: "subheader" },
    { text: "Concepto", style: "subheader" },
    { text: "Detalle", style: "subheader" },
    { text: "Cabezas", style: "subheader" },
    { text: "Peso Neto", style: "subheader" },
    { text: "Precio/Kg", style: "subheader" },
    { text: "Total", style: "subheader" },
  ]);

  data.movimientos.map((m) => {
    let detalle = m.detalle;

    if (
      m.concepto.toLowerCase() === "entrada" &&
      m.origen &&
      m.origen.length > 0
    ) {
      detalle = `${detalle} - de ${
        data.tropas[m.origen] ? data.tropas[m.origen].nombre : ""
      }`;
    }
    if (
      m.concepto.toLowerCase() === "salida" &&
      m.destino &&
      m.destino.length > 0
    ) {
      detalle = `${detalle} - a ${
        data.tropas[m.destino] ? data.tropas[m.destino].nombre : ""
      }`;
    }

    tablaMovimientos.table.body.push([
      {
        text: format("dd/MM/yyyy", new Date(m.fecha)),
        alignment: "left",
        width: 140,
        bold: true,
      },
      {
        text: m.nombre,
        alignment: "left",
      },
      {
        text: m.concepto,
        alignment: "left",
      },
      {
        text: detalle,
        alignment: "left",
      },
      {
        text: numeral(m.cabezas).format("0,0"),
        alignment: "right",
      },
      {
        text:
          m.concepto.toLowerCase() === "muerte" ||
          m.detalle.toLowerCase() === "entrada por recuento" ||
          m.detalle.toLowerCase() === "salida por recuento"
            ? 0
            : numeral(m.kilos).format("0,0"),
        alignment: "right",
      },
      {
        text:
          m.concepto.toLowerCase() === "muerte" ||
          m.detalle.toLowerCase() === "entrada por recuento" ||
          m.detalle.toLowerCase() === "salida por recuento"
            ? 0
            : numeral(Number(m.precio)).format("$0,0"),
        alignment: "right",
      },
      {
        text:
          m.concepto.toLowerCase() === "muerte" ||
          m.detalle.toLowerCase() === "entrada por recuento" ||
          m.detalle.toLowerCase() === "salida por recuento"
            ? 0
            : numeral(Number(m.precio) * m.kilos * m.cabezas).format("$0,0"),
        alignment: "right",
      },
    ]);
  });

  pdf.content.push(tablaMovimientos);

  pdf.content.push({
    canvas: [
      { type: "line", x1: 10, y1: 10, x2: 550 - 10, y2: 10, lineWidth: 2 },
    ],
  });

  return pdf;
};
