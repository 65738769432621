import React, { Component } from 'react';
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchCategorias } from '../actions';

import CategoriaNew from '../components/CategoriaNew';
import CategoriaEdit from '../components/CategoriaEdit';

class Categorias extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.buscarCategorias = props.actions.fetchCategorias;
    this.activarListener = props.actions.initialFetch;
    
    this.db = firebase.firestore();

    this.perfil = props.perfil;

    this.state = ({
      categorias: props.categorias || [],
      listeners: props.listeners || []
    })
    
  }

  componentWillMount = () => {
    const item = { id: "categorias" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "categorias";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarCategorias(this.perfil); 
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.categorias) this.setState({ categorias: nextProps.categorias});
    if(nextProps.listeners) this.setState({ listeners: nextProps.listeners});
  }
 
  formatear = (num, decimales = 0) => {
      var signo = (num >= 0 ? 1 : -1);
      num = num * signo;
      if (decimales === 0) //con 0 decimales
          return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split('e');
      num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
      // x * 10 ^ (-decimales)
      num = num.toString().split('e');
      return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
  }

  
  render() {
    const lista = this.state.categorias.map(
            (value) => {
                let activo = "No";
                if (value.activo) activo = "si";
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre} </Table.Cell>
                    <Table.Cell textAlign={"right"}>$ {this.formatear(value.compra, 2)} </Table.Cell>
                    <Table.Cell textAlign={"right"}>$ {this.formatear(value.venta, 2)} </Table.Cell>
                    <Table.Cell className="center aligned">{activo}</Table.Cell>
                    <Table.Cell><CategoriaEdit db={this.db} perfil={this.perfil} id={value.id} categoria={value} /></Table.Cell>
                  </Table.Row>   
                )
          }
        )
    
    return (
      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>
                    <span>Categorias </span>
                    <CategoriaNew db={this.db} perfil={this.perfil}/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Categoria</Table.HeaderCell>
                  <Table.HeaderCell>Precio Compra</Table.HeaderCell>
                  <Table.HeaderCell>Precio Venta</Table.HeaderCell>
                  <Table.HeaderCell>Activo</Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    categorias: state.personalStore.categorias,
    listeners: state.personalStore.listeners
  };
}

function mapDispatchToProps(dispatch, props) {
  
    const actions = {
        fetchCategorias: bindActionCreators(fetchCategorias, dispatch),
        initialFetch: bindActionCreators(initialFetch, dispatch),
    };
    
    return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Categorias);