import React, { Component } from 'react'
import { connect } from 'react-redux';
import { EDITAR_PRECIO_PRODUCTO} from './actions'
import { Input } from 'semantic-ui-react'

class InputPrecioProducto extends Component {

    handleChange = (value) => {
        const { dispatch, id } = this.props
        // if (!isNaN(value)){
            dispatch({
                type: EDITAR_PRECIO_PRODUCTO,
                payload: {
                    id,
                    value: Number(value)
                }
            })
        // }
    }

    render() {
        return (
            <Input  fluid value={this.props.value} icon='money' placeholder='Precio...' onChange={(val, {value}) => this.handleChange(value)}/>
        )
    }
}

export default connect()(InputPrecioProducto)