import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react'

class RestarCantidad extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    
    this.db = props.db;

    this.idDieta = props.idDieta;
    this.id = props.id;

    this.cantidad = props.cantidad
    
    this.timer = null;

    
   }

    componentWillReceiveProps = (nextProps) => {
        this.idDieta = nextProps.idDieta;
        this.id = nextProps.id;
        this.db = nextProps.db;
        this.cantidad = nextProps.cantidad;
    }
  
  restar = () => {
      var nuevoValor = (this.cantidad - 0.1);

      if ( nuevoValor > 0){
        var rodeoRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas').doc(this.idDieta).collection('detalle').doc(this.id);
        
        // Set the "capital" field of the city 'DC'
        return rodeoRef.update({
            "cantidad": Number(nuevoValor)
        })
        .then(function() {
            
        })
        .catch(function(error) {
            // The document probably doesn't exist.
            console.log('error');
        })
        }

  }


  addOne = () => {
    this.restar();
    this.timer = setTimeout(this.addOne, 200);
  }

  stopTimer = () => {
    clearTimeout(this.timer);
  }
  
  render () {
    
    return(
        <Button negative onMouseDown={this.addOne} onMouseUp={this.stopTimer} onMouseLeave={this.stopTimer} ><Icon disabled name='minus' /></Button>
    )

  }

}


export default RestarCantidad;