const detalledietas = (state = [], action) => {
  switch (action.type) {
    case "ADD_DETALLE_DIETA":
      let newState = [...state];
      let indice = state.findIndex((d) => d.id === action.detalleDietas.id);
      if (indice > -1) {
        newState[indice] = action.detalleDietas;
      } else {
        newState.push(action.detalleDietas);
      }
      return newState;
    default:
      return state;
  }
};

export default detalledietas;
