import React, { Component } from 'react';
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { initialFetch, fetchEmpresas } from '../actions';

import EmpresaNew from '../components/EmpresaNew';
//import EmpresaEdit from '../components/EmpresaEdit';

class Empresas extends Component {
  
  static isPrivate = true;
  constructor(props){
    super(props);

    this.fetchEmpresas = props.fetchEmpresas;
    this.activarListener = props.initialFetch;
    
    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.state = ({
      empresas: props.empresas || [],
      listeners: props.listeners || []
    })

  }  

  componentWillMount = () => {
    const itemM = { id: "empresas" };
    
    var oyenteM = this.state.listeners.find(function(element) {
      return element.id === "empresas";
    });
    
    if(oyenteM === undefined){
        this.activarListener(this.perfil, itemM);
        this.fetchEmpresas();
    }
  }
  

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      empresas: nextProps.empresas || [],
      listeners: nextProps.listeners || []
    })
  }

  prettyDate = (date) => {
    var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
                'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    
    var minutos = date.getMinutes();
    if (minutos < 10) minutos = '0' + minutos;

    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getHours() + ':' + minutos + ' ' + date.getFullYear() ;
  }  
  render() {

    if (this.state.empresas.length === 0){
      return ( 
        <div>
          <div className="row pull-right">
            {/* <EmpresaNew db={this.db} perfil={this.perfil} /> */}
          </div>
          <div className="row text-center">
            <span>No hay empresas dadas de alta.</span>
          </div>
        </div>
      )
    }

    let empresas = this.state.empresas.sort((a, b)=>{
        if (a.fechaVto > b.fechaVto) return 1
        if (a.fechaVto < b.fechaVto) return -1
        return 0
    })

    const lista = empresas.map(
            (value) => {                
                let activo = "Inactivo";
                if (value.activo) activo = "Activo";
                let rodeos = "No";
                if (value.rodeos) rodeos = "si";
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre} </Table.Cell>
                    <Table.Cell>{value.admin} </Table.Cell>
                    <Table.Cell className="center aligned">{value.email}</Table.Cell>
                    <Table.Cell className="center aligned">{value.tel}</Table.Cell>
                    <Table.Cell>{this.prettyDate( value.fechaAlta.toDate() ) } </Table.Cell>
                    <Table.Cell>{this.prettyDate( value.fechaVto.toDate() ) } </Table.Cell>
                    <Table.Cell>{ rodeos } </Table.Cell>
                    <Table.Cell>{ !value.procesando ? activo : "En Proceso"}</Table.Cell>
                    {/* <Table.Cell className="center aligned"><EmpresaEdit db={this.db} id={value.id} empresa={value} /></Table.Cell> */}
                  </Table.Row>   
                )
          }
        )
    
    return (

      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='9'>
                      <span>Empresas </span>
                      <EmpresaNew db={this.db} />
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Admin</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Tel</Table.HeaderCell>
                  <Table.HeaderCell>Alta</Table.HeaderCell>
                  <Table.HeaderCell>Vto</Table.HeaderCell>
                  <Table.HeaderCell>Rodeos</Table.HeaderCell>
                  <Table.HeaderCell>Estado</Table.HeaderCell>
                  <Table.HeaderCell>Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )

  }

}

function mapStateToProps(state, props) {
  return {
    empresas: state.personalStore.empresas,
    listeners: state.personalStore.listeners
  };
}

export default connect(
  mapStateToProps,
  { initialFetch, fetchEmpresas }
)(Empresas);