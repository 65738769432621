import React, { Component } from 'react';

import { Button, Modal, Icon, Message, Label, Form } from 'semantic-ui-react'

class ConfirmarSeguimiento extends Component {
  constructor (props) {
    super(props);

    this.perfil = props.perfil;
    this.id = props.id;
    this.rodeo = props.rodeo;
    this.empresa = props.empresa;

    this.db = props.db;

    this.state = {
      modalOpen: false,
      kilos: 0,
      precio: 0,
      error: ""
    };

    this.save = this.save.bind(this);

  }

  save = () => { 
    if ((Number(this.state.kilos) > 0 && Number(this.state.precio > 0)) || !this.empresa.rodeos || !this.rodeo.seguir){
      const batch = this.db.batch();

      var refRodeo = this.db.collection("empresas").doc(this.perfil.empresa).collection('rodeos').doc(this.id);
      
      batch.update(refRodeo, {  "seguir": true,
                                "ultimaPesada": Number(this.state.kilos),
                                "ultimaFechaPesada": new Date(), 
                                "ultimoPrecio": Number(this.state.precio)
                              });
    
      var newMovRodeo = this.db.collection("empresas").doc(this.perfil.empresa).collection("movrodeos").doc();

      batch.set(newMovRodeo, 
        {
          "fecha": new Date(),
          "rodeo": this.rodeo.id,
          "concepto": "Entrada",
          "detalle": "Inicio de seguimiento",
          "cabezas": Number(this.state.cabezas),
          "kilos": Number(this.state.kilos),
          "precio": Number(this.state.precio)
        });

      var _this = this;
      batch.commit().then(function () {
        _this.setState({
          modalOpen: false
        })
      }).catch(function(){
        _this.setState({
          error: 'Error al actualizar el rodeo.'
        })
      });
    }else{
      this.setState({
        error: "Debe ingresar el peso por cabeza y el costo por kilo"
      })
    }

  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }


  render () {
    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })}  size={'mini'} className="ui center aligned segment">
                     <Label>Seguir Rodeo</Label>
                </Button>}
        size={'small'}
        >
        <Modal.Header>Confirmar Seguimiento de Rodeo</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {this.rodeo.seguir && this.empresa.rodeos &&
            <Form>
                <Form.Field>
                  <Form.Input label={`Kg de entrada de las ${this.rodeo.cabezas} cabezas`} id="cabezas" type="number" step="1" min="0" placeholder="Kg por Cabeza"  onChange={(e, { value }) => this.setState({ kilos: value })} />
                </Form.Field>
                <Form.Field>
                  <Form.Input label="Precio por Kg" id="materia" type="number" step="0.25" min="0" placeholder="Precio por kg"  onChange={(e, { value }) => this.setState({ precio: value })} />
                </Form.Field>
                <Label color={'blue'}>Costo total: $ {this.state.kilos * this.rodeo.cabezas * this.state.precio}</Label>
            </Form>
            }
            <Message
                error
                header='Seguimiento de Rodeo'
                list={[
                    'Esta accion es irreversible, confirma realizar seguimiento a este rodeo?',
                ]}
            />

            <Label style={{color: 'red'}}>{this.state.error}</Label>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ConfirmarSeguimiento;