import React, { Component } from 'react'
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchMixers } from '../actions';

import MixerNew from '../components/MixerNew';
import MixerEdit from '../components/MixerEdit';

class Mixers extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.db = firebase.firestore();

    this.buscarMixers = props.actions.fetchMixers;
    this.activarListener = props.actions.initialFetch;

    this.perfil = props.perfil;

    this.state = {
      mixers: props.mixers || [],
      listeners: props.listeners || []
    }
  }

  componentWillMount = () => {
    const item = { id: "mixers" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "mixers";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarMixers(this.perfil);
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.mixers) this.setState({ mixers: nextProps.mixers});
  }
  
  render() {

    const lista = this.state.mixers.map(
            (value) => {
                let activo = "No";
                if (value.activo) activo = "si";
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre} </Table.Cell>
                    <Table.Cell className="center aligned">{activo}</Table.Cell>
                    <Table.Cell><MixerEdit db={this.db} perfil={this.perfil} id={value.id} mixer={value} /></Table.Cell>
                  </Table.Row>   
                )
          }
        )
    
    return (
      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>
                    <span>Mixers </span>
                    <MixerNew db={this.db} perfil={this.perfil}/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Activo</Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    mixers: state.personalStore.mixers,
    listeners: state.personalStore.listeners,
  };
}

function mapDispatchToProps(dispatch, props) {

  const actions = {
      fetchMixers: bindActionCreators(fetchMixers, dispatch),
      initialFetch: bindActionCreators(initialFetch, dispatch),
  };
  
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mixers);