import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Table } from "semantic-ui-react";
import InputKilos from "./InputKilos";
import InputPrecio from "./InputPrecio";

class ListaMovimientos extends Component {
  render() {
    const { movimientos } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Rodeo</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Fecha</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Concepto</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Detalle</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Cabezas</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Kilos</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">$/Kg</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.lista(movimientos)}</Table.Body>
      </Table>
    );
  }

  lista = (movimientos = []) => {
    movimientos.sort((a, b) => {
      if (new Date(a.fecha).getTime() > new Date(b.fecha).getTime()) return 1;
      if (new Date(a.fecha).getTime() < new Date(b.fecha).getTime()) return -1;
      return 0;
    });

    return movimientos.map((m) => {
      let detalle = m.detalle;

      if (
        m.concepto.toLowerCase() === "entrada" &&
        m.origen &&
        m.origen.length > 0
      ) {
        detalle = `${detalle} - de ${
          this.props.tropas[m.origen] ? this.props.tropas[m.origen].nombre : ""
        }`;
      }
      if (
        m.concepto.toLowerCase() === "salida" &&
        m.destino &&
        m.destino.length > 0
      ) {
        detalle = `${detalle} - a ${
          this.props.tropas[m.destino]
            ? this.props.tropas[m.destino].nombre
            : ""
        }`;
      }

      return (
        <Table.Row key={m.id}>
          <Table.Cell>{m.nombre}</Table.Cell>
          <Table.Cell>
            {moment(new Date(m.fecha)).format("DD/MM/YYYY")}
          </Table.Cell>
          <Table.Cell>{m.concepto}</Table.Cell>
          <Table.Cell>{detalle}</Table.Cell>
          <Table.Cell>{m.cabezas}</Table.Cell>
          {/* <Table.Cell> <InputKilos id={m.id} value={m.kilos} /> </Table.Cell> */}
          <Table.Cell> {Math.round(m.kilos)} </Table.Cell>
          <Table.Cell style={{ width: "150px" }}>
            {" "}
            <InputPrecio id={m.id} value={m.precio} />{" "}
          </Table.Cell>
        </Table.Row>
      );
    });
  };
}

const mapStateToProps = (state) => {
  return {
    movimientos: state.personalStore.nuevoReporte.movimientos,
  };
};

export default connect(mapStateToProps)(ListaMovimientos);
