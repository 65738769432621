import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';


export default class RodeoDeleteMov extends Component {


  state = { open: false }

  show = size => () => this.setState({ size, open: true })
  close = () => this.setState({ open: false })

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }
  render() {
    const { open, size } = this.state

    return (

      <Modal size={size} open={open} onClose={this.close}
        trigger={<Button circular color={this.props.color} icon="delete" className="ui floated right" onClick={this.show('small')} />}
      >

        <Modal.Header>
          Eliminar Movimiento
            </Modal.Header>
        <Modal.Content>
          <p>Esta seguro de eliminar este movimiento?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ open: false })}>
            No
              </Button>
          <Button positive icon='checkmark' labelPosition='right' content='Si'
            onClick={() => {
              console.log(this.props.empresa)
              var movimiento = this.props.db.collection("empresas").doc(this.props.empresa).collection("movrodeos").doc(this.props.mov.id);

              movimiento.delete()
              // this.props.mov.doc.ref.delete(); 
              this.setState({ open: false });
            }
            } />
        </Modal.Actions>
      </Modal>


    )
  }
}