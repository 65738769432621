import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Dimmer, Loader } from 'semantic-ui-react';
import ListaVentas from './ListaVentas';

class Ventas extends Component {
  render() {
    const { empresa, perfil, movrodeos, reportesVentas, categorias, rodeosInactivos, rodeosActivos, clientes } = this.props

    if (reportesVentas === undefined ||
        categorias === undefined ||
        rodeosInactivos === undefined ||
        rodeosActivos === undefined ||
        movrodeos === undefined ||
        empresa === undefined ||
        clientes === undefined)

        return (<Dimmer active><Loader /></Dimmer>)
  
      return (
        <ListaVentas 
            perfil={perfil} 
            categorias={categorias} 
            rodeosInactivos={rodeosInactivos} 
            rodeosActivos={rodeosActivos} 
            movrodeos={movrodeos} 
            empresa={empresa} 
            clientes={clientes} />
      )
    }
}

export default compose(
  firestoreConnect((props, state) => {
      return [
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            storeAs: 'empresa'
        },
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            subcollections: [{ collection: 'clientes' }],
            orderBy: [['nombre', 'asc']],
            storeAs: 'clientes'
        },
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            subcollections: [{ collection: 'rodeos' }],
            orderBy: [['nombre', 'asc']],
            storeAs: 'rodeosActivos',
            where: [['activo', '==', true]]
        },
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            subcollections: [{ collection: 'rodeos' }],
            orderBy: [['nombre', 'asc']],
            storeAs: 'rodeosInactivos',
            where: [['activo', '==', false]]
        },
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            subcollections: [{ collection: 'categorias' }],
            orderBy: [['nombre', 'asc']],
            storeAs: 'categorias'
        },
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            subcollections: [{ collection: 'reportes_ventas' }],
            orderBy: [['fecha', 'desc']],
            storeAs: 'reportesVentas'
        },
        {
            collection: 'empresas',
            doc: props.perfil.empresa,
            subcollections: [{ collection: 'movrodeos' }],
            orderBy: [['fecha', 'asc']],
            storeAs: 'movrodeos'
        },
      ]
  }), // sync todos collection from Firestore into redux
  connect((state, props) => {
      let reportesVentas, categorias, rodeosInactivos, rodeosActivos, clientes, movrodeos

      if (state.firestore.data.reportesVentas === null) reportesVentas = []
      if (state.firestore.data.categorias === null) categorias = []
      if (state.firestore.data.rodeosInactivos === null) rodeosInactivos = []
      if (state.firestore.data.rodeosActivos === null) rodeosActivos = []
      if (state.firestore.data.clientes === null) clientes = []
      if (state.firestore.data.movrodeos === null) movrodeos = []
      
      if (state.firestore.data.reportesVentas){
          reportesVentas = []
          Object.entries(state.firestore.data.reportesVentas).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              reportesVentas.push( obj)
          })
      }

      if (state.firestore.data.categorias){
          categorias = []
          Object.entries(state.firestore.data.categorias).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              categorias.push( obj)
          })
      }

      if (state.firestore.data.rodeosInactivos){
          rodeosInactivos = []
          Object.entries(state.firestore.data.rodeosInactivos).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              rodeosInactivos.push( obj)
          })
      }

      if (state.firestore.data.rodeosActivos){
          rodeosActivos = []
          Object.entries(state.firestore.data.rodeosActivos).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              rodeosActivos.push( obj)
          })
      }

      if (state.firestore.data.clientes){
          clientes = []
          Object.entries(state.firestore.data.clientes).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              clientes.push( obj)
          })
      }

      if (state.firestore.data.movrodeos){
          movrodeos = []
          Object.entries(state.firestore.data.movrodeos).map((item) => {
              let obj = item[1]
              obj.id = item[0]
              movrodeos.push( obj)
          })
      }

      return ({
        empresa: state.firestore.data.empresa,
        reportesVentas,
        categorias,
        rodeosInactivos, 
        rodeosActivos, 
        clientes,
        movrodeos
      })
  }
  )
)(Ventas)