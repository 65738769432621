import React, { Component } from 'react';
import firebase from '../firebase';
import { Table, Grid, Container, Icon, Button } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { initialFetch, fetchProductos, fetchReporteStock } from '../actions';

import BorrarReporte from '../components/BorrarReporte';
class ReporteStock extends Component {

    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.perfil = props.perfil;

        this.activarListener = props.initialFetch;
        this.fetchReporteStock = props.fetchReporteStock;
        this.fetchProductos = props.fetchProductos;

        this.state = ({
            listeners: props.listeners || [],
            reportes_stock: props.reportes_stock || [],
            productos: props.productos || []
        })
    }

    componentWillMount = () => {
        const itemProductos = { id: "productos" };

        var oyenteProductos = this.state.listeners.find(function (element) {
            return element.id === "productos";
        });

        if (oyenteProductos === undefined) {
            this.activarListener(this.perfil, itemProductos);
            this.fetchProductos(this.perfil);
        }

        const itemReportes = { id: "reporteStock" };

        var oyenteReportes = this.state.listeners.find(function (element) {
            return element.id === "reporteStock";
        });

        if (oyenteReportes === undefined) {
            this.activarListener(this.perfil, itemReportes);
            this.fetchReporteStock(this.perfil);
        }
    }


    componentWillReceiveProps = (nextProps) => {
        this.setState({
            listeners: nextProps.listeners || [],
            reportes_stock: nextProps.reportes_stock || [],
            productos: nextProps.productos || []
        })
    }

    formatoMoneda = (valor) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(valor)
    }

    generarPDF() {
        this.db.collection("empresas").doc(this.perfil.empresa).collection('reportes_stock').add({
            "procesado": false,
            "fecha": new Date()
        })
    }

    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        var minutos = date.getMinutes();

        if (minutos < 10) minutos = "0" + minutos;

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + date.getHours() + ':' + minutos;
    }

    render() {

        this.state.reportes_stock.sort((a, b) => {
            const fa = (a.fecha instanceof Object) ? a.fecha.toDate() : new Date(a.fecha);
            const fb = (b.fecha instanceof Object) ? b.fecha.toDate() : new Date(b.fecha);
            if (fa > fb) return -1;
            if (fa < fb) return 1;
            return 0;
        })

        const lista = this.state.reportes_stock.map(
            (value) => {
                let procesado = "En proceso";
                if (value.procesado) procesado = <a target='_blank' href={value.url}>
                    <Icon name="file pdf outline" size='big' link />
                </a>
                return (
                    <Table.Row key={value.id}>
                        <Table.Cell textAlign="center">Stock al dia de: {value.fecha.toDate ? this.prettyDate(value.fecha.toDate()) : this.prettyDate(new Date(value.fecha))} </Table.Cell>
                        <Table.Cell textAlign="center">{procesado}</Table.Cell>
                        <Table.Cell textAlign="center"><BorrarReporte doc={value} /></Table.Cell>
                    </Table.Row>
                )
            }
        )

        const listaProductos = this.state.productos.map(
            (value) => {
                return (
                    <Table.Row key={value.id}>
                        <Table.Cell >{value.nombre}</Table.Cell>
                        <Table.Cell >{value.stock} Kg</Table.Cell>
                        <Table.Cell >{value.minimo} Kg</Table.Cell>
                        <Table.Cell >{value.stock > 0 ? this.formatoMoneda(value.stock * value.precio) : "$0"}</Table.Cell>
                        <Table.Cell textAlign="right"> {value.fecha ? this.prettyDate((value.fecha instanceof Object) ? value.fecha.toDate() : new Date(value.fecha)) : "-"} </Table.Cell>
                    </Table.Row>
                )
            })

        return (
            <Container fluid>
                <Grid>
                    <Grid.Column width={10}>
                        <Grid.Row centered>

                            <Table
                                celled
                                striped
                                compact
                                collapsing
                                color={"blue"}
                                valigned={"center"}
                                unstackable={true}
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='5'>
                                            <span>Productos</span>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                                        <Table.HeaderCell>Stock</Table.HeaderCell>
                                        <Table.HeaderCell>Minimo</Table.HeaderCell>
                                        <Table.HeaderCell>Valorizacion</Table.HeaderCell>
                                        <Table.HeaderCell>Fecha de Ultimo Movimiento</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {listaProductos}
                                </Table.Body>
                            </Table>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid.Row centered>
                            <Table
                                celled
                                striped
                                color={"teal"}
                                valigned={"center"}
                                unstackable={true}
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='3'>
                                            <span>Lista de Reportes de Stock</span>
                                            <Button floated='right' onClick={() => this.generarPDF()} color='google plus' >
                                                <Icon name='plus' /> Generar PDF
                                        </Button>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="center">Fecha</Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">Descargar</Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">Borrar</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {lista}
                                </Table.Body>
                            </Table>

                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        reportes_stock: state.personalStore.reportes_stock,
        productos: state.personalStore.productos,
        listeners: state.personalStore.listeners
    };
}

export default connect(
    mapStateToProps,
    { initialFetch, fetchProductos, fetchReporteStock }
)(ReporteStock);