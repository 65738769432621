import React from 'react'
import { Modal, Button, Icon, Form } from 'semantic-ui-react'
export default class SetupColumns extends React.Component {
    constructor(props) {
        super(props)
        this.setColumns = props.setColumns
        this.columnas = props.columnas

        this.state = {
            modalOpen: false
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.columnas = nextProps.columnas
    }
    

    close = () => this.setState({modalOpen: false})


    render() {
        return (
            <Modal
                closeOnEscape={true}
                closeOnDimmerClick={true}
                open={this.state.modalOpen}
                size={'small'}
                trigger={<Button icon color="black" onClick={()=> this.setState({modalOpen: true})}>
                    <Icon name={'cogs'} color={"orange"} />
                </Button>}
            >
                <Modal.Header>Menu de Columnas</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                        <Form.Checkbox checked={this.columnas.col_dia} label='Dia' onClick={() =>this.setColumns("col_dia")} />
                        <Form.Checkbox checked={this.columnas.col_fecha} label='Fecha' onClick={() =>this.setColumns("col_fecha")} />
                        <Form.Checkbox checked={this.columnas.col_mixer} label='Mixer' onClick={() =>this.setColumns("col_mixer")} />
                        <Form.Checkbox checked={this.columnas.col_corral} label='Corral' onClick={() =>this.setColumns("col_corral")} />
                        <Form.Checkbox checked={this.columnas.col_rodeo} label='Rodeo' onClick={() =>this.setColumns("col_rodeo")} />
                        <Form.Checkbox checked={this.columnas.col_categoria} label='Categoria' onClick={() =>this.setColumns("col_categoria")} />
                        <Form.Checkbox checked={this.columnas.col_dieta} label='Dieta' onClick={() =>this.setColumns("col_dieta")} />
                        <Form.Checkbox checked={this.columnas.col_cabezas} label='Cabezas' onClick={() =>this.setColumns("col_cabezas")} />
                        <Form.Checkbox checked={this.columnas.col_remanente} label='Remanente' onClick={() =>this.setColumns("col_remanente")} />
                        <Form.Checkbox checked={this.columnas.col_descargado} label='Descargado' onClick={() =>this.setColumns("col_descargado")} />
                        <Form.Checkbox checked={this.columnas.col_meta} label='Meta' onClick={() =>this.setColumns("col_meta")} />
                        <Form.Checkbox checked={this.columnas.col_diferencia} label='Diferencia' onClick={() =>this.setColumns("col_diferencia")} />
                        <Form.Checkbox checked={this.columnas.col_cpcms} label='Promedio Materia Seca' onClick={() =>this.setColumns("col_cpcms")} />
                        <Form.Checkbox checked={this.columnas.col_cpctc} label='Promedio Tal Cual' onClick={() =>this.setColumns("col_cpctc")} />
                        <Form.Checkbox checked={this.columnas.col_costocabeza} label='Costo por cabeza' onClick={() =>this.setColumns("col_costocabeza")} />
                        <Form.Checkbox checked={this.columnas.col_mcal} label='Megacalorias por cabeza' onClick={() =>this.setColumns("col_mcal")} />
                        <Form.Checkbox checked={this.columnas.col_prot} label='Proteinas por cabeza' onClick={() =>this.setColumns("col_prot")} />
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.close} inverted>
                        <Icon name='checkmark' /> Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}