import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class ClienteNew extends Component {
    constructor(props) {
        super(props);
        this.perfil = props.perfil;
        this.db = props.db;

        this.state = {
            modalOpen: false,
            nombre: ""
        };

    }

    save = () => {
        if (this.state.nombre.trim().length > 0) {
            this.db.collection("empresas").doc(this.perfil.empresa).collection('clientes').add({
                "nombre": this.state.nombre
            })
            this.setState({ modalOpen: false, nombre: "" })
        }
    }

    render() {

        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size={'small'}
                trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                    <Icon name='plus' /> Nuevo
                </Button>}
            >

                <Modal.Header>Nuevo Cliente</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Field>
                                <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre del Cliente" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                            </Form.Field>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={(e, value) => this.setState({ modalOpen: false, nombre: "" })} inverted>
                        Cancelar
          </Button>
                    <Button color='green' onClick={this.save} inverted>
                        <Icon name='checkmark' /> Guardar
          </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}

export default ClienteNew;