import React from 'react'
import ReactTable from "react-table"
import { Grid, Checkbox } from 'semantic-ui-react'
import ModalMultiple from './../../components/filtros/ModalMultiple'
import Calendario from './../../components/filtros/ModalOneCalender';


const fechaCorta = (date) => {
    var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
}

export default class TableTropas extends React.Component{

    renderCategory = (cat) => {
        if (this.props.categorias && this.props.categorias[cat]){
            return this.props.categorias[cat].nombre
        }else{
            return null
        }
    }

    render(){

        const { tropas, select, selectAll, rodeosSeleccionados,
            filtros, activosF, tropasF, categoriasF, propietariosF, seleccionActivos,
            onSelectedActivosCerrados, onSelectedAllActivosCerrados,
            borrarFechaInicio, onChangeFechaInicio,
            onSelectedTropa, onSelectedAllTropas,
            onSelectedCategoria, onSelectedAllCategorias,
            onSelectedPropietario, onSelectedAllPropietarios
         } = this.props

        const columns = [
            {
                id: 'estado',
                Header: 'Estado',
                accessor: d => d.activo ? "Activo" : "Cerrado", // String-based value accessors!
                minWidth: 100,
                Filter: (
                    <ModalMultiple opciones={activosF}
                                    label={"Estado"}
                                    onSelected={onSelectedActivosCerrados}
                                    onSelectedAll={onSelectedAllActivosCerrados}
                                    todo={filtros.activos === null ? true : false}
                                    seleccion={seleccionActivos} />
                )
            }, {
                id: 'fecha',
                Header: 'Fecha Alta',
                accessor: d => d.fechaAlta.toDate ? d.fechaAlta.toDate() : new Date(d.fechaAlta),
                Cell: props => <span>{fechaCorta(props.value)}</span>, 
                minWidth: 100,
                Filter: (<Calendario delete={borrarFechaInicio} date={filtros.fechaInicio} onChange={onChangeFechaInicio} />),
            }, {
                id: 'tropa',
                Header: 'Tropa',
                accessor: d => d.nombre, // Custom value accessors!
                Cell: props => <span>{props.value}</span>,
                minWidth: 100,
                Filter: (<ModalMultiple opciones={tropasF}
                    label={"Tropas"}
                    onSelected={onSelectedTropa}
                    onSelectedAll={onSelectedAllTropas}
                    todo={filtros.allTropas}
                    seleccion={filtros.listaTropas} />),
                sortMethod: (a, b) => {
                    let numA = isNaN(parseInt(a)) ? 0 : parseInt(a);
                    let numB = isNaN(parseInt(b)) ? 0 : parseInt(b);
            
                    if (numA > numB && numB > 0) {
                        return 1;
                    }
            
                    if (numA < numB && numA > 0) {
                        return -1;
                    }
            
                    if (a > b) {
                        return 1;
                    }
            
                    if (a < b) {
                        return -1;
                    }
            
                    return 0;
                }
            }, {
                id: 'propietario',
                Header: 'Propietario',
                accessor: d => d.propietario ? d.propietario : "s/n",
                Cell: props => <span>{props.value}</span>,
                minWidth: 140,
                Filter: (<ModalMultiple opciones={propietariosF}
                    label={"Propietario"}
                    onSelected={onSelectedPropietario}
                    onSelectedAll={onSelectedAllPropietarios}
                    todo={filtros.allPropietarios}
                    seleccion={filtros.listaPropietarios} />),
            }, {
                id: 'categoria',
                Header: 'Categoria',
                accessor: d => d.categoria,
                Cell: props => <span>{this.renderCategory(props.value)}</span>,
                minWidth: 100,
                Filter: (<ModalMultiple opciones={categoriasF}
                    label={"Categoria"}
                    onSelected={onSelectedCategoria}
                    onSelectedAll={onSelectedAllCategorias}
                    todo={filtros.allCategorias}
                    seleccion={filtros.listaCategorias} />)
            }, {
                id: 'select',
                Header: 'Seleccionar',
                accessor: d => d.id,
                Cell: props => <Checkbox checked={rodeosSeleccionados.includes(props.value)} slider onClick={() => select(props.value)}/>,
                maxWidth: 90,
                Filter: <Checkbox label="Todo" checked={rodeosSeleccionados.length === tropas.length} onClick={() => rodeosSeleccionados.length === tropas.length ? selectAll([]) : selectAll(tropas.map(t => t.id))}/>
            }
        ]

        return(
            <Grid>
                <Grid.Column>
                    <Grid.Row>
                        <ReactTable
                            data={tropas}
                            columns={columns}
                            defaultPageSize={20}
                            showPaginationTop
                            showPaginationBottom
                            filterable
                            className="-striped -highlight"
                            previousText='Previo'
                            nextText='Siguiente'
                            loadingText="Cargando"
                            pageText="Pagina"
                            ofText="de"
                            rowsText="Filas"
                            defaultSorted={[{
                                id   : "tropa",
                                desc : false,
                              }]}
                            getTdProps={(state, rowInfo, column) => {
                                if (column && column.id === "select" && rowInfo && rowInfo.row && rowInfo.row.select) {
                                    return {
                                        style: {
                                            textAlign: "center"
                                        }
                                    }
                                }
                                return {
                                    style: {
                                        backgroundColor: 'white'
                                    }
                                }
                            }}
                        />
                    </Grid.Row>
                </Grid.Column>
            </Grid >
        )
    }
}