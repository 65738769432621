const initialState = {
    allCategorias: true,
    listaCategorias: [],
    allDietas: true,
    listaDietas: [],
    filtros: [],
    allMixer: true,
    listaMixer: []
};

const filtrosRodeos = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_RODEOS':
            return Object.assign({}, state, {
                ...state,
                ...action
            });
        default:
            return state
    }
}

export default filtrosRodeos;