import React, { Component } from 'react';
import { Grid, Table, Dropdown, Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { fetchTratamientos, fetchTratamientosProgramados, fetchProductosSanitarios, initialFetch, fetchCategoriasSanitarias } from '../actions';

import TratamientoNew from './TratamientoNew';
import ReprogramarTratamiento from './ReprogramarTratamiento';
import EliminarAlarmaTratamiento from './EliminarAlarmaTratamiento';
import TratamientoProgramado from './TratamientoProgramado';

import ConfirmarSeguimiento from './ConfirmarSeguimiento';

class RodeoSanidad extends Component {
  constructor (props) {
    super(props);

    this.empresa = props.empresa;
    this.rodeo = props.rodeo;
    this.perfil = props.perfil;
    this.db = props.db;
    this.tratamientos = props.tratamientos.filter((t)=> t.rodeo === this.rodeo.id)
    this.tratamientos_programados = props.tratamientos_programados.filter((t)=> t.rodeo === this.rodeo.id)

    this.productosSanitarios = props.productosSanitarios;
    this.categoriasSanitarias = props.categoriasSanitarias;

    this.fetchTratamientos = props.fetchTratamientos;
    this.fetchProductosSanitarios = props.fetchProductosSanitarios;
    this.fetchCategoriasSanitarias = props.fetchCategoriasSanitarias;
    this.fetchTratamientosProgramados = props.fetchTratamientosProgramados;

    this.activarListener = props.initialFetch;

    this.state = { modalOpen: false, listeners: props.listeners }
  }


  handleClose = () =>{
    this.setState({modalOpen: false})
  }
  handleCloseDos = () =>{
    this.setState({modalReprogramar: false})
  }
  handleCloseTres = () =>{
    this.setState({modalEliminarAlarma: false});
    this.fixBody();
  }
  componentWillMount = () => {
    const itemCat = { id: "categoriasSanitarias" };
    
    var oyenteCat = this.state.listeners.find(function(element) {
      return element.id === "categoriasSanitarias";
    });

    if(oyenteCat === undefined){
        this.activarListener(this.perfil, itemCat);
        this.fetchCategoriasSanitarias(this.perfil);
    }

    const item = { id: "productosSanitarios" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "productosSanitarios";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.fetchProductosSanitarios(this.perfil);
    }

   
  }

  

  // componentDidMount = () => {
  //   const item = { id: "tratamientos" };
    
  //   var oyente = this.state.listeners.find(function(element) {
  //     return element.id === "tratamientos";
  //   });
    
  //   if(oyente === undefined){
  //       this.activarListener(this.perfil, item);
  //       this.fetchTratamientos(this.perfil);
  //   }

  //   const itemTP = { id: "tratamientos_programados" };
    
  //   var oyenteTP = this.state.listeners.find(function(element) {
  //     return element.id === "tratamientos_programados";
  //   });

  //   if(oyenteTP === undefined){
  //       this.activarListener(this.perfil, itemTP);
  //       this.fetchTratamientosProgramados(this.perfil);
  //   }
    
  // }
  
  

  componentWillReceiveProps = (nextProps) => {
    this.categoriasSanitarias = nextProps.categoriasSanitarias;
    this.empresa = nextProps.empresa;
    this.rodeo = nextProps.rodeo;
    this.perfil = nextProps.perfil;
    this.tratamientos = nextProps.tratamientos.filter((t)=> t.rodeo === this.rodeo.id)
    this.tratamientos_programados = nextProps.tratamientos_programados.filter((t)=> t.rodeo === this.rodeo.id)
    this.productosSanitarios = nextProps.productosSanitarios;
    this.db = nextProps.db;

    this.setState({listeners: nextProps.listeners})
  }


  fechaCorta = (date) => {
      var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
                    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    
      return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  render () {

    this.tratamientos.sort((a, b)=>{
      const fa = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
      const fb = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return 1;
      if (fa < fb) return -1;
      return 0;
    })

    this.tratamientos_programados.sort((a, b)=>{
      const fa = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
      const fb = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return 1;
      if (fa < fb) return -1;
      return 0;
    })

    const lista = this.tratamientos.map(
        (value) => {
            return (
              <Table.Row key={value.id}>
                <Table.Cell>{ this.fechaCorta(value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha)) }</Table.Cell>
                <Table.Cell className="right aligned">{value.cabezas}</Table.Cell>
                <Table.Cell className="right aligned">
                    <Table 
                        celled 
                        striped
                        compact 
                        collapsing 
                        color={"teal"} 
                        valigned={"center"}
                        unstackable={true}
                        >
                        <Table.Body> 
                            {value.detalle.map((d)=> {
                              var producto = this.productosSanitarios.find((p)=> p.id === d.producto);
                              var categoria = this.categoriasSanitarias.find((c)=> c.id === producto.categoria);
                                return (<Table.Row key={d.producto}>
                                          <Table.Cell>{`${d.cantidad} ${producto.medida}`}</Table.Cell>
                                          <Table.Cell>{`${categoria.nombre} - ${producto.nombre}`}</Table.Cell>
                                        </Table.Row> )  
                              }
                            )}
                        </Table.Body>
                    </Table>
                </Table.Cell>
              </Table.Row>   
            )
      }
    )

    const listaProgramados = this.tratamientos_programados.map(
      (value) => {
        let f1 = (value.fecha instanceof Object) ? value.fecha.toDate() : new Date(value.fecha);
        f1.setHours(0);
        f1.setMinutes(0);
        f1.setSeconds(0);
        f1.setMilliseconds(0);
        let f2 = new Date();
        f2.setHours(0);
        f2.setMinutes(0);
        f2.setSeconds(0);
        f2.setMilliseconds(0);
        const alerta =  f2.getTime() - f1.getTime() >=0;
    
          return (
            <Table.Row key={value.id} >
              <Table.Cell  style={{backgroundColor: alerta ? "red" : "white"}}>{ this.fechaCorta(value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha)) }</Table.Cell>
              <Table.Cell className="right aligned">
                  <Table
                      celled 
                      striped
                      compact 
                      collapsing 
                      color={"teal"} 
                      valigned={"center"}
                      unstackable={true}
                      >
                      <Table.Body> 
                          {value.detalle.map((d)=> {
                            var producto = this.productosSanitarios.find((p)=> p.id === d.producto);
                            var categoria = this.categoriasSanitarias.find((c)=> c.id === producto.categoria);
                              return (<Table.Row key={d.producto}>
                                        <Table.Cell>{`${d.cantidad} ${producto.medida}`}</Table.Cell>
                                        <Table.Cell>{`${categoria.nombre} - ${producto.nombre}`}</Table.Cell>
                                      </Table.Row> )  
                            }
                          )}
                      </Table.Body>
                  </Table>
              </Table.Cell>
              <Table.Cell><ReprogramarTratamiento alarma={value} productos={this.productosSanitarios} categorias={this.categoriasSanitarias}/></Table.Cell>
              <Table.Cell><EliminarAlarmaTratamiento alarma={value} /></Table.Cell>
            </Table.Row>   
          )
    }
  )
   
    return(
      <Modal 
      
        closeOnEscape={true} 
        closeOnDimmerClick={true} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'large'}
        trigger={ <Dropdown.Item key={"sanidad"} text={"SANIDAD"} icon="medkit"  onClick={() => this.setState({ modalOpen: true })}/> }
        >

        <Modal.Header>Tratamiento Sanitario de Rodeo:  {this.rodeo.nombre}</Modal.Header>
        <Modal.Content>
          <Grid style={{padding: 0, margin: 2}}>
                    
              <Grid.Row centered>
                <Table 
                    celled 
                    compact 
                    collapsing 
                    color={"teal"} 
                    valigned={"center"}
                    unstackable={true}
                    >
                    <Table.Header>
                        <Table.HeaderCell colSpan='1'>
                          <TratamientoProgramado rodeo={this.rodeo} db={this.db} perfil={this.perfil} productos={this.productosSanitarios} categorias={this.categoriasSanitarias}/>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan='2'>
                          <TratamientoNew rodeo={this.rodeo} db={this.db} perfil={this.perfil} productos={this.productosSanitarios} categorias={this.categoriasSanitarias}/>
                        </Table.HeaderCell>
                        <Table.Row>
                          <Table.HeaderCell className="center aligned">Fecha</Table.HeaderCell>
                          <Table.HeaderCell className="center aligned">Cabezas</Table.HeaderCell>
                          <Table.HeaderCell className="center aligned">Detalle</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body> 
                        {lista}
                    </Table.Body>
                </Table>
              </Grid.Row>

              <Grid.Row centered>
                {listaProgramados.length > 0 &&
                      <Table
                      celled 
                      compact 
                      collapsing 
                      color={"black"} 
                      valigned={"center"}
                      unstackable={true}
                      >
                      <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell className="center aligned" colSpan='4'>Tratamientos Programados</Table.HeaderCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell className="center aligned">Fecha</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Detalle</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                            <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                          </Table.Row>
                      </Table.Header>
                      <Table.Body> 
                          {listaProgramados}
                      </Table.Body>
                  </Table>
                }
            </Grid.Row>
          </Grid>

          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={ (e, value) => this.setState({ modalOpen: false }) } inverted>
                CERRAR
            </Button>

          </Modal.Actions>
        </Modal>
                    
    )
  }

}


function mapStateToProps(state, props) {
  return {
    tratamientos: state.personalStore.tratamientos,
    productosSanitarios: state.personalStore.productosSanitarios,
    categoriasSanitarias: state.personalStore.categoriasSanitarias,
    tratamientos_programados: state.personalStore.tratamientos_programados,
    listeners: state.personalStore.listeners
  };
}

export default connect(
  mapStateToProps,
  { fetchTratamientos, fetchProductosSanitarios, fetchCategoriasSanitarias, fetchTratamientosProgramados, initialFetch }
)(RodeoSanidad);  