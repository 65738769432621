import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class CategoriaSanitariaNew extends Component {
  
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.db = props.db;
    this.error = [];

    this.options =  [
        {"key": "admin", "text": "Administrador", "value": "admin"},
        {"key": "operador", "text": "Operador", "value": "operador"}
    ];

    this.state = {
      tiempo: 15,
      mixer: '',
      cargando: false,
      modalOpen: false,
      alerta: false,
      nombre: ""
    };

  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }
  

  save = () => {

    this.error = [];

    if((this.state.nombre.trim().length === 0)) this.error.push('El nombre es obligatorio.');
  
    if(this.error.length === 0){
      var _this = this;
      this.setState({ cargando: true })

      this.db.collection("empresas").doc(this.perfil.empresa).collection("categoriassanitarias").add({
        "nombre": this.state.nombre
      })
      .then(function(docRef) {
          _this.setState({ alerta: false, modalOpen: false, nombre: "", cargando: false });
          _this.error = [];
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true, error: _this.state.error.push(`Error adding document: ${error}`)});
      });
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    
    if(this.state.cargando){
     return( 
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.cargando}
        size={'small'}
        >

        <Modal.Header>Espere un momento por favor...</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading {this.state.tiempo}</Loader>
              </Dimmer>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
     )
    }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                  <Icon name='plus' /> Agregar Categoria
                </Button>}
        >

        <Modal.Header>Agregar Categoria Sanitaria</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, nombre: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos'
              list={this.error}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default CategoriaSanitariaNew;