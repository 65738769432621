
const reportes_ventas = (state = [], action) => {
    switch (action.type) {
        case 'ADD_REPORTE_VENTA':
            return action.reportesVentas
        default:
            return state
    }
};

export default reportes_ventas;