import React, { Component } from "react";
import { Table, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import RowConsumoCarga from "./RowConsumoCarga";

class ListaConsumosCarga extends Component {
  constructor(props) {
    super(props);
    this.listaIngredientes = props.listaIngredientes || [];
  }

  componentWillReceiveProps = (nextProps) => {
    this.listaIngredientes = nextProps.listaIngredientes;
  };

  render() {
    if (!this.props.productos) {
      return <h3>Cargando...</h3>;
    }

    let costo = 0;
    let costoactual = 0;
    let talcual = 0;
    let ms = 0;
    let desvio = 0;

    Object.keys(this.listaIngredientes).map((ingrediente) => {
      let producto = this.props.productos[ingrediente];

      if (producto === undefined || producto === null) {
        producto = {};
        producto.nombre = "indefinido";
      } else {
        costoactual +=
          this.listaIngredientes[ingrediente].kilos * producto.precio;
      }
      costo += this.listaIngredientes[ingrediente].costo;
      talcual += this.listaIngredientes[ingrediente].kilos;
      ms += this.listaIngredientes[ingrediente].materiaseca;
    });

    return (
      <Grid.Row centered>
        <Table
          celled
          striped
          compact
          collapsing
          color={"teal"}
          valigned={"center"}
          unstackable={true}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <span style={{ fontSize: 20, fontWeight: "900" }}>
                  Productos Cargados
                </span>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Producto</Table.HeaderCell>
              <Table.HeaderCell>Kg (mat. seca)</Table.HeaderCell>
              <Table.HeaderCell>Kg (tal cual)</Table.HeaderCell>
              <Table.HeaderCell>Desvio</Table.HeaderCell>
              <Table.HeaderCell>Gasto (a valores actuales)</Table.HeaderCell>
              <Table.HeaderCell>Gasto (a valor historico)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(this.listaIngredientes).map((ingrediente) => {
              let producto = this.props.productos[ingrediente];

              //   console.log({
              //     sugerido: this.listaIngredientes[ingrediente].sugerido,
              //     kilos: this.listaIngredientes[ingrediente].kilos,
              //   });
              let desvioD =
                (this.listaIngredientes[ingrediente].sugerido * 100) /
                  this.listaIngredientes[ingrediente].kilos -
                100;
              //   desvio += desvioD;

              return (
                <RowConsumoCarga
                  key={ingrediente}
                  desvioD={desvioD}
                  ingrediente={this.listaIngredientes[ingrediente]}
                  producto={producto}
                  ms={ms}
                />
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row active>
              <Table.Cell style={{ fontSize: 16, fontWeight: "900" }}>
                Totales:{" "}
              </Table.Cell>
              <Table.Cell
                className="right aligned"
                style={{ fontSize: 16, fontWeight: "900" }}
              >
                {formatear(ms)} Kg (100%)
              </Table.Cell>
              <Table.Cell
                className="right aligned"
                style={{ fontSize: 16, fontWeight: "900" }}
              >
                {formatear(talcual)} Kg
              </Table.Cell>
              <Table.Cell
                className="right aligned"
                style={{ fontSize: 16, fontWeight: "900" }}
              ></Table.Cell>
              <Table.Cell
                className="right aligned"
                style={{ fontSize: 16, fontWeight: "900" }}
              >
                $ {formatear(costoactual)}
              </Table.Cell>
              <Table.Cell
                className="right aligned"
                style={{ fontSize: 16, fontWeight: "900" }}
              >
                $ {formatear(costo)}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Grid.Row>
    );
  }
}

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

const mapStateToProps = (state) => {
  return {
    productos: state.firestore.data.productos,
  };
};

export default connect(mapStateToProps)(ListaConsumosCarga);
