import firebase from "../firebase";
import { isEmpty } from "react-redux-firebase";
const db = firebase.firestore();

export const fetchDetalleDietas = (perfil, idDieta) => {
  return function (dispatch) {
    if (!isEmpty(perfil)) {
      db.collection("empresas")
        .doc(perfil.empresa)
        .collection("dietas")
        .doc(idDieta)
        .collection("detalle")
        .orderBy("orden", "asc")
        .onSnapshot(function (querySnapshot) {
          var items = [];
          querySnapshot.forEach(function (doc) {
            var item = doc.data();
            item.id = doc.id;
            items.push(item);
          });

          let detalledieta = {};
          detalledieta.id = idDieta;
          detalledieta.detalle = items;

          dispatch(addDetalle(detalledieta));
        });
    }
  };
};

const addDetalle = (detalleDietas) => ({
  type: "ADD_DETALLE_DIETA",
  detalleDietas,
});
