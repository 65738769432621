import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Loader,
  Segment,
  Dimmer,
  Image,
  Grid,
  Button,
  Input,
  Checkbox,
} from "semantic-ui-react";
import firebase from "../firebase";
import Reporte from "./Reporte";
import moment from "moment";
import {
  EDITAR_HOTELERIA,
  EDITAR_SANIDAD,
  REPORTE_VER_MOVIMIENTOS,
  REPORTE_VER_PRODUCTOS,
} from "./actions";
// import Api from './../api'
// const api = new Api();

import { pdfReporteTropas } from "./../utils/pdf/reporteTropas";

import { ExelReporteTropas } from "./../utils/excel/reporteTropas";

class Resultados extends Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.perfil = props.perfil;
    this.state = {
      resultados: null,
      pdf: false,
    };
  }

  componentDidMount = () => {
    this.calcularReporte();
  };

  sanidad = (val) => {
    const { dispatch } = this.props;
    if (!isNaN(val)) {
      dispatch({
        type: EDITAR_SANIDAD,
        payload: Number(val),
      });
    }
  };

  hoteleria = (val) => {
    const { dispatch } = this.props;
    if (!isNaN(val)) {
      dispatch({
        type: EDITAR_HOTELERIA,
        payload: Number(val),
      });
    }
  };

  movimientos = (val) => {
    const { dispatch } = this.props;
    if (!isNaN(val)) {
      dispatch({
        type: REPORTE_VER_MOVIMIENTOS,
        payload: val,
      });
    }
  };

  productos = (val) => {
    const { dispatch } = this.props;
    if (!isNaN(val)) {
      dispatch({
        type: REPORTE_VER_PRODUCTOS,
        payload: val,
      });
    }
  };

  calcularReporte = () => {
    const { movimientos, rodeos } = this.props;

    let existencia = 0;
    let entradas = 0;
    let salidas = 0;
    let compras = 0;
    let ventas = 0;
    let muertes = 0;
    let cabAcumuladas = 0;

    let raciones = 0;

    movimientos.forEach((movimiento, i) => {
      if (i > 0) {
        let fechaInicial = movimientos[i - 1].fecha.toDate
          ? movimientos[i - 1].fecha.toDate()
          : new Date(movimientos[i - 1].fecha);
        let fechaFinal = movimientos[i].fecha.toDate
          ? movimientos[i].fecha.toDate()
          : new Date(movimientos[i].fecha);

        fechaInicial.setHours(0);
        fechaInicial.setMinutes(0);
        fechaFinal.setHours(0);
        fechaFinal.setMinutes(0);

        let diasTotales = Math.round(
          moment(fechaFinal).diff(moment(fechaInicial), "hours") / 24
        );

        raciones += diasTotales * existencia;
      }

      if (movimiento.concepto.toLowerCase() === "entrada") {
        if (movimiento.detalle.toLowerCase() === "transferencia") {
          if (!rodeos.includes(movimiento.origen)) {
            cabAcumuladas += movimiento.cabezas;
          }
        } else {
          if (movimiento.origen && movimiento.origen.length > 0) {
            if (!rodeos.includes(movimiento.origen)) {
              cabAcumuladas += movimiento.cabezas;
            }
          } else {
            cabAcumuladas += movimiento.cabezas;
          }
        }
        //   salidas += movimiento.cabezas * movimiento.kilos;
        //   ventas += movimiento.cabezas * movimiento.kilos * movimiento.precio;
        if (movimiento.detalle.toLowerCase() !== "entrada por recuento") {
          entradas += movimiento.cabezas * movimiento.kilos;
          compras += movimiento.cabezas * movimiento.kilos * movimiento.precio;
        }
        existencia += movimiento.cabezas;
      }

      if (movimiento.concepto.toLowerCase() === "muerte") {
        existencia -= movimiento.cabezas;
        muertes += movimiento.cabezas;
      }

      if (movimiento.concepto.toLowerCase() === "salida") {
        existencia -= movimiento.cabezas;

        if (movimiento.detalle.toLowerCase() !== "salida por recuento") {
          //    entradas += movimiento.cabezas * movimiento.kilos;
          //    compras += movimiento.cabezas * movimiento.kilos * movimiento.precio;
          //  }else {
          salidas += movimiento.cabezas * movimiento.kilos;
          ventas += movimiento.cabezas * movimiento.kilos * movimiento.precio;
        }
      }
    });

    this.setState({
      resultados: {
        existencia,
        cabAcumuladas,
        compras,
        ventas,
        entradas,
        salidas,
        muertes,
        raciones,
      },
    });
  };

  solicitarPdf = async () => {
    this.setState({
      loading: true,
    });

    const {
      fechaInicio,
      fechaFinal,
      raciones,
      mcal,
      prot,
      ms,
      hoteleria,
      sanidad,
      rodeos,
      movimientos,
      productos,
      mostrarMovimientos,
      mostrarProductos,
    } = this.props;

    const listaProductos = [];
    Object.keys(productos).map((p) => {
      listaProductos.push(productos[p]);
    });

    let fechaIni = movimientos[0].fecha.toDate
      ? movimientos[0].fecha.toDate()
      : new Date(movimientos[0].fecha);
    let fechaFin = movimientos[movimientos.length - 1].fecha.toDate
      ? movimientos[movimientos.length - 1].fecha.toDate()
      : new Date(movimientos[movimientos.length - 1].fecha);

    await pdfReporteTropas({
      tropas: this.props.tropas,
      periodo:
        moment(new Date(fechaIni)).format("DD/MM/YYYY") +
        " - " +
        moment(new Date(fechaFin)).format("DD/MM/YYYY"),
      raciones: this.state.resultados.raciones,
      mcal,
      prot,
      ms,
      hoteleria,
      sanidad,
      rodeos,
      productos: listaProductos,
      movimientos,
      resultados: this.state.resultados,
      mostrarMovimientos,
      mostrarProductos,
    });

    this.setState({
      loading: false,
    });
  };

  render() {
    /*
    if (this.state.pdf){
      return <Redirect to="/reportes" />
    }
*/

    const {
      fechaInicio,
      fechaFinal,
      raciones,
      mcal,
      prot,
      ms,
      hoteleria,
      sanidad,
      rodeos,
      movimientos,
      productos,
      mostrarMovimientos,
      mostrarProductos,
    } = this.props;

    const listaProductos = [];
    Object.keys(productos).map((p) => {
      listaProductos.push(productos[p]);
    });

    let fechaIni = movimientos[0].fecha.toDate
      ? movimientos[0].fecha.toDate()
      : new Date(movimientos[0].fecha);
    let fechaFin = movimientos[movimientos.length - 1].fecha.toDate
      ? movimientos[movimientos.length - 1].fecha.toDate()
      : new Date(movimientos[movimientos.length - 1].fecha);

    if (this.state.resultados === null) {
      return (
        <Segment>
          <Dimmer active>
            <Loader />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      );
    }

    if (rodeos.length === 0) {
      return <Redirect to="/reportes" push={true} />;
    }

    return (
      <Container style={{ paddingBottom: 40 }}>
        <Grid centered>
          <Grid.Row centered>
            <h2>Resultados</h2>
          </Grid.Row>
          <Grid.Row centered>
            <Input
              label="Costo sanitario"
              value={sanidad}
              placeholder="sanidad.."
              onChange={(val, { value }) => this.sanidad(value)}
            />
          </Grid.Row>
          <Grid.Row centered>
            <Input
              label="Costo hoteleria"
              value={hoteleria}
              placeholder="hoteleria.."
              onChange={(val, { value }) => this.hoteleria(value)}
            />
          </Grid.Row>
          <Grid.Row>
            <Reporte
              tropas={this.props.tropas}
              resultados={this.state.resultados}
            />
          </Grid.Row>
          <Grid.Row centered>
            <Button
              as={Link}
              info
              floated="right"
              size="small"
              to="/reportes/setear_productos"
              style={{ marginRigth: 20 }}
            >
              Anterior
            </Button>
            <Button
              color={"red"}
              floated="right"
              size="small"
              // to="/reportes"
              style={{ marginLeft: 20 }}
              onClick={this.solicitarPdf}
            >
              Solicitar PDF
            </Button>

            <ExelReporteTropas
              data={{
                tropas: this.props.tropas,
                periodo:
                  moment(new Date(fechaIni)).format("DD/MM/YYYY") +
                  " - " +
                  moment(new Date(fechaFin)).format("DD/MM/YYYY"),
                raciones: this.state.resultados.raciones,
                mcal,
                prot,
                ms,
                hoteleria,
                sanidad,
                rodeos,
                productos: listaProductos,
                movimientos,
                resultados: this.state.resultados,
                mostrarMovimientos,
                mostrarProductos,
              }}
            />

            {/* <Checkbox
              style={{ padding: 10 }}
              onChange={() => this.productos(!mostrarProductos)}
              checked={mostrarProductos}
              label="Mostrar Productos"
            />
            <Checkbox
              style={{ padding: 10 }}
              onChange={() => this.movimientos(!mostrarMovimientos)}
              checked={mostrarMovimientos}
              label="Mostrar Movimientos"
            /> */}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.personalStore.nuevoReporte,
    tropas: state.firestore.data.rodeos,
  };
};

export default connect(mapStateToProps)(Resultados);
