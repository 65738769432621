
const categorias = (state = [], action) => {
    switch (action.type) {
        case 'ADD_CATEGORIA':
           return action.categorias
        default:
            return state
    }
};

export default categorias;