import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

//const PUBLIC_ROOT = '/login';


const AuthRoute = ({component, ...props}) => {

  let isPrivate = true;

  switch (props.path) {
    case '/':{
        isPrivate = false;
        break;
    }
    case '/logout':{
        isPrivate = false;
        break;
    }
    default: {
        isPrivate = true;
        break;
    }
  }

  if (props.user) {
    return <Route { ...props } component={ component } />;    
  } else {
    //User is not Authenticated
    if (isPrivate) {
      return <Redirect to="../" push={true} />
    }else {
      //If the route is public, the user may proceed.
      return <Route { ...props } component={ component } />;
    }
  }
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ])
};

export default AuthRoute;