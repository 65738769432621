import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react';

import CategoriaSanitariaNew from './CategoriaSanitariaNew';

class ProductoSanitarioNew extends Component {
  
  constructor (props) {
    super(props);

    this.perfil = props.perfil;
    this.db = props.db;
    this.error = [];

    this.categorias = props.categorias;

    this.options =  [
        {"key": "admin", "text": "Administrador", "value": "admin"},
        {"key": "operador", "text": "Operador", "value": "operador"}
    ];

    this.state = {
      tiempo: 15,
      mixer: '',
      cargando: false,
      modalOpen: false,
      alerta: false,
      nombre: "",
      costo: "",
      categoria: "",
      medida: "",
      activo: true
    };


  }

  componentWillReceiveProps = (nextProps) => {
    this.categorias = nextProps.categorias;
    this.setState({cargando: false})
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }
  

  save = () => {

    this.error = [];

    if((this.state.nombre.trim().length === 0)) this.error.push('El nombre es obligatorio.');
    if((this.state.categoria.trim().length === 0)) this.error.push('La categoria es obligatoria.');
  
    if(this.error.length === 0){
      var _this = this;
      this.setState({ cargando: true })

      this.db.collection("empresas").doc(this.perfil.empresa).collection("productossanitarios").add({
        "nombre": this.state.nombre,
        "costo": Number(this.state.costo),
        "categoria": this.state.categoria,
        "activo": this.state.activo,
        "medida": this.state.medida
      })
      .then(function(docRef) {
          _this.setState({ alerta: false, modalOpen: false, medida: "", nombre: "", costo: 0, categoria: "", cargando: false });
          _this.error = [];
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true, error: _this.state.error.push(`Error adding document: ${error}`)});
      });
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    var optionsCategorias  =  []

    this.categorias.forEach(function(element) {
      optionsCategorias.push({"key": element.id, "text": element.nombre, "value": element.id})
    }, this);

    var optionsMedidas  = [
      {"key": "dosis", "text": "dosis", "value": "dosis"},
      {"key": "ml", "text": "ml", "value": "ml"}
    ]
    
    if(this.state.cargando){
     return( 
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.cargando}
        size={'small'}
        >

        <Modal.Header>Espere un momento por favor...</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading {this.state.tiempo}</Loader>
              </Dimmer>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
     )
    }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                  <Icon name='plus' /> Nuevo
                </Button>}
        >

        <Modal.Header>Nuevo Producto Sanitario</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Select label="Categoria" options={optionsCategorias} placeholder='Seleccionar Categoria' value={this.state.categoria} onChange={(e, { value }) => this.setState({ categoria: value })} />
                    <CategoriaSanitariaNew db={this.db} perfil={this.perfil} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Costo" id="costo" type="number" step="1" placeholder="0" value={this.state.costo} onChange={(e, { value }) => this.setState({ costo: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Unidad de Medida" options={optionsMedidas} placeholder='Seleccionar unidad de Medida' value={this.state.medida} onChange={(e, { value }) => this.setState({ medida: value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, medida: "", activo: true, nombre: "", costo: 0, categoria: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos'
              list={this.error}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ProductoSanitarioNew;