import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class MixerNew extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    
    this.db = props.db;
    
    this.state = {
      nombre: "",
      activo: true,
      modalOpen: false,
      cargando: false
    };

  }

  save = () => {
    if((this.state.nombre.trim().length>0)){
      var _this = this;

      this.setState({cargando: true});

      this.db.collection("empresas").doc(this.perfil.empresa).collection('mixers').add({
        "nombre": this.state.nombre,
        "activo": this.state.activo
      })
      .then(function(docRef) {
          _this.setState({ cargando:false, modalOpen: false, alerta: false, nombre: "", capacidad: 0});
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
      });

      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='google plus'>
                  <Icon name='plus' /> Nuevo
                </Button>}
        size={'small'}
        >
        <Modal.Header>Nuevo Mixer</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre del Mixer" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: e.target.value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, nombre: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Debe ingresar un nombre valido y la capadidad debe ser mayor a cero!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default MixerNew;
