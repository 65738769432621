import React from 'react';
import { Modal, Button, Grid, Form, Divider } from 'semantic-ui-react/dist/commonjs'
 
export default class ModalMultiple extends React.Component {

  constructor(props){
    super(props);

    this.label = props.label;

    this.opciones = props.opciones || [];
    this.seleccion = props.seleccion || [];
    
    this.todo = props.todo;

    this.state = {
        modalOpen: false
    }

  }

  componentWillReceiveProps = (nextProps) => {
    this.opciones = nextProps.opciones || [];
    this.seleccion = nextProps.seleccion || [];

    this.todo = nextProps.todo;
  }
  
  close = ()=>{
      this.setState({ modalOpen: false })
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  
  render() {
    let listaopciones = [];

    Object.keys(this.opciones).map((opt)=> listaopciones.push(this.opciones[opt]) );

    // listaopciones.sort((a, b)=>{
    //     if (a.label > b.label) return 1;
    //     if (a.label < b.label) return -1;
    //     return 0;
    // })

    listaopciones = listaopciones.sort((a, b) => {
        let numA = isNaN(parseInt(a.label)) ? 0 : parseInt(a.label);
        let numB = isNaN(parseInt(b.label)) ? 0 : parseInt(b.label);

        if (numA > numB && numB > 0) {
            return 1;
        }

        if (numA < numB && numA > 0) {
            return -1;
        }

        if (a.label > b.label) {
            return 1;
        }

        if (a.label < b.label) {
            return -1;
        }

        return 0;
    })

    return (
        <Modal dimmer={"inverted"}
            closeOnEscape={true} 
            closeOnDimmerClick={true} 
            onClose={this.close}
            open={this.state.modalOpen}
            size={'mini'}
            trigger={
                      <Button compact style={{margin: 0}} size="medium" inverted={this.todo} color={"green"} onClick={()=>this.setState({modalOpen: true})} style={{fontSize: 14}}> {this.label} </Button>
                    }
            >

            <Modal.Header>
                        <Grid columns={2} >
                            <Grid.Row> 
                                <Grid.Column textAlign={"left"} >
                                    Seleccionar {this.label}
                                </Grid.Column>
                                <Grid.Column textAlign={"right"} >
                                    <Button color={"green"} inverted onClick={()=>this.setState({modalOpen: false})} icon={"external"} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
            </Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <Form style={{paddingLeft: "15%"}} className="ui left aligned segment">
                        <Form.Group widths='equal' key={"todo"}>
                        
                            <Form.Checkbox checked={this.todo} onClick={()=> this.props.onSelectedAll(this.opciones)} style={{fontSize: 18, fontWeight: "bold", color: "green"}}  value={"todo"} label={"Seleccionar Todo"} placeholder={"Seleccionar Todo"} />
                        
                        </Form.Group>
                        <Divider section/>
                        { listaopciones.map((opcion, index) =>{

                            var checked = this.seleccion.find((s) => s === opcion.id);

                            return (
                                <Form.Group widths='equal' key={opcion.id + index}>
                                
                                    <Form.Checkbox onClick={()=> this.props.onSelected(opcion.id, this.opciones) } style={{fontSize: 18, fontWeight: "bold"}} checked={(checked !== null && checked !== undefined) || this.todo} value={opcion.id} label={opcion.label} placeholder={opcion.label} />
                                
                                </Form.Group>
                                )}
                        )}
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
  }

}