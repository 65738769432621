
const stock = (state = null, action) => {
    switch (action.type) {
        case 'ADD_STOCK':
            return action.payload
        case 'DELETE_STOCK':
           return null;
        default:
            return state
    }
};

export default stock;