import React, { Component } from 'react';
import { Table, Card, Dropdown, Button, Modal } from 'semantic-ui-react'

import moment from "moment";

import RodeoNewMov from './RodeoNewMov';
import RodeoEditMov from './RodeoEditMov';
import RodeoDeleteMov from './RodeoDeleteMov';

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat('es-IN', {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos
  }).format(numero);
}

const prettyDate = (date) => {
  var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

  var minutos = date.getMinutes();

  if (minutos < 10) minutos = "0" + minutos;

  return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + date.getHours() + ':' + minutos;
}


class RodeoMov extends Component {
  constructor(props) {
    super(props);

    this.clientes = props.clientes;

    this.empresa = props.empresa;
    this.rodeo = props.rodeo;
    this.movrodeos = props.movrodeos.filter((m) => m.rodeo === this.rodeo.id) || [];
    this.perfil = props.perfil;
    this.db = props.db;

    this.rodeos_inactivos = props.rodeos_inactivos;
    this.rodeos = props.rodeos;
    this.corrales = props.corrales;
    this.corral = props.corral;
    this.dieta = props.dieta;
    this.categoria = props.categoria;

    this.state = { modalOpen: false }
  }

  componentWillReceiveProps = (nextProps) => {
    this.clientes = nextProps.clientes;

    this.empresa = nextProps.empresa;
    this.rodeo = nextProps.rodeo;
    this.rodeos_inactivos = nextProps.rodeos_inactivos;
    this.movrodeos = nextProps.movrodeos.filter((m) => m.rodeo === this.rodeo.id) || [];
    this.perfil = nextProps.perfil;
    this.db = nextProps.db;

    this.rodeos = nextProps.rodeos;
    this.corrales = nextProps.corrales;
    this.corral = nextProps.corral;
    this.dieta = nextProps.dieta;
    this.categoria = nextProps.categoria;
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  borrarMov = (mov) => {

  }

  render() {

    let optionsClientes = [];
    
    this.clientes.map( value => {
      optionsClientes.push({
        key: value.id,
        text: value.nombre,
        value: value.id
      })
    })


    const rodeos = this.rodeos.concat(this.rodeos_inactivos);

    var f1 = new Date();
    f1.setHours(0);
    f1.setMinutes(0);
    f1.setSeconds(0);
    f1.setMilliseconds(0);

    var f2 = new Date();
    
    if ( this.rodeo && this.rodeo.ultimaFechaCalculada && this.rodeo.ultimaFechaCalculada){
      f2 = this.rodeo.ultimaFechaCalculada.toDate ? this.rodeo.ultimaFechaCalculada.toDate() : new Date(this.rodeo.ultimaFechaCalculada);
    } 
    f2.setHours(0);
    f2.setMinutes(0);
    f2.setSeconds(0);
    f2.setMilliseconds(0);


    const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * this.rodeo.gdpv;
    const aumentoRaciones = ((f1.getTime() - f2.getTime()) / 86400000) * this.rodeo.cabezas;

    let dias = (Number(this.rodeo.racionesTotales) + ((f1.getTime() - f2.getTime()) / 86400000) * this.rodeo.cabezas) / this.rodeo.cabAcumuladaTotal;
    let diasInicio = (Number(this.rodeo.raciones) + ((f1.getTime() - f2.getTime()) / 86400000) * this.rodeo.cabezas) / this.rodeo.cabAcumuladas;

    let gananciaDiaria = this.rodeo.raciones > 0 ? (((this.rodeo.ultimaPesada + Number(aumento)) * this.rodeo.cabezas) - Number(this.rodeo.entradas) + Number(this.rodeo.salidas)) / (this.rodeo.raciones + Number(aumentoRaciones)) : this.rodeo.gdpv;

    const pesoActual = (Number(this.rodeo.ultimaPesada) ? this.rodeo.ultimaPesada : 0) + Number(aumento);


    this.movrodeos.sort((a, b) => {
      const fa = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
      const fb = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return 1;
      if (fa < fb) return -1;
      return 0;
    })

    return (
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'large'}
        trigger={<Dropdown.Item key={"movimientos"} text={"MOVIMIENTOS"} icon="move" onClick={() => this.setState({ modalOpen: true })} />}
      >

        {this.rodeo.seguir && this.empresa.rodeos &&
          <Modal.Header>Movimientos de Rodeo:  {this.rodeo.nombre} - Cabezas: {this.rodeo.cabezas} - Peso por cabeza: {formatear(pesoActual)} Kg al {prettyDate(new Date())} - (GDPV: {formatear(gananciaDiaria, 2)}) </Modal.Header>
        }
        {!this.rodeo.seguir || !this.empresa.rodeos &&
          <Modal.Header>Movimientos de Rodeo:  {this.rodeo.nombre} - Cabezas: {this.rodeo.cabezas}  </Modal.Header>
        }


        <Modal.Content>

          <Card fluid>
            <Table
              celled
              striped
              compact
              collapsing
              color={"teal"}
              valigned={"center"}
              unstackable={true}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={this.rodeo.seguir && this.empresa.rodeos ? '7' : '6'}>
                    <RodeoNewMov pesoActual={pesoActual} optionsClientes={optionsClientes} rodeos={this.rodeos} rodeo={this.rodeo} corrales={this.corrales} corral={this.corral} db={this.db} perfil={this.perfil} empresa={this.empresa} />
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row textAlign={'center'}>
                  <Table.HeaderCell>Fecha</Table.HeaderCell>
                  <Table.HeaderCell>Concepto</Table.HeaderCell>
                  <Table.HeaderCell>Detalle</Table.HeaderCell>
                  <Table.HeaderCell>Cabezas</Table.HeaderCell>
                  {this.rodeo.seguir && this.empresa.rodeos &&
                    <Table.HeaderCell>Peso</Table.HeaderCell>
                  }
                  {this.rodeo.seguir && this.empresa.rodeos &&
                    <Table.HeaderCell>$ x Kg</Table.HeaderCell>
                  }
                  <Table.HeaderCell>Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.movrodeos.map((m) => {
                  var cab
                  if (m.concepto !== 'Pesada') {
                    cab = m.cabezas > 0 ? m.cabezas : '-'
                  } else {
                    cab = "-"
                  }

                  let complemento = null;

                  if (m.concepto === "Entrada" && m.detalle === "Alta de rodeo") {
                    complemento = rodeos.find(r => r.id === m.origen)
                  }

                  if (m.concepto === "Salida" && m.detalle === "Generar Rodeo") {
                    complemento = rodeos.find(r => r.id === m.destino)
                  }

                  if (m.concepto === "Entrada" && m.detalle === "Transferencia") {
                    complemento = rodeos.find(r => r.id === m.origen)
                  }

                  if (m.concepto === "Salida" && m.detalle === "Transferencia") {
                    complemento = rodeos.find(r => r.id === m.destino)
                  }



                  return (
                    <Table.Row key={m.id} >
                      <Table.Cell>{prettyDate((m.fecha.toDate) ? m.fecha.toDate() : new Date(m.fecha))} </Table.Cell>
                      <Table.Cell>{m.concepto} </Table.Cell>

                      {m.concepto === "Entrada" && m.detalle === "Alta de rodeo" && complemento &&
                        <Table.Cell>{m.detalle} desde rodeo {complemento.nombre} </Table.Cell>
                      }

                      {m.concepto === "Salida" && m.detalle === "Generar Rodeo" && complemento &&
                        <Table.Cell>{m.detalle}: {complemento.nombre} </Table.Cell>
                      }

                      {m.concepto === "Entrada" && m.detalle === "Transferencia" && complemento &&
                        <Table.Cell>{m.detalle} desde rodeo {complemento.nombre} </Table.Cell>
                      }

                      {m.concepto === "Salida" && m.detalle === "Transferencia" && complemento &&
                        <Table.Cell>{m.detalle} a rodeo: {complemento.nombre} </Table.Cell>
                      }

                      {!complemento &&
                        <Table.Cell>{m.detalle} </Table.Cell>
                      }

                      <Table.Cell className="right aligned">{cab} </Table.Cell>
                      {this.rodeo.seguir && this.empresa.rodeos &&
                        <Table.Cell className="right aligned">{formatear(m.kilos)} Kg</Table.Cell>
                      }
                      {this.rodeo.seguir && this.empresa.rodeos &&
                        <Table.Cell className="right aligned">$ {m.precio} </Table.Cell>
                      }
                      <Table.Cell className="center aligned"> <RodeoEditMov optionsClientes={optionsClientes} id={m.id} db={this.db} perfil={this.perfil} empresa={this.empresa} rodeo={this.rodeo} mov={m} /> </Table.Cell>
                      {m.detalle !== 'Transferir a otro rodeo' && m.detalle !== 'Generar Rodeo' && m.detalle.indexOf("Transferencia", 0) < 0 && m.detalle.indexOf("Alta de rodeo", 0) < 0 && m.detalle.indexOf("Alta de Rodeo", 0) < 0  &&
                        <Table.Cell className="center aligned"> <RodeoDeleteMov mov={m} empresa={this.perfil.empresa} db={this.db} /> </Table.Cell>
                      }
 {/* <Table.Cell className="center aligned"> <RodeoDeleteMov mov={m} empresa={this.perfil.empresa} db={this.db} /> </Table.Cell> */}
                      {/* <Table.Cell className="center aligned" onClick={() => m.doc.ref.update({ detalle: "Salida a Campo" })}>Transferencia</Table.Cell> */}
                      {/* <Table.Cell className="center aligned" onClick={() => m.doc.ref.update({ detalle: "Transferencia" })}>Transferencia</Table.Cell> */}
                      
                      
                      {/* <Table.Cell className="center aligned" onClick={() => m.doc.ref.update({ destino: "OGbFGE75DdpZa5HR57ZG" })}> Agregar destino</Table.Cell> */}
                      
                      {/* <Table.Cell className="center aligned" onClick={() => m.doc.ref.update({ origen: "oZ63DihcJU6XSK1VRh8H" })}> Agregar origen</Table.Cell> */}
                      
                      {/* <Table.Cell className="center aligned" onClick={() => m.doc.ref.update({ concepto: "Entrada", detalle: "Alta de rodeo" })}> Corregir </Table.Cell> */}
                      {/* <Table.Cell className="right aligned"><Button icon="add" onClick={()=> m.doc.ref.delete()}/></Table.Cell> */}
                      {/* <Table.Cell className="right aligned"><Button icon="add" onClick={()=> console.log(m)}/></Table.Cell> */}

                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Card>

        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={(e, value) => this.setState({ modalOpen: false })} inverted>
            CERRAR
          </Button>

        </Modal.Actions>
      </Modal>
    )
  }

}

export default RodeoMov;