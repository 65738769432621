import { baseUrl } from "../commons/constants";

export default class Api {
  fetchDescargas = (empresa, rodeos, dispatch, fechaInicio, fechaFinal) => {
    Object.keys(rodeos).forEach(async (r) => {
      const response = await fetch(`${baseUrl}descargas/${empresa}/${r}`, {
        method: "POST",
        body: JSON.stringify({
          inicio: fechaInicio ? fechaInicio : new Date("2015-01-01"),
          fin: fechaFinal ? fechaFinal : new Date(),
        }),
        mode: "cors",
        cache: "default",
        headers: { "Content-type": "application/json" },
      });

      const data = await response.json();
      if (!data.error) {
        dispatch({
          type: "ADD_DESCARGAS",
          payload: data.lista_descargas,
        });
        // dispatch({
        //   type: 'ADD_INGREDIENTES',
        //   payload: data.ingredientes
        // })
      }
    });
  };

  getStock = async (empresa, dispatch) => {
    const response = await fetch(`${baseUrl}stock/${empresa}`);

    const data = await response.json();
    if (!data.error) {
      dispatch({
        type: "ADD_STOCK",
        payload: data,
      });
    }
  };

  getStockByDateInicial = async (empresa, fecha, dispatch) => {
    const response = await fetch(`${baseUrl}stock_by_date/${empresa}`, {
      method: "POST",
      body: JSON.stringify({
        fecha: fecha ? fecha : new Date(),
      }),
      mode: "cors",
      cache: "default",
      headers: { "Content-type": "application/json" },
    });

    const data = await response.json();
    if (!data.error) {
      dispatch({
        type: "ADD_STOCK_INICIAL",
        payload: data,
      });
    }
  };

  getStockByDateFinal = async (empresa, fecha, dispatch) => {
    const response = await fetch(`${baseUrl}stock_by_date/${empresa}`, {
      method: "POST",
      body: JSON.stringify({
        fecha: fecha ? fecha : new Date(),
      }),
      mode: "cors",
      cache: "default",
      headers: { "Content-type": "application/json" },
    });

    const data = await response.json();
    if (!data.error) {
      dispatch({
        type: "ADD_STOCK_FINAL",
        payload: data,
      });
    }
  };

  getRangoFechas = async (empresa, rodeos) => {
    const response = await fetch(`${baseUrl}rango_fechas/${empresa}`, {
      method: "POST",
      body: JSON.stringify({
        rodeos: rodeos,
      }),
      mode: "cors",
      cache: "default",
      headers: { "Content-type": "application/json" },
    });

    const data = await response.json();
    if (!data.error) {
      return data;
    }
  };
  getMovimientos = async (empresa, rodeos, fechaInicio, fechaFinal) => {
    const response = await fetch(`${baseUrl}movimientos/${empresa}`, {
      method: "POST",
      body: JSON.stringify({
        rodeos,
        fechaInicio,
        fechaFinal,
      }),
      mode: "cors",
      cache: "default",
      headers: { "Content-type": "application/json" },
    });

    const data = await response.json();
    console.log({ data });
    if (!data.error) {
      return data;
    }
  };

  getConsumos = async (empresa, rodeos, fechaInicio, fechaFinal) => {
    const response = await fetch(`${baseUrl}consumos/${empresa}`, {
      method: "POST",
      body: JSON.stringify({
        rodeos,
        inicio: fechaInicio,
        fin: fechaFinal,
      }),
      mode: "cors",
      cache: "default",
      headers: { "Content-type": "application/json" },
    });

    const data = await response.json();
    if (!data.error) {
      return data;
    }
    return { error: data.error };
  };

  solicitarReporte = async (empresa, datos) => {
    const response = await fetch(`${baseUrl}reporte_rodeo/${empresa}`, {
      method: "POST",
      body: JSON.stringify({
        empresa,
        ...datos,
      }),
      mode: "cors",
      cache: "default",
      headers: { "Content-type": "application/json" },
    });

    const data = await response.json();
    if (!data.error) {
      return data;
    }
    return { error: data.error };
  };

  solicitarDietaPdf = async (empresa, datos) => {
    const response = await fetch(
      `${baseUrl}pdf/${empresa}`,

      {
        method: "POST",
        body: JSON.stringify({
          ...datos,
        }),
        mode: "cors",
        cache: "default",
        headers: { "Content-type": "application/json" },
      }
    );

    const data = await response.json();
    if (!data.error) {
      return data;
    }
    return { error: data.error };
  };
}
