import React from "react";
import { Dropdown } from "semantic-ui-react";

const ButtonMenu = (props) => (
  <Dropdown
    icon="settings"
    floating
    button
    className={props.rodeo.cpcAutomatico ? "icon green" : "icon"}
  >
    <Dropdown.Menu>
      <Dropdown.Menu scrolling>
        {/* {props.resumen} */}
        {props.movimientos}
        {props.sanidad}
        {props.automatismo}
        {props.programarAutomatismo}
        {props.dietas}
        {props.edit}
      </Dropdown.Menu>
    </Dropdown.Menu>
  </Dropdown>
);

export default ButtonMenu;
