
const reportes_stock = (state = [], action) => {
    switch (action.type) {
        case 'ADD_REPORTE_STOCK':
           return action.reportesStock
        default:
            return state
    }
};

export default reportes_stock;