import React from 'react';
import firebase from '../firebase';
import { Table, Grid, Container, Image, Form, Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import FiltroFechas from '../components/filtros/ModalFechas';

import EditarPrecipitacion from '../components/EditarPrecipitacion';

class Clima extends React.Component {
    constructor(props) {
        super(props);

        this.db = firebase.firestore();
        this.perfil = props.perfil;

        this.state = {
            fechaInicio: null,
            fechaFinal: null,
            listeners: props.listeners || [],
            empresa: props.empresa,
            latitud: props.empresa ? props.empresa.lat || 0 : 0,
            longitud: props.empresa ? props.empresa.lon || 0 : 0,
            clima: props.clima || []
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            latitud: nextProps.empresa ? nextProps.empresa.lat || 0 : 0,
            longitud: nextProps.empresa ? nextProps.empresa.lon || 0 : 0
        })
    }

    formatoNumero = (valor = 0, decimales = 0) => {
        return new Intl.NumberFormat('de-DE', { maximumFractionDigits: decimales }).format(valor);
    }

    fechaCorta = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }

    //fecha vta

    onChangeFechaInicio = (fechaInicio) => {
        this.setState({
            fechaInicio
        })
    }
    onChangeFechaFinal = (fechaFinal) => {
        this.setState({
            fechaFinal
        })
    }
    borrarFechaInicio = () => {
        this.setState({
            fechaInicio: null
        })
    }
    borrarFechaFinal = () => {
        this.setState({
            fechaFinal: null
        })
    }


    save = () => {
        this.db.collection("empresas").doc(this.perfil.empresa).update({
            lat: this.state.latitud,
            lon: this.state.longitud
        })
    }
    render() {
        
        if (!this.props.clima){
            return <h2>Cargando...</h2>
        }
        
        let totalPrecipitaciones = 0;


        const clima = this.props.clima.filter((d) => {

            var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha); //new Date(d.detalle.fecha);

            let banderaFechaInicio = true;
            if (this.state.fechaInicio !== null) {
                var fechaInicio = new Date(this.state.fechaInicio);
                fechaInicio.setHours(0);
                banderaFechaInicio = fecha > fechaInicio
            }

            let banderaFechaFin = true;
            if (this.state.fechaFinal !== null) {

                var fechaFin = new Date(this.state.fechaFinal);
                fechaFin.setHours(23);
                fechaFin.getMinutes(59);
                banderaFechaFin = fechaFin > fecha
            }

            return banderaFechaInicio && banderaFechaFin;
        });

        clima.sort((a, b) => {
            let ad = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
            let bd = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);

            if (ad > bd) return -1;
            if (ad < bd) return 1;
            return 0;
        })

        const lista = clima.map(tiempo => {

            totalPrecipitaciones += (tiempo.precipitacion || 0);

            const ITH = ((((1.8 * tiempo.temp_c) + 32)) - ((0.55 - (0.55 * (tiempo.humidity / 100))) * ((1.8 * tiempo.temp_c) - 26)));

            return (
                <Table.Row key={tiempo.id}>
                    <Table.Cell>
                        {this.fechaCorta(tiempo.fecha.toDate ? tiempo.fecha.toDate() : new Date(tiempo.fecha))}
                    </Table.Cell>
                    <Table.Cell>
                        <Image src={`../assets/day/${tiempo.icon}`} size='tiny' floated='left' />
                        {/* </Table.Cell>
              <Table.Cell> */}
                        {tiempo.condition}
                    </Table.Cell>
                    <Table.Cell textAlign={'right'}>
                        {tiempo.temp_c}C°
              </Table.Cell>
                    <Table.Cell textAlign={'right'}>
                        {tiempo.humidity}%
              </Table.Cell>
                    <Table.Cell textAlign={'right'}>
                        {this.formatoNumero(ITH, 2)}
                    </Table.Cell>
                    <Table.Cell textAlign={'right'}>
                        {tiempo.precipitacion || 0} <EditarPrecipitacion db={this.db} perfil={this.perfil} tiempo={tiempo} />
                    </Table.Cell>
                    {/* <Table.Cell textAlign={'right'} onClick={()=> tiempo.doc.ref.delete()}>borrar</Table.Cell> */}
                </Table.Row>
            )
        })

        return (
            <Container>
                <Grid>
                    <Grid.Row centered>


                        <Form>
                            <Form.Field>
                                <Form.Input label="Latitud" id="lat" type="number" value={this.state.latitud} onChange={(e, { value }) => this.setState({ latitud: value })} />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label="Longitud" id="lon" type="number" value={this.state.longitud} onChange={(e, { value }) => this.setState({ longitud: value })} />
                            </Form.Field>
                            <Form.Field>
                                <Button color='green' onClick={this.save} inverted>
                                    <Icon name='checkmark' /> Guardar
                                    </Button>
                            </Form.Field>
                        </Form>


                        <Table celled
                            striped
                            compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                    <Table.HeaderCell textAlign={'right'}>
                                        {totalPrecipitaciones}mm
                                </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell textAlign="center">
                                        <FiltroFechas sin_limite={true} titulo={"Fecha"} borrarFechaInicio={this.borrarFechaInicio} borrarFechaFinal={this.borrarFechaFinal} fechaInicio={this.state.fechaInicio} fechaFinal={this.state.fechaFinal} onChangeFechaInicio={this.onChangeFechaInicio} onChangeFechaFinal={this.onChangeFechaFinal} />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Estado</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Temperatura</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Humedad</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>ITH</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Precipitaciones</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {lista}
                            </Table.Body>
                        </Table>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }

}

export default compose(
    firestoreConnect((props, state) => {
        return [
          {
              collection: 'empresas',
              doc: props.perfil.empresa,
              subcollections: [{ collection: 'clima' }],
              orderBy: [['fecha', 'desc']],
              storeAs: 'clima'
          },
        ]
    }), // sync todos collection from Firestore into redux
    connect((state, props) => {
        let clima
  
        if (state.firestore.data.clima === null) clima = []
        
        if (state.firestore.data.clima){
            clima = []
            Object.entries(state.firestore.data.clima).map((item) => {
                let obj = item[1]
                obj.id = item[0]
                clima.push( obj)
            })
        }
  
        return ({
          clima
        })
    }
    )
  )(Clima)