import React, { Component } from 'react';
import { Button, Modal, Segment, Dimmer, Loader, Header } from 'semantic-ui-react';
import Api from './../api'
import Pdf from './pdf'

const api = new Api()

class DietaPDF extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;
    this.dieta = props.dieta;
    this.db = props.db;

    this.state = {
        cargando: true,
        modalOpen: false
    }

  }

  handleClose = () => {
      this.setState({
          modalOpen: false,
          cargando: true
      })
  }

  handelOpen = () => {
    this.setState({
        cargando: true,
        modalOpen: true,
        file: ""
    })

    this.solicitarArchivo()
  }

  solicitarArchivo = async () => {
      const datos = {
          dieta: this.id,
          nombre: this.dieta.nombre,
          talcual: this.dieta.talcual
      }

      const response = await api.solicitarDietaPdf(this.perfil.empresa, datos)
      if (response.res){
        this.setState({
            cargando: false,
            file: response.res
        })
      }else{
          this.setState({
              modalOpen: false
          })
      }
  }

  componentWillReceiveProps = (nextProps) => {
    this.dieta = nextProps.dieta;
  }


  render () {

    return(

      <Modal 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'fullscreen'}
        trigger={<Button floated='right' 
        onClick={() => this.handelOpen()} color='red' size={'big'} 
        icon="file pdf" className="ui center aligned segment"/> }
        closeIcon
        >
        <Header icon='archive'  />
        <Modal.Content>     
                {this.state.cargando && (
                <Segment>
                    <Dimmer active inverted>
                    <Loader inverted>Loading {this.state.tiempo}</Loader>
                    </Dimmer>
                </Segment>
                )}

                {!this.state.cargando && (
                    <Pdf file={this.state.file}/>
                )}

        </Modal.Content>
      </Modal>
    )
  }

}

export default DietaPDF;