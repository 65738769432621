import React, { Component } from 'react';
import { Table, Grid, Label, Form } from 'semantic-ui-react/dist/commonjs';
import firebase from '../firebase';

import { connect } from 'react-redux';
import {
    initialFetch, fetchClientes, fetchEmpresa, fetchRodeosInactivos,
    fetchRodeosActivos, fetchCategorias, fetchMovRodeos,
    setFilterTropas
} from '../actions';

import EditarVenta from '../components/EditarVenta';
import EditarTropaActiva from '../components/EditarTropaActiva';
import FiltroFechas from '../components/filtros/ModalFechas';
import ModalMultiple from '../components/filtros/ModalMultiple';
class ListaRecria extends Component {

    constructor(props) {
        super(props);

        this.db = firebase.firestore();
        this.perfil = props.perfil;

        this.activarListener = props.initialFetch;
        this.fetchEmpresa = props.fetchEmpresa;
        this.fetchRodeosInactivos = props.fetchRodeosInactivos;
        this.fetchRodeosActivos = props.fetchRodeosActivos;
        this.fetchCategorias = props.fetchCategorias;
        this.fetchMovRodeos = props.fetchMovRodeos;
        this.fetchClientes = props.fetchClientes;

        this.setFilterTropas = props.setFilterTropas;

        this.seleccionVendidos = ["En Campo", "Pendientes"];

        this.vendidosF = {};
        this.vendidosF["En Campo"] = { id: "En Campo", label: "En Campo" };
        this.vendidosF["Pendientes"] = { id: "Pendientes", label: "Pendientes" };

        this.filteredVentas = {
            campo: props.filtrosTropasActivas.campo,
            fechaInicio: props.filtrosTropasActivas.fechaInicio,
            fechaFinal: props.filtrosTropasActivas.fechaFinal,
            allRodeos: props.filtrosTropasActivas.allRodeos,
            listaRodeos: props.filtrosTropasActivas.listaRodeos,
            allCategorias: props.filtrosTropasActivas.allCategorias,
            listaCategorias: props.filtrosTropasActivas.listaCategorias,
            filtros: props.filtrosTropasActivas.filtros
        }

        this.filtrosFijos = ["campo", "rodeos", "categorias"]
        this.clientesFventas = [];
        this.rodeosFventas = [];
        this.categoriasFventas = [];

        this.state = {
            listeners: props.listeners || [],
            reportes_ventas: props.reportes_ventas || [],
            rodeos_activos: props.rodeosActivos || [],
            rodeos_inactivos: props.rodeosInactivos || [],
            categorias: props.categorias || [],
            movrodeos: props.movrodeos || [],
            empresa: props.empresa,
            iva: props.empresa.iva || 10.5
        };
    }

    componentWillMount = () => {
        const empresa = { id: "empresa" };

        const oyenteEmpresa = this.state.listeners.find(function (element) {
            return element.id === "empresa";
        });

        if (oyenteEmpresa === undefined) {
            this.activarListener(this.perfil, empresa);
            this.fetchEmpresa(this.perfil);
        }

        const categorias = { id: "categorias" };

        const oyenteCategorias = this.state.listeners.find(function (element) {
            return element.id === "categorias";
        });

        if (oyenteCategorias === undefined) {
            this.activarListener(this.perfil, categorias);
            this.fetchCategorias(this.perfil);
        }

    }
    componentDidMount = () => {
        const rodeosActivos = { id: "rodeos_activos" };
        const rodeosInactivos = { id: "rodeos_inactivos" };

        const oyenteActivos = this.state.listeners.find(function (element) {
            return element.id === "rodeos_activos";
        });

        if (oyenteActivos === undefined) {
            this.activarListener(this.perfil, rodeosActivos);
            this.fetchRodeosActivos(this.perfil);
        }

        const oyenteInactivos = this.state.listeners.find(function (element) {
            return element.id === "rodeos_inactivos";
        });

        if (oyenteInactivos === undefined) {
            this.activarListener(this.perfil, rodeosInactivos);
            this.fetchRodeosInactivos(this.perfil);
        }

        const movimientos = { id: "movrodeos" };

        const oyenteMovimientos = this.state.listeners.find(function (element) {
            return element.id === "movrodeos";
        });

        if (oyenteMovimientos === undefined) {
            this.activarListener(this.perfil, movimientos);
            this.fetchMovRodeos(this.perfil);
        }
    }
    componentWillReceiveProps = (nextProps) => {
        this.setState({
            listeners: nextProps.listeners || [],
            movrodeos: nextProps.movrodeos || [],
            rodeos_activos: nextProps.rodeosActivos || [],
            rodeos_inactivos: nextProps.rodeosInactivos || [],
            categorias: nextProps.categorias || [],
            empresa: nextProps.empresa,
            iva: nextProps.empresa.iva || 10.5
        })


        this.filteredVentas = {
            campo: nextProps.filtrosTropasActivas.campo,
            fechaInicio: nextProps.filtrosTropasActivas.fechaInicio,
            fechaFinal: nextProps.filtrosTropasActivas.fechaFinal,
            allRodeos: nextProps.filtrosTropasActivas.allRodeos,
            listaRodeos: nextProps.filtrosTropasActivas.listaRodeos,
            allCategorias: nextProps.filtrosTropasActivas.allCategorias,
            listaCategorias: nextProps.filtrosTropasActivas.listaCategorias,
            filtros: nextProps.filtrosTropasActivas.filtros
        }


    }

    actualizarIva = (iva) => {
        this.state.empresa.doc.ref.update({
            iva
        })
    }

    formatoMoneda = (valor) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(valor)
    }

    formatoNumero = (valor = 0, decimales = 0) => {
        return new Intl.NumberFormat('de-DE', { maximumFractionDigits: decimales }).format(valor);
    }

    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }


    /////////////FECHAS//////////
    onChangeFechaInicio = (fechaInicio) => {
        this.filteredVentas.fechaInicio = fechaInicio;
        this.setFilterTropas(this.filteredVentas);
    }
    onChangeFechaFinal = (fechaFinal) => {
        this.filteredVentas.fechaFinal = fechaFinal;
        this.setFilterTropas(this.filteredVentas);
    }
    borrarFechaInicio = () => {
        this.filteredVentas.fechaInicio = null;
        this.setFilterTropas(this.filteredVentas);
    }
    borrarFechaFinal = () => {
        this.filteredVentas.fechaFinal = null;
        this.setFilterTropas(this.filteredVentas);
    }

    onSelectedAllRodeos = (opciones) => {
        let busqueda = this.filteredVentas.filtros.find((f) => f.nombre === "rodeos");
        if (busqueda !== null && busqueda !== undefined) {

            let index = this.filteredVentas.filtros.indexOf(busqueda);


            if (index > -1) {
                this.filteredVentas.filtros = this.filteredVentas.filtros.filter((e) => e.nombre !== "rodeos");
            }

        }

        let rodeos = [];
        if (!this.filteredVentas.allRodeos) {
            Object.keys(opciones).map(opcion => {
                rodeos.push(opciones[opcion].id);
            })

            this.filteredVentas.listaRodeos = rodeos;
            this.filteredVentas.allRodeos = true;
        } else {
            this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });
            this.filteredVentas.allRodeos = false;
            this.filteredVentas.listaRodeos = [];
        }
        this.setFilterTropas(this.filteredVentas);
    }
    onSelectedRodeo = (id, opciones) => {
        this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });

        let index = this.filteredVentas.listaRodeos.indexOf(id);

        if (index > -1) {
            this.filteredVentas.listaRodeos = this.filteredVentas.listaRodeos.filter((e) => e !== id);
            this.filteredVentas.allRodeos = false;
        } else {

            let rodeos = [];

            if (this.filteredVentas.allRodeos) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        rodeos.push(opciones[opcion].id);
                })

                this.filteredVentas.listaRodeos = rodeos;
                this.filteredVentas.allRodeos = false;
            } else {

                this.filteredVentas.listaRodeos = this.filteredVentas.listaRodeos.concat(id);
                this.filteredVentas.allRodeos = false;
            }
        }
        this.setFilterTropas(this.filteredVentas);
    }
    onSelectedAllCategorias = (opciones) => {

        let busqueda = this.filteredVentas.filtros.find((f) => f.nombre === "categorias");
        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filteredVentas.filtros.indexOf(busqueda);

            if (index > -1) {
                this.filteredVentas.filtros = this.filteredVentas.filtros.filter((e) => e.nombre !== "categorias");
            }
        }

        let categorias = [];
        if (!this.filteredVentas.allCategorias) {
            Object.keys(opciones).map(opcion => {
                categorias.push(opciones[opcion].id);
            })

            this.filteredVentas.listaCategorias = categorias;
            this.filteredVentas.allCategorias = true;
        } else {
            this.filteredVentas.filtros.push({ nombre: "categorias", hora: new Date() });
            this.filteredVentas.allCategorias = false;
            this.filteredVentas.listaCategorias = [];
        }
        this.setFilterTropas(this.filteredVentas);
    }
    onSelectedCategoria = (id, opciones) => {
        this.filteredVentas.filtros.push({ nombre: "categorias", hora: new Date() });

        let index = this.filteredVentas.listaCategorias.indexOf(id);

        if (index > -1) {
            this.filteredVentas.listaCategorias = this.filteredVentas.listaCategorias.filter((e) => e !== id);
            this.filteredVentas.allCategorias = false;
        } else {

            let categorias = [];

            if (this.filteredVentas.allCategorias) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        categorias.push(opciones[opcion].id);
                })

                this.filteredVentas.listaCategorias = categorias;
                this.filteredVentas.allCategorias = false;
            } else {

                this.filteredVentas.listaCategorias = this.filteredVentas.listaCategorias.concat(id);
                this.filteredVentas.allCategorias = false;
            }
        }
        this.setFilterTropas(this.filteredVentas);
    }

    onSelectedAllCampoPendientes = () => {
        let busqueda = this.filteredVentas.filtros.find((f) => f.nombre === "campo");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filteredVentas.filtros.indexOf(busqueda);
            if (index > -1) {
                this.filteredVentas.filtros = this.filteredVentas.filtros.filter((e) => e.nombre !== "campo");
            }
        }

        this.seleccionVendidos = ["En Campo", "Pendientes"];

        this.filteredVentas.campo = null;

        this.setFilterTropas(this.filteredVentas);
    }
    onSelectedCampoPendientes = (id, opciones) => {
        let busqueda = this.filteredVentas.filtros.find((f) => f.nombre === "campo");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filteredVentas.filtros.indexOf(busqueda);
            if (index > -1) {
                this.filteredVentas.filtros = this.filteredVentas.filtros.filter((e) => e.nombre !== "campo");
            }
        }

        if (id === "En Campo") {
            this.seleccionVendidos = ["En Campo"];

            this.filteredVentas.filtros.push({ nombre: "campo", hora: new Date() });
            this.filteredVentas.campo = true;
            this.setFilterTropas(this.filteredVentas);
        }

        if (id === "Pendientes") {
            this.seleccionVendidos = ["Pendientes"];

            this.filteredVentas.filtros.push({ nombre: "campo", hora: new Date() });
            this.filteredVentas.campo = false;
            this.setFilterTropas(this.filteredVentas);
        }
    }

    aplicarFiltroCampo = (lista) => {
        //filtrando por dietas

        lista = lista.filter((d) => {
            if (this.filteredVentas.campo === null) {
                return true;
            }

            if (this.filteredVentas.campo === true) {
                return d.campo === true;
            }

            if (this.filteredVentas.campo === false) {
                return d.campo === false;
            }
        });

        return lista;
    }
    aplicarFiltroCategorias = (lista) => {
        this.categoriasFventas = [];
        //filtrando por categorias
        lista = lista.filter((d) => {

            let categoria = {};
            categoria.id = d.categoria;
            categoria.label = d.categoria;
            this.categoriasFventas[categoria.id] = categoria;

            let bandera = true;
            if (!this.filteredVentas.allCategorias) {
                let index = this.filteredVentas.listaCategorias.indexOf(d.categoria);
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return lista;
    }
    aplicarFiltroRodeos = (lista) => {
        this.rodeosFventas = [];
        //filtrando por rodeo
        lista = lista.filter((d) => {

            let rodeo = {};
            rodeo.id = d.rodeo;
            rodeo.label = d.rodeo;
            this.rodeosFventas[rodeo.id] = rodeo;

            let bandera = true;
            if (!this.filteredVentas.allRodeos) {
                let index = this.filteredVentas.listaRodeos.indexOf(d.rodeo);
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return lista;
    }

    render() {


        var _this = this;

        //tropas activas
        let tropas = this.state.rodeos_activos.map(
            (value) => {
                var f1 = new Date();
                f1.setHours(0);
                f1.setMinutes(0);
                f1.setSeconds(0);
                f1.setMilliseconds(0);

                var f2 = value.ultimaFechaCalculada ? (value.ultimaFechaCalculada.toDate ? value.ultimaFechaCalculada.toDate() : new Date(value.ultimaFechaCalculada)) : new Date();
                f2.setHours(0);
                f2.setMinutes(0);
                f2.setSeconds(0);
                f2.setMilliseconds(0);


                const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * value.gdpv;
                const pesoActual = (Number(value.ultimaPesada) ? value.ultimaPesada : 0) + Number(aumento);

                //value.metaDiasEncierre
                //fecha de salida
                let fechaEstimada = new Date();
                let diasEncierre = (new Date()).getTime() - (value.fechaAlta.toDate ? value.fechaAlta.toDate().getTime() : (new Date(value.fechaAlta)).getTime());
                diasEncierre = Number(this.formatoNumero((diasEncierre / 86400000), 0));

                //kilos
                const metaDias = Number(value.metaDiasEncierre) || 0;
                const metaKilos = Number(value.metaKilos) || 0;
                let kilos = metaKilos;
                if (value.seguir) {
                    if (pesoActual >= metaKilos && metaKilos > 0) {
                        kilos = pesoActual;
                        fechaEstimada = new Date();
                    } else if (diasEncierre >= metaDias && metaDias > 0) {
                        fechaEstimada = new Date();
                    } else {
                        if (metaDias > 0 || metaKilos > 0) {
                            let faltantePorKilos = 0;
                            let faltantePorDias = 0;
                            if (metaKilos > 0) {
                                faltantePorKilos = (metaKilos - pesoActual) / value.gdpv;
                            }
                            if (metaDias > 0) {
                                faltantePorDias = metaDias - diasEncierre;
                            }

                            if (metaDias === 0) {
                                fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(faltantePorKilos) * 86400000));
                            }

                            if (metaKilos === 0) {
                                fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(faltantePorDias) * 86400000));
                            }

                            if (faltantePorKilos <= faltantePorDias && metaKilos > 0) {
                                fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(faltantePorKilos) * 86400000));
                            }

                            if (faltantePorDias <= faltantePorKilos && metaDias > 0) {
                                fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(faltantePorDias) * 86400000));
                            }
                        }
                    }
                } else {
                    if (diasEncierre >= metaDias) {
                        fechaEstimada = new Date();
                    } else {
                        fechaEstimada = new Date(fechaEstimada.getTime() + (Math.ceil(metaDias - diasEncierre) * 86400000));
                    }
                }


                let categoria = categoria = this.state.categorias.find(c => c.id === value.categoria);

                return {
                    gdpv: value.gdpv,
                    doc: value.doc,
                    id: value.id + fechaEstimada,
                    fecha: fechaEstimada,
                    dieta: value.dieta,
                    rodeo: value.nombre,
                    categoria: categoria ? categoria.nombre : "-",
                    cabezas: value.cabezas,
                    kilos,
                    consumo_estimado: value.consumo_estimado || 0,
                    precio: value.precioventa || 0,
                    neto: (value.cabezas * categoria.venta * kilos),
                    total: value.cabezas * categoria.venta * kilos * ((this.state.iva / 100) + 1),
                    vender: value.vender ? value.vender : false,
                    iva: Number(this.state.iva),
                    campo: false
                }

            }
        )

        tropas = tropas.filter(t => !t.vender)

        let listaVentas = this.state.movrodeos.filter(m => m.detalle.toLowerCase() === "salida a campo");

        let listaVentaReporte = [];

        listaVentas.map(
            (value) => {
                let rodeo = this.state.rodeos_inactivos.find(r => r.id === value.rodeo);

                if (!rodeo) {
                    rodeo = this.state.rodeos_activos.find(r => r.id === value.rodeo);
                }

                let categoria = null;

                if (rodeo) {
                    categoria = this.state.categorias.find(c => c.id === rodeo.categoria);
                }

                listaVentaReporte.push({
                    id: value.fecha + (rodeo ? rodeo.nombre : "-"),
                    campo: true,
                    dieta: rodeo ? rodeo.dieta : null,
                    doc: value.doc,
                    fecha: value.fecha,
                    consumo_estimado: 0,
                    rodeo: rodeo ? rodeo.nombre : "-",
                    categoria: categoria ? categoria.nombre : "-",
                    cabezas: value.cabezas,
                    kilos: value.kilos,
                    precio: value.precio,
                    iva: Number(this.state.iva)
                })
            })


        listaVentaReporte = listaVentaReporte.concat(tropas);

        let listaFiltrosV = this.filteredVentas.filtros.reduce((filtro, item) => {
            let val = item.nombre;
            filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
            return filtro;
        }, {});

        listaFiltrosV = Object.keys(listaFiltrosV).sort((a, b) => {

            if (listaFiltrosV[a].hora > listaFiltrosV[b].hora) {
                return 1;
            }

            if (listaFiltrosV[a].hora < listaFiltrosV[b].hora) {
                return -1;
            }
            return 0;
        })

        this.filtrosFijos.forEach((fijo) => {
            let index = listaFiltrosV.indexOf(fijo);
            if (index < 0) listaFiltrosV.push(fijo);
        });



        //por fecha 

        listaVentaReporte = listaVentaReporte.filter((d) => {

            var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha); //new Date(d.detalle.fecha);

            let banderaFechaInicio = true;
            if (this.filteredVentas.fechaInicio !== null) {
                var fechaInicio = new Date(this.filteredVentas.fechaInicio);
                fechaInicio.setHours(0);
                banderaFechaInicio = fecha > fechaInicio
            }

            let banderaFechaFin = true;
            if (this.filteredVentas.fechaFinal !== null) {

                var fechaFin = new Date(this.filteredVentas.fechaFinal);
                fechaFin.setHours(23);
                fechaFin.getMinutes(59);
                banderaFechaFin = fechaFin > fecha
            }

            return banderaFechaInicio && banderaFechaFin;
        });


        listaFiltrosV.forEach((f) => {
            if (f === "campo") listaVentaReporte = _this.aplicarFiltroCampo(listaVentaReporte);
            if (f === "rodeos") listaVentaReporte = _this.aplicarFiltroRodeos(listaVentaReporte);
            if (f === "categorias") listaVentaReporte = _this.aplicarFiltroCategorias(listaVentaReporte);
        })

        listaVentaReporte.sort((a, b) => {
            let ad = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
            let bd = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);

            if (ad > bd) return 1;
            if (ad < bd) return -1;
            return 0;
        })


        let totalKilos = 0;
        let totalCabezas = 0;



        const lista = listaVentaReporte.map(value => {

            totalKilos += value.kilos * value.cabezas;
            totalCabezas += value.cabezas;

            return (
                <Table.Row key={value.id} style={{ backgroundColor: value.campo ? 'fff' : "#CEF6F5" }}>
                    <Table.Cell>{value.campo ? "En Campo" : "Pendiente"} </Table.Cell>
                    <Table.Cell textAlign="right">{value.fecha ? this.prettyDate(value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha)) : "-"} </Table.Cell>
                    <Table.Cell>{value.rodeo} </Table.Cell>
                    <Table.Cell textAlign="right">{value.categoria} </Table.Cell>
                    <Table.Cell textAlign="right">{value.cabezas} </Table.Cell>
                    <Table.Cell textAlign="right">{this.formatoNumero(value.kilos)}Kg </Table.Cell>
                    <Table.Cell textAlign="right">{this.formatoNumero((value.kilos * value.cabezas))}Kg </Table.Cell>
                    <Table.Cell textAlign="right">{value.consumo_estimado} </Table.Cell>
                    <Table.Cell textAlign="center">
                        {!value.campo &&
                            <EditarTropaActiva perfil={this.perfil} db={this.db} recria={true} venta={value} />
                        }
                        {value.campo &&
                            <EditarVenta recria={true} venta={value} />
                        }
                    </Table.Cell>
                    {/* <Table.Cell textAlign="right" onClick={()=> value.doc.ref.update({detalle: "Salida a Campo"})}>
                        cambiar
                    </Table.Cell> */}
                    {/* <Table.Cell textAlign="right" onClick={() => value.doc.ref.delete()}> Borrar </Table.Cell> */}
                </Table.Row>
            )
        })


        // RENDERIZAR...

        return (
            <Grid.Row centered>

                <Form>
                    <Form.Group>
                        <Form.Field>
                            <Label color='red' ribbon>Iva Venta:</Label>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input id="ivaventa" type="number" step="0.1" min="0" placeholder="%" value={this.state.iva} onChange={(e, { value }) => this.actualizarIva(Number(value))} />
                        </Form.Field>
                    </Form.Group>
                </Form>

                <Table
                    celled
                    striped
                    color={"blue"}
                    valigned={"center"}
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <span>Ventas</span>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">{this.formatoNumero(Number(totalCabezas))}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">{totalCabezas > 0 ? this.formatoNumero(Number(totalKilos) / Number(totalCabezas), 2) : 0}Kg</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">{totalKilos > 0 ? this.formatoNumero(totalKilos) : 0}Kg</Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                <ModalMultiple opciones={this.vendidosF}
                                    label={"Estado"}
                                    onSelected={this.onSelectedCampoPendientes}
                                    onSelectedAll={this.onSelectedAllCampoPendientes}
                                    todo={this.filteredVentas.campo === null ? true : false}
                                    seleccion={this.seleccionVendidos} />
                            </Table.HeaderCell>
                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                <FiltroFechas sin_limite={true} titulo={"Fecha Salida"} borrarFechaInicio={this.borrarFechaInicio} borrarFechaFinal={this.borrarFechaFinal} fechaInicio={this.filteredVentas.fechaInicio} fechaFinal={this.filteredVentas.fechaFinal} onChangeFechaInicio={this.onChangeFechaInicio} onChangeFechaFinal={this.onChangeFechaFinal} />
                            </Table.HeaderCell>
                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                <ModalMultiple opciones={this.rodeosFventas}
                                    label={"Rodeo"}
                                    onSelected={this.onSelectedRodeo}
                                    onSelectedAll={this.onSelectedAllRodeos}
                                    todo={this.filteredVentas.allRodeos}
                                    seleccion={this.filteredVentas.listaRodeos} />
                            </Table.HeaderCell>
                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                <ModalMultiple opciones={this.categoriasFventas}
                                    label={"Categoria"}
                                    onSelected={this.onSelectedCategoria}
                                    onSelectedAll={this.onSelectedAllCategorias}
                                    todo={this.filteredVentas.allCategorias}
                                    seleccion={this.filteredVentas.listaCategorias} />
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Cabezas</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Kg x Cab</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Total Kilos</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Consumo</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Editar</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {lista}
                    </Table.Body>
                </Table>

            </Grid.Row>

        )
    }
}

function mapStateToProps(state, props) {
    return {
        movrodeos: state.personalStore.movrodeos,
        empresa: state.personalStore.empresa,
        categorias: state.personalStore.categorias,
        rodeosActivos: state.personalStore.rodeosActivos,
        rodeosInactivos: state.personalStore.rodeosInactivos,
        filtrosTropasActivas: state.personalStore.filtrosTropasActivas,
        listeners: state.personalStore.listeners
    };
}

export default connect(
    mapStateToProps,
    {
        initialFetch, fetchClientes, fetchEmpresa, fetchRodeosInactivos,
        fetchRodeosActivos, fetchCategorias, fetchMovRodeos, setFilterTropas
    }
)(ListaRecria);