import React from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react/dist/commonjs'

import Calendario from '../common/Calendario';

export default class ModalOneCalender extends React.Component {

  constructor(props){
    super(props);

    this.title = props.title;
    this.date = props.date;
    this.onChange = props.onChange;
    this.delete = props.delete;

    this.state = {
        modalOpen: false
    }

  }

  componentWillReceiveProps = (nextProps) => {
    this.date = nextProps.date;
  }
  
  close = ()=>{
    this.setState({ modalOpen: false })
  }
  render() {
    return (
        <Modal dimmer={"inverted"}
            closeOnEscape={false} 
            closeOnDimmerClick={false} 
            onClose={this.close}
            open={this.state.modalOpen}
            size={'tiny'}
            trigger={
                      <Button compact inverted={this.date === null} color={"green"} onClick={()=>this.setState({modalOpen: true})}>{this.title ? this.title : "Fecha"}</Button>
                    }
            >

            <Modal.Header>
                        <Grid columns={2} >
                            <Grid.Row> 
                                <Grid.Column textAlign={"left"} >
                                    A partir de..
                                </Grid.Column>
                                <Grid.Column textAlign={"right"} >
                                    <Button color={"green"} inverted onClick={
                                        ()=>{ 
                                            this.setState({
                                                modalOpen: false,
                                            });
                                        }
                                     } icon={"external"} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                        <Grid centered>
                            <Grid.Row centered>
                                <Calendario borrar={this.delete} style={{backGroundColor: "red"}} fecha={this.date} onChangeFecha={this.onChange}/>
                            </Grid.Row>
                        </Grid>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
  }

}