import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from "react-router-dom";
import { Container, Loader, Segment, Dimmer, Image, Grid, Button } from 'semantic-ui-react'

import Calendar from 'react-calendar';

import { NUEVO_REPORTE_FECHA_INICIO, NUEVO_REPORTE_FECHA_FINAL } from './actions'


class SeleccionarFechas extends Component {

  handleFechaInicio = (fecha) => {
    const { dispatch } = this.props
    dispatch({
      type: NUEVO_REPORTE_FECHA_INICIO,
      payload: fecha
    })
  }

  handleFechaFinal = (fecha) => {
    const { dispatch } = this.props
    dispatch({
      type: NUEVO_REPORTE_FECHA_FINAL,
      payload: fecha
    })
  }
  
  render() {

    const { fetched, rangoFechas, rodeos, fechaInicio, fechaFinal } = this.props

    if (fetched){
      return <Segment>
                <Dimmer active>
                  <Loader />
                </Dimmer>
            
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
              </Segment>
    }
    if (rodeos.length === 0){
      return <Redirect to="/reportes" push={true} />
    }
    // if (rodeos.length === 0){
      return <Redirect to="/reportes/setear_movimientos" push={true} />
    // }
    
    // return (
    //   <Container style={{paddingBottom: 40}}>
    //     <Grid centered >
    //       <Grid.Row centered>
    //         <h2>Indicar periodo</h2>
    //       </Grid.Row>
    //       <Grid.Row>
    //           <Calendar className='shadow'
    //               onChange={this.handleFechaInicio}
    //               value={fechaInicio}
    //               minDate={new Date(rangoFechas.min)}
    //               maxDate={new Date(rangoFechas.max)}
    //             />
               
       
    //           <Calendar className='shadow'
    //               onChange={this.handleFechaFinal}
    //               value={fechaFinal}
    //               minDate={new Date(rangoFechas.min)}
    //               maxDate={new Date(rangoFechas.max)}
    //             />
    //       </Grid.Row>
    //       <Grid.Row centered>
    //         <Button as={Link} info floated='right'  primary size='small'
    //             to="/reportes/nuevo_reporte"
    //             style={{marginRigth: 20}}
    //         >
    //             Anterior
    //         </Button>
    //         <Button as={Link} positive floated='right'  primary size='small'
    //             to="/reportes/setear_movimientos"
    //             style={{marginLeft: 20}}
    //         >
    //             Siguiente
    //         </Button>
    //       </Grid.Row>
    //     </Grid>
    //   </Container>
    // )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.personalStore.nuevoReporte
  }
}

export default connect(mapStateToProps)(SeleccionarFechas)