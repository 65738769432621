import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import InputPrecioProducto from './InputPrecioProducto'

const formatear= (numero, digitos = 0) => {
  return new Intl.NumberFormat('es-IN',{ 
              minimumFractionDigits: digitos, 
              maximumFractionDigits: digitos  
              }).format(numero);
}

class ListaMovimientos extends Component {
  render() {
    const { productos } = this.props
    return (
      <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell textAlign="center">Producto</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Materia Seca</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Kilos Tal cual</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Costo/Kg</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Costo total</Table.HeaderCell>
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {this.lista(productos)}
        </Table.Body>
      </Table>
    )
  }

  lista = (productos) => {
      
        return Object.keys(productos).map( p => 
            <Table.Row key={p}>
                <Table.Cell>{productos[p].nombre}</Table.Cell>
                <Table.Cell textAlign="right">{formatear(productos[p].materiaseca, 2)}</Table.Cell>
                <Table.Cell textAlign="right">{formatear(productos[p].kilos, 2)}</Table.Cell>
                <Table.Cell><InputPrecioProducto value={productos[p].precio} id={productos[p].producto} /></Table.Cell>
                <Table.Cell textAlign="right">{formatear(productos[p].kilos * productos[p].precio, 2)}</Table.Cell>
            </Table.Row>
        )
  }
}

const mapStateToProps = (state) => {
    return {
        productos: state.personalStore.nuevoReporte.productos
    }
}


export default connect(mapStateToProps)(ListaMovimientos)