import { 
    NUEVO_REPORTE, 
    NUEVO_REPORTE_FECHA_INICIO,
    NUEVO_REPORTE_FECHA_FINAL,
    REPORTE_VER_MOVIMIENTOS,
    REPORTE_VER_PRODUCTOS,
    REPORTE_VER_STOCK,
    NUEVO_REPORTE_SET_MOVIMIENTOS,
    NUEVO_REPORTE_SET_RODEOS,
    NUEVO_REPORTE_SET_PRODUCTOS,
    SET_RANGO_FECHAS,
    FETCHED,
    EDITAR_MOVIMIENTO,
    EDITAR_PRECIO,
    EDITAR_PRECIO_PRODUCTO,
    EDITAR_HOTELERIA,
    EDITAR_SANIDAD,
    NUEVO_REPORTE_SET_ALL_RODEOS,
    NUEVO_REPORTE_SET_FILTROS,
    NUEVO_REPORTE_SET_PRECIOS_GLOBAL
} from './actions'

import moment from 'moment'

let inicio = new Date(new Date().getTime() - (31536000000));
inicio.setHours(0)
inicio.setMinutes(0)

const initialState = {

    filtros: {
        fechaInicio: null,
        activos: null,
        allTropas: true,
        listaTropas: [],
        allPropietarios: true,
        listaPropietarios: [],
        allCategorias: true,
        listaCategorias: [],
        filtros: []
    },
    raciones: 0,
    mcal: 0,
    prot: 0, 
    ms: 0,
    hoteleria: 0,
    sanidad: 0,
    rodeos: [],
    rangoFechas: {
        min: new Date(),
        max: new Date()
    },
    fetched: false,
    fechaInicio: new Date,
    fechaFinal: new Date,
    movimientos: [],
    productos: [],
    mostrarMovimientos: false,
    mostrarProductos: false,
    mostrarStock: false
}

const nuevoReporte = (state = initialState, action) => {
    switch (action.type) {
        case NUEVO_REPORTE_SET_FILTROS:
            return { ...state, filtros: action.payload, rodeos: [] }
        case NUEVO_REPORTE: 
            return initialState;
        case EDITAR_HOTELERIA: 
            return { ...state, hoteleria: action.payload};
        case EDITAR_SANIDAD: 
            return { ...state, sanidad: action.payload};
        case NUEVO_REPORTE_FECHA_INICIO: 
            return { ...state, fechaInicio: action.payload};
        case NUEVO_REPORTE_FECHA_FINAL: 
            return { ...state, fechaFinal: action.payload};
        case REPORTE_VER_MOVIMIENTOS: 
            return { ...state, mostrarMovimientos: action.payload};
        case REPORTE_VER_PRODUCTOS: 
            return { ...state, mostrarProductos: action.payload};
        case REPORTE_VER_STOCK: 
            return { ...state, mostrarStock: action.payload};
        case NUEVO_REPORTE_SET_MOVIMIENTOS: 
            return { ...state, movimientos: action.payload, fetched: false};
        case NUEVO_REPORTE_SET_PRODUCTOS: 
            return { ...state, 
                productos: action.payload.productos,
                raciones: action.payload.raciones, 
                mcal: action.payload.mcal, 
                prot: action.payload.prot,
                ms: action.payload.ms, 
                fetched: false };
        case NUEVO_REPORTE_SET_RODEOS: 
            if (state.rodeos.includes(action.payload)){
                return { ...state, rodeos: state.rodeos.filter( r => r !== action.payload ) }
            }

            return { ...state, rodeos: state.rodeos.concat(action.payload) };
        
        case NUEVO_REPORTE_SET_ALL_RODEOS:
            return { ...state, rodeos: action.payload }

        case SET_RANGO_FECHAS: 
            let max = new Date(action.payload.max)

            if (moment(max).diff(moment(new Date()), 'days') < 0){
               max = new Date(max.getTime() + 86400000)
            }
            return { ...state, rangoFechas: action.payload, fetched: false, fechaInicio: new Date(action.payload.min), fechaFinal: max};
        case FETCHED: 
            return { ...state, fetched: true};
        case EDITAR_MOVIMIENTO: 
            return { ...state, movimientos: state.movimientos.map(m => {
                if (m.id !== action.payload.id) return m
                return {...m, kilos: action.payload.value}  
            })};
        case EDITAR_PRECIO: 
            return { ...state, movimientos: state.movimientos.map(m => {
                if (m.id !== action.payload.id) return m
                return {...m, precio: action.payload.value}  
            })};

        case NUEVO_REPORTE_SET_PRECIOS_GLOBAL: 
            return { ...state, movimientos: state.movimientos.map(m => {
                if (m.detalle.toLowerCase() === "venta" || m.detalle.toLowerCase() === "a fecha final de reporte") return {...m, precio: action.payload.precioVenta}
                return {...m, precio: action.payload.precioCompra}  
            })};

        case EDITAR_PRECIO_PRODUCTO: 
            return { ...state, productos: Object.keys(state.productos).map(p => {
                if (state.productos[p].producto !== action.payload.id) return state.productos[p]
                return {...state.productos[p], precio: action.payload.value}  
            })};
        default:
            return state
    }
}

export default nuevoReporte;