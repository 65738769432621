import React, { Component } from 'react';

import {Grid, Label, Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader, Table, Checkbox } from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';


const formatear= (numero, digitos = 0) => {
  return new Intl.NumberFormat('es-IN',{ 
                  minimumFractionDigits: digitos, 
                  maximumFractionDigits: digitos  
                  }).format(numero);
}

class TratamientoProgramado extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    
    this.db = props.db;
    this.rodeo = props.rodeo;

    this.state = {
      fecha: moment(),
      detalle: [],
      modalOpen: false,
      cargando: false,
      cantidad: 0,
      producto: ""
    };

    this.productos = props.productos.filter((p)=> p.activo);
    this.categorias = props.categorias;

  }

  componentWillReceiveProps = (nextProps) => {
    this.productos = nextProps.productos.filter((p)=> p.activo);
    this.categorias = nextProps.categorias;
  }
  

  handleChange = (date)=> {
    this.setState({
      fecha: date
    });
  }

  handleChangeSiguiente = (date)=> {
    this.setState({
      fechaSiguiente: date
    });
  }


agregarProducto = (producto) => {
    let item = {
      producto: this.state.producto,
      cantidad: this.state.cantidad
    }
    if (item.producto.length > 0 && this.state.cantidad > 0) this.setState({ detalle: this.state.detalle.concat(item), producto: "", cantidad: 0 });
 }

 componentWillUpdate() {
  this.fixBody();
}

componentDidUpdate() {
  this.fixBody();
}

fixBody() {
  const anotherModal = document.getElementsByClassName('ui page modals').length;
  if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
}
  save = () => {
    if(this.state.detalle.length > 0){
      var _this = this;

      this.setState({cargando: true});

      let detalle = [];

      this.state.detalle.forEach((item)=>{
        detalle.push( { producto: item.producto, cantidad: item.cantidad });
      })

     
    this.db.collection("empresas").doc(this.perfil.empresa).collection('tratamientos_programados').add({
        "fecha": new Date(this.state.fecha),
        "rodeo": this.rodeo.id,
        "detalle": detalle,
        "pendiente": true
    })
    .then(function(docRef) {
        _this.setState({ cargando:false, modalOpen: false, alerta: false, fecha: moment(), detalle: []});
    })
    .catch(function(error) {
        _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
    });

    this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {
    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'large'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    var optionsProductos  =  []

    this.productos.forEach((element)=> {
      if (!this.state.detalle.find((d)=> d.producto === element.id)){
        const categoria = this.categorias.find((c)=> c.id === element.categoria )
        optionsProductos.push({"key": element.id, "text": categoria.nombre + ': ' + element.nombre, "value": element.id})
      }
    }, this);


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='green'>
                  <Icon name='clock' /> Programar
                </Button>}
        size={'large'}
        >
        <Modal.Header className="ui center aligned grid">Programar Tratamiento</Modal.Header>
        <Modal.Content>

                    <Form>
                      <Form.Group className="ui center aligned grid">
                        <Form.Field >
                          <Label size={"large"}>Seleccionar Fecha</Label>
                        </Form.Field>
                        <Form.Field>
                          <DatePicker
                              minDate={moment(new Date())}
                              // maxDate={moment(new Date())}
                              dateFormat="DD/MM/YYYY"
                              selected={this.state.fecha}
                              onChange={this.handleChange}
                          />
                        </Form.Field>
                      </Form.Group>

                      <Form.Group>
                        <Form.Field width={14}>
                          <Form.Select label={"Producto"} options={optionsProductos} placeholder='Seleccionar Producto' value={this.state.producto} onChange={(e, { value }) => this.setState({ producto: value })} />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input label="Cantidad" id="cantidad" type="number" min="0" placeholder="Cantidad" value={ formatear(this.state.cantidad)} onChange={(e, { value }) => this.setState({ cantidad: Number(value) })} />
                        </Form.Field>
                        <Button color="red" icon="add" label="Agregar" onClick={()=>this.agregarProducto()} />
                      </Form.Group>

                       
                    </Form>

                      <Table>
                          <Table.Header>
                            <Table.HeaderCell>
                              Categoria
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Producto
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Cantidad
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Medida
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Borrar
                            </Table.HeaderCell>
                          </Table.Header>
                          <Table.Body>
                            {this.state.detalle.map(item => {
                              const producto = this.productos.find((p)=> p.id === item.producto)
                              const categoria = this.categorias.find((c)=> c.id === producto.categoria)
                              return (<Table.Row key={producto.id}>
                                        <Table.Cell>
                                          {categoria.nombre}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {producto.nombre}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {item.cantidad}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {producto.medida}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Button icon="delete" color="red" onClick={ ()=> this.setState({ detalle: this.state.detalle.filter((d)=> d.producto !== item.producto) }) }/>
                                        </Table.Cell>
                                      </Table.Row>)

                            })}
                          </Table.Body>
                        </Table>

        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, fecha: moment(), detalle: [] })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'El detalle del tratamiento no puede estar vacio',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default TratamientoProgramado;
