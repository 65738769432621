import React from 'react'
import { Button, Grid } from 'semantic-ui-react'
import { Link } from "react-router-dom";
export default class RowSiguiente extends React.Component{
    render(){
        const { toNext, toBack, disabled, negative } = this.props

        return(
            <Grid.Row centered>
                    <Button as={Link} negative={negative} info={!negative} size='small'
                        to={toBack}
                    >
                        Cancelar
                    </Button>
                    <Button as={Link} positive floated='right'  primary size='small'
                        to={toNext}
                        disabled={disabled}
                    >
                        Siguiente
                    </Button>
            </Grid.Row>
        )
    }
}