import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class UsuarioNew extends Component {
  
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.db = props.db;
    this.error = [];
    this.mixers = props.mixers;

    this.options =  [
        {"key": "admin", "text": "Administrador", "value": "admin"},
        {"key": "operador", "text": "Operador", "value": "operador"}
    ];

    this.state = {
      tiempo: 15,
      cargando: false,
      modalOpen: false,
      alerta: false,
      nombre: "",
      mixer: "",
      apellido: "",
      empresa: "",
      rol: "",
      email: "",
      activo: true,
      password: "",
      repassword: ""
    };

    this.refreshIntervalId = "";

  }

  componentWillReceiveProps = (nextProps) => {
    this.mixers = nextProps.mixers;
    this.setState({cargando: false})
    clearInterval(this.refreshIntervalId);
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }
  
  validateMail = (email) => {
      var resultado = false;
    
      var patron=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
      if(email.search(patron)===0)
      {
          resultado = true;
          
      }
      
      return resultado;
  }

  save = () => {

    this.setState({tiempo: 15});

    this.error = [];

    if(this.state.email.trim().length > 0 && !this.validateMail(this.state.email)) this.error.push('El correo ingresado no es valido');
    if((this.state.nombre.trim().length === 0)) this.error.push('El nombre es obligatorio.');
    if((this.state.apellido.trim().length === 0)) this.error.push('El apellido es obligatorio.');
    if((this.state.email.trim().length === 0)) this.error.push('El correo de usuario es obligatorio.');
    if((this.state.password.trim().length === 0)) this.error.push('La contraseña es obligatoria.');
    if((this.state.password.trim().length > 0 && this.state.password.trim().length < 6)) this.error.push('La contraseña debe tener como minimo 6 caracteres.');
    if((this.state.rol.trim().length === 0)) this.error.push('El Rol es obligatorio.');
    if((this.state.mixer.trim().length === 0)) this.error.push('El Mixer es obligatorio.');
    if((this.state.password.trim() !== this.state.repassword.trim())) this.error.push('El campo contraseña y su verificacion no coinciden.');
    if(this.state.password.trim().length < 6 ) this.error.push('La contraseña debe tener como minimo 6 caracteres');

    if(this.error.length === 0){
      var _this = this;
      
      this.db.collection("preuser").add({
        "empresa": this.perfil.empresa,
        "nombre": this.state.nombre,
        "mixer": this.state.mixer,
        "apellido": this.state.apellido,
        "activo": this.state.activo,
        "email": this.state.email,
        "rol": this.state.rol,
        "password": this.state.password,
        "disabled": !this.state.activo
      })
      .then(function(docRef) {
          _this.setState({ alerta: false, modalOpen: false, nombre: "", apellido: "", rol: "", activo: true, empresa: "", email: "", cargando: true });
          _this.error = [];
      })
      .catch(function(error) {
          _this.setState({alerta: true, error: _this.state.error.push(`Error adding document: ${error}`)});
      });

      this.refreshIntervalId = setInterval(this.esperar, 1000);

    }else{this.setState({ alerta: true });}
  }

  esperar = () => {
      if(this.state.tiempo>0){
        this.setState({tiempo: this.state.tiempo - 1});
      }else{
          clearInterval(this.refreshIntervalId);
          this.setState({cargando: false});
      }
  }

  render () {

    var optionsMixers  =  []

    this.mixers.forEach(function(element) {
      optionsMixers.push({"key": element.id, "text": element.nombre, "value": element.id})
    }, this);
    
    if(this.state.cargando){
     return( 
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.cargando}
        size={'small'}
        >

        <Modal.Header>Espere un momento por favor...</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading {this.state.tiempo}</Loader>
              </Dimmer>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
     )
    }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                  <Icon name='plus' /> Nuevo
                </Button>}
        >

        <Modal.Header>Nuevo Usuario</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Apellido" id="apellido" type="text" placeholder="Apellido" value={this.state.apellido} onChange={(e, { value }) => this.setState({ apellido: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Email" id="email" type="email" placeholder="Email" value={this.state.email} onChange={(e, { value }) => this.setState({ email: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Contraseña" id="passworde" type="password" placeholder="Contraseña" value={this.state.password} onChange={(e, { value }) => this.setState({ password: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Repetir Contraseña" id="repassword" type="password" placeholder="Repetir Contraseña" value={this.state.repassword} onChange={(e, { value }) => this.setState({ repassword: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Rol" options={this.options} placeholder='Seleccionar Rol' value={this.state.rol} onChange={(e, { value }) => this.setState({ rol: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Mixer" options={optionsMixers} placeholder='Seleccionar Mixer' value={this.state.mixer} onChange={(e, { value }) => this.setState({ mixer: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={(e, { value }) => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, apellido: "", activo: true, nombre: "", empresa: "", rol: "", email: "", error: [], mixer: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos'
              list={this.error}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default UsuarioNew;