
const clientes = (state = [], action) => {
    switch (action.type) {
        case 'ADD_CLIMA':
            return action.clima
        default:
            return state
    }
};

export default clientes;