import React, { Component } from 'react';
import { Grid, Dropdown, Button, Modal, Segment, List, Icon } from 'semantic-ui-react'
import Calendar from 'react-calendar';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import moment from 'moment'

class CambioDieta extends Component {
    constructor(props) {
        super(props);

        this.empresa = props.empresa;
        this.rodeo = props.rodeo;
        this.rodeos = props.rodeos;

        this.db= props.db
        this.perfil= props.perfil

        this.state = {
            modalOpen: false,
            fecha: new Date(),
            dieta: ""
        }
    }


    handleClose = () => {
        this.setState({
            modalOpen: false,
        })
    }

    handleFecha = (fecha) => {
        this.setState({
            fecha
        })
      }

    componentWillReceiveProps = (nextProps) => {
        this.empresa = nextProps.empresa;
        this.rodeo = nextProps.rodeo;
        this.db= nextProps.db
        this.perfil= nextProps.perfil
    }

    componentWillUpdate() {
        this.fixBody();
    }

    componentDidUpdate() {
        this.fixBody();
    }

    fixBody() {
        const anotherModal = document.getElementsByClassName('ui page modals').length;
        if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
    }

    agregar = async () => {
        if (this.state.dieta.length > 0){
            let fecha = new Date(this.state.fecha)
            fecha.setHours(0)
            fecha.setMinutes(0)
            fecha.setSeconds(0)
            fecha.setMilliseconds(0)

            this.rodeos.filter(r => r.corral === this.rodeo.corral ).map( ref => {
                this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas_programadas').add({
                    "rodeo": ref.id,
                    "dieta": this.state.dieta,
                    "fecha": fecha
                })
            })
            
        }
    }

    borrar = async (id) => {

        const programa = await this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas_programadas').doc(id).get()
        const pro = programa.data()
        
        const responseDelete = await this.db.collection("empresas").doc(this.perfil.empresa).collection("dietas_programadas").where("dieta", "==", pro.dieta).get()

        const programas = []
        responseDelete.forEach( doc => {
            programas.push({ ...doc.data(), id: doc.id })
        })

       
        this.rodeos.filter(r => r.corral === this.rodeo.corral ).map( ref => {
            const itemForDelete = programas.find(p => p.rodeo === ref.id)            
            if (itemForDelete){
                this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas_programadas').doc(itemForDelete.id).delete()
            }
        })
    }

    handleChange = (e, { name, value }) => this.setState({ dieta: value })

    dieta = (dieta) => {
        return this.props.dietas.find(d => d.id === dieta).nombre
    }

    render() {
        const { dietas, programas } = this.props
        const dietasOptions = []
        
        dietas.map(d =>{
            if (d.activo)
            dietasOptions.push({
              key: d.id,
              text: d.nombre,
              value: d.id,
            })
        });

        let lista = <div style={{height: "120px"}}>
            <span style={{fontSize: "1.3em", textAlign: "center"}}>No se han programado dietas</span>
        </div>

        if (programas.length > 0){
            lista = <List divided verticalAlign='middle' size="big">
                        { programas.map(p => 
                        <List.Item key={p.id}>
                            <List.Content>
                                <List.Description>
                                    <Button icon={"close"} onClick={() => this.borrar(p.id)} />
                                    <Icon name="time" />
                                    {this.dieta(p.dieta)}{" desde el: "}{moment(p.fecha.toDate()).format("DD-MM-YYYY")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        )}
                    </List>
            
        }

        return (
            <Modal

                closeOnEscape={true}
                closeOnDimmerClick={true}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size={'large'}
                trigger={<Dropdown.Item key={"dietas-automaticas"} text={"PROGRAMAR DIETAS"} icon="law" onClick={() => this.setState({ modalOpen: true })} />}
            >

                <Modal.Header>Programar cambios de dieta de la tropa:  {this.rodeo.nombre}</Modal.Header>
                <Modal.Content>
                    <Grid centered column={2} divided >
                        <Grid.Row centered>
                            <Grid.Column centered width={8}>
                                <Grid.Row centered textAlign='center'>
                                    <Segment raised>

                                            <Grid style={{margin: 4}}>
                                                <Grid.Row centered textAlign='center'>
                                                    <span style={{textAlign: "center", fontSize: "1.4em", fontWeight: "bold"}}>Seleccionar Fecha</span>
                                                </Grid.Row>
                                            </Grid>

                                            <Grid style={{margin: 4}}>
                                                <Grid.Row centered textAlign='center'>
                                                    <Calendar className='shadow'
                                                        onChange={this.handleFecha}
                                                        value={this.state.fecha}
                                                        minDate={new Date()}
                                                    />
                                                </Grid.Row>
                                            </Grid>
                                            
                                    
                                            <Grid style={{margin: 4}}>
                                                <Grid.Row centered textAlign='center'>
                                                    <span style={{textAlign: "center", fontSize: "1.4em", fontWeight: "bold"}}>Seleccionar Dieta</span>
                                                </Grid.Row>
                                            </Grid>
                                            <Dropdown
                                                placeholder='Seleccionar Dieta'
                                                fluid
                                                selection
                                                closeOnChange
                                                onChange={this.handleChange}
                                                options={dietasOptions}
                                            />

                                            
                                    </Segment>
                                </Grid.Row>
                                <Grid style={{margin: 10}}>
                                    <Grid.Row centered textAlign='center'>
                                        <Button primary onClick={()=> this.agregar()}>Programar cambio</Button>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <Segment raised>
                                    {lista}
                                </Segment>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>

                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })} inverted>
                        SALIR
                    </Button>

                </Modal.Actions>
            </Modal>

        )
    }

}



export default compose(
    firestoreConnect((props, state) => {
        return [
          {
              collection: 'empresas',
              doc: props.perfil.empresa,
              subcollections: [{ collection: 'dietas_programadas' }],
              orderBy: [['fecha', 'asc']],
              storeAs: 'programas' + props.rodeo.id,
              where: [['rodeo', '==', props.rodeo.id]]
          },
        ]
    }), // sync todos collection from Firestore into redux
    connect((state, props) => {
        let programas
  
        if (!state.firestore.data["programas"+props.rodeo.id]) programas = []
  
        if (state.firestore.data["programas"+props.rodeo.id]){
            programas = []
            Object.entries(state.firestore.data["programas"+props.rodeo.id]).map((item) => {
                let obj = item[1]
                obj.id = item[0]
                programas.push( obj)
            })
        }
  
        return ({
           programas
        })
    }
    )
  )(CambioDieta)