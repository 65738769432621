import React, { Component } from 'react';
import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class CategoriaEdit extends Component {

  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;
    this.db = props.db;
    this.state = {
      nombre: props.categoria.nombre,
      modalOpen: false,
      oldname: props.categoria.nombre,
      compra: props.categoria.compra,
      oldcompra: props.categoria.compra,
      venta: props.categoria.venta,
      oldventa: props.categoria.venta,
      activo: props.categoria.activo,
      oldactivo: props.categoria.activo,
    };

  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.categoria) this.setState({ 
      nombre: nextProps.categoria.nombre,
      oldname: nextProps.categoria.nombre,
      compra: nextProps.categoria.compra,
      oldcompra: nextProps.categoria.compra,
      venta: nextProps.categoria.venta,
      oldventa: nextProps.categoria.venta,
      activo: nextProps.categoria.activo,
      oldactivo: nextProps.categoria.activo
     });
  }
  

  save = () => {

    var _this = this;
    
    if(this.state.nombre.length >0){

      this.setState({cargando: true});

      var catRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('categorias').doc(this.id);
      
      // Set the "capital" field of the city 'DC'
      return catRef.update({
        "nombre": this.state.nombre,
        "activo": this.state.activo,
        "compra": Number(this.state.compra),
        "venta": Number(this.state.venta)
      })
      .then(function() {
          _this.setState({ cargando: false,  modalOpen: false, oldname: _this.state.nombre, oldactivo: _this.state.activo });    
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error})
      })
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
    
  }


  formatear = (num, decimales = 0) => {
      var signo = (num >= 0 ? 1 : -1);
      num = num * signo;
      if (decimales === 0) //con 0 decimales
          return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split('e');
      num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
      // x * 10 ^ (-decimales)
      num = num.toString().split('e');
      return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
  }

  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        >
        <Modal.Header>Editar Categoria</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Group>
                    <Form.Field>
                      <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre de la categoria" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label="Precio de compra" id="compra" type="number" min="0" placeholder="$ Compra" value={`${this.formatear(this.state.compra, 2)}`} onChange={(e, { value }) => this.setState({ compra: value })} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label="Precio de venta" id="venta" type="number" min="0" placeholder="$ Venta" value={`${this.formatear(this.state.venta, 2)}`} onChange={(e, { value }) => this.setState({ venta: value })} />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={ () => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={ (e, value)=> this.setState({ modalOpen: false, nombre: this.state.oldname, activo: this.state.oldactivo, compra: this.state.oldcompra, venta: this.state.oldventa })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Debe ingresar un nombre valido!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default CategoriaEdit;