import firebase from '../firebase';
import { isEmpty } from 'react-redux-firebase';
const db = firebase.firestore();

export const fetchCorrales = (perfil) => {
    return function (dispatch) {
        if (!isEmpty(perfil)){
            db.collection("empresas").doc(perfil.empresa).collection('corrales').orderBy('nombre', 'asc')
                .onSnapshot(function(querySnapshot) {
                    let items = []
                    querySnapshot.forEach(function(doc) {
                        let corral = doc.data();
                        corral.doc = doc;
                        corral.id = doc.id;
                        items.push(corral)
                    });
                    dispatch(addCorral(items));
                });
        }
    }
}

const addCorral = (corrales) => ({
    type: 'ADD_CORRAL',
    corrales
});
