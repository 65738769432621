
const reportes_tratamientos = (state = [], action) => {
    switch (action.type) {
        case 'ADD_REPORTE_TRATAMIENTO':
           return action.reportesSanitarios
        default:
            return state
    }
};

export default reportes_tratamientos;