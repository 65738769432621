import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class CorralNew extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.mixers = props.mixers;
    this.db = props.db;
    
    this.options = this.mixers.map((value) => { 
        var rObj = {};
        rObj["activo"] = value.activo;
        rObj["key"] = value.id;
        rObj["text"] = value.nombre;
        rObj["value"] = value.id;
        return rObj;
    });

    this.options = this.options.filter((element)=> {
      if (!element.activo) return false;
      return true;
    }) 

    this.state = {
      modalOpen: false,
      alerta: false,
      nombre: "",
      mixer: "",
      activo: true,
      cargando: false
    };

  }
  
  save = () => {
    var _this = this;

    if( this.state.nombre.trim().length > 0 && this.state.mixer.trim().length > 0) { 
      this.setState({cargando: true});

      this.db.collection("empresas").doc(this.perfil.empresa).collection('corrales').add({
        "nombre": this.state.nombre,
        "mixer": this.state.mixer,
        "activo": this.state.activo
      })
      .then(function(docRef) {
          _this.setState({ cargando: false, alerta: false, modalOpen: false, nombre: "", activo: true, mixer: "" }); 
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
      });
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }

  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                  <Icon name='plus' /> Nuevo
                </Button>}
        >

        <Modal.Header>Nuevo Corral</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre del Corral" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Mixer" options={this.options} placeholder='Seleccionar Mixer' value={this.state.mixer} onChange={(e, { value }) => this.setState({ mixer: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={(e, { value }) => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, nombre: "", activo: true, mixer: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Debe ingresar un nombre valido y designaler un mixer!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default CorralNew;