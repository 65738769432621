export const NUEVO_REPORTE = "NUEVO_REPORTE"
export const NUEVO_REPORTE_FECHA_INICIO = "NUEVO_REPORTE_FECHA_INICIO"
export const NUEVO_REPORTE_FECHA_FINAL = "NUEVO_REPORTE_FECHA_FINAL"
export const NUEVO_REPORTE_SET_MOVIMIENTOS = "NUEVO_REPORTE_SET_MOVIMIENTOS"
export const REPORTE_VER_MOVIMIENTOS = "REPORTE_VER_MOVIMIENTOS"
export const REPORTE_VER_PRODUCTOS = "REPORTE_VER_PRODUCTOS"
export const REPORTE_VER_STOCK = "REPORTE_VER_STOCK"
export const NUEVO_REPORTE_SET_RODEOS = "NUEVO_REPORTE_SET_RODEOS"
export const SET_RANGO_FECHAS = "SET_RANGO_FECHAS"
export const FETCHED = "FETCHED"
export const NUEVO_REPORTE_SET_PRODUCTOS = "NUEVO_REPORTE_SET_PRODUCTOS"
export const EDITAR_MOVIMIENTO = "EDITAR_MOVIMIENTO"
export const EDITAR_PRECIO = "EDITAR_PRECIO"
export const EDITAR_PRECIO_PRODUCTO = "EDITAR_PRECIO_PRODUCTO"
export const EDITAR_HOTELERIA = "EDITAR_HOTELERIA"
export const EDITAR_SANIDAD = "EDITAR_SANIDAD"
export const NUEVO_REPORTE_SET_ALL_RODEOS = "NUEVO_REPORTE_SET_ALL_RODEOS"
export const NUEVO_REPORTE_SET_FILTROS = "NUEVO_REPORTE_SET_FILTROS"
export const NUEVO_REPORTE_SET_PRECIOS_GLOBAL = "NUEVO_REPORTE_SET_PRECIOS_GLOBAL"