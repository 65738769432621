import React, { Component } from 'react';
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { firebaseConnect } from 'react-redux-firebase'

import RequireProfile from './containers/RequireProfile';
import Route from './AuthRoute';

import Login from './components/Login';
import NavBar from './components/NavBar';
import MenuConfiguracion from './containers/MenuConfiguracion';
import Rodeos from './containers/Rodeos';
import RodeosCerrados from './containers/RodeosCerrados';
import Descargas from './containers/Descargas';
import Cargas from './containers/Cargas';
import Seguimiento from './containers/Seguimiento';
import Empresas from './containers/Empresas';
import Logout from './components/Logout';
import Home from './components/Home';
import Stock from './containers/Stock';
import Sanidad from './containers/Sanidad';
import MenuReportes from './containers/MenuReportes';
import Clima from './containers/Clima';

// import Demo from './containers/GenerarDemo';

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      user: null,
    };

  }

  componentDidMount () {
    this.props.firebase.auth().onAuthStateChanged(user => {
      this.setState({ user });
      
      if (user === null){
        this.setState({ user: null });
      }

    });
  }
  
  render() {
    const Navi = RequireProfile(NavBar);
    
    if (!this.state.user) return <Login user={this.state.user} />


    return (
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)} >
        <div>
          <div>
            <Navi /> 

            <Switch>
              <Route exact={true} path="/" component={RequireProfile(Home)} />
              <Route path="/configuracion" user={this.state.user} component={RequireProfile(MenuConfiguracion)} />
              <Route path="/descargas" user={this.state.user} component={RequireProfile(Descargas)} />
              <Route path="/cargas" user={this.state.user} component={RequireProfile(Cargas)} />
              <Route path="/rodeos" user={this.state.user} component={RequireProfile(Rodeos)} />
              <Route path="/rodeos_cerrados" user={this.state.user} component={RequireProfile(RodeosCerrados)} />
              <Route path="/seguimiento" user={this.state.user} component={RequireProfile(Seguimiento)} />
              <Route path="/empresas" user={this.state.user} component={RequireProfile(Empresas)} />
              <Route path="/stock" user={this.state.user} component={RequireProfile(Stock)} />
              <Route path="/sanidad" user={this.state.user} component={RequireProfile(Sanidad)} />
              <Route path="/reportes" user={this.state.user} component={RequireProfile(MenuReportes)} />
              <Route path="/clima" user={this.state.user} component={RequireProfile(Clima)} />
              <Route path="/logout" component={Logout} />
              {/* <Route path="/demo" user={this.state.user} component={RequireProfile(Demo)} /> */}
              <Redirect to='/' />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }

}

const wrappedApp = firebaseConnect()(App)

export default wrappedApp;