import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
//import { PersistGate } from 'redux-persist/lib/integration/react'
import 'firebase/firestore'; // add this to use Firestore

import store from './configureStore'

import App from './App';
// import App from './pdf_dietas/pdf';
import 'semantic-ui-css/semantic.min.css';
import 'react-table/react-table.css'
import './App.css';

// Setup react-redux so that connect HOC can be used
const Aplication = () => (
  <Provider store={store}>
      <App />
  </Provider>
);

render(<Aplication />, document.getElementById('root'));