import React, { Component } from 'react';
import { Grid, Table, Label, Dropdown, Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { fetchTratamientos, fetchCategorias, fetchCorrales, fetchRodeosActivos, fetchTratamientosProgramados, fetchProductosSanitarios, initialFetch, fetchCategoriasSanitarias } from '../actions';

import ReprogramarTratamiento from '../components/ReprogramarTratamiento';
import EliminarAlarmaTratamiento from '../components/EliminarAlarmaTratamiento';

import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

class RodeoSanidad extends Component {
  constructor (props) {
    super(props);

    this.perfil = props.perfil;

    this.tratamientos = props.tratamientos;
    this.rodeos = props.rodeosActivos;
    this.tratamientos_programados = props.tratamientos_programados;

    this.productosSanitarios = props.productosSanitarios;
    this.categoriasSanitarias = props.categoriasSanitarias;

    this.fetchTratamientos = props.fetchTratamientos;
    this.fetchProductosSanitarios = props.fetchProductosSanitarios;
    this.fetchCategoriasSanitarias = props.fetchCategoriasSanitarias;
    this.fetchTratamientosProgramados = props.fetchTratamientosProgramados;
    this.fetchRodeosActivos = props.fetchRodeosActivos;
    this.fetchCategorias = props.fetchCategorias;
    this.fetchCorrales = props.fetchCorrales;

    this.categorias = props.categorias || [];
    this.corrales = props.corrales || [];

    this.activarListener = props.initialFetch;

    this.hoy = new Date();
    this.state = { 
      listeners: props.listeners,
      fechaInicial: null,
      fechaFinal: moment(new Date( this.hoy.getTime() + (86400000  * 30) ))
     }
  }

  handleChangeFechaInicial = (date) => {
    this.setState({
        fechaInicial: date 
    })
  }

  handleChangeFechaFinal = (date)=> {
    this.setState({
        fechaFinal: date
    })
  }

  handleClose = () =>{
    this.setState({modalOpen: false})
  }
  handleCloseDos = () =>{
    this.setState({modalReprogramar: false})
  }
  handleCloseTres = () =>{
    this.setState({modalEliminarAlarma: false});
    this.fixBody();
  }
  componentWillMount = () => {
    const itemCorrales = { id: "corrales" };
    
    var oyenteCorrales = this.state.listeners.find(function(element) {
      return element.id === "corrales";
    });
    
    if(oyenteCorrales === undefined){
        this.activarListener(this.perfil, itemCorrales);
        this.fetchCorrales(this.perfil); 
    }

    const itemCategorias = { id: "categorias" };
    
    var oyenteCategorias = this.state.listeners.find(function(element) {
      return element.id === "categorias";
    });
    
    if(oyenteCategorias === undefined){
        this.activarListener(this.perfil, itemCategorias);
        this.fetchCategorias(this.perfil); 
    }

    const rodeosActivos = { id: "rodeos_activos" };
    
    var oyenteActivos = this.state.listeners.find(function(element) {
      return element.id === "rodeos_activos";
    });
    
    if(oyenteActivos === undefined){
        this.activarListener(this.perfil, rodeosActivos);
        this.fetchRodeosActivos(this.perfil); 
    }

    const itemCat = { id: "categoriasSanitarias" };
    
    var oyenteCat = this.state.listeners.find(function(element) {
      return element.id === "categoriasSanitarias";
    });

    if(oyenteCat === undefined){
        this.activarListener(this.perfil, itemCat);
        this.fetchCategoriasSanitarias(this.perfil);
    }

    const item = { id: "productosSanitarios" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "productosSanitarios";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.fetchProductosSanitarios(this.perfil);
    }

   
  }

  

  componentDidMount = () => {

    const item = { id: "tratamientos" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "tratamientos";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.fetchTratamientos(this.perfil);
    }

    const itemTP = { id: "tratamientos_programados" };
    
    var oyenteTP = this.state.listeners.find(function(element) {
      return element.id === "tratamientos_programados";
    });

    if(oyenteTP === undefined){
        this.activarListener(this.perfil, itemTP);
        this.fetchTratamientosProgramados(this.perfil);
    }
    
  }
  
  

  componentWillReceiveProps = (nextProps) => {
    this.rodeos = nextProps.rodeosActivos;
    this.categorias = nextProps.categorias || [];
    this.corrales = nextProps.corrales || [];
    this.categoriasSanitarias = nextProps.categoriasSanitarias;
    this.empresa = nextProps.empresa;
    this.perfil = nextProps.perfil;
    this.tratamientos = nextProps.tratamientos;
    this.tratamientos_programados = nextProps.tratamientos_programados;
    this.productosSanitarios = nextProps.productosSanitarios;
    this.db = nextProps.db;

    this.setState({listeners: nextProps.listeners})
  }


  fechaCorta = (date) => {
      var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
                    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    
      return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  render () {
    this.tratamientos.sort((a, b)=>{
      const fa = (a.fecha instanceof Object) ? a.fecha.toDate() : new Date(a.fecha);
      const fb = (b.fecha instanceof Object) ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return 1;
      if (fa < fb) return -1;
      return 0;
    })

    this.tratamientos_programados.sort((a, b)=>{
      const fa = (a.fecha instanceof Object) ? a.fecha.toDate() : new Date(a.fecha);
      const fb = (b.fecha instanceof Object) ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return 1;
      if (fa < fb) return -1;
      return 0;
    })

    let ingredientes_necesarios = [];
    this.tratamientos_programados.map( value => {
      let fechaTratamiento = value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha);
      fechaTratamiento.setHours(0);
      fechaTratamiento.setMinutes(0);
      fechaTratamiento.setSeconds(0);
      fechaTratamiento.setMilliseconds(0);

      let fechaInicial = new Date(this.state.fechaInicial);
      fechaInicial.setHours(0);
      fechaInicial.setMinutes(0);
      fechaInicial.setSeconds(0);
      fechaInicial.setMilliseconds(0);

      let fechaFinal = new Date(this.state.fechaFinal);
      fechaFinal.setHours(0);
      fechaFinal.setMinutes(0);
      fechaFinal.setSeconds(0);
      fechaFinal.setMilliseconds(0);

      if (fechaTratamiento.getTime() >= fechaInicial.getTime() && fechaTratamiento.getTime() <= fechaFinal.getTime()){
        const rodeo = this.rodeos.find( r => r.id === value.rodeo )
        if (rodeo !== undefined && rodeo !== null){
          value.detalle.forEach(element => {
            let ing = {};
            ing.fecha = value.fecha;
            ing.producto = element.producto;
            ing.cantidad = element.cantidad * rodeo.cabezas;
            ingredientes_necesarios.push(ing);
          });
        }
      }
    });

    
    const ingredientes_reducer = ingredientes_necesarios.reduce( (lista, ing) => {
      const val = ing.producto;
      lista[val] = lista[val] || { cantidad: 0, producto: ing.producto }
      lista[val].cantidad += ing.cantidad; 
      return lista;
    }, {});
      
    const necesarios = Object.keys(ingredientes_reducer).map(ing =>{
      var producto = this.productosSanitarios.find((p)=> p.id === ingredientes_reducer[ing].producto);
      var categoria = this.categoriasSanitarias.find((c)=> c.id === producto.categoria);
        return (<Table.Row key={ing}>
                  <Table.Cell>{`${producto.nombre}`}</Table.Cell>
                  <Table.Cell>{`${categoria.nombre}`}</Table.Cell>
                  <Table.Cell textAlign="right">{`${ingredientes_reducer[ing].cantidad}`}</Table.Cell>
                  <Table.Cell>{`${producto.medida}`}</Table.Cell>
                </Table.Row> )  
      
    })

    const listaProgramados = this.tratamientos_programados.map(
      (value) => {
        let f1 = value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha);
        f1.setHours(0);
        f1.setMinutes(0);
        f1.setSeconds(0);
        f1.setMilliseconds(0);
        let f2 = new Date();
        f2.setHours(0);
        f2.setMinutes(0);
        f2.setSeconds(0);
        f2.setMilliseconds(0);
        const alerta =  f2.getTime() - f1.getTime() >=0;

      let fechaTratamiento = value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha);
      fechaTratamiento.setHours(0);
      fechaTratamiento.setMinutes(0);
      fechaTratamiento.setSeconds(0);
      fechaTratamiento.setMilliseconds(0);

      let fechaInicial = new Date(this.state.fechaInicial);
      fechaInicial.setHours(0);
      fechaInicial.setMinutes(0);
      fechaInicial.setSeconds(0);
      fechaInicial.setMilliseconds(0);

      let fechaFinal = new Date(this.state.fechaFinal);
      fechaFinal.setHours(0);
      fechaFinal.setMinutes(0);
      fechaFinal.setSeconds(0);
      fechaFinal.setMilliseconds(0);


        if (fechaTratamiento.getTime() >= fechaInicial.getTime() && fechaTratamiento.getTime() <= fechaFinal.getTime()){
          
            const rodeo = this.rodeos.find( r => r.id === value.rodeo )
            if (rodeo !== undefined && rodeo !== null){
              const categoria = this.categorias.find(c => c.id === rodeo.categoria)
              const corral = this.corrales.find(c => c.id === rodeo.corral)
              return (
                <Table.Row key={value.id} >
                  <Table.Cell  style={{backgroundColor: alerta ? "red" : "white"}}>{ this.fechaCorta((value.fecha instanceof Object) ? value.fecha.toDate() : new Date(value.fecha)) }</Table.Cell>
                  <Table.Cell className="center aligned">{ rodeo.nombre }</Table.Cell>
                  <Table.Cell className="center aligned">{ rodeo.cabezas }</Table.Cell>
                  <Table.Cell className="center aligned">{ categoria.nombre }</Table.Cell>
                  <Table.Cell className="center aligned">{ corral !== undefined ? corral.nombre : "Sin corral" }</Table.Cell>
                  <Table.Cell className="right aligned">
                      <Table
                          celled 
                          striped
                          compact 
                          collapsing 
                          color={"teal"} 
                          valigned={"center"}
                          unstackable={true}
                          >
                          <Table.Body> 
                              {value.detalle.map((d)=> {
                                var producto = this.productosSanitarios.find((p)=> p.id === d.producto);
                                var categoria = this.categoriasSanitarias.find((c)=> c.id === producto.categoria);
                                  return (<Table.Row key={d.producto}>
                                            <Table.Cell>{`${d.cantidad} ${producto.medida}`}</Table.Cell>
                                            <Table.Cell>{`${categoria.nombre} - ${producto.nombre}`}</Table.Cell>
                                            <Table.Cell textAlign="right">{`Total: ${d.cantidad * rodeo.cabezas} ${producto.medida}`}</Table.Cell>
                                          </Table.Row> )  
                                }
                              )}
                          </Table.Body>
                      </Table>
                  </Table.Cell>
                  <Table.Cell><ReprogramarTratamiento alarma={value} productos={this.productosSanitarios} categorias={this.categoriasSanitarias}/></Table.Cell>
                  <Table.Cell><EliminarAlarmaTratamiento alarma={value} /></Table.Cell>
                </Table.Row>   
              )
            }
        }
      }
  )
   
    return(
 
          <Grid style={{padding: 20, margin: 2}}>
              
              <Grid.Row centered>
                      <Table
                          celled 
                          compact 
                          collapsing 
                          color={"blue"} 
                          valigned={"center"}
                          unstackable={true}
                          >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="center aligned" colSpan='4'>Productos necesarios</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                              <Table.HeaderCell className="center aligned" colSpan='4'>
                                <Form>
                                  <Form.Group widths='equal' className="ui center aligned grid">
                                    <Form.Group grouped>
                                          <label>Desde Fecha</label>
                                          <Form.Field>
                                              <DatePicker
                                                  maxDate={moment(this.state.fechaFinal)}
                                                  dateFormat="DD/MM/YYYY"
                                                  selected={this.state.fechaInicial}
                                                  onChange={this.handleChangeFechaInicial}
                                              />
                                          </Form.Field>
                                      </Form.Group>
                                      <Form.Group grouped>
                                          <label>Hasta Fecha</label>
                                          <Form.Field>
                                              <DatePicker
                                                  minDate={moment(this.state.fechaInicial)}
                                                  dateFormat="DD/MM/YYYY"
                                                  selected={this.state.fechaFinal}
                                                  onChange={this.handleChangeFechaFinal}
                                          />
                                          </Form.Field>
                                      </Form.Group>
                                    </Form.Group>
                                  </Form>
                              </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                              <Table.HeaderCell className="center aligned">Producto</Table.HeaderCell>
                              <Table.HeaderCell className="center aligned">Categoria</Table.HeaderCell>
                              <Table.HeaderCell className="center aligned">Cantidad</Table.HeaderCell>
                              <Table.HeaderCell className="center aligned">Medida</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body> 
                            {necesarios}
                          </Table.Body> 
                        </Table>
              </Grid.Row>


              <Grid.Row centered>
                
                {listaProgramados.length > 0 &&
                      <Table
                      celled 
                      compact 
                      collapsing 
                      color={"black"} 
                      valigned={"center"}
                      unstackable={true}
                      >
                      <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell className="center aligned" colSpan='8'>Tratamientos Programados</Table.HeaderCell>
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell className="center aligned">Fecha</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Rodeo</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Cabezas</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Categoria</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Corral</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Detalle</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                            <Table.HeaderCell className="center aligned"></Table.HeaderCell>
                          </Table.Row>
                      </Table.Header>
                      <Table.Body> 
                          {listaProgramados}
                      </Table.Body>
                  </Table>
                }
            </Grid.Row>
          </Grid>
                    
    )
  }

}


function mapStateToProps(state, props) {
  return {
    tratamientos: state.personalStore.tratamientos,
    categorias: state.personalStore.categorias,
    corrales: state.personalStore.corrales,
    productosSanitarios: state.personalStore.productosSanitarios,
    categoriasSanitarias: state.personalStore.categoriasSanitarias,
    tratamientos_programados: state.personalStore.tratamientos_programados,
    listeners: state.personalStore.listeners,
    rodeosActivos: state.personalStore.rodeosActivos
  };
}

export default connect(
  mapStateToProps,
  { fetchTratamientos, fetchCategorias, fetchCorrales, fetchRodeosActivos, fetchProductosSanitarios, fetchCategoriasSanitarias, fetchTratamientosProgramados, initialFetch }
)(RodeoSanidad);  