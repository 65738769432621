import React, { Component } from 'react';

import { Button, Modal, Icon, Message } from 'semantic-ui-react'

class ConfNotificacionesDelete extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;

    this.db = props.db;

    this.state = {
      modalOpen: false
    };

    this.save = this.save.bind(this);

  }

  save = () => {
    var _this = this;
      this.db.collection("empresas").doc(this.perfil.empresa).collection('confnotificaciones').doc(this.id).delete().then(function() {
          _this.setState({ modalOpen: false, alerta: false});
      }).catch(function(error) {
          console.error("Error removing document: ", error);
      });
      this.setState({ cargando: false, modalOpen: false })
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }


  render () {
    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='red' size={'small'} 
        icon="delete" className="ui center aligned segment"/> }
        size={'small'}
        >
        <Modal.Header>Borrar correo a notificar?</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Message
                error
                header='Borrar correo de notificacion'
                list={[
                    'Confirma eliminar el correo configurado a recibir notificaciones?',
                ]}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Borrar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ConfNotificacionesDelete;