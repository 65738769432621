import React from "react";
import ReactExport from "react-export-excel";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import format from "date-format";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//format("dd/MM/yyyy", new Date(m.fecha))

export const ExelReporteVentas = ({ ventas }) => {
  return (
    <ExcelFile
      element={
        <button
          className="ui green small right floated button"
          style={{ marginLeft: "5px" }}
        >
          Exportar a Excel
        </button>
      }
    >
      <ExcelSheet data={ventas} name="Ventas">
        <ExcelColumn label="Empresa" value="empresa" />
        <ExcelColumn label="Estado" value="estado" />
        <ExcelColumn label="Destino" value="destino" />
        <ExcelColumn label="Cliente" value="cliente" />
        <ExcelColumn label="Fecha Vta" value="fecha" />
        <ExcelColumn label="Mes Vta" value="mesVenta" />
        <ExcelColumn label="Año Vta" value="anoVenta" />
        <ExcelColumn label="Plazo" value="plazo" />
        <ExcelColumn label="Fecha Cobro" value="fechaCobro" />
        <ExcelColumn label="Mes Cobro" value="mesCobro" />
        <ExcelColumn label="Año Cobro" value="anoCobro" />
        <ExcelColumn label="Tropa" value="rodeo" />
        <ExcelColumn label="Categoria" value="categoria" />
        <ExcelColumn label="Cabezas" value="cabezas" />
        <ExcelColumn label="Kilos" value="kilos" />
        <ExcelColumn label="Total Kilos" value="totalKilos" />
        <ExcelColumn label="Precio" value="precio" />
        <ExcelColumn label="Subtotal" value="total" />
        <ExcelColumn label="Total (+iva)" value="totalMasIva" />
        <ExcelColumn label="Consumo Estimado" value="consumoEstimado" />
      </ExcelSheet>
    </ExcelFile>
  );
};
