import React, { Component } from 'react'
import { Switch, Redirect, Route} from "react-router-dom";

import { Link } from "react-router-dom";
import { Menu, Icon } from 'semantic-ui-react'

import Corrales from './Corrales';
import Mixers from './Mixers';
import Productos from './Productos';
import Dietas from './Dietas';
import Usuarios from './Usuarios';

import Categorias from './Categorias';
import Clientes from './Clientes';

import ProdSanitarios from './ProdSanitarios';

import ConfNotificaciones from './ConfNotificaciones';
import RequireProfile from './RequireProfile';
import Logout from '../components/Logout';

class MenuConfiguracion extends Component {

  static isPrivate = true;

  constructor(props){
    super(props);

    this.perfil = props.perfil;
    this.state={
      activeItem: "dietas"
    };

    this.componente = props.componente;
  }

  componentWillReceiveProps = (nextProps) => {
    this.perfil = nextProps.perfil;
  }
  

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    if(!this.perfil){
      return (<span>cargando...</span>)
    }

    if (this.perfil.rol !== 'admin'){
      return <Redirect to="../" push={true} />
    }

    if(this.perfil){
      if(!this.perfil.activo){
        return (<Logout />)
      }
    }

    const { activeItem } = this.state
    return (
      <div>
         <Menu fluid widths={4} inverted>

            <Menu.Item  as={Link} 
                to='./productos'
                name='productos'
                active={activeItem === 'productos'}
                onClick={this.handleItemClick}>
                <Icon  name='product hunt' />
                Productos
            </Menu.Item>

            <Menu.Item  as={Link} 
                to='./dietas'
                name='dietas'
                active={activeItem === 'dietas'}
                onClick={this.handleItemClick}>
                <Icon  name='law' />
                Dietas
            </Menu.Item>

            <Menu.Item  as={Link} 
                to='./prodsanidad'
                name='prodsanidad'
                active={activeItem === 'prodsanidad'}
                onClick={this.handleItemClick}>
                <Icon  name='medkit' />
                Productos Sanitarios
            </Menu.Item>

            <Menu.Item  as={Link} 
                to='./categorias'
                name='categorias'
                active={activeItem === 'categorias'}
                onClick={this.handleItemClick}>
                <Icon  name='tag' />
                Categorias
            </Menu.Item>


          </Menu>

          <Menu fluid widths={5} inverted>

          <Menu.Item  as={Link} 
                to='./mixers'
                name='mixers'
                active={activeItem === 'mixers'}
                onClick={this.handleItemClick}>
                <Icon  name='modx' />
                Mixers
            </Menu.Item>
            
          <Menu.Item as={Link} 
                  to='./corrales'
                  name='corrales'
                  active={activeItem === 'corrales'}
                  onClick={this.handleItemClick}>
                <Icon  name='object ungroup' />
                Corrales
            </Menu.Item>

            <Menu.Item  as={Link} 
                to='./clientes'
                name='clientes'
                active={activeItem === 'clientes'}
                onClick={this.handleItemClick}>
                <Icon  name='user circle outline' />
                Clientes
            </Menu.Item>
            
            
            <Menu.Item  as={Link} 
                to='./notificaciones'
                name='notificaciones'
                active={activeItem === 'notificaciones'}
                onClick={this.handleItemClick}>
                <Icon  name='alarm' />
                Notificaciones
            </Menu.Item>


            <Menu.Item  as={Link} 
                to='./usuarios'
                name='usuarios'
                active={activeItem === 'usuarios'}
                onClick={this.handleItemClick}>
                <Icon  name='users' />
                Usuarios
            </Menu.Item>

          </Menu>
          {/* <Segment> */}
            <Switch>
              <Route exact={true} path="/configuracion"  component={RequireProfile(Corrales)} />
              <Route path="/configuracion/corrales" component={RequireProfile(Corrales)} />
              <Route path="/configuracion/mixers" component={RequireProfile(Mixers)} />
              <Route path="/configuracion/productos" component={RequireProfile(Productos)} />
              <Route path="/configuracion/prodsanidad" component={RequireProfile(ProdSanitarios)} />
              <Route path="/configuracion/dietas" component={RequireProfile(Dietas)} />
              <Route path="/configuracion/usuarios" component={RequireProfile(Usuarios)} />
              <Route path="/configuracion/notificaciones" component={RequireProfile(ConfNotificaciones)} />
              <Route path="/configuracion/categorias" component={RequireProfile(Categorias)} />
              <Route path="/configuracion/clientes" component={RequireProfile(Clientes)} />
              <Redirect to='/' />
            </Switch>
          {/* </Segment> */}
      </div>
    )
  }
}

export default MenuConfiguracion