const initialState = {
    campo: null,
    fechaInicio: null,
    fechaFinal: null,
    allRodeos: true,
    listaRodeos: [],
    allCategorias: true,
    listaCategorias: [],
    filtros: []
};

const filtrosTropasActivas = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_TROPAS_ACTIVAS':
            return Object.assign({}, state, {
                ...state,
                ...action
            });
        default:
            return state
    }
}

export default filtrosTropasActivas;