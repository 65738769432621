import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class EditarPrecipitacion extends Component {
  constructor (props) {
    super(props);

    this.tiempo = props.tiempo;
    this.perfil = props.perfil;
    this.db = props.db;

    this.state = {
      precipitacion: props.tiempo.precipitacion || 0,
      modalOpen: false
    };

  }

  componentWillReceiveProps = (nextProps) => {
    this.tiempo = nextProps.tiempo;
    this.setState({
        precipitacion: nextProps.tiempo.precipitacion || 0
    })
  }

  save = () => {
    this.db.collection("empresas").doc(this.perfil.empresa).collection("clima").doc(this.tiempo.id).update({
        precipitacion: Number(this.state.precipitacion)
    })

    this.setState({
        modalOpen: false
    })

  }

  render () {

    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'mini'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='blue' size={'tiny'} 
        icon="tint" className="ui center aligned segment"/> }
        >
        <Modal.Header>Editar Precipitacion</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Precipitacion" id="precipitacion" type="number" min="0" step="0.1"  value={this.state.precipitacion} onChange={(e, { value }) => this.setState({ precipitacion: value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={ (e, value)=> this.setState({ modalOpen: false, precipitacion: this.tiempo.precipitacion || 0 })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

export default EditarPrecipitacion;