import React, { Component } from "react";
import { Form, Button, Modal, Icon, Message } from 'semantic-ui-react/dist/commonjs';

import 'react-datepicker/dist/react-datepicker.css';

export default class EditarVenta extends Component {
    constructor(props) {
        super(props);

        this.perfil = props.perfil;
        this.db = props.db;

        this.recria = props.recria || false;
        this.optionsClientes = props.optionsClientes;
        this.venta = props.venta;
        this.data = props.venta;
        this.state = {
            modalOpen: false,
            kilos: props.venta.kilos || 0,
            desbaste: this.data.desbaste || 0,
            precio: props.venta.precio || 0,
            plazo: props.venta.plazo || 0,
            cliente: props.venta.cliente || ""
        }
    }


    componentWillReceiveProps = (nextProps) => {
        this.recria = nextProps.recria || false;
        this.venta = nextProps.venta;
        this.optionsClientes = nextProps.optionsClientes;

        this.data = nextProps.venta;

        this.setState({
            kilos: nextProps.venta.kilos || 0,
            desbaste: this.data.desbaste || 0,
            precio: nextProps.venta.precio || 0,
            plazo: nextProps.venta.plazo || 0,
            cliente: nextProps.venta.cliente || ""
        })
    }


    save = () => {
        // console.log(this.state.desbaste)
        this.db.collection("empresas").doc(this.perfil.empresa).collection("movrodeos").doc(this.venta.movId).update({
            kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
            desbaste: this.state.desbaste,
            precio: this.state.precio,
            plazo: this.state.plazo,
            cliente: this.state.cliente
        })

        this.setState({
            modalOpen: false
        })
    }

    formatear = (num, decimales = 0) => {
        var signo = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
    }
    render() {

        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size={'small'}
                trigger={<Button floated='center' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='green' >
                    <Icon name='edit' /> Editar
                      </Button>}
            >

                <Modal.Header>{`Editar Movimiento del Rodeo: ${this.venta.nombreRodeo} `}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Group widths='equal'>

                                <Form.Field>
                                    <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={`${this.formatear(this.state.kilos, 2)}`} onChange={(e, { value }) => this.setState({ kilos: value })} />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={`${this.formatear(this.state.desbaste, 2)}`} onChange={(e, { value }) => this.setState({ desbaste: value })} />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input readonly label="Total peso neto" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Form.Input readonly label="Cabezas" id="total" type="text" placeholder="-" value={this.venta.cabezas} />
                                </Form.Field>

                                {!this.recria &&
                                    <Form.Field>
                                        <Form.Input label="Precio neto x kilo" id="precio" type="number" min="0" step="0.5" placeholder="-" value={this.state.precio} onChange={(e, { value }) => this.setState({ precio: value })} />
                                    </Form.Field>
                                }
                                {!this.recria &&
                                    <Form.Field>
                                        <Form.Input readonly label="Total Neto $" id="total" type="text" placeholder="-" value={this.formatear(this.venta.cabezas * this.state.precio * (this.state.kilos * (1 - (this.state.desbaste / 100))), 2)} />
                                    </Form.Field>
                                }
                            </Form.Group>
                            {!this.recria &&
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input label="Plazo de venta (en dias)" id="precio" type="number" min="0" step="1" placeholder="-" value={this.state.plazo} onChange={(e, { value }) => this.setState({ plazo: value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Select label="Cliente" options={this.optionsClientes} placeholder='Seleccionar Cliente' value={this.state.cliente} onChange={(e, { value }) => this.setState({ cliente: value })} />
                                    </Form.Field>
                                </Form.Group>
                            }

                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={(e, value) => this.setState({
                        modalOpen: false,
                        kilos: this.venta.kilos || 0,
                        desbaste: this.data.desbaste || 0,
                        precio: this.venta.precio || 0,
                        plazo: this.venta.plazo || 0,
                        cliente: this.venta.cliente || ""
                    })} inverted>
                        Cancelar
                </Button>
                    <Button color='green' onClick={this.save} inverted>
                        <Icon name='checkmark' /> Guardar
                </Button>
                    {this.state.alerta &&
                        <Message
                            error
                            header='Verificar Datos'
                        />
                    }
                </Modal.Actions>
            </Modal>
        )
    }
}