import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { Link } from "react-router-dom";
import { Segment, Menu, Icon } from "semantic-ui-react";

import RequireProfile from "./RequireProfile";
import ReportesRodeos from "../reportes/Reportes";
import ReporteStock from "./ReporteStock";
import ReporteVentas from "./ReporteVentas";
import ReporteTratamientos from "./ReporteTratamientos";
import ReporteProgramasSanitarios from "./ReporteProgramasSanitarios";

import SeleccionarRodeos from "../reportes/SeleccionarRodeos";
import SeleccionarFechas from "../reportes/SeleccionarFechas";
import SetearMovimientos from "../reportes/SetearMovimientos";
// import SetearProductos from '../reportes/SetearProductos'
import SetearProductos from "../reportes/SetearProductosv2";
import Resultados from "../reportes/Resultados";

import Logout from "../components/Logout";

class MenuReportes extends Component {
  static isPrivate = true;

  constructor(props) {
    super(props);

    this.perfil = props.perfil;
    this.state = {
      activeItem: "reporte_rodeos",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.perfil = nextProps.perfil;
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    if (!this.perfil) {
      return <span>cargando...</span>;
    }

    if (this.perfil.rol !== "admin") {
      return <Redirect to="../" push={true} />;
    }

    if (this.perfil) {
      if (!this.perfil.activo) {
        return <Logout />;
      }
    }

    const { activeItem } = this.state;
    return (
      <div>
        <Menu fluid widths={5} inverted>
          <Menu.Item
            as={Link}
            to="/reportes/reporte_rodeos"
            name="reporte_rodeos"
            active={activeItem === "reporte_rodeos"}
            onClick={this.handleItemClick}
          >
            <Icon name="tags" />
            Rodeos
          </Menu.Item>

          {/* <Menu.Item  as={Link} 
                to='/reportes/reporte_stock'
                name='stock'
                active={activeItem === 'stock'}
                onClick={this.handleItemClick}>
                <Icon  name='balance scale' />
                Stock
            </Menu.Item> */}

          <Menu.Item
            as={Link}
            to="/reportes/reporte_tratamientos"
            name="tratamientos"
            active={activeItem === "tratamientos"}
            onClick={this.handleItemClick}
          >
            <Icon name="calendar alternate" />
            Tratamientos Sanitarios
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/reportes/reporte_programas_sanitarios"
            name="programa"
            active={activeItem === "programa"}
            onClick={this.handleItemClick}
          >
            <Icon name="calendar alternate outline" />
            Programa Sanitario
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/reportes/ventas"
            name="ventas"
            active={activeItem === "ventas"}
            onClick={this.handleItemClick}
          >
            <Icon name="industry" />
            Ventas, Recria y Demanda de Consumo
          </Menu.Item>
        </Menu>
        {/* <Segment> */}
        <Switch>
          {/* <Route exact={true} path="/reportes"  component={RequireProfile(ReportesRodeos)} /> */}
          <Route
            exact={true}
            path="/reportes"
            component={RequireProfile(SeleccionarRodeos)}
          />
          <Route
            path="/reportes/nuevo_reporte"
            component={RequireProfile(SeleccionarRodeos)}
          />
          <Route
            path="/reportes/seleccionar_fecha"
            component={RequireProfile(SeleccionarFechas)}
          />
          <Route
            path="/reportes/setear_movimientos"
            component={RequireProfile(SetearMovimientos)}
          />
          <Route
            path="/reportes/setear_productos"
            component={RequireProfile(SetearProductos)}
          />
          <Route
            path="/reportes/resultados"
            component={RequireProfile(Resultados)}
          />

          {/* <Route
            path="/reportes/reporte_rodeos"
            component={RequireProfile(ReportesRodeos)}
          /> */}
          <Route
            path="/reportes/reporte_rodeos"
            component={RequireProfile(SeleccionarRodeos)}
          />

          <Route
            path="/reportes/reporte_stock"
            component={RequireProfile(ReporteStock)}
          />
          <Route
            path="/reportes/reporte_tratamientos"
            component={RequireProfile(ReporteTratamientos)}
          />
          <Route
            path="/reportes/reporte_programas_sanitarios"
            component={RequireProfile(ReporteProgramasSanitarios)}
          />
          <Route
            path="/reportes/ventas"
            component={RequireProfile(ReporteVentas)}
          />
          <Redirect to="/" />
        </Switch>
        {/* </Segment> */}
      </div>
    );
  }
}

export default MenuReportes;
