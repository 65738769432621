import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import filtrosDescargas from '../reducers/filtrosDescargas';


export default function(ComposedComponent) {
  
    class RequireProfile extends Component {

        static propTypes = {
            perfil: PropTypes.object,
        }
        
        render() {
            const { perfil } = this.props;

            if (!isLoaded(perfil)){
                return (
                    <div>

                    </div>
                  )
            }

            return <ComposedComponent {...this.props} />
        }
        
    }

const wrappedComponent = firestoreConnect()(RequireProfile)
    return connect(
        ({ firebase: { auth, profile }, personalStore }) => 
        ({
            perfil: profile,
            auth: auth,
            fechaInicio: personalStore.filtrosSeguimiento.fechaInicio,
            fechaCargaInicio: personalStore.filtrosTablero.fechaInicio
        })
    ) (wrappedComponent);
}