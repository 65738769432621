const initialState = {
    fechaInicio: null,
    fechaFinal: null,
    allCorrales: true,
    listaCorrales: [],
    allRodeos: true,
    listaRodeos: [],
    allProductos: true,
    listaProductos: [],
    allCategorias: true,
    listaCategorias: [],
    filtros: []
};

const filtrosReportesProgramasSanitarios = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_REPORTES_PROGRAMAS_SANITARIOS':
            return Object.assign({}, state, {
                ...state,
                ...action
            });
        default:
            return state
    }
}

export default filtrosReportesProgramasSanitarios;