const productos = (state = [], action) => {
  switch (action.type) {
    case "ADD_PRODUCTO":
      return action.productos;
    default:
      return state;
  }
};

export default productos;
