import React from 'react';
import { Table, Grid } from 'semantic-ui-react';

import GraficoConsumo from '../containers/GraficoConsumo';
import RodeoMov from './RodeoMov';

import ButtonMenu from './common/ButtonMenu';

import ModalMultiple from './filtros/ModalMultiple';

export default class ListaRodeosCerrados extends React.PureComponent {

    constructor(props) {
        super(props)
        this.perfil = props.perfil

    }

    componentWillReceiveProps = (nextProps) => {
        this.perfil = nextProps.perfil
    }

    render() {

        let fecha = (new Date());
        fecha.setHours(0);
        fecha.setMinutes(0);

        let listaActivos = [];

        listaActivos = this.props.rodeosactivos.map(
            (value) => {

                const corral = this.props.corrales.find((item) => {
                    return item.id === value.corral;
                })

                const dieta = this.props.dietas.find((item) => {
                    return item.id === value.dieta;
                })

                value.meta = value.materiaSeca * value.cabezas * dieta.talcual;

                const categoria = this.props.categorias.find((item) => {
                    return item.id === value.categoria;
                })

                return (
                    <Table.Row key={value.id} >
                        <Table.Cell className="center aligned">
                            <ButtonMenu
                                rodeo={value}
                                movimientos={<RodeoMov clientes={this.props.clientes} rodeos_inactivos={this.props.rodeosInactivos} corral={corral} dieta={dieta} categoria={categoria} rodeos={this.props.rodeosactivos} corrales={this.props.corrales} empresa={this.props.empresa} rodeo={value} movrodeos={this.props.movrodeos} db={this.props.db} perfil={this.props.perfil} />}
                               
                            />
                        </Table.Cell>
                        <Table.Cell className="center aligned">
                            <GraficoConsumo perfil={this.perfil} clima={this.props.clima} dietas={this.props.dietas} categoria={categoria.nombre} tropa={value.nombre} rodeo={value.id} />
                        </Table.Cell>
                        <Table.Cell>{value.nombre} </Table.Cell>
                        <Table.Cell>{categoria.nombre} </Table.Cell>
                        <Table.Cell>{dieta.nombre} </Table.Cell>
                       
                    </Table.Row>
                )
            }
        )

        return (

            <Grid style={{ paddingBottom: 100, marginTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                <Grid.Row centered>

                    <Table
                        celled
                        striped
                        compact
                        collapsing
                        color={"teal"}
                        valigned={"center"}
                        unstackable={true}
                    >
                        <Table.Header>


                            <Table.Row textAlign={'center'}>
                                <Table.HeaderCell className="center aligned">Menu</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">Consumo</Table.HeaderCell>

                                <Table.HeaderCell>Tropa</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <ModalMultiple opciones={this.props.categoriasF}
                                        label={"Categoria"}
                                        onSelected={this.props.onSelectedCategoria}
                                        onSelectedAll={this.props.onSelectedAllCategorias}
                                        todo={this.props.filtered.allCategorias}
                                        seleccion={this.props.filtered.listaCategorias} />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <ModalMultiple opciones={this.props.dietasF}
                                        label={"Dieta"}
                                        onSelected={this.props.onSelectedDieta}
                                        onSelectedAll={this.props.onSelectedAllDietas}
                                        todo={this.props.filtered.allDietas}
                                        seleccion={this.props.filtered.listaDietas} />
                                </Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {listaActivos}
                        </Table.Body>
                    </Table>

                </Grid.Row>
            </Grid>
        )

    }
}