import React, { Component } from 'react';
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchConfNotificacion } from '../actions';

import ConfNotificacionesNew from '../components/ConfNotificacionesNew';
import ConfNotificacionesEdit from '../components/ConfNotificacionesEdit';
import ConfNotificacionesDelete from '../components/ConfNotificacionesDelete';

class ConfNotificaciones extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.buscarConf = props.actions.fetchConfNotificacion;
    this.activarListener = props.actions.initialFetch;
    
    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.state = ({
      notificaciones: props.notificaciones || [],
      listeners: props.listeners || []
    })
  }

  componentWillMount = () => {
    const item = { id: "confnotificaciones" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "confnotificaciones";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarConf(this.perfil); 
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.estado) this.setState({ estado: nextProps.estado.estate});
    if(nextProps.notificaciones) this.setState({ notificaciones: nextProps.notificaciones});
    if(nextProps.listeners) this.setState({ listeners: nextProps.listeners});
  }
 
  
  render() {
     
    const lista = this.state.notificaciones.map(
            (value) => {
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre}</Table.Cell>
                    <Table.Cell>{value.email}</Table.Cell>
                    <Table.Cell><ConfNotificacionesEdit db={this.db} perfil={this.perfil} id={value.id} notificacion={value} /></Table.Cell>
                    <Table.Cell><ConfNotificacionesDelete db={this.db} perfil={this.perfil} id={value.id} /></Table.Cell>
                  </Table.Row>   
                )
          }
        )
    
    return (
      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>
                    <span>Notificar eventos a los siguientes correos</span>
                    <ConfNotificacionesNew db={this.db} perfil={this.perfil}/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">Editar</Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">Borrar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    notificaciones: state.personalStore.confnotificaciones,
    listeners: state.personalStore.listeners
  };
}

function mapDispatchToProps(dispatch, props) {
  
    const actions = {
        fetchConfNotificacion: bindActionCreators(fetchConfNotificacion, dispatch),
        initialFetch: bindActionCreators(initialFetch, dispatch),
    };
    
    return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfNotificaciones);