import React, { Component } from "react";
import { connect } from "react-redux";
import { EDITAR_PRECIO } from "./actions";
import { Input } from "semantic-ui-react";

class InputPrecio extends Component {
  handleChange = (value) => {
    const { dispatch, id } = this.props;
    // if (!isNaN(value)){
    dispatch({
      type: EDITAR_PRECIO,
      payload: {
        id,
        value: value,
        // value: Number(value)
      },
    });
    // }
  };

  render() {
    return (
      <Input
        style={{ width: "150px" }}
        fluid
        value={this.props.value}
        icon="money"
        placeholder="Precio..."
        onChange={(val, { value }) => this.handleChange(value)}
      />
    );
  }
}

export default connect()(InputPrecio);
