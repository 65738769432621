import React, { Component } from "react";
import firebase from "../firebase";
import { Table, Grid } from "semantic-ui-react";

import { connect } from "react-redux";

import FiltroFechas from "../components/filtros/ModalFechas";
import ModalMultiple from "../components/filtros/ModalMultiple";

import StockNew from "../components/StockNew";
import StockEntrePeriodos from "../components/StockEntrePeriodos";

import Api from "./../api";

const db = firebase.firestore();
const api = new Api();

const prettyDate = (date) => {
  var months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  var minutos = date.getMinutes();
  if (minutos < 10) minutos = "0" + minutos;

  return (
    date.getDate() +
    " " +
    months[date.getMonth()] +
    " " +
    new Date(date).getFullYear() +
    " " +
    date.getHours() +
    ":" +
    minutos
  );
};

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

class Stock extends Component {
  static isPrivate = true;

  constructor(props) {
    super(props);

    this.perfil = props.perfil;

    this.state = {
      listeners: props.listeners || [],
    };

    this.productosF = [];

    this.filtrosFijos = ["productos"];

    this.filtered = {
      fechaInicio: props.filtrosStock.fechaInicio,
      fechaFinal: props.filtrosStock.fechaFinal,
      listaProductos: props.filtrosStock.listaProductos,
      allProductos: props.filtrosStock.allProductos,
      filtros: props.filtrosStock.filtros,
    };
  }

  componentDidMount = async () => {
    this.getStock();
  };

  getStock = () => api.getStock(this.props.perfil.empresa, this.props.dispatch);

  componentWillReceiveProps = (nextProps) => {
    this.filtered = {
      fechaInicio: nextProps.filtrosStock.fechaInicio,
      fechaFinal: nextProps.filtrosStock.fechaFinal,
      listaProductos: nextProps.filtrosStock.listaProductos,
      allProductos: nextProps.filtrosStock.allProductos,
      filtros: nextProps.filtrosStock.filtros,
    };
  };

  onChangeFechaInicio = (fechaInicio) => {
    this.filtered.fechaInicio = fechaInicio;
    this.props.dispatch({
      type: "SET_FILTROS_STOCK",
      ...this.filtered,
    });
  };
  onChangeFechaFinal = (fechaFinal) => {
    this.filtered.fechaFinal = fechaFinal;
    this.props.dispatch({
      type: "SET_FILTROS_STOCK",
      ...this.filtered,
    });
  };
  onSelectedAllProductos = (opciones) => {
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "productos");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "productos"
        );
      }
    }

    let productos = [];
    if (!this.filtered.allProductos) {
      Object.keys(opciones).map((opcion) => {
        productos.push(opciones[opcion].id);
      });

      this.filtered.listaProductos = productos;
      this.filtered.allProductos = true;
    } else {
      this.filtered.filtros.push({ nombre: "productos", hora: new Date() });
      this.filtered.allProductos = false;
      this.filtered.listaProductos = [];
    }
    this.props.dispatch({
      type: "SET_FILTROS_STOCK",
      ...this.filtered,
    });
  };

  onSelectedProducto = (id, opciones) => {
    this.filtered.filtros.push({ nombre: "productos", hora: new Date() });

    let index = this.filtered.listaProductos.indexOf(id);

    if (index > -1) {
      this.filtered.listaProductos = this.filtered.listaProductos.filter(
        (e) => e !== id
      );
      this.filtered.allProductos = false;
    } else {
      let productos = [];

      if (this.filtered.allProductos) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) productos.push(opciones[opcion].id);
        });

        this.filtered.listaProductos = productos;
        this.filtered.allProductos = false;
      } else {
        this.filtered.listaProductos = this.filtered.listaProductos.concat(id);
        this.filtered.allProductos = false;
      }
    }
    this.props.dispatch({
      type: "SET_FILTROS_STOCK",
      ...this.filtered,
    });
  };

  aplicarFiltroProductos = (listaDescarga) => {
    this.productosF = [];
    //filtrando por dietas
    listaDescarga = listaDescarga.filter((d) => {
      let producto = {};
      producto.id = d.producto;
      producto.label = this.props.stock.productos.find(
        (r) => r.id === d.producto
      ).nombre;
      this.productosF[producto.id] = producto;

      let bandera = true;
      if (!this.filtered.allProductos) {
        let index = this.filtered.listaProductos.indexOf(d.producto);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  render() {
    if (!this.props.stock) return <div>Consultando stock...</div>;

    let listaFiltros = this.filtered.filtros.reduce((filtro, item) => {
      let val = item.nombre;
      filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
      return filtro;
    }, {});

    listaFiltros = Object.keys(listaFiltros).sort((a, b) => {
      if (listaFiltros[a].hora > listaFiltros[b].hora) {
        return 1;
      }

      if (listaFiltros[a].hora < listaFiltros[b].hora) {
        return -1;
      }
      return 0;
    });

    this.filtrosFijos.forEach((fijo) => {
      let index = listaFiltros.indexOf(fijo);
      if (index < 0) listaFiltros.push(fijo);
    });

    var _this = this;

    let listaMovStock = this.props.stock.movimientos || [];

    listaMovStock.sort((a, b) => {
      const fa = a.fecha.toDate ? a.fecha.toDate() : new Date(a.fecha);
      const fb = b.fecha.toDate ? b.fecha.toDate() : new Date(b.fecha);
      if (fa > fb) return -1;
      if (fa < fb) return 1;
      return 0;
    });

    listaFiltros.forEach((f) => {
      if (f === "productos")
        listaMovStock = _this.aplicarFiltroProductos(
          this.props.stock.movimientos
        );
    });

    listaMovStock = listaMovStock.filter((d) => {
      var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha);

      let banderaFechaInicio = true;
      if (this.filtered.fechaInicio !== null) {
        var fechaInicio = new Date(this.filtered.fechaInicio);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filtered.fechaFinal !== null) {
        var fechaFin = new Date(this.filtered.fechaFinal);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin;
    });

    const listaProductos = this.props.stock.productos.map((value) => {
      return (
        <Table.Row key={value.id}>
          <Table.Cell>{value.nombre}</Table.Cell>
          <Table.Cell>{formatear(value.stock, 2)}</Table.Cell>
          <Table.Cell>{formatear(value.minimo, 2)}</Table.Cell>
          {value.fecha && (
            <Table.Cell>
              {" "}
              {value.fecha.toDate && value.fecha.toDate()
                ? new Date(value.fecha)
                : "-"}{" "}
            </Table.Cell>
          )}
        </Table.Row>
      );
    });

    const lista = listaMovStock.map((value) => {
      const producto = this.props.stock.productos.find((item) => {
        return item.id === value.producto;
      });

      if (this.perfil !== undefined) {
        return (
          <Table.Row key={value.id}>
            <Table.Cell>
              {" "}
              {prettyDate(
                value.fecha.toDate
                  ? value.fecha.toDate()
                  : new Date(value.fecha)
              )}{" "}
            </Table.Cell>
            <Table.Cell>{producto.nombre}</Table.Cell>
            <Table.Cell>{value.concepto}</Table.Cell>
            <Table.Cell>{value.detalle}</Table.Cell>
            <Table.Cell className="right aligned">
              {formatear(value.entrada, 2)}
            </Table.Cell>
            <Table.Cell className="right aligned">
              {formatear(value.salida, 2)}
            </Table.Cell>
            {/* <Table.Cell className="right aligned" onClick={()=> value.doc.ref.delete()}>borrar</Table.Cell> */}
          </Table.Row>
        );
      }
      return <div key={value.id}></div>;
    });

    return (
      <Grid celled style={{ padding: 20, margin: 2 }}>
        <Grid.Row centered>
          <Grid.Column width={6}>
            <Table
              celled
              striped
              compact
              collapsing
              color={"blue"}
              valigned={"center"}
              unstackable={true}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="4">
                    <span>Productos</span>
                    <StockEntrePeriodos perfil={this.perfil} />
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Stock</Table.HeaderCell>
                  <Table.HeaderCell>Minimo</Table.HeaderCell>
                  <Table.HeaderCell>Fecha Ult. Mov.</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{listaProductos}</Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column width={10}>
            <Table
              celled
              striped
              compact
              collapsing
              color={"teal"}
              valigned={"center"}
              unstackable={true}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="6">
                    <span>Movimientos de Stock </span>
                    <StockNew
                      getStock={this.getStock}
                      db={db}
                      perfil={this.perfil}
                      productos={this.props.stock.productos}
                    />
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <FiltroFechas
                      fechaInicio={this.filtered.fechaInicio}
                      fechaFinal={this.filtered.fechaFinal}
                      onChangeFechaInicio={this.onChangeFechaInicio}
                      onChangeFechaFinal={this.onChangeFechaFinal}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <ModalMultiple
                      opciones={this.productosF}
                      label={"Producto"}
                      onSelected={this.onSelectedProducto}
                      onSelectedAll={this.onSelectedAllProductos}
                      todo={this.filtered.allProductos}
                      seleccion={this.filtered.listaProductos}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Concepto</Table.HeaderCell>
                  <Table.HeaderCell>Detalle</Table.HeaderCell>
                  <Table.HeaderCell>Entrada</Table.HeaderCell>
                  <Table.HeaderCell>Salida</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{lista}</Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    stock: state.personalStore.stock,
    listeners: state.personalStore.listeners,
    filtrosStock: state.personalStore.filtrosStock,
  };
}

export default connect(mapStateToProps)(Stock);
