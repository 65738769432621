import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader, Label } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
class EmpresaNew extends Component {
  
  constructor (props) {
    super(props);

    this.db = props.db;
    this.error = [];

    this.state = {
      modalOpen: false,
      alerta: false,
      tel: "",
      email: "",
      adminNombre: "",
      adminApellido: "",
      nombre: "",
      email: "",
      activo: true,
      rodeos: false,
      procesando: true,
      fechaAlta: new Date(),
      fechaVto: new Date().setMonth((new Date()).getMonth() + 1)
    };

  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }
  
  validateMail = (email) => {
      var resultado = false;
    
      var patron=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
      if(email.search(patron)===0)
      {
          resultado = true;
          
      }
      
      return resultado;
  }

  save = () => {

    this.setState({tiempo: 15});

    this.error = [];

    if(this.state.email.trim().length > 0 && !this.validateMail(this.state.email)) this.error.push('El correo ingresado no es valido');
    if((this.state.adminNombre.trim().length === 0)) this.error.push('El nombre del administrador es obligatorio.');
    if((this.state.adminApellido.trim().length === 0)) this.error.push('El apellido del administrador es obligatorio.');
    if((this.state.nombre.trim().length === 0)) this.error.push('El nombre del establecimiento es obligatorio.');
    if((this.state.tel.trim().length === 0)) this.error.push('El telefono del administrador es obligatorio.');

    if(this.error.length === 0){
      var _this = this;
      
      this.db.collection("empresas").add({
        "nombre": this.state.nombre,
        "admin": this.state.adminNombre + " " + this.state.adminApellido,
        "adminNombre":  this.state.adminNombre,
        "adminApellido": this.state.adminApellido,
        "email": this.state.email,
        "tel": this.state.tel,
        "fechaAlta": this.state.fechaAlta,
        "fechaVto": new Date(this.state.fechaVto),
        "activo": this.state.activo,
        "procesando": this.state.procesando,
        "tolerancia": Number(10),
        "rodeos": this.state.rodeos
      })
      .then(function(docRef) {
          _this.setState({ alerta: false, modalOpen: false, rodeos: false, nombre: "", email: "", tel: "", adminNombre: "", adminApellido: "", fechaVto: new Date().setMonth((new Date()).getMonth() + 1) });
          _this.error = [];
      })
      .catch(function(error) {
          _this.setState({alerta: true, error: _this.state.error.push(`Error adding document: ${error}`)});
      });

      this.refreshIntervalId = setInterval(this.esperar, 1000);

    }else{this.setState({ alerta: true });}
  }

  esperar = () => {
      if(this.state.tiempo>0){
        this.setState({tiempo: this.state.tiempo - 1});
      }else{
          clearInterval(this.refreshIntervalId);
          this.setState({cargando: false});
      }
  }

  render () {
    
    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
                  <Icon name='plus' /> Agregar Empresa
                </Button>}
        >

        <Modal.Header>Nueva Empresa</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre de Empresa" id="nombre" type="text" placeholder="Empresa" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Nombre Administrador" id="adminNombre" type="text" placeholder="Nombre Administrador" value={this.state.adminNombre} onChange={(e, { value }) => this.setState({ adminNombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Apellido Administrador" id="adminApellido" type="text" placeholder="Apellido Administrador" value={this.state.adminApellido} onChange={(e, { value }) => this.setState({ adminApellido: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Email Administrador" id="email" type="email" placeholder="Email" value={this.state.email} onChange={(e, { value }) => this.setState({ email: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Tel Administrador" id="tel" type="text" placeholder="Tel" value={this.state.tel} onChange={(e, { value }) => this.setState({ tel: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="Rodeos" toggle checked={this.state.rodeos} onChange={(e, { value }) => this.setState({ rodeos: !this.state.rodeos })}/>
                  </Form.Field>
                  <Form.Field >
                      <Label>Vencimiento: </Label> 
                      <DatePicker
                        minDate={moment(this.state.fechaVto)}
                        dateFormat="DD/MM/YYYY"
                        onChange={(fechaVto) => this.setState({fechaVto})}
                        selected={moment(this.state.fechaVto)}
                        />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ alerta: false, modalOpen: false, rodeos: false, nombre: "", email: "", tel: "", adminNombre: "", adminApellido: "", fechaVto: new Date().setMonth((new Date()).getMonth() + 1) })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos'
              list={this.error}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default EmpresaNew;