import firebase from "../firebase";
import { isEmpty } from "react-redux-firebase";
const db = firebase.firestore();

export const fetchReportesTratamientos = (perfil) => {
  return function (dispatch) {
    if (!isEmpty(perfil)) {
      db.collection("empresas")
        .doc(perfil.empresa)
        .collection("reportes_tratamientos")
        .orderBy("fecha", "desc")
        .onSnapshot(function (querySnapshot) {
          let items = [];
          querySnapshot.forEach(function (doc) {
            let item = doc.data();
            item.doc = doc;
            item.id = doc.id;
            items.push(item);
          });
          dispatch(add(items));
        });
    }
  };
};

const add = (reportesSanitarios) => ({
  type: "ADD_REPORTE_TRATAMIENTO",
  reportesSanitarios,
});
