import React, { Component } from "react";
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

export default class RodeoEditMov extends Component {
    constructor(props) {
        super(props);

        this.id = props.id;
        this.db = props.db;
        this.perfil = props.perfil;

        this.mov = props.mov;
        this.rodeo = props.rodeo;
        this.empresa = props.empresa;

        this.optionClientes = props.optionsClientes || [];

        this.state = {
            modalOpen: false,
            fecha: moment(this.mov.fecha.toDate ? this.mov.fecha.toDate() : new Date(this.mov.fecha)),
            kilos: this.mov.desbaste ? (this.mov.desbaste > 0 ? this.mov.kilos * 100 / (100 - this.mov.desbaste) : 100) : this.mov.kilos,
            precio: this.mov.precio,
            desbaste: this.mov.desbaste || 0
        }
    }

    formatear = (num, decimales = 0) => {
        var signo = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
    }

    componentWillUpdate() {
        this.fixBody();
    }

    componentDidUpdate() {
        this.fixBody();
    }

    fixBody() {
        const anotherModal = document.getElementsByClassName('ui page modals').length;
        if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
    }

    componentWillReceiveProps = (nextProps) => {
        this.mov = nextProps.mov;
        this.rodeo = nextProps.rodeo;
        this.empresa = nextProps.empresa;

        this.optionClientes = nextProps.optionsClientes || [];

        this.setState({
            fecha: moment(this.mov.fecha.toDate ? this.mov.fecha.toDate() : new Date(this.mov.fecha)),
            kilos: this.mov.desbaste ? (this.mov.desbaste > 0 ? this.mov.kilos * 100 / (100 - this.mov.desbaste) : 100) : this.mov.kilos,
            precio: this.mov.precio,
            desbaste: this.mov.desbaste || 0
        })
    }

    save = () => {
        var movRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('movrodeos').doc(this.id);
        var _this = this;
        // Set the "capital" field of the city 'DC'
        movRef.update({
            fecha: new Date(this.state.fecha),
            precio: Number(this.state.precio),
            kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
            desbaste: Number(this.state.desbaste),
        })
            .then(function () {
                _this.setState({ modalOpen: false })
            })
            .catch(function (error) {
                _this.setState({ modalOpen: false })
                console.log('error');
            })
        this.setState({ cargando: false, modalOpen: false })
    }

    render() {
        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size={'small'}
                trigger={<Button floated='center' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='green' >
                    <Icon name='edit' /> Editar
                      </Button>}
            >

                <Modal.Header>Editar Movimiento de Rodeo</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <span style={{ fontWeight: 'bold' }}>Fecha: </span>
                            <DatePicker
                                minDate={moment(this.rodeo.fechaAlta.toDate ? this.rodeo.fechaAlta.toDate() : new Date(this.rodeo.fechaAlta))}
                                maxDate={moment(new Date())}
                                dateFormat="DD/MM/YYYY-hh:mm:ss"
                                selected={this.state.fecha}
                                onChange={(fecha) => this.setState({ fecha })}
                            />

                        
                                <Form.Group widths='equal'>

                                    <Form.Field>
                                        <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={`${this.formatear(this.state.kilos, 2)}`} onChange={(e, { value }) => this.setState({ kilos: value })} />
                                    </Form.Field>
                                    {this.mov.concepto !== "Muerte" &&
                                        <Form.Field>
                                            <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={`${this.formatear(this.state.desbaste, 2)}`} onChange={(e, { value }) => this.setState({ desbaste: value })} />
                                        </Form.Field>
                                    }
                                    {this.mov.concepto !== "Muerte" &&
                                        <Form.Field>
                                            <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
                                        </Form.Field>
                                    }
                                </Form.Group>
                         

                            {this.mov.detalle.toLowerCase() === 'venta' &&
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input label="Precio por kg" id="precio" type="number" min="0" step="0.5" placeholder="Precio" value={`${this.formatear(this.state.precio, 2)}`} onChange={(e, { value }) => this.setState({ precio: value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Select label="Cliente" options={this.optionClientes} placeholder='Seleccionar Cliente' value={this.state.cliente} onChange={(e, { value }) => this.setState({ cliente: value })} />
                                    </Form.Field>
                                </Form.Group>
                            }

                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={(e, value) => this.setState({ modalOpen: false, fecha: moment((this.mov.fecha.toDate ? this.mov.fecha.toDate() : new Date(this.mov.fecha))), kilos: this.mov.desbaste ? (this.mov.desbaste > 0 ? this.mov.kilos * 100 / (100 - this.mov.desbaste) : 100) : this.mov.kilos, precio: this.mov.precio })} inverted>
                        Cancelar
                </Button>
                    <Button color='green' onClick={this.save} inverted>
                        <Icon name='checkmark' /> Guardar
                </Button>
                    {this.state.alerta &&
                        <Message
                            error
                            header='Verificar Datos'
                            list={this.error}
                        />
                    }
                </Modal.Actions>
            </Modal>
        )
    }
}