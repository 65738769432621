import React, { Component } from "react";
import {
  Grid,
  Dropdown,
  Form,
  Button,
  Modal,
  Segment,
  Label,
} from "semantic-ui-react";

export default class Automatismo extends Component {
  constructor(props) {
    super(props);

    this.empresa = props.empresa;
    this.rodeo = props.rodeo;

    this.db = props.db;
    this.perfil = props.perfil;

    this.state = {
      modalOpen: false,
      tolerancia_remanente: this.empresa.tolerancia_remanente || 5,
      oldtolerancia_remanente: this.empresa.tolerancia_remanente || 5,

      cpcAutomatico: this.rodeo.cpcAutomatico || false,
      incremento: this.rodeo.incremento || 0,
      decremento: this.rodeo.decremento || 0,
      oldcpcAutomatico: this.rodeo.cpcAutomatico || false,
      oldincremento: this.rodeo.incremento || 0,
      olddecremento: this.rodeo.decremento || 0,
    };
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
      cpcAutomatico: this.state.oldcpcAutomatico,
      tolerancia_remanente: this.state.oldtolerancia_remanente,
      cpcAutomatico: this.state.oldcpcAutomatico,
      incremento: this.state.oldincremento,

      incremento: this.state.oldincremento,
      decremento: this.state.olddecremento,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    this.empresa = nextProps.empresa;
    this.rodeo = nextProps.rodeo;
    this.db = nextProps.db;
    this.perfil = nextProps.perfil;

    this.setState({
      tolerancia_remanente: this.empresa.tolerancia_remanente || 5,
      oldtolerancia_remanente: this.empresa.tolerancia_remanente || 5,

      cpcAutomatico: this.rodeo.cpcAutomatico || false,
      incremento: this.rodeo.incremento || 0,
      decremento: this.rodeo.decremento || 0,
      oldcpcAutomatico: this.rodeo.cpcAutomatico || false,
      oldincremento: this.rodeo.incremento || 0,
      olddecremento: this.rodeo.decremento || 0,
    });
  };

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName("ui page modals")
      .length;
    if (anotherModal > 0)
      document.body.classList.add("scrolling", "dimmable", "dimmed");
  }

  guardarCambios = () => {
    const _this = this;

    this.db
      .collection("empresas")
      .doc(this.perfil.empresa)
      .update({
        tolerancia_remanente: Number(this.state.tolerancia_remanente),
      });

    this.db
      .collection("empresas")
      .doc(this.perfil.empresa)
      .collection("rodeos")
      .doc(this.rodeo.id)
      .update({
        cpcAutomatico: this.state.cpcAutomatico,
        incremento: Number(this.state.incremento),
        decremento: Number(this.state.decremento),
      })
      .then(function () {
        _this.handleClose();
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        _this.setState({ cargando: false, alerta: true, error: error });
      });

    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <Modal
        closeOnEscape={true}
        closeOnDimmerClick={true}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={"large"}
        trigger={
          <Dropdown.Item
            key={"automatismo"}
            text={"AUTOMATIZAR"}
            icon="play"
            onClick={() => this.setState({ modalOpen: true })}
          />
        }
      >
        <Modal.Header>Automatizar Rodeo: {this.rodeo.nombre}</Modal.Header>
        <Modal.Content>
          <Grid style={{ padding: 0, margin: 2 }}>
            <Grid.Row centered>
              <Segment>
                <Form.Input
                  label="% de Tolerancia de remanente "
                  type="number"
                  length="4"
                  max="100"
                  min="0"
                  step="0.5"
                  value={this.state.tolerancia_remanente}
                  onChange={(e, { value }) =>
                    this.setState({ tolerancia_remanente: value })
                  }
                />
                <Label>Esta tolerancia se aplica a todas las tropas</Label>
              </Segment>
            </Grid.Row>

            <Grid.Row centered>
              <Segment raised>
                <Form.Field>
                  <Form.Checkbox
                    label="Gestion automatica de consumo"
                    toggle
                    checked={this.state.cpcAutomatico}
                    onChange={() =>
                      this.setState({
                        cpcAutomatico: !this.state.cpcAutomatico,
                      })
                    }
                  />
                </Form.Field>

                <Form.Group>
                  <Form.Field>
                    <Label size="large" color="red" ribbon>
                      Aumentar consumo con lecturas de remanente 0:
                    </Label>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      id="incremento"
                      type="number"
                      step="0.25"
                      min="0"
                      placeholder="%"
                      value={this.state.incremento}
                      onChange={(e, { value }) =>
                        this.setState({ incremento: Number(value) })
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <Label size="large" color="#866810" ribbon>
                      {`Bajar consumo con lecturas de remanente mayor a ${this.state.tolerancia_remanente}% (en % =>)`}
                      :
                    </Label>
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      id="decremento"
                      type="number"
                      step="0.25"
                      min="0"
                      placeholder="%"
                      value={this.state.decremento}
                      onChange={(e, { value }) =>
                        this.setState({ decremento: Number(value) })
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Segment>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={(e, value) => this.setState({ modalOpen: false })}
            inverted
          >
            CERRAR
          </Button>
          <Button positive onClick={() => this.guardarCambios()} inverted>
            GUARDAR
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    tratamientos: state.personalStore.tratamientos,
    productosSanitarios: state.personalStore.productosSanitarios,
    categoriasSanitarias: state.personalStore.categoriasSanitarias,
    tratamientos_programados: state.personalStore.tratamientos_programados,
    listeners: state.personalStore.listeners,
  };
}
