import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Loader,
  Segment,
  Dimmer,
  Image,
  Grid,
  Button,
} from "semantic-ui-react";
import firebase from "./../firebase";
// import { compose } from 'redux'
// import { firestoreConnect } from 'react-redux-firebase'

import ListaConsumos from "./ListaConsumos";

import { FETCHED, NUEVO_REPORTE_SET_PRODUCTOS } from "./actions";
// import Api from './../api'

// const api = new Api();
const db = firebase.firestore();
class SetearProductos extends Component {
  reduceConsumos = (lista, ing) => {
    const lista_reducida = [];
    let ms = 0;
    let mcal = 0;
    let prot = 0;
    const reduccion = lista.reduce((productos, item) => {
      let index = item.producto;
      ms += item.materiaseca;
      mcal += item.materiaseca * ing[index].mcal;
      prot += item.materiaseca * ing[index].prot;
      productos[index] = productos[index]
        ? productos[index]
        : {
            ...item,
            costo: 0,
            kilos: 0,
            materiaseca: 0,
            nombre: ing[index].nombre,
            precio: ing[index].precio,
          };
      productos[index].costo += item.costo ? Number(item.costo) : 0;
      productos[index].kilos += item.kilos ? Number(item.kilos) : 0;
      productos[index].materiaseca += item.materiaseca
        ? Number(item.materiaseca)
        : 0;
      return productos;
    }, {});

    Object.keys(reduccion).map((i) => lista_reducida.push(reduccion[i]));

    if (ms > 0) {
      prot = prot / ms;
      mcal = mcal / ms;
    }

    return { lista_reducida, ms, mcal, prot };
  };

  componentDidMount = async () => {
    const { dispatch, rodeos, perfil } = this.props;

    try {
      dispatch({
        type: FETCHED,
      });

      let consumos_rodeos = {};
      let consumos_detalle = [];
      let productos = {};

      const productosResponse = await db
        .collection("empresas")
        .doc(perfil.empresa)
        .collection("productos")
        .get();
      productosResponse.forEach((doc) => {
        productos[doc.id] = doc.data();
      });
      const consumosResponse = await db
        .collection("empresas")
        .doc(perfil.empresa)
        .collection("consumos_reportes")
        .get();
      consumosResponse.forEach((doc) => {
        consumos_rodeos[doc.id] = doc.data();
      });

      const consumosDetalleResponse = await db
        .collection("empresas")
        .doc(perfil.empresa)
        .collection("consumos_reportes_detalle")
        .get();
      consumosDetalleResponse.forEach((doc) => {
        consumos_detalle.push(doc.data());
      });

      const lista_consumos = consumos_detalle.filter((c) =>
        rodeos.includes(c.rodeo)
      );
      //console.log(lista_consumos)
      const consumos = this.reduceConsumos(lista_consumos, productos);
      let cabezas = 0;
      rodeos.map((r) => {
        if (consumos_rodeos[r]) cabezas += consumos_rodeos[r].cabezas;
      });

      const data = {
        productos: consumos.lista_reducida,
        raciones: cabezas,
        ms: consumos.ms,
        mcal: consumos.mcal,
        prot: consumos.prot,
      };

      console.log(data);

      dispatch({
        type: NUEVO_REPORTE_SET_PRODUCTOS,
        payload: data,
      });
    } catch (err) {
      //window.location.reload()
      console.log(err);
    }
  };

  render() {
    const { fetched, rodeos } = this.props;

    if (fetched || !this.props.productos) {
      return (
        <Segment>
          <Dimmer active>
            <Loader />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      );
    }
    if (rodeos.length === 0) {
      return <Redirect to="/reportes" push={true} />;
    }

    return (
      <Container style={{ paddingBottom: 40 }}>
        <Grid centered>
          <Grid.Row centered>
            <h2>Paso 3: Configurar consumos</h2>
          </Grid.Row>

          <Grid.Row centered>
            <Button
              as={Link}
              info
              floated="right"
              primary
              size="small"
              to="/reportes/setear_movimientos"
              style={{ marginRigth: 20 }}
            >
              Anterior
            </Button>
            <Button
              as={Link}
              positive
              floated="right"
              primary
              size="small"
              to="/reportes/resultados"
              style={{ marginLeft: 20 }}
            >
              Siguiente
            </Button>
          </Grid.Row>

          <Grid.Row>
            <ListaConsumos />
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.personalStore.nuevoReporte,
  };
};

export default connect(mapStateToProps)(SetearProductos);
