import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class MixerEdit extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;
    this.db = props.db;
    this.state = {
      nombre: props.mixer.nombre,
      modalOpen: false,
      oldname: props.mixer.nombre,
      activo: props.mixer.activo,
      oldactivo: props.mixer.activo,
    };

  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.mixer) this.setState({ 
      nombre: nextProps.mixer.nombre,
      oldname: nextProps.mixer.nombre,
      activo: nextProps.mixer.activo,
      oldactivo: nextProps.mixer.activo,
    });
  }

  save = () => {

    var _this = this;
    
    if(this.state.nombre.length >0 ){
      this.setState({cargando: true});
      var mixerRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('mixers').doc(this.id);
      
      // Set the "capital" field of the city 'DC'
      return mixerRef.update({
        "nombre": this.state.nombre,
        "activo": this.state.activo
      })
      .then(function() {
          _this.setState({ cargando: false, modalOpen: false, oldname: _this.state.nombre, oldactivo: _this.state.activo });    
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error})
      })
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
    
  }

  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        >
        <Modal.Header>Editar Mixer</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre del Mixer" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={ () => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={ (e, value)=> this.setState({ modalOpen: false, nombre: this.state.oldname, activo: this.state.oldactivo })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Debe ingresar un nombre valido y la capadidad debe ser mayor a cero!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default MixerEdit;