import React, { Component } from 'react';

import {Grid, Label, Divider, Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react';

import DetalleDieta from '../containers/DetalleDieta';

class DietaEdit extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;
    
    this.productos = props.productos;

    this.db = props.db;

    this.state = {
      nombre: props.dieta.nombre,
      oldnombre: props.dieta.nombre,
      agua: props.dieta.agua,
      oldagua: props.dieta.agua,
      activo: props.dieta.activo,
      oldactivo: props.dieta.activo,
      modalOpen: false
    };

    this.timer = null;

  }

  addOne = () => {
    this.setState({agua: Number(this.state.agua + 1)})
    this.timer = setTimeout(this.addOne, 200);
  }

  delOne = () => {
    if (this.state.agua - 1 >= 0){
      this.setState({agua: Number(this.state.agua - 1)})
    }
    this.timer = setTimeout(this.delOne, 200);
  }

  stopTimer = () => {
    clearTimeout(this.timer);
  }

  formatear = (numero, digitos = 0) => {
    return new Intl.NumberFormat('es-ES',{ 
                    minimumFractionDigits: digitos, 
                    maximumFractionDigits: digitos  
                    }).format(numero);
  }

  componentWillReceiveProps = (nextProps) => {
    this.productos = nextProps.productos;

    if(nextProps.dieta) this.setState({ 
      nombre: nextProps.dieta.nombre,
      oldnombre: nextProps.dieta.nombre,
      agua: nextProps.dieta.agua,
      oldagua: nextProps.dieta.agua,
      activo: nextProps.dieta.activo,
      oldactivo: nextProps.dieta.activo,
    });
  }

  save = () => {
    var _this = this;

    if( this.state.nombre.trim().length> 0 ){
      this.setState({cargando: true});
      var dietaRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('dietas').doc(this.id);
      
      return dietaRef.update({
        "nombre": this.state.nombre,
        "agua": Number(this.state.agua),
        "activo": this.state.activo
      })
      .then(function() {
          _this.setState({ cargando: false, modalOpen: false, alerta: false, oldnombre: _this.state.nombre, oldactivo: _this.state.activo, oldagua: _this.state.agua });
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error})
      })
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'large'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        >
        <Modal.Header>Editar Dieta</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" maxlength="15" placeholder="Nombre de la Dieta" value={this.state.nombre} onChange={(e, { value }) => { if(value.length < 16 ) this.setState({ nombre: value })} }/>
                  </Form.Field>

                  <DetalleDieta perfil={this.perfil} agua={this.state.agua} productos={this.productos} id={this.id} />

                  <Grid centered style={{marginTop: 10}}>

                    <Grid.Row style={{marginBottom: 0, paddingBottom: 0}}>
                        <Label basic color='blue' pointing='below' size={"big"}>% de Agua</Label>
                    </Grid.Row>

                    <Grid.Row style={{marginTop: 0, paddingTop: 0}}>
                      <Button.Group>
                        <Button negative onMouseDown={this.delOne} onMouseUp={this.stopTimer} onMouseLeave={this.stopTimer}><Icon disabled name='minus' /></Button>
                        <Button.Or text={this.formatear(this.state.agua, 2)} />
                        <Button positive onMouseDown={this.addOne} onMouseUp={this.stopTimer} onMouseLeave={this.stopTimer}><Icon disabled name='plus' /></Button>
                      </Button.Group>
                    </Grid.Row>

                  </Grid>

                  <Divider />

                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={ () => this.setState({ activo: !this.state.activo })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, agua: this.state.oldagua, nombre: this.state.oldnombre, activo: this.state.oldactivo })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos!'
              list={[
                'Ingrese un nombre valido',
                'El detalle no puede estar vacio'
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default DietaEdit;