import React, { Component } from "react";
import firebase from "../firebase";
import { Dimmer, Loader } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  setFilterRodeos,
  initialFetch,
  fetchTratamientos,
  fetchProductosSanitarios,
  fetchCategoriasSanitarias,
  fetchTratamientosProgramados,
} from "../actions";

import ListaRodeos from "../components/ListaRodeosNew";

class Rodeos extends Component {
  constructor(props) {
    super(props);

    this.firebase = props.firebase;
    this.perfil = props.perfil;

    this.setFilterRodeos = props.setFilterRodeos;

    this.fetchTratamientos = props.fetchTratamientos;
    this.fetchProductosSanitarios = props.fetchProductosSanitarios;
    this.fetchCategoriasSanitarias = props.fetchCategoriasSanitarias;
    this.fetchTratamientosProgramados = props.fetchTratamientosProgramados;

    this.activarListener = props.initialFetch;

    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.state = {
      page: 1,
      rowsPerPage: 25,
      clima: props.clima,
      clientes: props.clientes,
      mixers: props.mixers,
      rodeosActivos: props.rodeosActivos,
      rodeosInactivos: props.rodeosInactivos,
      //   rodeosInactivos: props.rodeosActivos || [],
      //   rodeosActivos: props.rodeosInactivos || [],
      productos: props.productos,
      movrodeos: props.movrodeos,
      corrales: props.corrales,
      consumos: props.consumos,
      consumos_sin_seguimiento: props.consumos_sin_seguimiento,
      dietas: props.dietas,
      categorias: props.categorias,
      empresa: props.empresa,
      listeners: props.listeners || [],
    };

    this.filtered = {
      allCategorias: props.filtrosRodeos.allCategorias,
      listaCategorias: props.filtrosRodeos.listaCategorias,
      allDietas: props.filtrosRodeos.allDietas,
      listaDietas: props.filtrosRodeos.listaDietas,
      filtros: props.filtrosRodeos.filtros,
      allMixer: props.filtrosRodeos.allMixer,
      listaMixer: props.filtrosRodeos.listaMixer,
    };

    this.filtrosFijos = ["categorias", "dietas", "mixers"];

    this.categoriasF = [];
    this.dietasF = [];
    this.mixerF = [];
  }

  componentWillMount = () => {
    const itemProducto = { id: "productos_sanitarios" };

    var oyente_sanitario_p = this.state.listeners.find(function (element) {
      return element.id === "productos_sanitarios";
    });

    if (oyente_sanitario_p === undefined) {
      this.activarListener(this.perfil, itemProducto);
      this.fetchProductosSanitarios(this.perfil);
    }

    const itemCateSani = { id: "categorias_sanitarias" };

    var oyente_catego_sanita = this.state.listeners.find(function (element) {
      return element.id === "categorias_sanitarias";
    });

    if (oyente_catego_sanita === undefined) {
      this.activarListener(this.perfil, itemCateSani);
      this.fetchCategoriasSanitarias(this.perfil);
    }

    const item = { id: "tratamientos" };

    var oyente = this.state.listeners.find(function (element) {
      return element.id === "tratamientos";
    });

    if (oyente === undefined) {
      this.activarListener(this.perfil, item);
      this.fetchTratamientos(this.perfil);
    }

    const itemTP = { id: "tratamientos_programados" };

    var oyenteTP = this.state.listeners.find(function (element) {
      return element.id === "tratamientos_programados";
    });

    if (oyenteTP === undefined) {
      this.activarListener(this.perfil, itemTP);
      this.fetchTratamientosProgramados(this.perfil);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    this.filtered = {
      allCategorias: nextProps.filtrosRodeos.allCategorias,
      listaCategorias: nextProps.filtrosRodeos.listaCategorias,
      allDietas: nextProps.filtrosRodeos.allDietas,
      listaDietas: nextProps.filtrosRodeos.listaDietas,
      filtros: nextProps.filtrosRodeos.filtros,
      allMixer: nextProps.filtrosRodeos.allMixer,
      listaMixer: nextProps.filtrosRodeos.listaMixer,
      listeners: nextProps.listeners || [],
    };

    this.setState({
      clima: nextProps.clima,
      clientes: nextProps.clientes,
      mixers: nextProps.mixers,
      rodeosActivos: nextProps.rodeosActivos,
      rodeosInactivos: nextProps.rodeosInactivos,
      //   rodeosInactivos: nextProps.rodeosActivos,
      //   rodeosActivos: nextProps.rodeosInactivos,
      productos: nextProps.productos,
      movrodeos: nextProps.movrodeos,
      corrales: nextProps.corrales,
      consumos: nextProps.consumos,
      consumos_sin_seguimiento: nextProps.consumos_sin_seguimiento,
      dietas: nextProps.dietas,
      categorias: nextProps.categorias,
      empresa: nextProps.empresa,
    });
  };

  semaforo(data) {
    let semaforo = {
      backgroundColor: "white",
      color: "black",
    };

    if (data.cpc > 0 && data.descargado > 0) {
      let res = (Number(data.materiaSeca) / Number(data.cpc) - 1) * 100;
      semaforo.backgroundColor = "green";
      semaforo.color = "white";
      if (res < -5) semaforo.backgroundColor = "red";
      if (res > 5) {
        semaforo.backgroundColor = "yellow";
        semaforo.color = "black";
      }
    }

    return semaforo;
  }
  semaforoRemanente(data) {
    let semaforo = {
      backgroundColor: "white",
      color: "black",
    };

    if (data.descargado > 0) {
      let res = (Number(data.remanente) / Number(data.meta)) * 100;
      semaforo.backgroundColor = "green";
      semaforo.color = "white";
      if (res === 0) semaforo.backgroundColor = "red";
      if (res > this.state.empresa.tolerancia) {
        semaforo.backgroundColor = "yellow";
        semaforo.color = "black";
      }
    }

    return semaforo;
  }
  semaforoFaltante(data) {
    let semaforo = {
      backgroundColor: "white",
      color: "black",
    };

    if (data.meta > 0 && data.descargado > 0) {
      let res =
        (Number(data.meta - data.descargado - data.remanente) /
          Number(data.meta)) *
        100;
      semaforo.backgroundColor = "green";
      semaforo.color = "white";
      if (res < 0) {
        semaforo.backgroundColor = "yellow";
        semaforo.color = "black";
      }
      if (res > 0 && res < this.state.empresa.tolerancia)
        semaforo.backgroundColor = "green";
      if (res > this.state.empresa.tolerancia) semaforo.backgroundColor = "red";
    }

    return semaforo;
  }

  subirTolerancia = () => {
    var nuevoValor = this.state.empresa.tolerancia + 1;

    if (nuevoValor < 100) {
      var empresaRef = this.db.collection("empresas").doc(this.perfil.empresa);

      // Set the "capital" field of the city 'DC'
      return empresaRef
        .update({
          tolerancia: Number(nuevoValor),
        })
        .then(function () {})
        .catch(function (error) {
          // The document probably doesn't exist.
          console.log("error");
        });
    }
  };

  bajarTolerancia = () => {
    var nuevoValor = this.state.empresa.tolerancia - 1;

    if (nuevoValor > 0) {
      var empresaRef = this.db.collection("empresas").doc(this.perfil.empresa);

      // Set the "capital" field of the city 'DC'
      return empresaRef
        .update({
          tolerancia: Number(nuevoValor),
        })
        .then(function () {})
        .catch(function (error) {
          // The document probably doesn't exist.
          console.log("error");
        });
    }
  };

  onSelectedAllCategorias = (opciones) => {
    this.setState({ page: 1 });
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "categorias");
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "categorias"
        );
      }
    }

    let categorias = [];
    if (!this.filtered.allCategorias) {
      Object.keys(opciones).map((opcion) => {
        categorias.push(opciones[opcion].id);
      });

      this.filtered.listaCategorias = categorias;
      this.filtered.allCategorias = true;
    } else {
      this.filtered.filtros.push({ nombre: "categorias", hora: new Date() });
      this.filtered.allCategorias = false;
      this.filtered.listaCategorias = [];
    }
    this.setFilterRodeos(this.filtered);
  };

  onSelectedCategoria = (id, opciones) => {
    this.setState({ page: 1 });

    this.filtered.filtros.push({ nombre: "categorias", hora: new Date() });

    let index = this.filtered.listaCategorias.indexOf(id);

    if (index > -1) {
      this.filtered.listaCategorias = this.filtered.listaCategorias.filter(
        (e) => e !== id
      );
      this.filtered.allCategorias = false;
    } else {
      let categorias = [];

      if (this.filtered.allCategorias) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) categorias.push(opciones[opcion].id);
        });

        this.filtered.listaCategorias = categorias;
        this.filtered.allCategorias = false;
      } else {
        this.filtered.listaCategorias = this.filtered.listaCategorias.concat(
          id
        );
        this.filtered.allCategorias = false;
      }
    }
    this.setFilterRodeos(this.filtered);
  };

  onSelectedAllDietas = (opciones) => {
    this.setState({ page: 1 });
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "dietas");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "dietas"
        );
      }
    }

    let dietas = [];
    if (!this.filtered.allDietas) {
      Object.keys(opciones).map((opcion) => {
        dietas.push(opciones[opcion].id);
      });

      this.filtered.listaDietas = dietas;
      this.filtered.allDietas = true;
    } else {
      this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });
      this.filtered.allDietas = false;
      this.filtered.listaDietas = [];
    }
    this.setFilterRodeos(this.filtered);
  };

  onSelectedDieta = (id, opciones) => {
    this.setState({ page: 1 });
    this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });

    let index = this.filtered.listaDietas.indexOf(id);

    if (index > -1) {
      this.filtered.listaDietas = this.filtered.listaDietas.filter(
        (e) => e !== id
      );
      this.filtered.allDietas = false;
    } else {
      let dietas = [];

      if (this.filtered.allDietas) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) dietas.push(opciones[opcion].id);
        });

        this.filtered.listaDietas = dietas;
        this.filtered.allDietas = false;
      } else {
        this.filtered.listaDietas = this.filtered.listaDietas.concat(id);
        this.filtered.allDietas = false;
      }
    }
    this.setFilterRodeos(this.filtered);
  };

  onSelectedAllMixer = (opciones) => {
    this.setState({ page: 1 });
    let busqueda = this.filtered.filtros.find((f) => f.nombre === "mixers");

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filtered.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filtered.filtros = this.filtered.filtros.filter(
          (e) => e.nombre !== "mixers"
        );
      }
    }

    let mixers = [];
    if (!this.filtered.allMixer) {
      Object.keys(opciones).map((opcion) => {
        mixers.push(opciones[opcion].id);
      });

      this.filtered.listaMixer = mixers;
      this.filtered.allMixer = true;
    } else {
      this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });
      this.filtered.allMixer = false;
      this.filtered.listaMixer = [];
    }
    this.setFilterRodeos(this.filtered);
  };

  onSelectedMixer = (id, opciones) => {
    this.setState({ page: 1 });
    this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });

    let index = this.filtered.listaMixer.indexOf(id);

    if (index > -1) {
      this.filtered.listaMixer = this.filtered.listaMixer.filter(
        (e) => e !== id
      );
      this.filtered.allMixer = false;
    } else {
      let mixers = [];

      if (this.filtered.allMixer) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) mixers.push(opciones[opcion].id);
        });

        this.filtered.listaMixer = mixers;
        this.filtered.allMixer = false;
      } else {
        this.filtered.listaMixer = this.filtered.listaMixer.concat(id);
        this.filtered.allMixer = false;
      }
    }
    this.setFilterRodeos(this.filtered);
  };

  aplicarFiltroMixers = (listaDescarga) => {
    this.mixerF = [];
    //filtrando por categorias
    listaDescarga = listaDescarga.filter((d) => {
      let mixer = {};
      let corral = this.state.corrales.find((c) => c.id === d.corral);
      if (!corral) {
        mixer.id = "-";
        mixer.label = "-";
      } else {
        mixer.id = corral.mixer;
        mixer.label = this.state.mixers.find(
          (r) => r.id === corral.mixer
        ).nombre;
      }

      this.mixerF[mixer.id] = mixer;

      let bandera = true;
      if (!this.filtered.allMixer) {
        let index = this.filtered.listaMixer.indexOf(
          corral ? corral.mixer : "-"
        );
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  aplicarFiltroCategorias = (listaDescarga) => {
    this.categoriasF = [];
    //filtrando por categorias
    listaDescarga = listaDescarga.filter((d) => {
      let categoria = {};
      categoria.id = d.categoria;
      categoria.label = this.state.categorias.find(
        (r) => r.id === d.categoria
      ).nombre;
      this.categoriasF[categoria.id] = categoria;

      let bandera = true;
      if (!this.filtered.allCategorias) {
        let index = this.filtered.listaCategorias.indexOf(d.categoria);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };
  aplicarFiltroDietas = (listaDescarga) => {
    this.dietasF = [];
    //filtrando por dietas
    listaDescarga = listaDescarga.filter((d) => {
      let dieta = {};
      dieta.id = d.dieta;
      dieta.label = this.state.dietas.find((r) => r.id === d.dieta).nombre;
      this.dietasF[dieta.id] = dieta;

      let bandera = true;
      if (!this.filtered.allDietas) {
        let index = this.filtered.listaDietas.indexOf(d.dieta);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return listaDescarga;
  };

  setPage = (activePage) => this.setState({ page: activePage });
  setRows = (count) => this.setState({ rowsPerPage: count });

  render() {
    if (
      this.state.categorias === undefined ||
      this.state.movrodeos === undefined ||
      this.state.corrales === undefined ||
      this.state.productos === undefined ||
      this.state.dietas === undefined ||
      this.state.rodeosActivos === undefined ||
      this.state.rodeosInactivos === undefined ||
      this.state.clima === undefined ||
      this.state.consumos_sin_seguimiento === undefined ||
      this.state.consumos === undefined ||
      this.state.mixers === undefined ||
      this.state.clientes === undefined
    )
      return <Loader active />;

    let { rodeosActivos } = this.state;

    if (
      this.state.categorias.length > 0 &&
      this.state.dietas.length > 0 &&
      this.state.corrales.length > 0
    ) {
      let listaFiltros = this.filtered.filtros.reduce((filtro, item) => {
        let val = item.nombre;
        filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
        return filtro;
      }, {});

      listaFiltros = Object.keys(listaFiltros).sort((a, b) => {
        if (listaFiltros[a].hora > listaFiltros[b].hora) {
          return 1;
        }

        if (listaFiltros[a].hora < listaFiltros[b].hora) {
          return -1;
        }
        return 0;
      });

      this.filtrosFijos.forEach((fijo) => {
        let index = listaFiltros.indexOf(fijo);
        if (index < 0) listaFiltros.push(fijo);
      });

      var _this = this;

      listaFiltros.forEach((f) => {
        if (f === "dietas")
          rodeosActivos = _this.aplicarFiltroDietas(rodeosActivos);
        if (f === "categorias")
          rodeosActivos = _this.aplicarFiltroCategorias(rodeosActivos);
        if (f === "mixers")
          rodeosActivos = _this.aplicarFiltroMixers(rodeosActivos);
      });

      rodeosActivos = rodeosActivos.sort((a, b) => {
        const corralA = _this.state.corrales.find((c) => c.id === a.corral);
        const corralB = _this.state.corrales.find((c) => c.id === b.corral);

        if (!corralA) return -1;
        if (!corralB) return 1;

        let numA = isNaN(parseInt(corralA.nombre))
          ? 0
          : parseInt(corralA.nombre);
        let numB = isNaN(parseInt(corralB.nombre))
          ? 0
          : parseInt(corralB.nombre);

        if (numA > numB && numB > 0) {
          return 1;
        }

        if (numA < numB && numA > 0) {
          return -1;
        }

        if (corralA.nombre > corralB.nombre) {
          return 1;
        }

        if (corralA.nombre < corralB.nombre) {
          return -1;
        }

        return 0;
      });
    }

    return (
      <ListaRodeos
        db={this.db}
        page={this.state.page}
        setPage={this.setPage}
        rowsPerPage={this.state.rowsPerPage}
        setRows={this.setRows}
        filtered={this.filtered}
        semaforo={this.semaforo}
        semaforoFaltante={this.semaforoFaltante}
        semaforoRemanente={this.semaforoRemanente}
        onSelectedAllCategorias={this.onSelectedAllCategorias}
        onSelectedAllDietas={this.onSelectedAllDietas}
        onSelectedDieta={this.onSelectedDieta}
        onSelectedAllMixer={this.onSelectedAllMixer}
        onSelectedMixer={this.onSelectedMixer}
        onSelectedCategoria={this.onSelectedCategoria}
        subirTolerancia={this.subirTolerancia}
        bajarTolerancia={this.bajarTolerancia}
        onSelectedDieta={this.onSelectedDieta}
        categoriasF={this.categoriasF}
        dietasF={this.dietasF}
        mixerF={this.mixerF}
        setFilterRodeos={this.setFilterRodeos}
        empresa={this.state.empresa}
        categorias={this.state.categorias}
        movrodeos={this.state.movrodeos}
        corrales={this.state.corrales}
        mixers={this.state.mixers}
        productos={this.state.productos}
        dietas={this.state.dietas}
        rodeosInactivos={this.state.rodeosInactivos}
        clima={this.state.clima}
        consumos={this.state.consumos}
        consumos_sin_seguimiento={this.state.consumos_sin_seguimiento}
        clientes={this.state.clientes}
        rodeosactivos={rodeosActivos}
        perfil={this.perfil}
        empresa={this.state.empresa}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    filtrosRodeos: state.personalStore.filtrosRodeos,
    tratamientos: state.personalStore.tratamientos,
    productosSanitarios: state.personalStore.productosSanitarios,
    categoriasSanitarias: state.personalStore.categoriasSanitarias,
    tratamientos_programados: state.personalStore.tratamientos_programados,
    listeners: state.personalStore.listeners,
  };
}

const WrappedComponent = connect(mapStateToProps, {
  setFilterRodeos,
  fetchTratamientos,
  fetchProductosSanitarios,
  fetchCategoriasSanitarias,
  fetchTratamientosProgramados,
  initialFetch,
})(Rodeos);

export default compose(
  firestoreConnect((props, state) => {
    return [
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        storeAs: "empresa",
      },
      {
        collection: "usuarios",
        storeAs: "usuariosCargas",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "corrales" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "corrales",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "mixers" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "mixers",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "productos" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "productos",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "dietas" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "dietas",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "categorias" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "categorias",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "rodeos" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "rodeosActivos",
        where: [["activo", "==", true]],
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "rodeos" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "rodeosInactivos",
        where: [["activo", "==", false]],
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "clima" }],
        orderBy: [["fecha", "desc"]],
        storeAs: "clima",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "clientes" }],
        orderBy: [["nombre", "asc"]],
        storeAs: "clientes",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "movrodeos" }],
        orderBy: [["fecha", "asc"]],
        storeAs: "movrodeos",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "consumos" }],
        storeAs: "consumos",
      },
      {
        collection: "empresas",
        doc: props.perfil.empresa,
        subcollections: [{ collection: "consumos_sin_seguimiento" }],
        storeAs: "consumos_sin_seguimiento",
      },
    ];
  }), // sync todos collection from Firestore into redux
  connect((state, props) => {
    let corrales,
      productos,
      dietas,
      categorias,
      rodeosActivos,
      rodeosInactivos,
      clima,
      clientes,
      movrodeos,
      consumos,
      consumos_sin_seguimiento,
      mixers;

    if (state.firestore.data.corrales === null) corrales = [];
    if (state.firestore.data.mixers === null) mixers = [];
    if (state.firestore.data.productos === null) productos = [];
    if (state.firestore.data.dietas === null) dietas = [];
    if (state.firestore.data.categorias === null) categorias = [];
    if (state.firestore.data.rodeosActivos === null) rodeosActivos = [];
    if (state.firestore.data.rodeosInactivos === null) rodeosInactivos = [];
    if (state.firestore.data.clima === null) clima = [];
    if (state.firestore.data.clientes === null) clientes = [];
    if (state.firestore.data.movrodeos === null) movrodeos = [];
    if (state.firestore.data.consumos === null) consumos = [];
    if (state.firestore.data.consumos_sin_seguimiento === null)
      consumos_sin_seguimiento = [];

    if (state.firestore.data.mixers) {
      mixers = [];
      Object.entries(state.firestore.data.mixers).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        mixers.push(obj);
      });
    }
    if (state.firestore.data.corrales) {
      corrales = [];
      Object.entries(state.firestore.data.corrales).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        corrales.push(obj);
      });
    }
    if (state.firestore.data.dietas) {
      dietas = [];
      Object.entries(state.firestore.data.dietas).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        dietas.push(obj);
      });
    }
    if (state.firestore.data.productos) {
      productos = [];
      Object.entries(state.firestore.data.productos).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        productos.push(obj);
      });
    }
    if (state.firestore.data.categorias) {
      categorias = [];
      Object.entries(state.firestore.data.categorias).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        categorias.push(obj);
      });
    }
    if (state.firestore.data.rodeosActivos) {
      rodeosActivos = [];
      Object.entries(state.firestore.data.rodeosActivos).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        rodeosActivos.push(obj);
      });
    }

    if (state.firestore.data.rodeosInactivos) {
      rodeosInactivos = [];
      Object.entries(state.firestore.data.rodeosInactivos).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        rodeosInactivos.push(obj);
      });
    }
    if (state.firestore.data.clima) {
      clima = [];
      Object.entries(state.firestore.data.clima).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        clima.push(obj);
      });
    }
    if (state.firestore.data.clientes) {
      clientes = [];
      Object.entries(state.firestore.data.clientes).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        clientes.push(obj);
      });
    }
    if (state.firestore.data.movrodeos) {
      movrodeos = [];
      Object.entries(state.firestore.data.movrodeos).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        movrodeos.push(obj);
      });
    }
    if (state.firestore.data.consumos) {
      consumos = [];
      Object.entries(state.firestore.data.consumos).map((item) => {
        let obj = item[1];
        obj.id = item[0];
        consumos.push(obj);
      });
    }
    if (state.firestore.data.consumos_sin_seguimiento) {
      consumos_sin_seguimiento = [];
      Object.entries(state.firestore.data.consumos_sin_seguimiento).map(
        (item) => {
          let obj = item[1];
          obj.id = item[0];
          consumos_sin_seguimiento.push(obj);
        }
      );
    }

    return {
      empresa: state.firestore.data.empresa,
      corrales: corrales,
      mixers: mixers,
      productos: productos,
      dietas: dietas,
      categorias: categorias,
      rodeosActivos: rodeosActivos,
      rodeosInactivos: rodeosInactivos,
      clima: clima,
      clientes: clientes,
      movrodeos: movrodeos,
      consumos: consumos,
      consumos_sin_seguimiento: consumos_sin_seguimiento,
    };
  })
)(WrappedComponent);
