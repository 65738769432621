import moment from 'moment'

let inicio = new Date(new Date().getTime() - (2592000000));
inicio.setHours(0)
inicio.setMinutes(0)

const initState = {
    cargando: false,
    stockInicial: null,
    stockFinal: null,
    fechaInicial: moment(new Date(inicio)),
    fechaFinal: moment(new Date()),
    modalOpen: false
}
const stock = (state = initState, action) => {
    switch (action.type) {
        case 'SELECT_FECHA_INICIAL':
            return { ...state, fechaInicial: action.payload, cargando: true}
        case 'SELECT_FECHA_FINAL':
            return { ...state, fechaFinal: action.payload, cargando: true}
        case 'ADD_STOCK_INICIAL':
            return { ...state, stockInicial: action.payload, cargando: false}
        case 'ADD_STOCK_FINAL':
            return { ...state, stockFinal: action.payload, cargando: false}
        case 'CLOSE_STOCK_ENTRE_PERIODOS':
            return { ...state, modalOpen: false, cargando: false}
        case 'OPEN_STOCK_ENTRE_PERIODOS':
            return { ...state, modalOpen: true, cargando: false}
        default:
            return state
    }
};

export default stock;