import React, { Component } from 'react';
import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class CategoriaNew extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    
    this.db = props.db;
    
    this.state = {
      nombre: "",
      compra: 0,
      venta: 0,
      activo: true,
      modalOpen: false,
      cargando: false
    };

  }

  save = () => {
    if(this.state.nombre.trim().length>0){
      var _this = this;
      this.setState({cargando: true});

      this.db.collection("empresas").doc(this.perfil.empresa).collection('categorias').add({
        "nombre": this.state.nombre,
        "activo": this.state.activo,
        "compra": Number(this.state.compra),
        "venta": Number(this.state.venta)
      })
      .then(function(docRef) {
          _this.setState({ cargando: false, modalOpen: false, alerta: false, nombre: "", compra: 0, venta: 0});
      })
      .catch(function(error) {
          _this.setState({ cargando: false, alerta: true, error:`Error adding document: ${error}`});
      });
      this.setState({ cargando: false, modalOpen: false })
      
    }else{this.setState({ alerta: true });}
  }

  formatear = (num, decimales = 0) => {
      var signo = (num >= 0 ? 1 : -1);
      num = num * signo;
      if (decimales === 0) //con 0 decimales
          return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split('e');
      num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
      // x * 10 ^ (-decimales)
      num = num.toString().split('e');
      return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
  }
  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' onClick={() => this.setState({ modalOpen: true })} color='google plus'>
                  <Icon name='plus' /> Nuevo
                </Button>}
        size={'small'}
        >
        <Modal.Header>Nueva Categoria</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Group>
                    <Form.Field>
                      <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre de la categoria" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input label="Precio de compra" id="compra" type="number" min="0" placeholder="$ Compra" value={`${this.formatear(this.state.compra, 2)}`} onChange={(e, { value }) => this.setState({ compra: value })} />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input label="Precio de venta" id="venta" type="number" min="0" placeholder="$ Venta" value={`${this.formatear(this.state.venta, 2)}`} onChange={(e, { value }) => this.setState({ venta: value })} />
                    </Form.Field>
                  </Form.Group>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, nombre: "" })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={[
                'Debe ingresar un nombre valido!',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default CategoriaNew;
