import React from "react";
import { Grid, Segment, Table, Label } from "semantic-ui-react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import firebase from "../firebase";

import { connect } from "react-redux";
import {
  initialFetch,
  fetchClientes,
  fetchEmpresa,
  fetchRodeosInactivos,
  fetchRodeosActivos,
  fetchCategorias,
  fetchReporteVentas,
  fetchMovRodeos,
  setFilterVentas,
  setFilterTropas,
  fetchDetalleDietas,
  fetchProductos,
  fetchDietas,
} from "../actions";

import ModalMultiple from "../components/filtros/ModalMultiple";
import FiltroFechas from "../components/filtros/ModalFechas";

import Api from "./../api";

const api = new Api();

class DemandaConsumo extends React.Component {
  constructor(props) {
    super(props);

    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.activarListener = props.initialFetch;
    this.fetchReporteVentas = props.fetchReporteVentas;
    this.fetchEmpresa = props.fetchEmpresa;
    this.fetchRodeosInactivos = props.fetchRodeosInactivos;
    this.fetchRodeosActivos = props.fetchRodeosActivos;
    this.fetchCategorias = props.fetchCategorias;
    this.fetchMovRodeos = props.fetchMovRodeos;
    this.fetchClientes = props.fetchClientes;
    this.fetchDietas = props.fetchDietas;

    this.fetchDetalleDietas = props.fetchDetalleDietas;
    this.fetchProductos = props.fetchProductos;

    this.setFilterTropas = props.setFilterTropas;
    this.setFilterVentas = props.setFilterVentas;

    this.seleccionVendidos = ["Vendidos", "Pendientes"];

    this.vendidosF = {};
    this.vendidosF["Vendidos"] = { id: "Vendidos", label: "Vendidos" };
    this.vendidosF["Pendientes"] = { id: "Pendientes", label: "Pendientes" };

    this.filteredVentas = {
      vendidos: props.filtrosVentas.vendidos,
      fechaInicio: props.filtrosVentas.fechaInicio,
      fechaFinal: props.filtrosVentas.fechaFinal,
      fechaInicioVta: props.filtrosVentas.fechaInicioVta,
      fechaFinalVta: props.filtrosVentas.fechaFinalVta,
      allRodeos: props.filtrosVentas.allRodeos,
      listaRodeos: props.filtrosVentas.listaRodeos,
      allCategorias: props.filtrosVentas.allCategorias,
      listaCategorias: props.filtrosVentas.listaCategorias,
      allClientes: props.filtrosVentas.allClientes,
      listaClientes: props.filtrosVentas.listaClientes,
      filtros: props.filtrosVentas.filtros,
      fechaCobro: props.filtrosVentas.fechaCobro,
    };

    this.filtrosFijosVentas = ["vendidos", "clientes", "rodeos", "categorias"];
    this.clientesFventas = [];
    this.rodeosFventas = [];
    this.categoriasFventas = [];

    this.state = {
      width: 450,
      height: 304,
      dietas: props.dietas || [],
      detalledietas: props.detalledietas || [],
      productos: props.productos || [],
      descripcion: "",
      listeners: props.listeners || [],
      clientes: props.clientes || [],
      reportes_ventas: props.reportes_ventas || [],
      rodeos_activos: props.rodeosActivos || [],
      rodeos_inactivos: props.rodeosInactivos || [],
      categorias: props.categorias || [],
      movrodeos: props.movrodeos || [],
      empresa: props.empresa,
      iva: props.empresa.iva || 10.5,
    };
  }

  buscarDietasProgramadas = () => {
    let dietasProgramads = this.db
      .collection("empresas")
      .doc(this.perfil.empresa)
      .collection("dietas_programadas")
      .orderBy("fecha", "asc")
      .onSnapshot((snap) => {
        let dietas_programadas = [];
        snap.docs.map((doc) => {
          dietas_programadas.push(doc.data());
        });
        console.log({ dietas_programadas });
      });
  };

  buscarDetalleDieta = (id) => {
    if (id) {
      const item = { id: id };

      var oyente = this.state.listeners.find(function (element) {
        return element.id === id;
      });

      if (oyente === undefined) {
        this.activarListener(this.perfil, item);
        this.fetchDetalleDietas(this.perfil, id);
      }
    }
  };

  updateDimensions() {
    if (window.innerWidth < 500) {
      this.setState({ width: 450, height: 302 });
    } else {
      let update_width = window.innerWidth - 100;
      let update_height = Math.round(update_width / 3);
      this.setState({ width: update_width, height: update_height });
    }
  }

  componentWillMount = () => {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();

    this.buscarDietasProgramadas();

    const empresa = { id: "empresa" };

    const oyenteEmpresa = this.state.listeners.find(function (element) {
      return element.id === "empresa";
    });

    if (oyenteEmpresa === undefined) {
      this.activarListener(this.perfil, empresa);
      this.fetchEmpresa(this.perfil);
    }

    const cliente = { id: "clientes" };

    const oyenteCliente = this.state.listeners.find(function (element) {
      return element.id === "clientes";
    });

    if (oyenteCliente === undefined) {
      this.activarListener(this.perfil, cliente);
      this.fetchClientes(this.perfil);
    }

    const categorias = { id: "categorias" };

    const oyenteCategorias = this.state.listeners.find(function (element) {
      return element.id === "categorias";
    });

    if (oyenteCategorias === undefined) {
      this.activarListener(this.perfil, categorias);
      this.fetchCategorias(this.perfil);
    }

    const itemReportes = { id: "reporteVentas" };

    const oyenteReportes = this.state.listeners.find(function (element) {
      return element.id === "reporteVentas";
    });

    if (oyenteReportes === undefined) {
      this.activarListener(this.perfil, itemReportes);
      this.fetchReporteVentas(this.perfil);
    }
  };

  componentDidMount = () => {
    this.getStock();

    const rodeosActivos = { id: "rodeos_activos" };
    const rodeosInactivos = { id: "rodeos_inactivos" };

    const oyenteActivos = this.state.listeners.find(function (element) {
      return element.id === "rodeos_activos";
    });

    if (oyenteActivos === undefined) {
      this.activarListener(this.perfil, rodeosActivos);
      this.fetchRodeosActivos(this.perfil);
    }

    const dietas = "dietas";
    const oyenteDietas = this.state.listeners.find(function (element) {
      return element.id === "dietas";
    });

    if (oyenteDietas === undefined) {
      this.activarListener(this.perfil, dietas);
      this.fetchDietas(this.perfil);
    }

    const oyenteInactivos = this.state.listeners.find(function (element) {
      return element.id === "rodeos_inactivos";
    });

    if (oyenteInactivos === undefined) {
      this.activarListener(this.perfil, rodeosInactivos);
      this.fetchRodeosInactivos(this.perfil);
    }

    const movimientos = { id: "movrodeos" };

    const oyenteMovimientos = this.state.listeners.find(function (element) {
      return element.id === "movrodeos";
    });

    if (oyenteMovimientos === undefined) {
      this.activarListener(this.perfil, movimientos);
      this.fetchMovRodeos(this.perfil);
    }

    const productos = { id: "productos" };

    const oyenteProductos = this.state.listeners.find(function (element) {
      return element.id === "productos";
    });

    if (oyenteProductos === undefined) {
      this.activarListener(this.perfil, productos);
      this.fetchProductos(this.perfil);
    }
  };

  getStock = () => api.getStock(this.props.perfil.empresa, this.props.dispatch);

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      dietas: nextProps.dietas || [],
      detalledietas: nextProps.detalledietas || [],
      dietas: nextProps.dietas || [],
      productos: nextProps.productos || [],
      listeners: nextProps.listeners || [],
      clientes: nextProps.clientes || [],
      movrodeos: nextProps.movrodeos || [],
      reportes_ventas: nextProps.reportes_ventas || [],
      rodeos_activos: nextProps.rodeosActivos || [],
      rodeos_inactivos: nextProps.rodeosInactivos || [],
      categorias: nextProps.categorias || [],
      empresa: nextProps.empresa,
      iva: nextProps.empresa.iva || 10.5,
    });

    this.filteredVentas = {
      vendidos: nextProps.filtrosVentas.vendidos,
      fechaInicioVta: nextProps.filtrosVentas.fechaInicioVta,
      fechaFinalVta: nextProps.filtrosVentas.fechaFinalVta,
      fechaInicio: nextProps.filtrosVentas.fechaInicio,
      fechaFinal: nextProps.filtrosVentas.fechaFinal,
      allRodeos: nextProps.filtrosVentas.allRodeos,
      listaRodeos: nextProps.filtrosVentas.listaRodeos,
      allCategorias: nextProps.filtrosVentas.allCategorias,
      listaCategorias: nextProps.filtrosVentas.listaCategorias,
      allClientes: nextProps.filtrosVentas.allClientes,
      listaClientes: nextProps.filtrosVentas.listaClientes,
      filtros: nextProps.filtrosVentas.filtros,
      fechaCobro: nextProps.filtrosVentas.fechaCobro,
    };
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  selectedFechaCobro = () => {
    this.filteredVentas.fechaCobro = true;
    this.setFilterVentas(this.filteredVentas);
  };

  selectedFechaVenta = () => {
    this.filteredVentas.fechaCobro = false;
    this.setFilterVentas(this.filteredVentas);
  };

  generarPDF() {
    this.db
      .collection("empresas")
      .doc(this.perfil.empresa)
      .collection("reportes_stock")
      .add({
        procesado: false,
        descripcion: this.state.descripcion,
        fecha: new Date(),
      });
  }

  actualizarIva = (iva) => {
    this.state.empresa.doc.ref.update({
      iva,
    });
  };

  formatoMoneda = (valor) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(valor);
  };
  formatoNumero = (valor, decimales) => {
    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: decimales,
    }).format(valor);
  };
  prettyDate = (date) => {
    var months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return (
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
    );
  };

  /////////////FECHAS//////////
  onChangeFechaInicio = (fechaInicio) => {
    this.filteredVentas.fechaInicio = fechaInicio;
    this.setFilterVentas(this.filteredVentas);
  };
  onChangeFechaFinal = (fechaFinal) => {
    this.filteredVentas.fechaFinal = fechaFinal;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaInicio = () => {
    this.filteredVentas.fechaInicio = null;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaFinal = () => {
    this.filteredVentas.fechaFinal = null;
    this.setFilterVentas(this.filteredVentas);
  };

  //fecha vta

  onChangeFechaInicioVta = (fechaInicio) => {
    this.filteredVentas.fechaInicioVta = fechaInicio;
    this.setFilterVentas(this.filteredVentas);
  };
  onChangeFechaFinalVta = (fechaFinal) => {
    this.filteredVentas.fechaFinalVta = fechaFinal;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaInicioVta = () => {
    this.filteredVentas.fechaInicioVta = null;
    this.setFilterVentas(this.filteredVentas);
  };
  borrarFechaFinalVta = () => {
    this.filteredVentas.fechaFinalVta = null;
    this.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllRodeos = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "rodeos"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "rodeos"
        );
      }
    }

    let rodeos = [];
    if (!this.filteredVentas.allRodeos) {
      Object.keys(opciones).map((opcion) => {
        rodeos.push(opciones[opcion].id);
      });

      this.filteredVentas.listaRodeos = rodeos;
      this.filteredVentas.allRodeos = true;
    } else {
      this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });
      this.filteredVentas.allRodeos = false;
      this.filteredVentas.listaRodeos = [];
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedRodeo = (id, opciones) => {
    this.filteredVentas.filtros.push({ nombre: "rodeos", hora: new Date() });

    let index = this.filteredVentas.listaRodeos.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaRodeos = this.filteredVentas.listaRodeos.filter(
        (e) => e !== id
      );
      this.filteredVentas.allRodeos = false;
    } else {
      let rodeos = [];

      if (this.filteredVentas.allRodeos) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) rodeos.push(opciones[opcion].id);
        });

        this.filteredVentas.listaRodeos = rodeos;
        this.filteredVentas.allRodeos = false;
      } else {
        this.filteredVentas.listaRodeos =
          this.filteredVentas.listaRodeos.concat(id);
        this.filteredVentas.allRodeos = false;
      }
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedAllCategorias = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "categorias"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "categorias"
        );
      }
    }

    let categorias = [];
    if (!this.filteredVentas.allCategorias) {
      Object.keys(opciones).map((opcion) => {
        categorias.push(opciones[opcion].id);
      });

      this.filteredVentas.listaCategorias = categorias;
      this.filteredVentas.allCategorias = true;
    } else {
      this.filteredVentas.filtros.push({
        nombre: "categorias",
        hora: new Date(),
      });
      this.filteredVentas.allCategorias = false;
      this.filteredVentas.listaCategorias = [];
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedCategoria = (id, opciones) => {
    this.filteredVentas.filtros.push({
      nombre: "categorias",
      hora: new Date(),
    });

    let index = this.filteredVentas.listaCategorias.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaCategorias =
        this.filteredVentas.listaCategorias.filter((e) => e !== id);
      this.filteredVentas.allCategorias = false;
    } else {
      let categorias = [];

      if (this.filteredVentas.allCategorias) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) categorias.push(opciones[opcion].id);
        });

        this.filteredVentas.listaCategorias = categorias;
        this.filteredVentas.allCategorias = false;
      } else {
        this.filteredVentas.listaCategorias =
          this.filteredVentas.listaCategorias.concat(id);
        this.filteredVentas.allCategorias = false;
      }
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedAllClientes = (opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "clientes"
    );
    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);

      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "clientes"
        );
      }
    }

    let clientes = [];
    if (!this.filteredVentas.allClientes) {
      Object.keys(opciones).map((opcion) => {
        clientes.push(opciones[opcion].id);
      });

      this.filteredVentas.listaClientes = clientes;
      this.filteredVentas.allClientes = true;
    } else {
      this.filteredVentas.filtros.push({
        nombre: "clientes",
        hora: new Date(),
      });
      this.filteredVentas.allClientes = false;
      this.filteredVentas.listaClientes = [];
    }
    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedClientes = (id, opciones) => {
    this.filteredVentas.filtros.push({ nombre: "clientes", hora: new Date() });

    let index = this.filteredVentas.listaClientes.indexOf(id);

    if (index > -1) {
      this.filteredVentas.listaClientes =
        this.filteredVentas.listaClientes.filter((e) => e !== id);
      this.filteredVentas.allClientes = false;
    } else {
      let clientes = [];

      if (this.filteredVentas.allClientes) {
        Object.keys(opciones).map((opcion) => {
          if (opciones[opcion].id !== id) clientes.push(opciones[opcion].id);
        });

        this.filteredVentas.listaClientes = clientes;
        this.filteredVentas.allClientes = false;
      } else {
        this.filteredVentas.listaClientes =
          this.filteredVentas.listaClientes.concat(id);
        this.filteredVentas.allClientes = false;
      }
    }
    this.setFilterVentas(this.filteredVentas);
  };

  onSelectedAllVendidosPendientes = () => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "vendidos"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "vendidos"
        );
      }
    }

    this.seleccionVendidos = ["Vendidos", "Pendientes"];

    this.filteredVentas.vendidos = null;

    this.setFilterVentas(this.filteredVentas);
  };
  onSelectedVendidosPendientes = (id, opciones) => {
    let busqueda = this.filteredVentas.filtros.find(
      (f) => f.nombre === "vendidos"
    );

    if (busqueda !== null && busqueda !== undefined) {
      let index = this.filteredVentas.filtros.indexOf(busqueda);
      if (index > -1) {
        this.filteredVentas.filtros = this.filteredVentas.filtros.filter(
          (e) => e.nombre !== "vendidos"
        );
      }
    }

    if (id === "Vendidos") {
      this.seleccionVendidos = ["Vendidos"];

      this.filteredVentas.filtros.push({
        nombre: "vendidos",
        hora: new Date(),
      });
      this.filteredVentas.vendidos = true;
      this.setFilterVentas(this.filteredVentas);
    }

    if (id === "Pendientes") {
      this.seleccionVendidos = ["Pendientes"];

      this.filteredVentas.filtros.push({
        nombre: "vendidos",
        hora: new Date(),
      });
      this.filteredVentas.vendidos = false;
      this.setFilterVentas(this.filteredVentas);
    }
  };

  aplicarFiltroClientes = (lista) => {
    this.clientesFventas = [];
    //filtrando por categorias
    lista = lista.filter((d) => {
      const objCliente = this.state.clientes.find((c) => c.id === d.cliente);

      let cliente = {};
      cliente.id = d.cliente;
      cliente.label = objCliente ? objCliente.nombre : "Sin definir";
      this.clientesFventas[cliente.id] = cliente;

      let bandera = true;
      if (!this.filteredVentas.allClientes) {
        let index = this.filteredVentas.listaClientes.indexOf(d.cliente);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  aplicarFiltroVendidos = (lista) => {
    //filtrando por dietas

    lista = lista.filter((d) => {
      if (this.filteredVentas.vendidos === null) {
        return true;
      }

      if (this.filteredVentas.vendidos === true) {
        return d.vendido === true;
      }

      if (this.filteredVentas.vendidos === false) {
        return d.vendido === false;
      }
    });

    return lista;
  };
  aplicarFiltroCategorias = (lista) => {
    this.categoriasFventas = [];
    //filtrando por categorias
    lista = lista.filter((d) => {
      let categoria = {};
      categoria.id = d.categoria;
      categoria.label = d.categoria;
      this.categoriasFventas[categoria.id] = categoria;

      let bandera = true;
      if (!this.filteredVentas.allCategorias) {
        let index = this.filteredVentas.listaCategorias.indexOf(d.categoria);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };
  aplicarFiltroRodeos = (lista) => {
    this.rodeosFventas = [];
    //filtrando por rodeo
    lista = lista.filter((d) => {
      let rodeo = {};
      rodeo.id = d.rodeo;
      rodeo.label = d.rodeo;
      this.rodeosFventas[rodeo.id] = rodeo;

      let bandera = true;
      if (!this.filteredVentas.allRodeos) {
        let index = this.filteredVentas.listaRodeos.indexOf(d.rodeo);
        if (index < 0) bandera = false;
      }

      return bandera;
    });

    return lista;
  };

  buscarDietas = (dietas) => {
    Object.keys(dietas).map((value) => {
      this.buscarDetalleDieta(value);
    });
  };

  render() {
    const { stock } = this.props;

    if (!stock) {
      return <h2>Cargando...</h2>;
    }

    var _this = this;

    //tropas activas
    let listaVentaReporte = this.state.rodeos_activos.map((value) => {
      var f1 = new Date();
      f1.setHours(0);
      f1.setMinutes(0);
      f1.setSeconds(0);
      f1.setMilliseconds(0);

      var f2 = value.ultimaFechaCalculada
        ? value.ultimaFechaCalculada.toDate
          ? value.ultimaFechaCalculada.toDate()
          : new Date(value.ultimaFechaCalculada)
        : new Date();
      f2.setHours(0);
      f2.setMinutes(0);
      f2.setSeconds(0);
      f2.setMilliseconds(0);

      const aumento = ((f1.getTime() - f2.getTime()) / 86400000) * value.gdpv;
      const pesoActual =
        (Number(value.ultimaPesada) ? value.ultimaPesada : 0) + Number(aumento);

      //value.metaDiasEncierre
      //fecha de salida
      let fechaEstimada = new Date();
      let diasEncierre =
        new Date().getTime() -
        (value.fechaAlta.toDate
          ? value.fechaAlta.toDate().getTime()
          : new Date(value.fechaAlta).getTime());
      diasEncierre = Number(this.formatoNumero(diasEncierre / 86400000, 0));

      let dias = 0;
      //kilos
      const metaDias = Number(value.metaDiasEncierre) || 0;
      const metaKilos = Number(value.metaKilos) || 0;
      let kilos = metaKilos;
      if (value.seguir) {
        if (pesoActual >= metaKilos && metaKilos > 0) {
          kilos = pesoActual;
          fechaEstimada = new Date();
        } else if (diasEncierre >= metaDias && metaDias > 0) {
          fechaEstimada = new Date();
        } else {
          if (metaDias > 0 || metaKilos > 0) {
            let faltantePorKilos = 0;
            let faltantePorDias = 0;
            if (metaKilos > 0) {
              faltantePorKilos = (metaKilos - pesoActual) / value.gdpv;
            }
            if (metaDias > 0) {
              faltantePorDias = metaDias - diasEncierre;
            }

            if (metaDias === 0) {
              dias = Math.ceil(faltantePorKilos);
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorKilos) * 86400000
              );
            }

            if (metaKilos === 0) {
              dias = Math.ceil(faltantePorDias);
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorDias) * 86400000
              );
            }

            if (faltantePorKilos <= faltantePorDias && metaKilos > 0) {
              dias = Math.ceil(faltantePorKilos);
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorKilos) * 86400000
              );
            }

            if (faltantePorDias <= faltantePorKilos && metaDias > 0) {
              dias = Math.ceil(faltantePorDias);
              fechaEstimada = new Date(
                fechaEstimada.getTime() + Math.ceil(faltantePorDias) * 86400000
              );
            }
          }
        }
      } else {
        if (diasEncierre >= metaDias) {
          fechaEstimada = new Date();
        } else {
          dias = metaDias - diasEncierre;
          fechaEstimada = new Date(
            fechaEstimada.getTime() +
              Math.ceil(metaDias - diasEncierre) * 86400000
          );
        }
      }

      let categoria = this.state.categorias.find(
        (c) => c.id === value.categoria
      );

      let fechaCobro = fechaEstimada;

      if (value.plazo)
        fechaCobro = new Date(fechaCobro.getTime() + value.plazo * 86400000);

      return {
        dias,
        doc: value.doc,
        id: value.id + fechaEstimada,
        fecha: fechaEstimada,
        fechaCobro,
        cliente: "Sin definir",
        dieta: value.dieta,
        rodeo: value.nombre,
        categoria: categoria ? categoria.nombre : "-",
        cabezas: value.cabezas,
        kilos,
        plazo: value.plazo || 0,
        consumo_estimado: value.consumo_estimado || 0,
        precio: value.precioventa || 0,
        neto: value.cabezas * categoria.venta * kilos,
        total:
          value.cabezas * categoria.venta * kilos * (this.state.iva / 100 + 1),
        vender: value.vender ? value.vender : false,
        iva: Number(this.state.iva),
        vendido: false,
      };
    });

    //let listaVentaReporte = tropas.filter(t => t.vender)

    let listaFiltrosV = this.filteredVentas.filtros.reduce((filtro, item) => {
      let val = item.nombre;
      filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
      return filtro;
    }, {});

    listaFiltrosV = Object.keys(listaFiltrosV).sort((a, b) => {
      if (listaFiltrosV[a].hora > listaFiltrosV[b].hora) {
        return 1;
      }

      if (listaFiltrosV[a].hora < listaFiltrosV[b].hora) {
        return -1;
      }
      return 0;
    });

    this.filtrosFijosVentas.forEach((fijo) => {
      let index = listaFiltrosV.indexOf(fijo);
      if (index < 0) listaFiltrosV.push(fijo);
    });

    listaVentaReporte = listaVentaReporte.filter((d) => {
      var fecha = d.fecha.toDate ? d.fecha.toDate() : new Date(d.fecha); //new Date(d.detalle.fecha);

      let banderaFechaInicio = true;
      if (this.filteredVentas.fechaInicioVta !== null) {
        var fechaInicio = new Date(this.filteredVentas.fechaInicioVta);
        fechaInicio.setHours(0);
        banderaFechaInicio = fecha > fechaInicio;
      }

      let banderaFechaFin = true;
      if (this.filteredVentas.fechaFinalVta !== null) {
        var fechaFin = new Date(this.filteredVentas.fechaFinalVta);
        fechaFin.setHours(23);
        fechaFin.getMinutes(59);
        banderaFechaFin = fechaFin > fecha;
      }

      return banderaFechaInicio && banderaFechaFin && !d.vendido;
    });

    listaFiltrosV.forEach((f) => {
      // if (f === "vendidos") listaVentaReporte = _this.aplicarFiltroVendidos(listaVentaReporte);
      if (f === "rodeos")
        listaVentaReporte = _this.aplicarFiltroRodeos(listaVentaReporte);
      if (f === "categorias")
        listaVentaReporte = _this.aplicarFiltroCategorias(listaVentaReporte);
      // if (f === "clientes") listaVentaReporte = _this.aplicarFiltroClientes(listaVentaReporte);
    });

    ////LO QUE IMPORTA

    //let data = []
    const listaReduced = listaVentaReporte.reduce((dieta, item) => {
      const diet = this.state.dietas.find((d) => d.id === item.dieta);
      let nombre = "Sin definir";
      if (diet) {
        nombre = diet.nombre;
      }
      let val = item.dieta;
      dieta[val] = dieta[val] || { dieta: val, nombre, consumo: 0 };
      dieta[val].consumo += Number(
        Number(item.cabezas) * Number(item.consumo_estimado) * item.dias
      );
      return dieta;
    }, {});

    this.buscarDietas(listaReduced);

    let lista_productos = [];

    let supertotal_tc = 0;
    let supertotal_ms = 0;
    let supertotal_pesos = 0;

    if (
      Object.keys(listaReduced).length > 0 &&
      this.state.detalledietas.length > 0 &&
      this.state.productos.length > 0
    ) {
      let productos = [];

      this.state.detalledietas.map((dieta) => {
        dieta.detalle.map((producto) => {
          productos.push({
            dieta: dieta.id,
            producto: producto.producto,
            cantidad: producto.cantidad,
          });
        });
      });

      this.state.productos.map((producto) => {
        let detalle = [];
        let total = 0;
        let totalms = 0;
        const coeficiente =
          producto.materia > 0 ? 1 / (producto.materia / 100) : 0;

        Object.keys(listaReduced).map((dieta) => {
          const pd = productos.find(
            (p) => p.dieta === dieta && p.producto === producto.id
          );
          let cantidad = pd ? pd.cantidad : 0;
          let subtotal =
            cantidad > 0 ? listaReduced[dieta].consumo / (100 / cantidad) : 0;
          detalle.push({ dieta, subtotal: subtotal * coeficiente });
          totalms += subtotal;
          total += subtotal * coeficiente;
        });

        supertotal_ms += totalms;
        supertotal_tc += total;
        supertotal_pesos += total * producto.precio;

        const producto_stock = stock.productos.find(
          (p) => p.id === producto.id
        );

        let p = {
          precio: producto.precio,
          detalle,
          totalms,
          total,
          id: producto.id,
          nombre: producto.nombre,
          stock: producto_stock.stock > 0 ? producto_stock.stock : 0,
          diferencia: producto_stock.stock - total,
        };
        lista_productos.push(p);
      });
    }

    lista_productos = lista_productos.filter((p) => p.total > 0);

    let data = [];

    lista_productos.map((p) => {
      if (p.total > p.stock) {
        data.push({ name: p.nombre, Stock: p.stock, Demanda: p.total });
      }
    });

    let renderProductos = lista_productos.map((producto) => (
      <Table.Row>
        <Table.Cell textAlign="left">{producto.nombre}</Table.Cell>
        {producto.detalle.map((d) => (
          <Table.Cell textAlign="right">
            {this.formatoNumero(d.subtotal, 0)}
          </Table.Cell>
        ))}
        <Table.Cell textAlign="right">
          {this.formatoNumero(producto.total, 0)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {this.formatoNumero(producto.totalms, 0)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {this.formatoMoneda(producto.total * producto.precio)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {this.formatoNumero(producto.stock, 0)}
        </Table.Cell>
        <Table.Cell
          style={{
            backgroundColor: producto.diferencia < 0 ? "#FF4000" : "white",
          }}
          textAlign="right"
        >
          {this.formatoNumero(producto.diferencia, 0)}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Grid>
        <Grid.Row centered>
          <Segment raised>
            <Table celled striped color={"blue"} valigned={"center"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">
                    <FiltroFechas
                      sin_limite={true}
                      titulo={"Fecha Vta"}
                      borrarFechaInicio={this.borrarFechaInicioVta}
                      borrarFechaFinal={this.borrarFechaFinalVta}
                      fechaInicio={this.filteredVentas.fechaInicioVta}
                      fechaFinal={this.filteredVentas.fechaFinalVta}
                      onChangeFechaInicio={this.onChangeFechaInicioVta}
                      onChangeFechaFinal={this.onChangeFechaFinalVta}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <ModalMultiple
                      opciones={this.rodeosFventas}
                      label={"Rodeo"}
                      onSelected={this.onSelectedRodeo}
                      onSelectedAll={this.onSelectedAllRodeos}
                      todo={this.filteredVentas.allRodeos}
                      seleccion={this.filteredVentas.listaRodeos}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    <ModalMultiple
                      opciones={this.categoriasFventas}
                      label={"Categoria"}
                      onSelected={this.onSelectedCategoria}
                      onSelectedAll={this.onSelectedAllCategorias}
                      todo={this.filteredVentas.allCategorias}
                      seleccion={this.filteredVentas.listaCategorias}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </Segment>
        </Grid.Row>

        <Grid.Row centered>
          <Table celled striped color={"blue"} valigned={"center"}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                {Object.keys(listaReduced).map((dieta) => (
                  <Table.HeaderCell></Table.HeaderCell>
                ))}
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  {this.formatoNumero(supertotal_tc, 3)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  {this.formatoNumero(supertotal_ms, 3)}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  {this.formatoMoneda(supertotal_pesos)}
                </Table.HeaderCell>

                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                ></Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                ></Table.HeaderCell>
              </Table.Row>

              <Table.Row>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  Producto
                </Table.HeaderCell>
                {Object.keys(listaReduced).map((dieta) => (
                  <Table.HeaderCell
                    className="center aligned"
                    style={{ padding: 2 }}
                  >
                    {listaReduced[dieta].nombre}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  Total TC
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  Total MS
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  Total $
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  Stock
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="center aligned"
                  style={{ padding: 2 }}
                >
                  Diferencia
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderProductos}</Table.Body>
          </Table>
        </Grid.Row>

        {data.length > 0 && (
          <Grid.Row centered>
            <div
              style={{
                overflow: "scroll",
                height: `${this.state.height + 60}px;`,
                width: `${this.state.width}px;`,
              }}
            >
              <ComposedChart
                layout="vertical"
                width={this.state.width}
                height={this.state.height}
                data={data}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <YAxis dataKey="name" type="category" />
                <XAxis type="number" />
                <Tooltip
                  formatter={(value, name, props) =>
                    `${this.formatoNumero(value, 3)}`
                  }
                />
                <Legend verticalAlign="top" iconType={"diamond"} />
                {/* <Area dataKey='amt' fill='#8884d8' stroke='#8884d8' /> */}
                <Bar dataKey="Stock" fill="#04B431" />
                <Bar dataKey="Demanda" fill="#FF4000" />
                {/* <Line dataKey='uv' stroke='#ff7300' /> */}
              </ComposedChart>
            </div>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    stock: state.personalStore.stock,
    dietas: state.personalStore.dietas,
    productos: state.personalStore.productos,
    detalledietas: state.personalStore.detalledietas,
    clientes: state.personalStore.clientes,
    movrodeos: state.personalStore.movrodeos,
    empresa: state.personalStore.empresa,
    categorias: state.personalStore.categorias,
    reportes_ventas: state.personalStore.reportes_ventas,
    rodeosActivos: state.personalStore.rodeosActivos,
    rodeosInactivos: state.personalStore.rodeosInactivos,
    filtrosVentas: state.personalStore.filtrosVentas,
    filtrosTropasActivas: state.personalStore.filtrosTropasActivas,
    listeners: state.personalStore.listeners,
  };
}

export default connect(mapStateToProps, {
  initialFetch,
  fetchClientes,
  fetchEmpresa,
  fetchRodeosInactivos,
  fetchRodeosActivos,
  fetchReporteVentas,
  fetchCategorias,
  fetchMovRodeos,
  setFilterVentas,
  setFilterTropas,
  fetchProductos,
  fetchDetalleDietas,
  fetchDietas,
})(DemandaConsumo);
