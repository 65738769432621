const cargas = (state = [], action) => {
    switch (action.type) {
        case 'ADD_CARGA':
                return action.cargas
        case 'USER_LOGOUT':
           return [];
        default:
            return state
    }
};

export default cargas;