const rodeos_reporte = (state = [], action) => {
    switch (action.type) {
        case 'ADD_RODEO_REPORTE':
           return action.rodeosReportes
        case 'ADD_TODOS_RODEOS_REPORTE':
                    let items = [];
                    
                    Object.keys(action).forEach((r)=>{
                        if (r !== "type")
                        items.push({id: action[r].id, nombre: action[r].nombre, fechaInicio: action[r].fechaInicio, gdpv: action[r].gdpv, dieta: action[r].dieta, categoria: action[r].categoria, ultimaFechaPesada: action[r].ultimaFechaPesada, fechaAlta: action[r].fechaAlta, ultimoPrecio: action[r].ultimoPrecio, activo: action[r].activo });
                    })
                    return items
        default:
            return state
    }
};

export default rodeos_reporte;