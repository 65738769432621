import React from 'react';
import { firestoreConnect } from 'react-redux-firebase'
import { Menu, Button, Form, Modal, Image, Header } from 'semantic-ui-react'
import firebase from '../firebase';

class Login extends React.Component {

  static isPrivate = false;

  constructor(props) {
    super(props);

    this.ruta = props.ruta;
    this.client = props.client;
    this.user = props.user;

    this.db = firebase.firestore();

    this.state = ({
      count: 1,
      usuario: "",
      password: "",
      error: "",
      isLogin: false,
      nombre: "",
      correo: "",
      telefono: "",
      msj: ""
    })

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.signIn = this.signIn.bind(this);
    this.userInvalidad = this.userInvalidad.bind(this);
    this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);

    this.interval = null;
  }

  aumentar = () => {

    if (this.state.count > 8) {
      this.setState({
        count: 1
      })
    } else {
      this.setState({
        count: this.state.count + 1
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.user = nextProps.user

    // if (this.user && this.interval)
    //   clearInterval(this.interval);
  }


  // componentDidMount = () => {
  //   let _this = this;

  //   if (!this.user) {
  //     this.interval = setInterval(function () {
  //       _this.aumentar();
  //       let div = document.getElementById('slide')
  //       if (div) {
  //         div.style.backgroundRepeat = "none";
  //         switch (_this.state.count) {
  //           case 1:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F1.png?alt=media&token=d49ab107-366f-4b06-bf75-4048368d5e55')";
  //             break;
  //           case 2:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F2.png?alt=media&token=9ae8d2f4-a04a-4938-925a-f1c017c43e38')"
  //             break;
  //           case 3:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F3.png?alt=media&token=6bbec74f-3d4f-4057-bc84-3bf4c0392432')"
  //             break;
  //           case 4:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F4.png?alt=media&token=106d05fa-dde9-4948-ac3d-91b48640bdec')"
  //             break;
  //           case 5:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F5.png?alt=media&token=d6e3b3f5-9c8a-4481-9047-36e950883a21')"
  //             break;
  //           case 6:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F6.png?alt=media&token=103e3c2c-00b3-4e22-937c-be8bdd1ac0ec')"
  //             break;
  //           case 7:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F7.png?alt=media&token=6bd1ab32-9a04-4719-a877-7a89faf10f92')"
  //             break;
  //           case 8:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F8.png?alt=media&token=ceae1f39-e749-45a4-811b-240e63c8402d')"
  //             break;
  //           case 9:
  //             div.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F8.png?alt=media&token=ceae1f39-e749-45a4-811b-240e63c8402d')"
  //             break;

  //         }
  //       }

  //     }, 6000)
  //   }
  // }



  handleChangeUsuario(e) {
    this.setState({ usuario: e.target.value });
  }

  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      evt.persist();
      this.signIn(evt);
    }
  };

  userInvalidad = (error) => {
    this.setState({ error })
  }

  signIn = (e) => {
    e.preventDefault();
    const userInvalid = this.userInvalidad;
    if (this.state.password.length > 0 && this.state.usuario.length > 0) {

      this.props.firebase.auth().signInWithEmailAndPassword(this.state.usuario, this.state.password)
        .then((p) => {
          if (this.interval)
            clearInterval(this.interval);
          // console.log(this.props.firebase.get())
          //window.location.assign(this.ruta);
        })
        .catch(error => userInvalid("Usuario o contraseña incorrecto/s"));

    } else {
      this.setState({ error: "Debe ingresar usuario y contraseña!" });
    }
  };


  solicitar =(e)=>{
    e.preventDefault();

    if (this.state.correo.trim().length === 0 || this.state.nombre.trim().length === 0 || this.state.telefono.trim().length === 0){
      this.setState({msj: "Debe completar todos los datos"})
    }else{
      this.db.collection("info").add({
        nombre: this.state.nombre,
        correo: this.state.correo,
        telefono: this.state.telefono
      }).then(ref => {
        this.setState({
          msj: "",
          nombre: "",
          correo: "",
          telefono: ""
        })
      });
      
    }

  }

  info = () => (
    <Modal
      open={this.state.modalOpen}
      onClose={this.handleClose}
      trigger={<button
        style={{
          backgroundColor: "#4CAF50",
          color: "white:",
          fontSize: "1em",
          padding: "14px 20px",
          margin: "8px 0",
          border: "none",
          cursor: "pointer",
          width: "100%"
        }}
      ><span style={{ color: "white", fontSize: "1.2em" }}>Solicitar más información</span></button>}
      size='mediums'
    >
      <Modal.Content image>
          <Image wrapped size='large' src='./assets/info.png' />
        <Modal.Description>
          <form>
            
              <label>Nombre</label>
              <input type="text" placeholder="Nombre" id="nombre" value={this.state.nombre} onChange={(e) => this.setState({ nombre: e.target.value })} />
              <label>Correo Electronico</label>
              <input type="text" placeholder="Correo Electronico" value={this.state.correo} onChange={(e) => this.setState({ correo: e.target.value })} />
              <label>Telefono</label>
              <input type="text" placeholder="Telefono / WhatsApp" value={this.state.telefono} onChange={(e) => this.setState({ telefono: e.target.value })} />
            
              <button className="buttonLogin" onClick={(e) => this.solicitar(e)}><span>Solicitar</span></button>
              <span style={{color: "black", fontSize: "1em", textAlign: "center"}}>{this.state.msj}</span>
          </form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )



  render() {

    return (
      <div className="container-init">



        <div className="item-init">
          <div className="container-login">
            <div className="item-login">
              <img src="/assets/logo.png" alt="" />
              <p>Mis Corrales</p>
            </div>
            <div className="item-login">
              <form action="">
                <label htmlFor="user">Usuario</label>
                <input type="text" id="usuario" value={this.state.usuario} onChange={this.handleChangeUsuario} onKeyUp={this.handleKey} />
                <label htmlFor="password">Contraseña</label>
                <input type="password" value={this.state.password} onChange={this.handleChangePassword} onKeyUp={this.handleKeyUp} />
                <button className="buttonLogin" type="submit" onClick={(e) => this.signIn(e)}>Entrar</button>
                <span className="errorLogin">{this.state.error}</span>
              </form>
            </div>

            <div className="item-login">
              {this.info()}
            </div>
          </div>
        </div>

        <div className="item-init">
              
              <div className="fb-page" data-href="https://www.facebook.com/miscorralescom/" data-tabs="timeline" data-width="800" data-height="800" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/miscorralescom/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/miscorralescom/">Miscorrales.com</a></blockquote></div>
        
        </div>
        {/* <div className="item-init" id="slide">
        </div> */}
        {/* <div className="item-init">
            <video controls>
            <source src="https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2Fmiscorrales.mp4?alt=media&token=a4c0e0fc-d718-4e88-9359-e21dd7068924" type="video/mp4" />
            Tu navegador no soporta el formato de este video
            </video>
        </div> */}

        {/* <script src="/assets/static/slide.js"></script> */}
      </div>
    )
  };
}

const wrappedLogin = firestoreConnect()(Login)

export default wrappedLogin;