const demanda = (state = [], action) => {
    switch (action.type) {
        case 'SET_DEMANDA':
            // if (state.length === 0) {
                let demanda = [];

                Object.keys(action).forEach((d) => {
                    if (d !== "type") demanda.push(action[d]);
                })
                return demanda
            // } else {
            //     Object.keys(action).forEach((d) => {
            //         if (d !== "type") state.push(action[d])
            //     })
            //     return state;
            // }
        default:
            return state
    }
}

export default demanda;