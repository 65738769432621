import React from 'react';

import { Switch, Redirect, Route } from "react-router-dom";
import { Link } from "react-router-dom";

import { Grid, Container, Icon, Menu, Segment } from 'semantic-ui-react';

import Ventas from './../ventas/Ventas';
import ListaRecria from './ListaRecria';
import GraficoVentas from './GraficoVentas';
import DemandaConsumo from './DemandaConsumos';

import Simulador from './Simulador';

import RequireProfile from './RequireProfile';

import WrappedDemanda from './WrappedDemanda';


// function envolver(WrappedComponent, datos) {
//     return class extends React.Component {
//         render() {
//             return <WrappedComponent data={datos} {...this.props} />;
//         }
//     };
// }

export default class ReporteVentas extends React.Component {
    constructor(props) {
        super(props);

        this.activeItem = "datos";

        if (props.location && props.location.pathname){
            if (props.location.pathname === "/reportes/ventas/consumos"){
                this.activeItem = "consumos";
            }
            if (props.location.pathname === "/reportes/ventas/chart"){
                this.activeItem = "chart";
            }
            if (props.location.pathname === "/reportes/ventas/recria"){
                this.activeItem = "recria";
            }
            if (props.location.pathname === "/reportes/ventas/simulador"){
                this.activeItem = "simulador";
            }
        }
    }

    render() {


        return (
            <Container fluid>
                <Grid>
                    <Grid.Column width={16}>

                        <Segment raised>

                            <Menu icon='labeled'>

                                <Menu.Item as={Link}
                                    to='/reportes/ventas/datos'
                                    name='datos'
                                    active={this.activeItem === 'datos'}>
                                    <Icon name='database' />
                                    Planilla de Salidas
                                </Menu.Item>

                                {/* <Menu.Item as={Link}
                                    to='/reportes/ventas/recria'
                                    name='recria'
                                    active={this.activeItem === 'recria'}>
                                    <Icon name='database' />
                                    Planilla Recria
                                </Menu.Item> */}
                                
                                <Menu.Item as={Link}
                                    to='/reportes/ventas/chart'
                                    name='chart'
                                    active={this.activeItem === 'chart'}>
                                    <Icon name='chart bar' />
                                    Resumen Venta
                                </Menu.Item>


                                <Menu.Item as={Link}
                                    to='/reportes/ventas/consumos'
                                    name='consumos'
                                    active={this.activeItem === 'consumos'}>
                                    <Icon name='table' />
                                    Demanda
                                </Menu.Item>

                                <Menu.Item as={Link}
                                    to='/reportes/ventas/simulador'
                                    name='simulador'
                                    active={this.activeItem === 'simulador'}>
                                    <Icon name='calculator' />
                                    Simulador
                                </Menu.Item>
                            </Menu>

                            <Segment raised>
                                <Switch>
                                    <Route exact={true} path="/reportes/ventas" component={RequireProfile(Ventas)} />
                                    <Route path="/reportes/ventas/datos" component={RequireProfile(Ventas)} />
                                    <Route path="/reportes/ventas/recria" component={RequireProfile(ListaRecria)} />
                                    <Route path="/reportes/ventas/chart" component={RequireProfile(GraficoVentas)} />
                                    <Route path="/reportes/ventas/consumos" component={RequireProfile(DemandaConsumo)} />
                                    <Route path="/reportes/ventas/simulador" component={RequireProfile(WrappedDemanda(Simulador))} />
                                    <Redirect to='/' />
                                </Switch>
                            </Segment>
                            {/* <ListaTropasActivas tropas={tropas} /> */}
                        </Segment>

                    </Grid.Column>

                </Grid>
            </Container>
        )
    }
}