import { combineReducers } from 'redux';

import user from './user';
import empresa from './empresa';
import mixers from './mixers';
import corrales from './corrales';
import categorias from './categorias';
import productos from './productos';
import dietas from './dietas';
import detalledietas from './detalledietas';
import listeners from './listeners';
import confnotificaciones from './confnotificaciones';
import usuarios from './usuarios';
import stock from './stock';
import rodeosActivos from './rodeos_activos';
import rodeosInactivos from './rodeos_inactivos';
import tratamientos from './tratamientos';
import categoriasSanitarias from './categoriasSanitarias';
import productosSanitarios from './productosSanitarios';
import descargas from './descargas';
import ingredientes from './ingredientes';
import filtrosDescargas from './filtrosDescargas';
import filtrosSeguimiento from './filtrosSeguimiento';
import filtrosCargas from './filtrosCargas';
import filtrosRodeos from './filtrosRodeos';
import filtrosStock from './filtrosStock';
import movrodeos from './movrodeos';
import cargas from './cargas';
import empresas from './empresas';
import consumos from './consumos';
import consumos_sin_seguimiento from './consumos_sin_seguimiento';
import tratamientos_programados from './tratamientos_programados';
import reportes from './reportes';
import rodeos_reporte from './rodeos_reporte';
import filtrosReportes from './filtrosReportes';
import reportes_stock from './reportes_stock';
import reportes_ventas from './reportes_ventas';
import reportes_tratamientos from './reportes_tratamientos';
import reportes_programas_sanitarios from './reportes_programas_sanitarios';
import filtrosReportesTratamientos from './filtrosReportesTratamientos';
import filtrosReportesProgramasSanitarios from './filtrosReportesProgramasSanitarios';
import ordenes_compra from './reportes_ordenes_compra';
import clientes from './clientes';
import filtrosVentas from './filtrosVentas';
import filtrosTropasActivas from './filtrosTropasActivas';
import filtrosTablero from './filtrosTablero';
import clima from './clima';
import demanda from './demanda';
import stockPorPeriodos from './stockPorPeriodos';
import nuevoReporte from './../reportes/reducers'

const rootReducer = combineReducers({
    filtrosTablero,
    nuevoReporte,
    clima,
    filtrosTropasActivas,
    filtrosVentas,
    clientes,
    ordenes_compra,
    filtrosReportesProgramasSanitarios,
    filtrosReportesTratamientos,
    reportes_programas_sanitarios,
    reportes_tratamientos,
    reportes_ventas,
    reportes_stock,
    user,
    cargas,
    empresa,
    mixers,
    corrales,
    categorias,
    productos,
    dietas,
    detalledietas,
    listeners,
    confnotificaciones,
    usuarios,
    stock,
    rodeosActivos,
    rodeosInactivos,
    tratamientos,
    categoriasSanitarias,
    productosSanitarios,
    descargas,
    ingredientes,
    movrodeos,
    filtrosSeguimiento,
    filtrosDescargas,
    filtrosCargas,
    filtrosRodeos,
    filtrosStock,
    consumos,
    consumos_sin_seguimiento,
    tratamientos_programados,
    empresas,
    reportes,
    rodeos_reporte,
    filtrosReportes,
    demanda,
    stockPorPeriodos
});

export default rootReducer;