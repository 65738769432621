
const listeners = (state = [], action) => {
    switch (action.type) {
        case 'SEARCH':
            if (state.map(m => m.id).includes(action.id)) {
                return state;
            }else{
                return [
                ...state,
                action
                ]
            }
        default:
            return state
    }
};

export default listeners;