import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader, LabelDetail } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';


const optionsEntrada = [
  {
    key: "Compra",
    text: "Compra",
    value: "Compra"
  },
  {
    key: "Encierre",
    text: "Encierre",
    value: "Encierre"
  },
  {
    key: "Recuento",
    text: "Entrada por Recuento",
    value: "Entrada por Recuento"
  }
];

const optionsSalida = [
  {
    key: "Venta",
    text: "Venta",
    value: "Venta"
  },
  {
    key: "Campo",
    text: "Salida a Campo",
    value: "Salida a Campo"
  },
  {
    key: "Transferencia",
    text: "Transferencia",
    value: "Transferencia"
  },
  {
    key: "Generar Rodeo",
    text: "Generar Rodeo",
    value: "Generar Rodeo"
  },
  {
    key: "Recuento",
    text: "Salida por Recuento",
    value: "Salida por Recuento"
  }
];

class RodeoNewMovimiento extends Component {
  constructor(props) {
    super(props);

    this.perfil = props.perfil;
    this.corrales = props.corrales || [];
    this.db = props.db;

    this.rodeos = props.rodeos;

    this.rodeo = props.rodeo;

    this.empresa = props.empresa;

    this.error = [];

    this.optionsRodeos = [];
    this.optionsCorrales = [];
    this.optionClientes = props.optionsClientes || [];

    this.corrales.forEach((r) => {
      if (r.id !== this.rodeo.corral) {
        this.optionsCorrales.push({
          key: r.id,
          text: r.nombre,
          value: r.id
        })
      }
    })

    this.rodeos.forEach((r) => {
      if (r.id !== this.rodeo.id) {
        this.optionsRodeos.push({
          key: r.id,
          text: `Rodeo ${r.nombre} en corral ${this.corrales.find((c) => c.id === r.corral) !== undefined ? this.corrales.find((c) => c.id === r.corral).nombre : 'sin corral'}`,
          value: r.id
        })
      }
    });

    this.optionsConceptos = []

    if (this.rodeo.seguir && this.empresa.rodeos) {
      this.optionsConceptos = [
        {
          key: "Entrada",
          text: "Entrada",
          value: "Entrada"
        },
        {
          key: "Salida",
          text: "Salida",
          value: "Salida"
        },
        {
          key: "Pesada",
          text: "Pesada",
          value: "Pesada"
        },
        {
          key: "Muerte",
          text: "Muerte",
          value: "Muerte"
        }
      ];
    } else {
      this.optionsConceptos = [
        {
          key: "Entrada",
          text: "Entrada",
          value: "Entrada"
        },
        {
          key: "Salida",
          text: "Salida",
          value: "Salida"
        },
        {
          key: "Muerte",
          text: "Muerte",
          value: "Muerte"
        }
      ];
    }

    this.hoy = moment(new Date)
    this.ultimaFechaCalculada = moment(props.rodeo.ultimaFechaCalculada)
    this.dif = this.hoy.diff(this.ultimaFechaCalculada, 'days')

    this.state = {
      modalOpen: false,
      cargando: false,
      fecha: moment(),
      concepto: '',
      rodeoDestino: '',
      detalle: '',
      corral: '',
      nombre: '',
      cabezas: 0,
      desbaste: 0,
      kilos: props.rodeo.ultimaPesada + Number(this.dif * props.rodeo.gdpv) || 0,
      oldKilos: props.rodeo.ultimaPesada + Number(this.dif * props.rodeo.gdpv) || 0,
      precio: props.rodeo.ultimoPrecio,
      plazo: 0,
      cliente: ""
    };

  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  componentWillReceiveProps = (nextProps) => {

    this.optionClientes = nextProps.optionsClientes || [];

    this.perfil = nextProps.perfil;
    this.corrales = nextProps.corrales || [];
    this.db = nextProps.db;

    this.rodeo = nextProps.rodeo;

    this.rodeos = nextProps.rodeos;

    this.empresa = nextProps.empresa;


    this.hoy = moment(new Date)
    this.ultimaFechaCalculada = moment(nextProps.rodeo.ultimaFechaCalculada)
    this.dif = this.hoy.diff(this.ultimaFechaCalculada, 'days')

    this.setState({
      kilos: nextProps.rodeo.ultimaPesada + Number(this.dif * nextProps.rodeo.gdpv) || 0,
      oldKilos: nextProps.rodeo.ultimaPesada + Number(this.dif * nextProps.rodeo.gdpv) || 0,
      precio: nextProps.rodeo.ultimoPrecio || 0
    });

    nextProps.corrales.forEach((r) => {
      if (r.id !== this.rodeo.corral) {
        this.optionsCorrales.push({
          key: r.id,
          text: r.nombre,
          value: r.id
        })
      }
    });

  }


  save = async () => {
    try {

      this.error = [];

      this.setState({ cargando: true })

      if (this.state.concepto === "Salida") {
        if (this.state.cabezas > this.rodeo.cabezas) this.error.push('La cantidad de cabezas exede la existencia.');
      }

      if (this.state.concepto.trim().length === 0) this.error.push('El concepto es obligatorio.');
      if (this.state.detalle.trim().length === 0 && this.state.concepto !== "Muerte" && this.state.concepto !== "Pesada") this.error.push('El detalle es obligatorio.');
      if ((this.state.concepto === "Entrada" || this.state.concepto === "Salida") && this.state.cabezas < 1) this.error.push('La cantidad de cabezas es obligatoria');
      if (this.state.detalle === "Generar Rodeo") {
        if (this.state.nombre.length === 0) {
          this.error.push('Debe ingresar un nombre de rodeo');
        } else {
          let nombre = false;
          this.rodeos.forEach(element => {
            if (element.nombre === this.state.nombre && this.rodeo.id !== element.id) nombre = true
          });

          if (nombre) this.error.push('El nombre del rodeo ya existe!.');
        }
      }



      if (this.error.length === 0) {

        const batch = this.db.batch();

        const rodeoref = this.db.collection("empresas").doc(this.perfil.empresa).collection("rodeos").doc(this.rodeo.id);
        const newMovRodeo = this.db.collection("empresas").doc(this.perfil.empresa).collection("movrodeos").doc();

        if (this.state.concepto === "Muerte") {
          batch.set(newMovRodeo,
            {
              cabezas: Number(this.state.cabezas),
              concepto: this.state.concepto,
              detalle: "Ajuste por muerte",
              fecha: new Date(this.state.fecha),
              kilos: Number(0),
              precio: Number(0),
              desbaste: 0,
              rodeo: this.rodeo.id
            }
          );

        }

        if (this.state.concepto === "Pesada") {
          batch.set(newMovRodeo,
            {
              cabezas: Number(this.rodeo.cabezas),
              concepto: this.state.concepto,
              detalle: "Pesada de control",
              fecha: new Date(this.state.fecha),
              kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
              desbaste: Number(this.state.desbaste),
              precio: Number(this.state.precio),
              rodeo: this.rodeo.id
            }
          );

        }


        if (this.state.concepto === "Entrada") {
          batch.set(newMovRodeo,
            {
              cabezas: Number(this.state.cabezas),
              concepto: this.state.concepto,
              detalle: this.state.detalle,
              fecha: new Date(this.state.fecha),
              kilos: this.state.kilos > 0 ? Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))) : this.props.pesoActual,
              desbaste: Number(this.state.desbaste),
              precio: Number(this.state.precio),
              rodeo: this.rodeo.id
            }
          );
        }

        if (this.state.concepto === "Salida") {
          if (this.state.detalle === "Venta") {
            batch.set(newMovRodeo,
              {
                cabezas: Number(this.state.cabezas),
                concepto: this.state.concepto,
                detalle: this.state.detalle,
                fecha: new Date(this.state.fecha),
                kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                desbaste: Number(this.state.desbaste),
                precio: Number(this.state.precio),
                rodeo: this.rodeo.id,
                cliente: this.state.cliente,
                plazo: Number(this.state.plazo)
              }
            );
          }

          if (this.state.detalle === "Salida a Campo") {
            batch.set(newMovRodeo,
              {
                cabezas: Number(this.state.cabezas),
                concepto: this.state.concepto,
                detalle: this.state.detalle,
                fecha: new Date(this.state.fecha),
                kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                desbaste: Number(this.state.desbaste),
                precio: Number(this.state.precio),
                rodeo: this.rodeo.id
              }
            );
          }

          if (this.state.detalle === "Salida por Recuento") {
            batch.set(newMovRodeo,
              {
                cabezas: Number(this.state.cabezas),
                concepto: this.state.concepto,
                detalle: this.state.detalle,
                fecha: new Date(this.state.fecha),
                kilos: this.props.pesoActual , // Number(this.formatear(this.state.kilos)),
                desbaste: Number(0),
                precio: Number(this.rodeo.ultimoPrecio),
                rodeo: this.rodeo.id
              }
            );
          }

          if (this.state.detalle === "Transferencia") {
            const newMovRodeoDestino = this.db.collection("empresas").doc(this.perfil.empresa).collection("movrodeos").doc();

            batch.set(newMovRodeo,
              {
                cabezas: Number(this.state.cabezas),
                concepto: this.state.concepto,
                destino: this.state.rodeoDestino,
                detalle: this.state.detalle,
                fecha: new Date(this.state.fecha),
                kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                desbaste: Number(this.state.desbaste),
                precio: Number(this.state.precio),
                rodeo: this.rodeo.id
              }
            );

            batch.set(newMovRodeoDestino,
              {
                cabezas: Number(this.state.cabezas),
                concepto: "Entrada",
                detalle: "Transferencia",
                origen: this.rodeo.id,
                fecha: new Date(this.state.fecha),
                kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                desbaste: Number(this.state.desbaste),
                precio: Number(this.state.precio),
                rodeo: this.state.rodeoDestino
              }
            );
          }

          if (this.state.detalle === "Generar Rodeo") {
            const nuevoRodeo = this.db.collection("empresas").doc(this.perfil.empresa).collection("rodeos").doc();
            const newMovNuevoRodeo = this.db.collection("empresas").doc(this.perfil.empresa).collection("movrodeos").doc();

            const rprevio = this.rodeos.find((r) => r.corral === this.state.corral);
            // if (rodeo !== undefined && rodeo !== null) {
            //   batch.update(rodeo.doc.ref, { "corral": "" });
            // }

            batch.set(newMovRodeo,
              {
                cabezas: Number(this.state.cabezas),
                concepto: this.state.concepto,
                destino: nuevoRodeo.id,
                detalle: this.state.detalle,
                fecha: new Date(this.state.fecha),
                kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                desbaste: Number(this.state.desbaste),
                precio: Number(this.state.precio),
                rodeo: this.rodeo.id
              }
            );


            let fecha_alta = this.rodeo.fechaAlta.toDate ? this.rodeo.fechaAlta.toDate() : new Date(this.rodeo.fechaAlta) //new Date();
           

            let hoy = new Date();
                  hoy.setHours(0);
                  hoy.setMinutes(0);
                  hoy.setSeconds(0);
                  hoy.setMilliseconds(0);

            let diasAcumulados = (hoy.getTime() - fecha_alta.getTime()) / 86400000;
            diasAcumulados += this.rodeo.diasAcumulados ? this.rodeo.diasAcumulados : 0
            batch.set(nuevoRodeo,
              {
                activo: true,
                cabezas: this.state.cabezas,
                categoria: this.rodeo.categoria,
                diasAcumulados: diasAcumulados || 0,
                cerrado: false,
                corral: this.state.corral,
                cpc: Number(0),
                descargado: Number(0),
                dieta: rprevio && rprevio.dieta ? rprevio.dieta : this.rodeo.dieta,
                fechaAlta: new Date(this.state.fecha),
                gdpv: Number(this.rodeo.gdpv),
                hoteleria: Number(this.rodeo.hoteleria),
                idDescarga: null,
                materiaSeca: Number(this.rodeo.materiaSeca),
                nombre: this.state.nombre,
                primerDescarga: ((new Date().getTime()) - 86400000),
                remanente: 0,
                seguir: this.rodeo.seguir,
                ultimaDescarga: ((new Date().getTime()) - 86400000),
                ultimaFechaPesada: new Date(this.state.fecha),
                ultimaPesada: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                ultimoPrecio: Number(this.state.precio),
                propietario: this.rodeo && this.rodeo.propietario ? this.rodeo.propietario : "",
                incremento: rprevio && rprevio.incremento ? rprevio.incremento : 0,
                decremento: rprevio && rprevio.decremento ? rprevio.decremento: 0,
                cpcAutomatico: rprevio && rprevio.cpcAutomatico ? rprevio.cpcAutomatico : false
              }
            );

            batch.set(newMovNuevoRodeo,
              {
                cabezas: Number(this.state.cabezas),
                concepto: "Entrada",
                detalle: "Alta de rodeo",
                origen: this.rodeo.id,
                fecha: new Date(this.state.fecha),
                kilos: Number(this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))),
                desbaste: Number(this.state.desbaste),
                precio: Number(this.state.precio),
                rodeo: nuevoRodeo.id
              }
            );

          }

        }

        await batch.commit().then(() => {
          this.setState({
            alerta: false,
            modalOpen: false,
            cargando: false,
            concepto: "",
            detalle: "",
            rodeoDestino: "",
            corral: '',
            nombre: '',
            cabezas: 0,
            desbaste: 0,
            precio: 0,
            plazo: 0
          });
        });
        this.setState({ cargando: false, modalOpen: false, desbaste: 0 })
      } else { this.setState({ alerta: true, cargando: false }); }
    } catch (err) {
      console.log(err);
      this.setState({
        alerta: false,
        modalOpen: false,
        cargando: false,
        desbaste: 0,
        precio: 0,
        plazo: 0
      })
    }

  }



  completar = () => {
    switch (this.state.detalle) {
      case 'Compra':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="number" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
            </Form.Field>

          </Form.Group>

        
            <Form.Group widths='equal'>
              <Form.Field>
                <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={this.formatear(this.state.kilos) || 0} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={this.state.desbaste || 0} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
              </Form.Field>

            </Form.Group>
       
        </Form>)
      case 'Encierre':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="number" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
            </Form.Field>

          </Form.Group>

     
            <Form.Group widths='equal'>
              <Form.Field>
                <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={this.formatear(this.state.kilos) || 0} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={this.state.desbaste || 0} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
              </Form.Field>

            </Form.Group>
     
        </Form>)
      case 'Entrada por Recuento':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="text" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
            </Form.Field>
          </Form.Group>
        </Form>)
      case 'Venta':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="number" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
            </Form.Field>

          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={this.formatear(this.state.kilos) || 0} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
            </Form.Field>

            <Form.Field>
              <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={this.state.desbaste || 0} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
            </Form.Field>

            <Form.Field>
              <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
            </Form.Field>

          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Precio" id="precio" type="number" min="0" step="0.5" placeholder="Precio por kg" value={this.state.precio || 0} onChange={(e, { value }) => this.setState({ precio: Number(value) })} />
            </Form.Field>

            <Form.Field>
              <Form.Input label="Plazo" id="plazo" type="number" min="0" step="1" placeholder="Plazo" value={this.state.plazo || 0} onChange={(e, { value }) => this.setState({ plazo: Number(value) })} />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.Select label="Cliente" options={this.optionClientes} placeholder='Seleccionar Cliente' value={this.state.cliente} onChange={(e, { value }) => this.setState({ cliente: value })} />
          </Form.Field>
        </Form>)
      case 'Salida por Recuento':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="text" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value <= this.rodeo.cabezas ? value : this.rodeo.cabezas) })} />
            </Form.Field>
          </Form.Group>
        </Form>)
      case 'Salida a Campo':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="number" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
            </Form.Field>

          </Form.Group>

        
            <Form.Group widths='equal'>
              <Form.Field>
                <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={this.formatear(this.state.kilos) || 0} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={this.state.desbaste || 0} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
              </Form.Field>

            </Form.Group>
         
        </Form>)
      case 'Transferencia':
        let elotrorodeo = null;
        if (this.state.rodeoDestino.length > 0) {
          elotrorodeo = this.rodeos.find((r) => r.id === this.state.rodeoDestino)
        }
        return (<Form>
          <Form.Field>
            <Form.Select label="Rodeo Destino" options={this.optionsRodeos} placeholder='Seleccionar Rodeo Destino' value={this.state.rodeoDestino} onChange={(e, { value }) => this.setState({ rodeoDestino: value })} />
          </Form.Field>
          {this.state.rodeoDestino.length > 0 &&
            <div>
              <Form.Group widths='equal'>
                <Form.Field>
                  <Form.Input label="Cantidad de cabezas" id="cabezas" type="number" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
                </Form.Field>

              </Form.Group>

           
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={this.formatear(this.state.kilos) || 0} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={this.state.desbaste || 0} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
                  </Form.Field>

                </Form.Group>
       
            </div>
          }


        </Form>)

      case 'Generar Rodeo':
        return (<Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Nombre del nuevo rodeo" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
            </Form.Field>
            <Form.Field>
              <Form.Select label="Corral destino" options={this.optionsCorrales} placeholder='Seleccionar Corral' value={this.state.corral} onChange={(e, { value }) => this.setState({ corral: value })} />
            </Form.Field>
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Cantidad de cabezas" id="cabezas" type="number" placeholder="Cabezas" value={this.state.cabezas || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
            </Form.Field>

          </Form.Group>

        
            <Form.Group widths='equal'>
              <Form.Field>
                <Form.Input label="kg por cabeza" id="kilos" type="number" min="0" placeholder="Kilos" value={this.formatear(this.state.kilos) || 0} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input label="Desbaste %" id="kilos" type="number" min="0" max="100" placeholder="Desbaste" value={this.state.desbaste || 0} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
              </Form.Field>

              <Form.Field>
                <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
              </Form.Field>

            </Form.Group>
      

        </Form>)

      default:
        return (<div></div>)
    }
  }


  formatear = (num, decimales = 0) => {
    var signo = (num >= 0 ? 1 : -1);
    num = num * signo;
    if (decimales === 0) //con 0 decimales
      return signo * Math.round(num);
    // round(x * 10 ^ decimales)
    num = num.toString().split('e');
    num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    num = num.toString().split('e');
    return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
  }
  render() {

    if (this.state.cargando) {
      return (
        <Modal
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={this.state.cargando}
          size={'small'}
        >

          <Modal.Header>Espere un momento por favor...</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Segment>
                <Dimmer active inverted>
                  <Loader inverted>Loading {this.state.tiempo}</Loader>
                </Dimmer>
              </Segment>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      )
    }

    return (
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='google plus' >
          <Icon name='plus' /> Registrar Movimiento
                </Button>}
      >

        <Modal.Header>Nuevo Movimiento de Rodeo</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <span style={{ fontWeight: 'bold' }}>Fecha: </span>
              <DatePicker
                minDate={moment(this.rodeo.fechaAlta)}
                maxDate={moment(new Date())}
                dateFormat="DD/MM/YYYY" //Thh:mm
                selected={this.state.fecha}
                onChange={(fecha) => this.setState({ fecha })}
              />

              <Form.Field style={{ marginTop: 10 }}>
                <Form.Select label="Concepto" options={this.optionsConceptos} placeholder='Seleccionar Concepto' value={this.state.concepto} onChange={(e, { value }) => this.setState({ concepto: value, detalle: '' })} />
              </Form.Field>

              {this.state.concepto.length > 0 && this.state.concepto === 'Entrada' &&
                <Form.Field>
                  <Form.Select label="Motivo" options={optionsEntrada} placeholder='Seleccionar Motivo' value={this.state.detalle} onChange={(e, { value }) => this.setState({ detalle: value })} />
                </Form.Field>
              }

              {this.state.concepto.length > 0 && this.state.concepto === 'Salida' &&
                <Form.Field>
                  <Form.Select label="Motivo" options={optionsSalida} placeholder='Seleccionar Motivo' value={this.state.detalle} onChange={(e, { value }) => this.setState({ detalle: value })} />
                </Form.Field>
              }

              {this.state.concepto === 'Pesada' &&
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Form.Input label="kg por cabeza" id="kilos" type="text" placeholder="Kilos" value={`${this.formatear(this.state.kilos, 2)}`} onChange={(e, { value }) => this.setState({ kilos: Number(value) })} />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input label="Desbaste %" id="kilos" type="text" placeholder="Desbaste" value={`${this.formatear(this.state.desbaste, 2)}`} onChange={(e, { value }) => this.setState({ desbaste: Number(value) })} />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input readonly label="Total" id="total" type="text" placeholder="-" value={`${this.formatear(this.state.kilos * (1 - (this.state.desbaste / 100)))} Kg`} />
                  </Form.Field>
                </Form.Group>
              }

              {this.state.concepto.length > 0 && this.state.concepto === 'Muerte' &&
                <Form.Field>
                  <Form.Input label="Cantidad de cabezas" id="cabezas" type="text" placeholder="Cabezas" value={this.formatear(this.state.cabezas) || 0} onChange={(e, { value }) => this.setState({ cabezas: Number(value) })} />
                </Form.Field>
              }

              {this.state.concepto.length > 0 && this.state.detalle.length > 0 &&
                this.completar()
              }

              {/* <Form.Field>
                    <Form.Select label="Corral" options={this.optionsCorrales} placeholder='Seleccionar Corral' value={this.state.corral} onChange={(e, { value }) => this.setState({ corral: value })} />
                  </Form.Field> */}

            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({
            modalOpen: false,
            concepto: "",
            detalle: "",
            rodeoDestino: "",
            corral: '',
            kiolos: this.state.oldKilos,
            desbaste: 0,
            nombre: '',
            cabezas: 0,
            precio: 0,
            plazo: 0
          })} inverted>
            Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Verificar Datos'
              list={this.error}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default RodeoNewMovimiento;