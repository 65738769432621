import React, { Component } from 'react';
import firebase from '../firebase';
import {Table, Grid, Button, Label } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchDetalleDietas, fetchProductos } from '../actions';

import DetalleNew from '../components/DetalleNew';
import DetalleEdit from '../components/DetalleEdit';
import DetalleDelete from '../components/DetalleDelete';

import SumarCantidad from '../components/SumarCantidad';
import RestarCantidad from '../components/RestarCantidad';

class DetalleDieta extends Component {
  
  constructor (props) {
    super(props);
    this.firebase = props.firebase;
    this.perfil = props.perfil;

    this.buscarProductos = props.actions.fetchProductos;
    this.buscarDetalleDieta = props.actions.fetchDetalleDietas;
    this.activarListener = props.actions.initialFetch;

    this.db = firebase.firestore();
    this.perfil = props.perfil;

    this.id = props.id;

    this.talcual = props.talcual;

    this.agua = props.agua;

    var detalles = {};
    if(props.detalle.length>0) {
       detalles = props.detalle.find(function(element) {
        return element.id === props.id;
      });
    }

    var det = [];

    if ( detalles !== null && detalles !== undefined) { 
      if ( detalles.detalle !== null && detalles.detalle !== undefined) { 
        det = detalles.detalle 
      }
    }

    this.state = ({
      productos: props.productos || [],
      detalle: det || [],
      listeners: props.listeners || []
    });


  }
  
  static isPrivate = true;

  formatear = (numero, digitos = 0) => {
    return new Intl.NumberFormat('es-ES',{ 
                    minimumFractionDigits: digitos, 
                    maximumFractionDigits: digitos  
                    }).format(numero);
  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  componentWillMount = () => {
    const item = { id: "productos" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "productos";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarProductos(this.perfil); 
    }
  }

  componentDidMount = () => {
    var id = this.id;

    const item = { id: id };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === id;
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarDetalleDieta(this.perfil, this.id); 
    }
  }
  
  
  componentWillReceiveProps = (nextProps) => {
    this.agua = nextProps.agua;
    var idDieta = this.id;

    this.setState({
      productos: nextProps.productos,
      listeners: nextProps.listeners,
    })
    

    if(nextProps.detalle.length>0) {
      var detalles = nextProps.detalle.find(function(element) {
        return element.id === idDieta;
      });
      if(detalles !== undefined){
        if(detalles.detalle.length >= 0)
          this.setState({ detalle: detalles.detalle });
      }
    }
  }
 

  redondear = (num, decimales = 0) => {
      var signo = (num >= 0 ? 1 : -1);
      num = num * signo;
      if (decimales === 0) //con 0 decimales
          return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split('e');
      num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
      // x * 10 ^ (-decimales)
      num = num.toString().split('e');
      return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
  }

  render() {


    if (this.state.detalle.length === 0 || this.state.productos.length ===0 ){
      return ( 
        <div>
            <DetalleNew db={this.db} perfil={this.perfil} id={this.id} productos={this.state.productos} />
          <div className="row text-center">
            <span>No hay detalles dados de alta en esta dieta.</span>
          </div>
        </div>
      )
    }
  
    let talcual = 0;
    let cantidad = 0;
    let totalCosto = 0;

    let fdn = 0;
    let calcio = 0;
    let fosforo = 0;
    let mcal = 0;
    let prot = 0;

    let totalIncTalCual = 0;

    let talcualInmediato = 0;

      this.state.detalle.map( (value) => {
        const producto = this.state.productos.find( (item )=> {
            return item.id === value.producto;
        })

        if(producto.materia > 0){
          talcualInmediato+= value.cantidad / producto.materia;
        }else{
          talcualInmediato+= value.cantidad / 100;
      }
    });

    totalIncTalCual = (talcualInmediato + (this.agua/100) ) > 0 ? totalIncTalCual +  ( (this.agua / 100) * 100) / (talcualInmediato + (this.agua/100) ) : totalIncTalCual + 0; 

    const lista = this.state.detalle.map( (value) => {
            const producto = this.state.productos.find( (item )=> {
                return item.id === value.producto;
            })

            if(producto.materia > 0){
              talcual+= value.cantidad / producto.materia;
              totalCosto+=Number(( value.cantidad / producto.materia) * producto.precio);
            }else{
              talcual+= value.cantidad / 100;
              totalCosto+=Number(( value.cantidad / 100) * producto.precio);
            }

            totalIncTalCual = (talcualInmediato + (this.agua/100) ) > 0 ? totalIncTalCual +  ( (value.cantidad / producto.materia) * 100) / (talcualInmediato + (this.agua/100) ) : totalIncTalCual + 0;

            cantidad+=Number(value.cantidad);

            fdn+=Number( value.cantidad * producto.fdn);
            calcio+=Number( value.cantidad * producto.calcio );
            fosforo+=Number( value.cantidad * producto.fosforo );
            mcal+=Number( value.cantidad * producto.mcal );
            prot+=Number( value.cantidad * producto.prot );

            return (
              <Table.Row key={value.id}>
                <Table.Cell>{value.orden} </Table.Cell>
                <Table.Cell>{producto.nombre} </Table.Cell>

                <Table.Cell className="right aligned">
                      <Button.Group>
                        <RestarCantidad cantidad={value.cantidad} id={value.id} idDieta={this.id} db={this.db} perfil={this.perfil} />
                        <Button.Or text={this.formatear(value.cantidad, 2)} />
                        <SumarCantidad cantidad={value.cantidad} id={value.id} idDieta={this.id} db={this.db} perfil={this.perfil} />
                      </Button.Group>
                </Table.Cell>

                <Table.Cell className="right aligned">{(talcualInmediato + (this.agua/100) ) > 0 ? this.formatear( ( (value.cantidad / producto.materia) * 100) / (talcualInmediato + (this.agua/100) ), 2 ) : 0 } %</Table.Cell>   

                { producto.materia > 0 
                  ?<Table.Cell className="right aligned">$ {this.formatear( Number(( value.cantidad / producto.materia) * producto.precio) , 2 )}</Table.Cell>
                  :<Table.Cell className="right aligned">$ 0</Table.Cell>
                }
                <Table.Cell className="right aligned">{this.formatear((value.cantidad * producto.mcal) / 100, 2 )}</Table.Cell>
                <Table.Cell className="right aligned">{this.formatear((value.cantidad  * producto.prot) / 100, 2 )} %</Table.Cell>
                <Table.Cell className="right aligned">{this.formatear((value.cantidad  * producto.calcio) / 100, 2 )} %</Table.Cell>
                <Table.Cell className="right aligned">{this.formatear((value.cantidad  * producto.fosforo) / 100, 2 )} %</Table.Cell>
                <Table.Cell className="right aligned">{this.formatear((value.cantidad * producto.fdn) / 100, 2 )} %</Table.Cell>

                <Table.Cell><DetalleEdit db={this.db} perfil={this.perfil} idDieta={this.id} id={value.id} productos={this.state.productos} detalle={value} /></Table.Cell>
                {this.state.detalle.length > 1 &&
                <Table.Cell><DetalleDelete  db={this.db} perfil={this.perfil} idDieta={this.id} id={value.id} /></Table.Cell>
                }
              </Table.Row>   
            )
          }

        )
    
    return (

      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='12'>
                      <span>Detalle</span>
                      <DetalleNew db={this.db} perfil={this.perfil} id={this.id} productos={this.state.productos} />
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Orden</Table.HeaderCell>
                  <Table.HeaderCell>Producto</Table.HeaderCell>
                  <Table.HeaderCell>% dieta</Table.HeaderCell>
                  <Table.HeaderCell>% Inc tal cual</Table.HeaderCell>
                  <Table.HeaderCell>Gasto</Table.HeaderCell>
                  <Table.HeaderCell>MCal</Table.HeaderCell>
                  <Table.HeaderCell>Proteina</Table.HeaderCell>
                  <Table.HeaderCell>Calcio</Table.HeaderCell>
                  <Table.HeaderCell>Fosforo</Table.HeaderCell>
                  <Table.HeaderCell>FDN</Table.HeaderCell>
                  <Table.HeaderCell>Editar</Table.HeaderCell>
                  <Table.HeaderCell>Borrar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
              <Table.Footer> 
                <Table.Row positive>
                  <Table.Cell colSpan='2'>Totales:</Table.Cell>
                  <Table.Cell className="center aligned"><Label size={"large"} color={ this.redondear(cantidad, 2) === 100 ? "green" : "red"} >{this.formatear(cantidad, 2)} %</Label></Table.Cell>
                  
                  <Table.Cell className="right aligned">{this.formatear(totalIncTalCual, 2)} %</Table.Cell>

                  <Table.Cell className="right aligned">$ {this.formatear(totalCosto, 2)}</Table.Cell>
                  <Table.Cell className="right aligned">{this.formatear(mcal/cantidad, 2)}</Table.Cell>
                  <Table.Cell className="right aligned">{this.formatear(prot / cantidad, 2)} %</Table.Cell>
                  <Table.Cell className="right aligned">{this.formatear(calcio / cantidad, 2)} %</Table.Cell>
                  <Table.Cell className="right aligned">{this.formatear(fosforo /cantidad, 2)} %</Table.Cell>
                  <Table.Cell className="right aligned">{this.formatear(fdn / cantidad, 2)} %</Table.Cell>
                  <Table.Cell colSpan='2'></Table.Cell>
                </Table.Row>
                <Table.Row positive>
                  <Table.Cell colSpan='12' className="center aligned"><Label size={"big"}>Materia Seca: {this.formatear( ( 1 / (talcual + (this.agua/100) ) ) * cantidad, 2)} %</Label></Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}



function mapStateToProps(state, props) {
  return {
    productos: state.personalStore.productos,
    detalle: state.personalStore.detalledietas,
    listeners: state.personalStore.listeners
  };
}

function mapDispatchToProps(dispatch, props) {
  
    const actions = {
      fetchProductos: bindActionCreators(fetchProductos, dispatch),
      fetchDetalleDietas: bindActionCreators(fetchDetalleDietas, dispatch),
      initialFetch: bindActionCreators(initialFetch, dispatch),
    };
    
    return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetalleDieta);