import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";

const formatear = (numero, digitos = 0) => {
  return new Intl.NumberFormat("es-IN", {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos,
  }).format(numero);
};

export default ({ movimientos = [] }) => {
  const [mov, setMov] = useState([]);

  useEffect(() => {
    const resultado = calcularMovimientos();
    setMov(resultado);
  }, [movimientos]);

  const calcularMovimientos = () => {
    let existencia = {
      etiqueta: "Existencias",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let altas = {
      etiqueta: "Altas de tropa",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let compra = {
      etiqueta: "Entrada por compras",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let encierre = {
      etiqueta: "Entrada por encierre",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let venta = {
      etiqueta: "Salida por ventas",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let transferenciasOutput = {
      etiqueta: "Salida por transferencias",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let transferenciasInput = {
      etiqueta: "Entrada por transferencias",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let salidaCampo = {
      etiqueta: "Salida a campo",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let ajustePositivo = {
      etiqueta: "Ajuste positivo",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let ajusteNegativo = {
      etiqueta: "Ajuste negativo",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let muertes = {
      etiqueta: "Muertes",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let entradas = {
      etiqueta: "Total Entradas",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };
    let salidas = {
      etiqueta: "Total Salidas",
      cantidad: 0,
      kilosTotales: 0,
      monetizacion: 0,
    };

    let resultado = [];

    movimientos.forEach((movimiento) => {
      if (movimiento.concepto.toLowerCase() === "entrada") {
        if (movimiento.detalle === "Alta de rodeo") {
          altas.cantidad += movimiento.cabezas;
          altas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          altas.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          entradas.cantidad += movimiento.cabezas;
          entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          entradas.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Transferencia") {
          transferenciasInput.cantidad += movimiento.cabezas;
          transferenciasInput.kilosTotales +=
            movimiento.cabezas * movimiento.kilos;
          transferenciasInput.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          entradas.cantidad += movimiento.cabezas;
          entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          entradas.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Encierre") {
          encierre.cantidad += movimiento.cabezas;
          encierre.kilosTotales += movimiento.cabezas * movimiento.kilos;
          encierre.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          entradas.cantidad += movimiento.cabezas;
          entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          entradas.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Entrada por Recuento") {
          ajustePositivo.cantidad += movimiento.cabezas;
          // ajustePositivo.kilosTotales += movimiento.cabezas * movimiento.kilos
          // ajustePositivo.monetizacion -= movimiento.cabezas * movimiento.precio * movimiento.kilos

          entradas.cantidad += movimiento.cabezas;
          // entradas.kilosTotales += movimiento.cabezas * movimiento.kilos
          // entradas.monetizacion -= movimiento.cabezas * movimiento.precio * movimiento.kilos
        }
        if (movimiento.detalle === "Compra") {
          compra.cantidad += movimiento.cabezas;
          compra.kilosTotales += movimiento.cabezas * movimiento.kilos;
          compra.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          entradas.cantidad += movimiento.cabezas;
          entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          entradas.monetizacion -=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
      }

      if (movimiento.concepto.toLowerCase() === "salida") {
        if (movimiento.detalle === "A fecha final de reporte") {
          existencia.cantidad += movimiento.cabezas;
          existencia.kilosTotales += movimiento.cabezas * movimiento.kilos;
          existencia.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          salidas.cantidad += movimiento.cabezas;
          salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          salidas.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Generar Rodeo") {
          transferenciasOutput.cantidad += movimiento.cabezas;
          transferenciasOutput.kilosTotales +=
            movimiento.cabezas * movimiento.kilos;
          transferenciasOutput.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          salidas.cantidad += movimiento.cabezas;
          salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          salidas.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Transferencia") {
          transferenciasOutput.cantidad += movimiento.cabezas;
          transferenciasOutput.kilosTotales +=
            movimiento.cabezas * movimiento.kilos;
          transferenciasOutput.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          salidas.cantidad += movimiento.cabezas;
          salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          salidas.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Salida a Campo") {
          salidaCampo.cantidad += movimiento.cabezas;
          salidaCampo.kilosTotales += movimiento.cabezas * movimiento.kilos;
          salidaCampo.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          salidas.cantidad += movimiento.cabezas;
          salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          salidas.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Salida por Recuento") {
          ajusteNegativo.cantidad += movimiento.cabezas;
          //   ajusteNegativo.kilosTotales += movimiento.cabezas * movimiento.kilos;
          //   ajusteNegativo.monetizacion +=
          //     movimiento.cabezas * movimiento.precio * movimiento.kilos;

          salidas.cantidad += movimiento.cabezas;
          //   salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          //   salidas.monetizacion +=
          //     movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
        if (movimiento.detalle === "Venta") {
          venta.cantidad += movimiento.cabezas;
          venta.kilosTotales += movimiento.cabezas * movimiento.kilos;
          venta.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;

          salidas.cantidad += movimiento.cabezas;
          salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
          salidas.monetizacion +=
            movimiento.cabezas * movimiento.precio * movimiento.kilos;
        }
      }

      if (movimiento.concepto.toLowerCase() === "muerte") {
        muertes.cantidad += movimiento.cabezas;
      }
    });

    resultado.push(altas);
    resultado.push(compra);
    resultado.push(encierre);
    resultado.push(transferenciasInput);
    resultado.push(ajustePositivo);
    resultado.push(venta);
    resultado.push(salidaCampo);
    resultado.push(transferenciasOutput);
    resultado.push(ajusteNegativo);
    resultado.push(muertes);
    resultado.push(existencia);
    resultado.push(entradas);
    resultado.push(salidas);

    return resultado;
  };

  return (
    <Table.Body>
      {mov.map((m, i) => (
        <Table.Row
          key={i}
          style={{
            background:
              m.etiqueta === "Total Entradas" || m.etiqueta === "Total Salidas"
                ? "lightgrey"
                : "#FFF",
          }}
        >
          <Table.Cell>{m.etiqueta}</Table.Cell>
          <Table.Cell textAlign="right">{m.cantidad}</Table.Cell>
          <Table.Cell textAlign="right">
            {formatear(m.cantidad > 0 ? m.kilosTotales / m.cantidad : 0, 2)}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {formatear(m.kilosTotales, 2)}
          </Table.Cell>
          <Table.Cell textAlign="right">
            $
            {formatear(
              Math.abs(m.kilosTotales > 0 ? m.monetizacion / m.kilosTotales : 0)
            )}
          </Table.Cell>
          <Table.Cell textAlign="right">
            ${formatear(m.monetizacion)}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};
