const initialState = {  
    fechaInicio: new Date(),
};

const filtrosTablero = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_TRABLERO':
            return Object.assign({}, state, {
            ...state,
            ...action.payload
            });
        default:
            return state
    }
}

export default filtrosTablero;