
import React, { Component } from 'react';
import firebase from '../firebase';
import { Table, Grid, Container, Icon, Button, Segment, Label } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { initialFetch, fetchCategoriasSanitarias, fetchProductosSanitarios, 
    fetchReportesProgramasSanitarios, fetchRodeosActivos, fetchRodeosInactivos, 
    fetchCategorias, fetchCorrales, fetchTratamientosProgramados, 
    setFilterReportesProgramasSanitarios, fetchOrdenesCompra } from '../actions';

import BorrarReporte from '../components/BorrarReporte';

import ModalMultiple from '../components/filtros/ModalMultiple';
import FiltroFechas from '../components/filtros/ModalFechas';

class ReporteProgramasSanitarios extends Component {

    constructor(props) {
        super(props);
        this.db = firebase.firestore();
        this.perfil = props.perfil;

        this.activarListener = props.initialFetch;
        this.fetchReportesProgramasSanitarios = props.fetchReportesProgramasSanitarios;
        this.fetchProductosSanitarios = props.fetchProductosSanitarios;
        this.fetchCategoriasSanitarias = props.fetchCategoriasSanitarias;
        this.fetchRodeosActivos = props.fetchRodeosActivos;
        this.fetchRodeosInactivos = props.fetchRodeosInactivos;
        this.fetchCategorias = props.fetchCategorias;
        this.fetchCorrales = props.fetchCorrales;
        this.fetchTratamientosProgramados = props.fetchTratamientosProgramados;
        this.setFilterReportesProgramasSanitarios = props.setFilterReportesProgramasSanitarios;
        this.fetchOrdenesCompra = props.fetchOrdenesCompra;

        this.state = ({
            activeIndex: 0,
            listeners: props.listeners || [],
            reportes_tratamientos: props.reportes_tratamientos || [],
            tratamientos: props.tratamientos || [],
            categorias: props.categorias || [],
            corrales: props.corrales || [],
            productosSanitarios: props.productosSanitarios || [],
            categoriasSanitarias: props.categoriasSanitarias || [],
            rodeosActivos: props.rodeosActivos || [],
            ordenes_compra: props.ordenes_compra || [],
            rodeosInactivos: []
        })

        this.filtered = {
            fechaInicio: props.filtrosReportesTratamientos.fechaInicio,
            fechaFinal: props.filtrosReportesTratamientos.fechaFinal,
            allCorrales: props.filtrosReportesTratamientos.allCorrales,
            listaCorrales: props.filtrosReportesTratamientos.listaCorrales,
            allRodeos: props.filtrosReportesTratamientos.allRodeos,
            listaRodeos: props.filtrosReportesTratamientos.listaRodeos,
            allCategorias: props.filtrosReportesTratamientos.allCategorias,
            listaCategorias: props.filtrosReportesTratamientos.listaCategorias,
            allProductos: props.filtrosReportesTratamientos.allProductos,
            listaProductos: props.filtrosReportesTratamientos.listaProductos,
            filtros: props.filtrosReportesTratamientos.filtros
        }

        this.filtrosFijos = ["corrales", "rodeos", "categorias", "productos"]

        this.diasF = [];
        this.productosF = [];
        this.corralesF = [];
        this.rodeosF = [];
        this.categoriasF = [];
        this.dietasF = [];
    }


    onChangeFechaInicio = (fechaInicio) => {
        this.filtered.fechaInicio = fechaInicio;
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onChangeFechaFinal = (fechaFinal) => {
        this.filtered.fechaFinal = fechaFinal;
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    borrarFechaInicio = () => {
        this.filtered.fechaInicio = null;
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    borrarFechaFinal = () => {
        this.filtered.fechaFinal = null;
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedAllCorrales = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "corrales");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filtered.filtros.indexOf(busqueda);


            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "corrales")
            }

        }

        let corrales = [];
        if (!this.filtered.allCorrales) {
            Object.keys(opciones).map(opcion => {
                corrales.push(opciones[opcion].id);
            })
            this.filtered.listaCorrales = corrales;
            this.filtered.allCorrales = true;
        } else {
            this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });
            this.filtered.allCorrales = false; this.filtered.listaCorrales = [];
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedCorral = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });

        let index = this.filtered.listaCorrales.indexOf(id);

        if (index > -1) {
            this.filtered.listaCorrales = this.filtered.listaCorrales.filter((e) => e !== id);
            this.filtered.allCorrales = false;
        } else {

            let corrales = [];

            if (this.filtered.allCorrales) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        corrales.push(opciones[opcion].id);
                })

                this.filtered.listaCorrales = corrales;
                this.filtered.allCorrales = false;
            } else {
                this.filtered.listaCorrales = this.filtered.listaCorrales.concat(id);
                this.filtered.allCorrales = false;
            }
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedAllProductos = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "productos");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filtered.filtros.indexOf(busqueda);


            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "productos")
            }

        }

        let productos = [];
        if (!this.filtered.allProductos) {
            Object.keys(opciones).map(opcion => {
                productos.push(opciones[opcion].id);
            })
            this.filtered.listaProductos = productos;
            this.filtered.allProductos = true;
        } else {
            this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });
            this.filtered.allProductos = false; this.filtered.listaProductos = [];
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedProducto = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "productos", hora: new Date() });

        let index = this.filtered.listaProductos.indexOf(id);

        if (index > -1) {
            this.filtered.listaProductos = this.filtered.listaProductos.filter((e) => e !== id);
            this.filtered.allProductos = false;
        } else {

            let productos = [];

            if (this.filtered.allProductos) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        productos.push(opciones[opcion].id);
                })

                this.filtered.listaProductos = productos;
                this.filtered.allProductos = false;
            } else {
                this.filtered.listaProductos = this.filtered.listaProductos.concat(id);
                this.filtered.allProductos = false;
            }
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedAllRodeos = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "rodeos");
        if (busqueda !== null && busqueda !== undefined) {

            let index = this.filtered.filtros.indexOf(busqueda);


            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "rodeos");
            }

        }

        let rodeos = [];
        if (!this.filtered.allRodeos) {
            Object.keys(opciones).map(opcion => {
                rodeos.push(opciones[opcion].id);
            })

            this.filtered.listaRodeos = rodeos;
            this.filtered.allRodeos = true;
        } else {
            this.filtered.filtros.push({ nombre: "rodeos", hora: new Date() });
            this.filtered.allRodeos = false;
            this.filtered.listaRodeos = [];
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedRodeo = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "rodeos", hora: new Date() });

        let index = this.filtered.listaRodeos.indexOf(id);

        if (index > -1) {
            this.filtered.listaRodeos = this.filtered.listaRodeos.filter((e) => e !== id);
            this.filtered.allRodeos = false;
        } else {

            let rodeos = [];

            if (this.filtered.allRodeos) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        rodeos.push(opciones[opcion].id);
                })

                this.filtered.listaRodeos = rodeos;
                this.filtered.allRodeos = false;
            } else {

                this.filtered.listaRodeos = this.filtered.listaRodeos.concat(id);
                this.filtered.allRodeos = false;
            }
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedAllCategorias = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "categorias");
        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filtered.filtros.indexOf(busqueda);

            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "categorias");
            }
        }

        let categorias = [];
        if (!this.filtered.allCategorias) {
            Object.keys(opciones).map(opcion => {
                categorias.push(opciones[opcion].id);
            })

            this.filtered.listaCategorias = categorias;
            this.filtered.allCategorias = true;
        } else {
            this.filtered.filtros.push({ nombre: "categorias", hora: new Date() });
            this.filtered.allCategorias = false;
            this.filtered.listaCategorias = [];
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }

    onSelectedCategoria = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "categorias", hora: new Date() });

        let index = this.filtered.listaCategorias.indexOf(id);

        if (index > -1) {
            this.filtered.listaCategorias = this.filtered.listaCategorias.filter((e) => e !== id);
            this.filtered.allCategorias = false;
        } else {

            let categorias = [];

            if (this.filtered.allCategorias) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        categorias.push(opciones[opcion].id);
                })

                this.filtered.listaCategorias = categorias;
                this.filtered.allCategorias = false;
            } else {

                this.filtered.listaCategorias = this.filtered.listaCategorias.concat(id);
                this.filtered.allCategorias = false;
            }
        }
        this.setFilterReportesProgramasSanitarios(this.filtered);
    }


    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }
    componentWillMount = () => {
        const itemOrdenes = { id: "ordenes" };

        var oyenteOrdenes = this.state.listeners.find(function (element) {
            return element.id === "ordenes";
        });

        if (oyenteOrdenes === undefined) {
            this.activarListener(this.perfil, itemOrdenes);
            this.fetchOrdenesCompra(this.perfil);
        }

        const itemCatSanitarias = { id: "categorias_sanitarias" };

        var oyenteCategoriasSanitarias = this.state.listeners.find(function (element) {
            return element.id === "categorias_sanitarias";
        });

        if (oyenteCategoriasSanitarias === undefined) {
            this.activarListener(this.perfil, itemCatSanitarias);
            this.fetchCategoriasSanitarias(this.perfil);
        }

        const itemProdSanitarios = { id: "productos_sanitarios" };

        var oyenteProdSanitarios = this.state.listeners.find(function (element) {
            return element.id === "productos_sanitarios";
        });

        if (oyenteProdSanitarios === undefined) {
            this.activarListener(this.perfil, itemProdSanitarios);
            this.fetchProductosSanitarios(this.perfil);
        }

        const itemReportes = { id: "reportesProgramasSanitarios" };

        var oyenteReportes = this.state.listeners.find(function (element) {
            return element.id === "reportesProgramasSanitarios";
        });

        if (oyenteReportes === undefined) {
            this.activarListener(this.perfil, itemReportes);
            this.fetchReportesProgramasSanitarios(this.perfil);
        }

        const itemCategorias = { id: "categorias" };

        var oyenteCategorias = this.state.listeners.find(function (element) {
            return element.id === "categorias";
        });

        if (oyenteCategorias === undefined) {
            this.activarListener(this.perfil, itemCategorias);
            this.fetchCategorias(this.perfil);
        }

        const itemCorrales = { id: "corrales" };

        var oyenteCorrales = this.state.listeners.find(function (element) {
            return element.id === "corrales";
        });

        if (oyenteCorrales === undefined) {
            this.activarListener(this.perfil, itemCorrales);
            this.fetchCorrales(this.perfil);
        }

        const itemRodAct = { id: "rodeos_activos" };

        var oyenteRodAct = this.state.listeners.find(function (element) {
            return element.id === "rodeos_activos";
        });

        if (oyenteRodAct === undefined) {
            this.activarListener(this.perfil, itemRodAct);
            this.fetchRodeosActivos(this.perfil);
        }

        const itemRodInact = { id: "rodeos_inactivos" };

        var oyenteRodInact = this.state.listeners.find(function (element) {
            return element.id === "rodeos_inactivos";
        });

        if (oyenteRodInact === undefined) {
            this.activarListener(this.perfil, itemRodInact);
            this.fetchRodeosInactivos(this.perfil);
        }

        const itemTratamientos = { id: "tratamientos_programados" };

        var oyenteTratamientos = this.state.listeners.find(function (element) {
            return element.id === "tratamientos_programados";
        });

        if (oyenteTratamientos === undefined) {
            this.activarListener(this.perfil, itemTratamientos);
            this.fetchTratamientosProgramados(this.perfil);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            listeners: nextProps.listeners || [],
            reportes_tratamientos: nextProps.reportes_tratamientos || [],
            tratamientos: nextProps.tratamientos || [],
            categorias: nextProps.categorias || [],
            corrales: nextProps.corrales || [],
            productosSanitarios: nextProps.productosSanitarios || [],
            categoriasSanitarias: nextProps.categoriasSanitarias || [],
            rodeosActivos: nextProps.rodeosActivos || [],
            ordenes_compra: nextProps.ordenes_compra || [],
            rodeosInactivos: []
        })

        this.filtered = {
            fechaInicio: nextProps.filtrosReportesTratamientos.fechaInicio,
            fechaFinal: nextProps.filtrosReportesTratamientos.fechaFinal,
            allCorrales: nextProps.filtrosReportesTratamientos.allCorrales,
            listaCorrales: nextProps.filtrosReportesTratamientos.listaCorrales,
            allRodeos: nextProps.filtrosReportesTratamientos.allRodeos,
            listaRodeos: nextProps.filtrosReportesTratamientos.listaRodeos,
            allCategorias: nextProps.filtrosReportesTratamientos.allCategorias,
            listaCategorias: nextProps.filtrosReportesTratamientos.listaCategorias,
            allProductos: nextProps.filtrosReportesTratamientos.allProductos,
            listaProductos: nextProps.filtrosReportesTratamientos.listaProductos,
            filtros: nextProps.filtrosReportesTratamientos.filtros
        }
    }

    formatoMoneda = (valor) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(valor)
    }

    generarOrdenCompra(productos){
        const lista_productos = [];

        Object.keys(productos).map(value => {
            lista_productos.push({
                fecha: productos[value].fecha,
                producto: productos[value].producto.nombre,
                medida: productos[value].producto.medida,
                cantidad: productos[value].cantidad,
            })
        })

        this.db.collection("empresas").doc(this.perfil.empresa).collection('ordenes_compra').add({
            "procesado": false,
            "fecha": new Date(),
            "productos": lista_productos
        })
    }

    generarPDF(tratamientos, productos) {
        const lista_productos = [];
        const lista_tratamientos = [];

        Object.keys(productos).map(value => {
            lista_productos.push({
                fecha: productos[value].fecha,
                producto: productos[value].producto.nombre,
                medida: productos[value].producto.medida,
                cantidad: productos[value].cantidad,
            })
        })

        tratamientos.map(value => {
            lista_tratamientos.push({
                fecha: value.fecha,
                rodeo: value.rodeo ? value.rodeo.nombre : "-",
                corral: value.corral ? value.corral.nombre : "-",
                categoria: value.categoria ? value.categoria.nombre : "-",
                producto: value.producto ? value.producto.nombre : "-",
                cantidad: value.cantidad ? value.cantidad : 1,
                medida: value.producto ? value.producto.medida : "-",
                cabezas: value.rodeo ? value.rodeo.cabezas : 1,
                total: (value.cantidad ? value.cantidad : 1) * (value.rodeo ? value.rodeo.cabezas : 1)
            })
        })

        this.db.collection("empresas").doc(this.perfil.empresa).collection('reportes_programas_sanitarios').add({
            "procesado": false,
            "fecha": new Date(),
            "inicio": this.filtered.fechaInicio ? new Date(this.filtered.fechaInicio) : null,
            "final": this.filtered.fechaFinal ? new Date(this.filtered.fechaFinal) : null,
            "tratamientos": lista_tratamientos,
            "productos": lista_productos
        })
    }

    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        var minutos = date.getMinutes();

        if (minutos < 10) minutos = "0" + minutos;

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + date.getHours() + ':' + minutos;
    }


    //aplicando filtros

    aplicarFiltroCorrales = (lista) => {
        this.corralesF = [];
        //filtrando por corral
        lista = lista.filter((d) => {
            let corral = {};
            corral.id = d.corral ? d.corral.id : "-";

            corral.label = d.corral ? d.corral.nombre : "-";
            this.corralesF[corral.id] = corral;

            let bandera = true;
            if (!this.filtered.allCorrales) {
                let index = this.filtered.listaCorrales.indexOf(d.corral ? d.corral.id : "-");
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return lista;
    }

    aplicarFiltroRodeos = (lista) => {
        this.rodeosF = [];
        //filtrando por rodeo
        lista = lista.filter((d) => {

            let rodeo = {};
            rodeo.id = d.rodeo ? d.rodeo.id : "-";
            rodeo.label = d.rodeo ? d.rodeo.nombre : "-";
            this.rodeosF[rodeo.id] = rodeo;

            let bandera = true;
            if (!this.filtered.allRodeos) {
                let index = this.filtered.listaRodeos.indexOf(d.rodeo ? d.rodeo.id : "-");
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return lista;
    }

    aplicarFiltroCategorias = (lista) => {
        this.categoriasF = [];
        //filtrando por categorias
        lista = lista.filter((d) => {

            let categoria = {};
            categoria.id = d.categoria ? d.categoria.id : "-";
            categoria.label = d.categoria ? d.categoria.nombre : "-";
            this.categoriasF[categoria.id] = categoria;

            let bandera = true;
            if (!this.filtered.allCategorias) {
                let index = this.filtered.listaCategorias.indexOf(d.categoria ? d.categoria.id : "-");
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return lista;
    }

    aplicarFiltroProductos = (lista) => {
        this.productosF = [];
        //filtrando por categorias
        lista = lista.filter((d) => {

            let producto = {};
            producto.id = d.producto ? d.producto.id : "-";
            producto.label = d.producto ? d.producto.nombre : "-";
            this.productosF[producto.id] = producto;

            let bandera = true;
            if (!this.filtered.allProductos) {
                let index = this.filtered.listaProductos.indexOf(d.producto ? d.producto.id : "-");
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return lista;
    }

    render() {
        var _this = this;

        let listaFiltros = this.filtered.filtros.reduce((filtro, item) => {
            let val = item.nombre;
            filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
            return filtro;
        }, {});

        listaFiltros = Object.keys(listaFiltros).sort((a, b) => {

            if (listaFiltros[a].hora > listaFiltros[b].hora) {
                return 1;
            }

            if (listaFiltros[a].hora < listaFiltros[b].hora) {
                return -1;
            }
            return 0;
        })

        this.filtrosFijos.forEach((fijo) => {
            let index = listaFiltros.indexOf(fijo);
            if (index < 0) listaFiltros.push(fijo);
        });


        this.state.ordenes_compra.sort((a, b) => {
            let fa = (a.fecha instanceof Object) ? a.fecha.toDate() : new Date(a.fecha);
            let fb = (b.fecha instanceof Object) ? b.fecha.toDate() : new Date(b.fecha);

            if (fa.final) {
                fa = (a.fecha instanceof Object) ? a.final.toDate() : new Date(a.final);
            }

            if (fb.final) {
                fb = (b.fecha instanceof Object) ? b.final.toDate() : new Date(b.final);
            }


            if (fa > fb) return -1;
            if (fa < fb) return 1;
            return 0;
        })

        this.state.reportes_tratamientos.sort((a, b) => {
            let fa = (a.fecha instanceof Object) ? a.fecha.toDate() : new Date(a.fecha);
            let fb = (b.fecha instanceof Object) ? b.fecha.toDate() : new Date(b.fecha);

            if (fa.final) {
                fa = (a.fecha instanceof Object) ? a.final.toDate() : new Date(a.final);
            }

            if (fb.final) {
                fb = (b.fecha instanceof Object) ? b.final.toDate() : new Date(b.final);
            }


            if (fa > fb) return -1;
            if (fa < fb) return 1;
            return 0;
        })

        const lista = this.state.reportes_tratamientos.map(
            (value) => {
                let procesado = "En proceso";
                if (value.procesado) procesado = <a target='_blank' href={value.url}>
                    <Icon name="file pdf outline" size='big' link />
                </a>

                let fecha = value.fecha.toDate ? this.prettyDate(value.fecha.toDate()) : this.prettyDate(new Date(value.fecha));

                if (value.final)
                    fecha = value.final.toDate ? this.prettyDate(value.final.toDate()) : this.prettyDate(new Date(value.final));

                return (
                    <Table.Row key={value.id}>
                        <Table.Cell textAlign="center">Programa al dia de: {fecha} </Table.Cell>
                        <Table.Cell textAlign="center">{procesado}</Table.Cell>
                        <Table.Cell textAlign="center"><BorrarReporte doc={value} /></Table.Cell>
                    </Table.Row>
                )
            }
        )

        const listaOrdenes = this.state.ordenes_compra.map(
            (value) => {
                let procesado = "En proceso";
                if (value.procesado) procesado = <a target='_blank' href={value.url}>
                    <Icon name="file pdf outline" size='big' link />
                </a>

                return (
                    <Table.Row key={value.id}>
                        <Table.Cell textAlign="center">{value.fecha.toDate ? this.prettyDate(value.fecha.toDate()) : this.prettyDate(new Date(value.fecha))} </Table.Cell>
                        <Table.Cell textAlign="center">{procesado}</Table.Cell>
                        <Table.Cell textAlign="center"><BorrarReporte doc={value} /></Table.Cell>
                    </Table.Row>
                )
            }
        )


        let listaTratamientos = [];

        this.state.tratamientos.map(
            (value) => {
                value.detalle.map(
                    (detalle) => {
                        let rodeo = this.state.rodeosActivos.find(r => r.id === value.rodeo);

                        if (!rodeo) {
                            rodeo = this.state.rodeosInactivos.find(r => r.id === value.rodeo);
                        }

                        let categoria = null;

                        if (rodeo)
                            categoria = this.state.categorias.find(c => c.id === rodeo.categoria);

                        let producto = this.state.productosSanitarios.find(p => p.id === detalle.producto);

                        let corral;
                        if (rodeo)
                            corral = this.state.corrales.find(c => c.id === rodeo.corral);

                        if (rodeo && rodeo.activo) {
                            listaTratamientos.push({
                                corral,
                                categoria,
                                producto,
                                rodeo,
                                fecha: value.fecha,
                                cabezas: rodeo.cabezas,
                                id: value.id + detalle.producto,
                                cantidad: detalle.cantidad,
                                doc: value.doc
                            })
                        }
                    }
                )
            })

        listaTratamientos = listaTratamientos.sort((a, b) => {
            const fa = (a.fecha instanceof Object) ? a.fecha.toDate() : new Date(a.fecha);
            const fb = (b.fecha instanceof Object) ? b.fecha.toDate() : new Date(b.fecha);
            if (fa > fb) return 1;
            if (fa < fb) return -1;
            return 0;
        })

        listaTratamientos = listaTratamientos.filter((d) => {

            var fecha = ((d.fecha instanceof Object) ? d.fecha.toDate() : new Date(d.fecha)); //new Date(d.detalle.fecha);

            let banderaFechaInicio = true;
            if (this.filtered.fechaInicio !== null) {
                var fechaInicio = new Date(this.filtered.fechaInicio);
                fechaInicio.setHours(0);
                banderaFechaInicio = fecha > fechaInicio
            }

            let banderaFechaFin = true;
            if (this.filtered.fechaFinal !== null) {

                var fechaFin = new Date(this.filtered.fechaFinal);
                fechaFin.setHours(23);
                fechaFin.getMinutes(59);
                banderaFechaFin = fechaFin > fecha
            }

            return banderaFechaInicio && banderaFechaFin;
        });


        listaFiltros.forEach((f) => {
            // if (f === "dias") listaDescarga = _this.aplicarFiltroDias(listaDescarga);
            if (f === "corrales") listaTratamientos = _this.aplicarFiltroCorrales(listaTratamientos);
            if (f === "rodeos") listaTratamientos = _this.aplicarFiltroRodeos(listaTratamientos);
            if (f === "categorias") listaTratamientos = _this.aplicarFiltroCategorias(listaTratamientos);
            if (f === "productos") listaTratamientos = _this.aplicarFiltroProductos(listaTratamientos);
            // if (f === "dietas") listaDescarga = _this.aplicarFiltroDietas(listaDescarga);
        })


        // Calculo de productos necesarios o aplicados
        let productos = listaTratamientos.reduce((lista, value) => {
            const val = value.producto ? value.producto.id : "-";
            let fechaTratamiento = value.fecha.toDate ? value.fecha.toDate() : new Date(value.fecha);
            fechaTratamiento.setHours(0);
            fechaTratamiento.setMinutes(0);
            fechaTratamiento.setSeconds(0);
            fechaTratamiento.setMilliseconds(0);

            let fechaInicial = new Date(this.filtered.fechaInicio);
            fechaInicial.setHours(0);
            fechaInicial.setMinutes(0);
            fechaInicial.setSeconds(0);
            fechaInicial.setMilliseconds(0);

            let fechaFinal = new Date(this.filtered.fechaFinal);
            fechaFinal.setHours(0);
            fechaFinal.setMinutes(0);
            fechaFinal.setSeconds(0);
            fechaFinal.setMilliseconds(0);

            if ((this.filtered.fechaInicio ? fechaTratamiento.getTime() >= fechaInicial.getTime() : true)
                && (this.filtered.fechaFinal ? fechaTratamiento.getTime() <= fechaFinal.getTime() : true)) {
                if (value.rodeo && value.rodeo.activo && value.cantidad > 0) {
                    lista[val] = lista[val] || { cantidad: 0, producto: value.producto, fecha: value.fecha };
                    lista[val].cantidad += (value.cantidad ? value.cantidad : 1) * (value.rodeo ? value.rodeo.cabezas : 1);
                    return lista;
                }
            }
        }, {});

        let listaProductos = Object.keys(productos).map(
            (value) => {
                return (
                    <Table.Row key={productos[value].producto ? productos[value].producto.nombre : "-"}>
                        <Table.Cell textAlign="left">{productos[value].producto ? productos[value].producto.nombre : "-"}</Table.Cell>
                        <Table.Cell textAlign="right">{productos[value].cantidad}</Table.Cell>
                        <Table.Cell textAlign="left">{productos[value].producto ? productos[value].producto.medida : "-"}</Table.Cell>
                    </Table.Row>
                )
            }
        )


        let totalCabezas = 0;
        let totalDosis = 0;

        const tratamientos = listaTratamientos.map(
            (value) => {
                totalCabezas += value.rodeo ? value.rodeo.cabezas : 1;
                totalDosis += (value.cantidad ? value.cantidad : 1) * (value.rodeo ? value.rodeo.cabezas : 1);
                return (
                    <Table.Row key={value.id}>
                        <Table.Cell textAlign="center">{value.fecha.toDate ? this.prettyDate(value.fecha.toDate()) : this.prettyDate(new Date(value.fecha))} </Table.Cell>
                        <Table.Cell textAlign="left">{value.rodeo ? value.rodeo.nombre : "-"}</Table.Cell>
                        <Table.Cell textAlign="left">{value.corral ? value.corral.nombre : "-"}</Table.Cell>
                        <Table.Cell textAlign="left">{value.categoria ? value.categoria.nombre : "-"}</Table.Cell>
                        <Table.Cell textAlign="left">{value.producto ? value.producto.nombre : "-"}</Table.Cell>
                        <Table.Cell textAlign="right">{value.cantidad ? value.cantidad : 1}</Table.Cell>
                        <Table.Cell textAlign="left">{value.producto ? value.producto.medida : "-"}</Table.Cell>
                        <Table.Cell textAlign="right">{value.rodeo ? value.rodeo.cabezas : 1}</Table.Cell>
                        <Table.Cell textAlign="right">{(value.cantidad ? value.cantidad : 1) * (value.rodeo ? value.rodeo.cabezas : 1)}</Table.Cell>
                        {/* <Table.Cell textAlign="left" onClick={()=> value.doc.ref.delete()} >borrar</Table.Cell> */}
                    </Table.Row>
                )
            }
        )


        return (
            <Container fluid>
                <Grid>
                    <Grid.Column width={11}>

                        <Grid.Row centered>
                            <Segment raised>
                                <Label color='red' ribbon>Productos necesarios</Label>
                                <Table
                                    celled
                                    striped
                                    compact
                                    collapsing
                                    color={"blue"}
                                    valigned={"center"}
                                    unstackable={true}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Producto</Table.HeaderCell>
                                            <Table.HeaderCell>Cantidad</Table.HeaderCell>
                                            <Table.HeaderCell>Medida</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {listaProductos}
                                    </Table.Body>
                                </Table>
                            </Segment >
                        </Grid.Row>

                        <Grid.Row centered>
                            <Segment raised>
                                <Table
                                    celled
                                    striped
                                    compact
                                    collapsing
                                    color={"blue"}
                                    valigned={"center"}
                                    unstackable={true}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='7'>
                                                <span>Programas Sanitarios</span>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>{totalCabezas}</Table.HeaderCell>
                                            <Table.HeaderCell>{totalDosis}</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                                <FiltroFechas borrarFechaInicio={this.borrarFechaInicio} borrarFechaFinal={this.borrarFechaFinal} fechaInicio={this.filtered.fechaInicio} fechaFinal={this.filtered.fechaFinal} onChangeFechaInicio={this.onChangeFechaInicio} onChangeFechaFinal={this.onChangeFechaFinal} />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                                <ModalMultiple opciones={this.rodeosF}
                                                    label={"Rodeo"}
                                                    onSelected={this.onSelectedRodeo}
                                                    onSelectedAll={this.onSelectedAllRodeos}
                                                    todo={this.filtered.allRodeos}
                                                    seleccion={this.filtered.listaRodeos} />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                                <ModalMultiple opciones={this.corralesF}
                                                    label={"Corral"}
                                                    onSelected={this.onSelectedCorral}
                                                    onSelectedAll={this.onSelectedAllCorrales}
                                                    todo={this.filtered.allCorrales}
                                                    seleccion={this.filtered.listaCorrales} />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                                <ModalMultiple opciones={this.categoriasF}
                                                    label={"Categoria"}
                                                    onSelected={this.onSelectedCategoria}
                                                    onSelectedAll={this.onSelectedAllCategorias}
                                                    todo={this.filtered.allCategorias}
                                                    seleccion={this.filtered.listaCategorias} />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned" style={{ padding: 2 }}>
                                                <ModalMultiple opciones={this.productosF}
                                                    label={"Producto"}
                                                    onSelected={this.onSelectedProducto}
                                                    onSelectedAll={this.onSelectedAllProductos}
                                                    todo={this.filtered.allProductos}
                                                    seleccion={this.filtered.listaProductos} />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Cantidad</Table.HeaderCell>
                                            <Table.HeaderCell>Medida</Table.HeaderCell>
                                            <Table.HeaderCell>Cabezas</Table.HeaderCell>
                                            <Table.HeaderCell>Total</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tratamientos}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Row>

                    </Grid.Column>

                    <Grid.Column width={5}>
                        <Grid.Row centered>
                            <Segment raised>
                                <Table
                                    celled
                                    striped
                                    color={"teal"}
                                    valigned={"center"}
                                    unstackable={true}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3'>
                                                <span>Lista de Reportes</span>
                                                <Button floated='right' onClick={() => this.generarPDF(listaTratamientos, productos)} color='google plus' >
                                                    <Icon name='plus' /> Generar PDF
                                        </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="center">Fecha</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Descargar</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Borrar</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {lista}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Row>

                        <Grid.Row centered>
                            <Segment raised>
                                <Table
                                    celled
                                    striped
                                    color={"teal"}
                                    valigned={"center"}
                                    unstackable={true}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3'>
                                                <span>Ordenes de Compra</span>
                                                <Button floated='right' onClick={() => this.generarOrdenCompra(productos)} color='google plus' >
                                                    <Icon name='plus' /> Generar Orden
                                        </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="center">Fecha</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Descargar</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Borrar</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {listaOrdenes}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        filtrosReportesTratamientos: state.personalStore.filtrosReportesProgramasSanitarios,
        reportes_tratamientos: state.personalStore.reportes_programas_sanitarios,
        tratamientos: state.personalStore.tratamientos_programados,
        categorias: state.personalStore.categorias,
        corrales: state.personalStore.corrales,
        productosSanitarios: state.personalStore.productosSanitarios,
        categoriasSanitarias: state.personalStore.categoriasSanitarias,
        rodeosActivos: state.personalStore.rodeosActivos,
        rodeosInactivos: state.personalStore.rodeosInactivos,
        ordenes_compra: state.personalStore.ordenes_compra,
        listeners: state.personalStore.listeners
    };
}

export default connect(
    mapStateToProps,
    {
        initialFetch, fetchCategoriasSanitarias, fetchProductosSanitarios,
        fetchReportesProgramasSanitarios, fetchRodeosActivos, fetchRodeosInactivos,
        fetchCategorias, fetchCorrales, fetchTratamientosProgramados, setFilterReportesProgramasSanitarios, fetchOrdenesCompra
    }
)(ReporteProgramasSanitarios);