
const corrales = (state = [], action) => {
    switch (action.type) {
        case 'ADD_CORRAL':
            return action.corrales
        default:
            return state
    }
};

export default corrales;