import { isEmpty } from 'react-redux-firebase';

export const initialFetch = (perfil, item) => {
    return function (dispatch) {
        if (!isEmpty(perfil)){
            dispatch(initialSearch(item));
        }
    }
}

const initialSearch = (item) => ({
    type: 'SEARCH',
    ...item
});