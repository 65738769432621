
const reportes = (state = [], action) => {
    switch (action.type) {
        case 'ADD_REPORTE':
           return action.reportes
        default:
            return state
    }
};

export default reportes;