const initialState = null;

const user = (state = initialState, action) => {

    switch (action.type) {
        
        case '@@reactReduxFirebase/SET_PROFILE':{
            if(action.profile !== undefined && action.profile !== null){
                return Object.assign({}, state, {
                    activo: action.profile.activo,
                    apellido: action.profile.apellido,
                    disabled: action.profile.disabled,
                    email: action.profile.email,
                    empresa: action.profile.empresa,
                    nombre: action.profile.nombre,
                    password: action.profile.password,
                    rol: action.profile.rol
                });
            }
            break;
        }
        default:{
            return state;
        }
    }
}

export default user;