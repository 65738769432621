import React, { Component } from 'react';

import { Button, Form, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'

class ConfNotificacionesNew extends Component {
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;
    
    this.db = props.db;
    
    this.state = {
      nombre: props.notificacion.nombre,
      email: props.notificacion.email,
      oldnombre: props.notificacion.nombre,
      oldemail: props.notificacion.email,
      modalOpen: false
    };

  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.notificacion) this.setState({ 
      nombre: nextProps.notificacion.nombre,
      email: nextProps.notificacion.email,
      oldnombre: nextProps.notificacion.nombre,
      oldemail: nextProps.notificacion.email,
     });
  }

  validateMail = (email) => {
        var resultado = false;

        var patron=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        if(email.search(patron)===0)
        {
            resultado = true;
        }
        
        return resultado;
    }

  save = () => {
    if((this.state.nombre.trim().length>0) &&(this.validateMail(this.state.email))){
      var _this = this;
      this.setState({cargando: true});

      var notiRef = this.db.collection("empresas").doc(this.perfil.empresa).collection('confnotificaciones').doc(this.id);
      
      // Set the "capital" field of the city 'DC'
      return notiRef.update({
        "nombre": this.state.nombre,
        "email": this.state.email
      })
      .then(function() {
          _this.setState({ cargando: false, modalOpen: false, oldname: _this.state.nombre, oldemail: _this.state.email });    
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error})
      })
      this.setState({ cargando: false, modalOpen: false })
    }else{this.setState({ alerta: true });}
  }


  render () {

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }


    return(

      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        size={'small'}
        >
        <Modal.Header>Editar Correo</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: e.target.value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Email" id="email" type="email" placeholder="Email" value={this.state.email} onChange={(e, { value }) => this.setState({ email: e.target.value })} />
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ modalOpen: false, alerta: false, nombre: this.state.oldnombre, email: this.state.oldemail })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header='Datos invalidos'
              list={[
                'Debe ingresar un nombre y un email valido',
              ]}
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default ConfNotificacionesNew;