import firebase from "../firebase";
import { isEmpty } from "react-redux-firebase";
const db = firebase.firestore();

export const fetchMixers = (perfil) => {
  return function (dispatch) {
    if (!isEmpty(perfil)) {
      db.collection("empresas")
        .doc(perfil.empresa)
        .collection("mixers")
        .orderBy("nombre", "asc")
        .onSnapshot(function (querySnapshot) {
          var items = [];
          querySnapshot.forEach(function (doc) {
            let mix = doc.data();
            mix.id = doc.id;
            items.push(mix);
          });
          dispatch(add(items));
        });
    }
  };
};

const add = (mixers) => ({
  type: "ADD_MIXER",
  mixers,
});
