import React, { Component } from 'react';
import { Table, Grid } from 'semantic-ui-react';
import firebase from '../firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchMixers, fetchCorrales } from '../actions';

import CorralEdit from '../components/CorralEdit';
import CorralNew from '../components/CorralNew';


class Corrales extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.db = firebase.firestore();

    this.buscarMixers = props.actions.fetchMixers;
    this.buscarCorrales = props.actions.fetchCorrales;
    this.activarListener = props.actions.initialFetch;
    
    this.perfil = props.perfil;

    this.state = ({
      corrales: props.corrales || [],
      mixers: props.mixers || [],
      listeners: props.listeners || []
    })
  }

  componentWillMount = () => {
    const item = { id: "mixers" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "mixers";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarMixers(this.perfil);
    }
  }

  componentDidMount = () =>{
    const item = { id: "corrales" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "corrales";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarCorrales(this.perfil);
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.mixers) this.setState({ mixers: nextProps.mixers});
    if(nextProps.corrales) this.setState({ corrales: nextProps.corrales});
  }
 
  render() {

    if (this.state.mixers.length === 0){
      return ( 
        <div>
          <div className="row text-center">
            <span>Deben definirse los mixers antes de dar de alta corrales.</span>
          </div>
        </div>
      )
    }


    const listaCorrales = this.state.corrales.map(
            (value) => {
              const mixer = this.state.mixers.find( (item )=> {
                  return item.id === value.mixer;
              })

              if (this.perfil !== undefined ){
                let activo = "No";
                if (value.activo) activo = "si";
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre} </Table.Cell>
                    <Table.Cell className="right aligned">{mixer.nombre}</Table.Cell>
                    <Table.Cell className="center aligned">{activo}</Table.Cell>
                    <Table.Cell><CorralEdit db={this.db} perfil={this.perfil} id={value.id} corral={value} mixers={this.state.mixers}/></Table.Cell>
                  </Table.Row>   
                )
              }
              return ( <div key={value.id}></div> )
          }
        )
    
    return (

      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='5'>
                      <span>Corrales </span>
                      <CorralNew db={this.db} perfil={this.perfil} mixers={this.state.mixers} />
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Corral</Table.HeaderCell>
                  <Table.HeaderCell>Mixer</Table.HeaderCell>
                  <Table.HeaderCell>Activo</Table.HeaderCell>
                  <Table.HeaderCell>Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {listaCorrales}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    mixers: state.personalStore.mixers,
    corrales: state.personalStore.corrales,
    listeners: state.personalStore.listeners,
  };
}

function mapDispatchToProps(dispatch, props) {

  const actions = {
      fetchMixers: bindActionCreators(fetchMixers, dispatch),
      fetchCorrales: bindActionCreators(fetchCorrales, dispatch),
      initialFetch: bindActionCreators(initialFetch, dispatch),
  };
  
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Corrales);