import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Icon, Segment } from 'semantic-ui-react/dist/commonjs';

import 'react-datepicker/dist/react-datepicker.css';
 
export default (props) =>  {
     return(  
      <Segment.Group horizontal compact>
          <Segment compact>
            <DatePicker
              minDate={props.minDate ? props.minDate : null}
              maxDate={props.sin_limite ? null : moment(new Date())}
              dateFormat="DD/MM/YYYY"
              onChange={props.onChangeFecha}
              selected={props.fecha}
            />
          </Segment>
          <Segment compact onClick={props.borrar} style={{cursor: "pointer"}}>
            <Icon name="delete" />
          </Segment>
      </Segment.Group>
     )
}