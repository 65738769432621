
const ordenes_compra = (state = [], action) => {
    switch (action.type) {
        case 'ADD_ORDEN_COMPRA':
           return action.ordenesCompra
        default:
            return state
    }
};

export default ordenes_compra;