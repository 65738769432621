import React, { Component } from 'react'
import firebase from '../firebase';
import {Table, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialFetch, fetchProductos } from '../actions';

import ProductoNew from '../components/ProductoNew';
import ProductoEdit from '../components/ProductoEdit';

class Productos extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.db = firebase.firestore();
    
    this.buscarProductos = props.actions.fetchProductos;
    this.activarListener = props.actions.initialFetch;

    this.perfil = props.perfil;

    this.state = { 
      productos: props.productos || [],
      listeners: props.listeners || []
    };

  }

  componentWillMount = () => {
    
    const item = { id: "productos" };
    
    var oyente = this.state.listeners.find(function(element) {
      return element.id === "productos";
    });
    
    if(oyente === undefined){
        this.activarListener(this.perfil, item);
        this.buscarProductos(this.perfil);
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.productos) this.setState({ productos: nextProps.productos});
  }

  formatear = (numero, digitos = 0) => {
    return new Intl.NumberFormat('es-ES',{ 
                    minimumFractionDigits: digitos, 
                    maximumFractionDigits: digitos  
                    }).format(numero);
  }
  
  render() {

    this.state.productos.sort((a, b)=>{
      if (a.nombre > b.nombre) return 1;
      if (a.nombre < b.nombre) return -1;
      return 0;
    })

    const lista = this.state.productos.map(
            (value) => {
                let activo = "No";
                if (value.activo) activo = "si";
                return (
                  <Table.Row key={value.id}>
                    <Table.Cell>{value.nombre} </Table.Cell>
                    <Table.Cell className="right aligned">{this.formatear(value.materia, 2)} %</Table.Cell>
                    <Table.Cell className="right aligned">$ {this.formatear(value.precio, 2 )}</Table.Cell>
                    <Table.Cell className="right aligned">{this.formatear(value.mcal, 2 )}</Table.Cell>
                    <Table.Cell className="right aligned">{this.formatear(value.prot, 2)} %</Table.Cell>
                    <Table.Cell className="right aligned">{this.formatear(value.fdn, 2 )} %</Table.Cell>
                    <Table.Cell className="right aligned">{this.formatear(value.calcio, 2)} %</Table.Cell>
                    <Table.Cell className="right aligned">{this.formatear(value.fosforo, 2)} %</Table.Cell>
                    {/* <Table.Cell className="right aligned">{this.formatear(value.stock)} Kg</Table.Cell> */}
                    <Table.Cell className="right aligned">{this.formatear(value.minimo)} Kg</Table.Cell>
                    <Table.Cell className="center aligned">{activo}</Table.Cell>
                    <Table.Cell><ProductoEdit db={this.db} perfil={this.perfil} id={value.id} producto={value} /></Table.Cell>
                  </Table.Row>   
                )
          }
        )
    
    return (
      <Grid>
        <Grid.Row centered>

            <Table 
              celled 
              striped 
              compact 
              collapsing 
              color={"teal"} 
              valigned={"center"}
              unstackable={true}
              >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='12'>
                    <span>Productos </span>
                    <ProductoNew db={this.db} perfil={this.perfil}/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Materia Seca</Table.HeaderCell>
                  <Table.HeaderCell>Precio</Table.HeaderCell>
                  <Table.HeaderCell>MCAL /kg ms</Table.HeaderCell>
                  <Table.HeaderCell>PROT /kg ms</Table.HeaderCell>
                  <Table.HeaderCell>FDN /kg ms</Table.HeaderCell>
                  <Table.HeaderCell>Calcio /kg ms</Table.HeaderCell>
                  <Table.HeaderCell>Fosforo /kg ms</Table.HeaderCell>
                  {/* <Table.HeaderCell>Stock</Table.HeaderCell> */}
                  <Table.HeaderCell>Stock Minimo</Table.HeaderCell>
                  <Table.HeaderCell>Activo</Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">Editar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body> 
                {lista}
              </Table.Body>
            </Table>

        </Grid.Row>
      </Grid>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    productos: state.personalStore.productos,
    listeners: state.personalStore.listeners,
  };
}

function mapDispatchToProps(dispatch, props) {

  const actions = {
    fetchProductos: bindActionCreators(fetchProductos, dispatch),
    initialFetch: bindActionCreators(initialFetch, dispatch),
  };
  
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Productos);