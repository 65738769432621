import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import ListaCargas from '../components/ListaCargas';

class Cargas extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);

    this.perfil = props.perfil;
  }
 
  render() {
    if (this.props.cargas === undefined ||
        this.props.empresa === undefined ||
        this.props.dietas === undefined ||
        this.props.productos === undefined ||
        this.props.usuariosCargas === undefined ||
        this.props.mixers === undefined)
        return (<ListaCargas cargando={true} cargas={[]} usuarios={[]} mixers={[]} />)

      if (this.props.cargas === null ||
        this.props.empresa === null ||
        this.props.dietas === null ||
        this.props.productos === null ||
        this.props.usuariosCargas === null ||
        this.props.mixers === null)
        return (<ListaCargas cargando={false}  productos={this.props.productos} empresa={this.props.empresa} rodeos={this.props.rodeos} dietas={this.props.dietas} cargas={[]} usuarios={this.props.usuariosCargas} mixer={this.props.mixers} />)
  

    return (
      <ListaCargas productos={this.props.productos} empresa={this.props.empresa} dietas={this.props.dietas} cargas={this.props.cargas} mixers={this.props.mixers} usuarios={this.props.usuariosCargas}/>
    )
  }

}

export default compose(
    firestoreConnect((props, state) => {
      return [
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          storeAs: 'empresa'
        },
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          subcollections: [{ collection: 'cargas' }],
          orderBy: [['fecha', 'desc']],
          storeAs: 'cargas'
        },
        {
          collection: 'usuarios',
          storeAs: 'usuariosCargas'
        },
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          subcollections: [{ collection: 'mixers' }],
          storeAs: 'mixers'
        },
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          subcollections: [{ collection: 'productos' }],
          orderBy: [['nombre', 'asc']],
          storeAs: 'productos'
        },
        {
          collection: 'empresas',
          doc: props.perfil.empresa,
          subcollections: [{ collection: 'dietas' }],
          orderBy: [['nombre', 'asc']],
          storeAs: 'dietas'
        }
      ]
    }), // sync todos collection from Firestore into redux
    connect((state, props) => ({
      empresa: state.firestore.data.empresa,
      usuariosCargas: state.firestore.data.usuariosCargas,
      cargas: state.firestore.data.cargas,
      mixers: state.firestore.data.mixers,
      productos: state.firestore.data.productos,
      dietas: state.firestore.data.dietas,
    }))
  )(Cargas)