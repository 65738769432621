import React, { Component } from 'react';
import { Form, Button, Modal, Icon } from 'semantic-ui-react'

class ClienteEdit extends Component {
    constructor(props) {
        super(props);

        this.cliente = props.cliente;

        this.state = {
            modalOpen: false,
            nombre: this.props.cliente.nombre || ""
        };

    }

    save = () => {
        if (this.state.nombre.trim().length > 0) {
            this.cliente.doc.ref.update({
                "nombre": this.state.nombre
            })
            this.setState({ modalOpen: false })
        }
    }

    render() {

        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size={'small'}
                trigger={<Button floated='right' onClick={(e, { value }) => this.setState({ modalOpen: true })} color='teal' >
                    <Icon name='plus' /> Editar
                </Button>}
            >

                <Modal.Header>Editar Cliente</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Form.Field>
                                <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre del Cliente" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                            </Form.Field>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={(e, value) => this.setState({ modalOpen: false, nombre: this.cliente.nombre })} inverted>
                        Cancelar
          </Button>
                    <Button color='green' onClick={this.save} inverted>
                        <Icon name='checkmark' /> Guardar
          </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}

export default ClienteEdit;