import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import firebase from '../firebase';

import { Loader, Dimmer, Grid, Button } from 'semantic-ui-react'
import ReactTable from "react-table";


import {
    setFilterSeguimiento
} from '../actions';

import BorrarCargaDescarga from '../components/BorrarDescarga';

import FiltroFechas from '../components/filtros/ModalFechas';
import ModalMultiple from '../components/filtros/ModalMultiple';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class Seguimiento extends Component {

    constructor(props) {
        super(props);

        this.db = firebase.firestore();

        this.perfil = props.perfil;

        this.activarListener = props.initialFetch;

        this.setFilterSeguimiento = props.setFilterSeguimiento;

        this.filtered = {
            fechaInicio: props.filtrosSeguimiento.fechaInicio,
            fechaFinal: props.filtrosSeguimiento.fechaFinal,

            allCorrales: props.filtrosSeguimiento.allCorrales,
            listaCorrales: props.filtrosSeguimiento.listaCorrales,

            allDietas: props.filtrosSeguimiento.allDietas,
            listaDietas: props.filtrosSeguimiento.listaDietas,
            filtros: props.filtrosSeguimiento.filtros,
            allMixer: props.filtrosSeguimiento.allMixer,
            listaMixer: props.filtrosSeguimiento.listaMixer
        }

        this.filtrosFijos = ["dietas", "mixers"]

        this.corralesF = [];
        this.dietasF = [];
        this.mixerF = [];

    }

    confirmarBorrar = (value) => {
        confirmAlert({
          title: 'Confirma eliminar este registro',
          message: 'Esta usted seguro de eliminar este registro de forma permanente?',
          buttons: [
            {
              label: 'Si',
              onClick: () => this.borrar(value)
            },
            {
              label: 'No',
              onClick: () => console.log('Click No')
            }
          ]
        })
      };

    componentWillReceiveProps = (nextProps) => {
        this.perfil = nextProps.perfil;

        this.filtered = {
            fechaInicio: nextProps.filtrosSeguimiento.fechaInicio,
            fechaFinal: nextProps.filtrosSeguimiento.fechaFinal,
            allCorrales: nextProps.filtrosSeguimiento.allCorrales,
            listaCorrales: nextProps.filtrosSeguimiento.listaCorrales,
            allDietas: nextProps.filtrosSeguimiento.allDietas,
            listaDietas: nextProps.filtrosSeguimiento.listaDietas,
            filtros: nextProps.filtrosSeguimiento.filtros,
            allMixer: nextProps.filtrosSeguimiento.allMixer,
            listaMixer: nextProps.filtrosSeguimiento.listaMixer
        }
    }

    redondear = (num, decimales = 0) => {
        var signo = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
    }

    prettyDate = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        var minutos = date.getMinutes();
        if (minutos < 10) minutos = '0' + minutos;

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getHours() + ':' + minutos;
    }



    onChangeFechaInicio = (fechaInicio) => {
        this.filtered.fechaInicio = fechaInicio;
        this.setFilterSeguimiento(this.filtered);
    }
    onChangeFechaFinal = (fechaFinal) => {
        this.filtered.fechaFinal = fechaFinal;
        this.setFilterSeguimiento(this.filtered);
    }

    borrarFechaInicio = () => {
        this.filtered.fechaInicio = null;
        this.setFilterSeguimiento(this.filtered);
    }

    borrarFechaFinal = () => {
        this.filtered.fechaFinal = null;
        this.setFilterSeguimiento(this.filtered);
    }


    onSelectedAllCorrales = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "corrales");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filtered.filtros.indexOf(busqueda);


            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "corrales")
            }

        }

        let corrales = [];
        if (!this.filtered.allCorrales) {
            Object.keys(opciones).map(opcion => {
                corrales.push(opciones[opcion].id);
            })
            this.filtered.listaCorrales = corrales;
            this.filtered.allCorrales = true;
        } else {
            this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });
            this.filtered.allCorrales = false; this.filtered.listaCorrales = [];
        }
        this.setFilterSeguimiento(this.filtered);
    }

    onSelectedCorral = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "corrales", hora: new Date() });

        let index = this.filtered.listaCorrales.indexOf(id);

        if (index > -1) {
            this.filtered.listaCorrales = this.filtered.listaCorrales.filter((e) => e !== id);
            this.filtered.allCorrales = false;
        } else {

            let corrales = [];

            if (this.filtered.allCorrales) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        corrales.push(opciones[opcion].id);
                })

                this.filtered.listaCorrales = corrales;
                this.filtered.allCorrales = false;
            } else {
                this.filtered.listaCorrales = this.filtered.listaCorrales.concat(id);
                this.filtered.allCorrales = false;
            }
        }
        this.setFilterSeguimiento(this.filtered);
    }


    onSelectedDieta = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });

        let index = this.filtered.listaDietas.indexOf(id);

        if (index > -1) {
            this.filtered.listaDietas = this.filtered.listaDietas.filter((e) => e !== id);
            this.filtered.allDietas = false;
        } else {

            let dietas = [];

            if (this.filtered.allDietas) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        dietas.push(opciones[opcion].id);
                })

                this.filtered.listaDietas = dietas;
                this.filtered.allDietas = false;
            } else {

                this.filtered.listaDietas = this.filtered.listaDietas.concat(id);
                this.filtered.allDietas = false;
            }
        }
        this.setFilterSeguimiento(this.filtered);
    }

    onSelectedAllDietas = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "dietas");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filtered.filtros.indexOf(busqueda);
            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "dietas");
            }
        }

        let dietas = [];
        if (!this.filtered.allDietas) {
            Object.keys(opciones).map(opcion => {
                dietas.push(opciones[opcion].id);
            })


            this.filtered.listaDietas = dietas;
            this.filtered.allDietas = true;
        } else {
            this.filtered.filtros.push({ nombre: "dietas", hora: new Date() });
            this.filtered.allDietas = false;
            this.filtered.listaDietas = [];
        }
        this.setFilterSeguimiento(this.filtered);
    }


    aplicarFiltroCorrales = (listaDescarga) => {


        this.corralesF = [];
        //filtrando por corral
        listaDescarga = listaDescarga.filter((d) => {

            if (d[1].corral === undefined || d[1].corral === null) return false;

            let corral = {};
            corral.id = d[1].corral;


            corral.label = this.props.corrales[corral.id].nombre
            this.corralesF[corral.id] = corral;

            let bandera = true;
            if (!this.filtered.allCorrales) {
                let index = this.filtered.listaCorrales.indexOf(d.corral);
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return listaDescarga;
    }

    aplicarFiltroDietas = (listaDescarga) => {
        this.dietasF = [];
        //filtrando por dietas
        listaDescarga = listaDescarga.filter((d) => {

            let dieta = {};
            dieta.id = d[1].dieta;
            dieta.label = this.props.dietas[dieta.id].nombre
            this.dietasF[dieta.id] = dieta;

            let bandera = true;
            if (!this.filtered.allDietas) {
                let index = this.filtered.listaDietas.indexOf(d.dieta);
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return listaDescarga;
    }



    onSelectedAllMixer = (opciones) => {

        let busqueda = this.filtered.filtros.find((f) => f.nombre === "mixers");

        if (busqueda !== null && busqueda !== undefined) {
            let index = this.filtered.filtros.indexOf(busqueda);
            if (index > -1) {
                this.filtered.filtros = this.filtered.filtros.filter((e) => e.nombre !== "mixers");
            }
        }

        let mixers = [];
        if (!this.filtered.allMixer) {
            Object.keys(opciones).map(opcion => {
                mixers.push(opciones[opcion].id);
            })


            this.filtered.listaMixer = mixers;
            this.filtered.allMixer = true;
        } else {
            this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });
            this.filtered.allMixer = false;
            this.filtered.listaMixer = [];
        }
        this.setFilterSeguimiento(this.filtered);
    }


    onSelectedMixer = (id, opciones) => {
        this.filtered.filtros.push({ nombre: "mixers", hora: new Date() });

        let index = this.filtered.listaMixer.indexOf(id);

        if (index > -1) {
            this.filtered.listaMixer = this.filtered.listaMixer.filter((e) => e !== id);
            this.filtered.allMixer = false;
        } else {

            let mixers = [];

            if (this.filtered.allMixer) {
                Object.keys(opciones).map(opcion => {
                    if (opciones[opcion].id !== id)
                        mixers.push(opciones[opcion].id);
                })

                this.filtered.listaMixer = mixers;
                this.filtered.allMixer = false;
            } else {

                this.filtered.listaMixer = this.filtered.listaMixer.concat(id);
                this.filtered.allMixer = false;
            }
        }
        this.setFilterSeguimiento(this.filtered);
    }
    aplicarFiltroMixers = (listaDescarga) => {
        this.mixerF = [];
        //filtrando por categorias
        listaDescarga = listaDescarga.filter((d) => {

            let m = this.props.mixers[d[1].mixer]
            let mixer = {};

            mixer.id = m ? m.id : '-';
            mixer.label = m ? m.nombre : '-'

            this.mixerF[mixer.id] = mixer;

            let bandera = true;
            if (!this.filtered.allMixer) {
                let index = this.filtered.listaMixer.indexOf(mixer ? mixer.id : "-");
                if (index < 0) bandera = false;
            }

            return bandera;
        });

        return listaDescarga;
    }

    borrar = (value) => {
        if (value[1].tipo === "descarga"){
            this.db.collection("empresas").doc(this.perfil.empresa).collection("descargas").doc(value[0]).delete()
        }else{
            this.db.collection("empresas").doc(this.perfil.empresa).collection("cargas").doc(value[0]).delete()
        }
    }

    render() {
        if (this.props.cargas === undefined ||
            this.props.empresa === undefined ||
            this.props.dietas === undefined ||
            this.props.descargas === undefined ||
            this.props.usuarios === undefined ||
            this.props.corrales === undefined ||
            this.props.mixers === undefined)
            return (<Loader active />)

        let listaDescargas = []
        let listaSeguimiento = [];

        if (this.props.cargas !== null &&
            this.props.empresa !== null &&
            this.props.dietas !== null &&
            this.props.descargas !== null &&
            this.props.usuarios !== null &&
            this.props.corrales !== null &&
            this.props.mixers !== null)
        {
            listaDescargas = Object.entries(this.props.descargas).sort(function (a, b) {
                const fa = (a[1].fecha.toDate) ? a[1].fecha.toDate() : new Date(a[1].fecha);
                const fb = (b[1].fecha.toDate) ? b[1].fecha.toDate() : new Date(b[1].fecha);
                if (fa > fb) return -1;
                if (fa < fb) return 1;
                return 0;
            });

            Object.entries(this.props.cargas).map((data) => {
                var semaforo = { color: "white", backgroundColor: "blue" };

                data[1].tipo = "carga";
                data[1].semaforo = semaforo;
                data[1].saldo = Number(data[1].totalcarga) + Number(data[1].agua);
                let saldo = Number(data[1].totalcarga) + Number(data[1].agua);
                const descargas = listaDescargas.filter((d) => d[1].idCarga === data[0]);
            
                listaSeguimiento.push(data);

                descargas.map((dataD, i) => {

                    dataD[1].id = dataD[0]
                    dataD[1].mixer = data[1].mixer;
                    semaforo = { color: "white", backgroundColor: "grey" };
                    dataD[1].tipo = "descarga";
                    saldo -= dataD[1].kilos;
                    dataD[1].saldo = saldo;

                    if (i + 1 === descargas.length) {
                        if ((Math.abs(((saldo) * 100) / data[1].totalcarga)) >= 10) semaforo = { color: "white", backgroundColor: "red" };
                    }
                    dataD[1].semaforo = semaforo;
                    listaSeguimiento.push(dataD);
                })

            })
        }

        let listaFinal = listaSeguimiento.filter((d) => {

            var fecha = d[1].fecha.toDate ? d[1].fecha.toDate() : new Date(d[1].fecha);

            let banderaFechaInicio = true;
            if (this.filtered.fechaInicio !== null) {
                var fechaInicio = new Date(this.filtered.fechaInicio);
                fechaInicio.setHours(0);
                banderaFechaInicio = fecha > fechaInicio
            }

            let banderaFechaFin = true;
            if (this.filtered.fechaFinal !== null) {

                var fechaFin = new Date(this.filtered.fechaFinal);
                fechaFin.setHours(23);
                fechaFin.getMinutes(59);
                banderaFechaFin = fechaFin > fecha
            }

            return banderaFechaInicio && banderaFechaFin;
        });

        /**
         * 
         * APLICANDO FILTROS
         * 
         * console
         */

        let listaFiltros = this.filtered.filtros.reduce((filtro, item) => {
            let val = item.nombre;
            filtro[val] = item[val] || { nombre: item.nombre, hora: item.hora };
            return filtro;
        }, {});

        listaFiltros = Object.keys(listaFiltros).sort((a, b) => {
            if (listaFiltros[a].hora > listaFiltros[b].hora) {
                return 1;
            }

            if (listaFiltros[a].hora < listaFiltros[b].hora) {
                return -1;
            }
            return 0;
        })

        this.filtrosFijos.forEach((fijo) => {
            let index = listaFiltros.indexOf(fijo);
            if (index < 0) listaFiltros.push(fijo);
        });

        var _this = this;
        listaFiltros.forEach((f) => {
            if (f === "dietas") listaFinal = _this.aplicarFiltroDietas(listaFinal);
            // if (f === "mixers") listaFinal = _this.aplicarFiltroMixers(listaFinal);
            if (f === "corrales") listaFinal = _this.aplicarFiltroCorrales(listaFinal);
        })

        /**
         * FIN APLICACION DE FILTROS
         */


        let totalCarga = 0;
        let totalDescarga = 0;
        listaFinal.map((data) => {
            if (data[1].tipo === "carga") totalCarga += Number(data[1].totalcarga) + Number(data[1].agua);
            if (data[1].tipo === "descarga") totalDescarga += data[1].kilos;
        });

        const columns = [
            {
                id: 'fecha',
                Header: 'Fecha',
                accessor: d => (d[1].fecha.toDate) ? d[1].fecha.toDate() : new Date(d[1].fecha),
                Cell: props => <span>{this.prettyDate(props.value)}</span>, // Custom cell components!
                maxWidth: 100,
                Filter: (<FiltroFechas borrarFechaInicio={this.borrarFechaInicio} borrarFechaFinal={this.borrarFechaFinal} fechaInicio={this.filtered.fechaInicio} fechaFinal={this.filtered.fechaFinal} onChangeFechaInicio={this.onChangeFechaInicio} onChangeFechaFinal={this.onChangeFechaFinal} />),
            },
            // , {
            //     id: 'mixer',
            //     Header: 'Mixer',
            //     accessor: d => this.props.usuarios[d[1].usuario].mixer, // Custom value accessors!
            //     Cell: props => <span>{this.props.mixers[props.value] ? this.props.mixers[props.value].nombre : ""}</span>,
            //     maxWidth: 100,
            //     Filter: (<ModalMultiple opciones={this.mixerF}
            //         label={"Mixer"}
            //         onSelected={this.onSelectedMixer}
            //         onSelectedAll={this.onSelectedAllMixer}
            //         todo={this.filtered.allMixer}
            //         seleccion={this.filtered.listaMixer} />)
            // },
             {
                id: 'dieta',
                Header: 'Dieta',
                accessor: d => d[1].dieta,
                Cell: props => <span>{this.props.dietas[props.value] ? this.props.dietas[props.value].nombre : ""}</span>,
                maxWidth: 140,
                Filter: (<ModalMultiple opciones={this.dietasF}
                    label={"Dieta"}
                    onSelected={this.onSelectedDieta}
                    onSelectedAll={this.onSelectedAllDietas}
                    todo={this.filtered.allDietas}
                    seleccion={this.filtered.listaDietas} />),
            }, {
                id: 'cargado',
                Header: 'Cargado',
                accessor: d => d[1].tipo === "carga" ? (Number(d[1].totalcarga) + Number(d[1].agua)) : 0,
                Cell: props => <span>{formatear(props.value)} kg</span>,
                maxWidth: 200,
                style: {
                    textAlign: "right",
                },
                Filter: (<span style={{ fontWeight: "bold", fontSize: "1.1em" }}>{formatear(totalCarga)}</span>)
            }, {
                id: 'descargado',
                Header: 'Descargado',
                accessor: d => d[1].tipo === "descarga" ? Number(d[1].kilos) : 0,
                Cell: props => <span>{formatear(props.value)} kg</span>,
                maxWidth: 200,
                style: {
                    textAlign: "right",
                },
                Filter: (<span style={{ fontWeight: "bold", fontSize: "1.1em" }}>{formatear(totalDescarga)}</span>)
            },{
                id: 'corral',
                Header: 'Corral',
                accessor: d => d[1].corral, // Custom value accessors!
                Cell: props => <span>{this.props.corrales[props.value] ? this.props.corrales[props.value].nombre : ""}</span>,
                style: {
                    textAlign: "right",
                },
                Filter: <span></span>
            }, 
            
            {
                id: 'saldo',
                Header: 'Saldo',
                accessor: d => d[1].saldo,
                Cell: props => <span>{formatear(props.value)} kg</span>,
                maxWidth: 200,
                style: {
                    textAlign: "right",
                },
                Filter: (<span style={{ fontWeight: "bold", fontSize: "1.1em" }}>-</span>)
            },
            {
                id: 'borrar',
                Header: 'Borrar',
                accessor: d => d,
                Cell: props => <Button 
                onClick={()=> this.confirmarBorrar(props.value)}
                // onClick={()=> console.log(props.value)}
                // onClick={()=> this.db.collection("empresas").doc(this.perfil.empresa).collection("descargas").doc(props.value[0]).update({
                //     kilos: 700,
                //     materiaseca: 406
                // })}
                >Borrar</Button>,
                maxWidth: 200,
                style: {
                    textAlign: "right",
                },
                Filter: (<span style={{ fontWeight: "bold", fontSize: "1.1em" }}>-</span>)
            }
        ]



        return (
            <Grid centered >

                <Grid.Row centered>

                    <ReactTable
                        data={listaFinal}
                        noDataText={"No hay cargas registradas"}
                        columns={columns}
                        defaultPageSize={50}
                        showPaginationTop
                        showPaginationBottom
                        filterable
                        className="-striped -highlight"
                        previousText='Previo'
                        nextText='Siguiente'
                        loadingText="Cargando"
                        pageText="Pagina"
                        ofText="de"
                        rowsText="Filas"
                        getTdProps={(state, rowInfo, column) => {
                            if (column && column.id === "saldo" && rowInfo && rowInfo.row && rowInfo.row.saldo) {
                                return {
                                    style: rowInfo.original[1].semaforo
                                }
                            }
                            if (rowInfo && rowInfo.original && rowInfo.original[1] && rowInfo.original[1].tipo === "carga"){
                                return {
                                    style: rowInfo.original[1].semaforo
                                }
                            }
                            return {
                                style: {
                                    backgroundColor: 'white'
                                }
                            }
                        }}
                    />
                </Grid.Row>

            </Grid>
        );
    }
}

const formatear = (numero, digitos = 0) => {
    return new Intl.NumberFormat('es-IN', {
        minimumFractionDigits: digitos,
        maximumFractionDigits: digitos
    }).format(numero);
}


const mapStateToProps = (state) => {
    return {
        filtrosSeguimiento: state.personalStore.filtrosSeguimiento
    }
}

const WrappedComponent = connect(
    mapStateToProps,
    {
        setFilterSeguimiento
    }
)(Seguimiento);

const FireConnect = compose(
    firestoreConnect((props, state) => {
        return [
            {
                collection: 'empresas',
                doc: props.perfil.empresa,
                storeAs: 'empresa'
            },
            {
                collection: 'empresas',
                doc: props.perfil.empresa,
                subcollections: [{ collection: 'cargas' }],
                where: [['fecha', '>=', new Date(props.fechaInicio).getTime()]],
                // orderBy: [['fecha', 'desc']],
                storeAs: 'cargas'
            },
            {
                collection: 'empresas',
                doc: props.perfil.empresa,
                subcollections: [{ collection: 'descargas' }],
                where: [['fecha', '>=', new Date(props.fechaInicio)]],
                // orderBy: [['fecha', 'desc']],
                storeAs: 'descargasSeguimiento'
            },
            {
                collection: 'usuarios',
                storeAs: 'usuarios'
            },
            {
                collection: 'empresas',
                doc: props.perfil.empresa,
                subcollections: [{ collection: 'mixers' }],
                storeAs: 'mixers'
            },
            {
                collection: 'empresas',
                doc: props.perfil.empresa,
                subcollections: [{ collection: 'corrales' }],
                orderBy: [['nombre', 'asc']],
                storeAs: 'corrales'
            },
            {
                collection: 'empresas',
                doc: props.perfil.empresa,
                subcollections: [{ collection: 'dietas' }],
                orderBy: [['nombre', 'asc']],
                storeAs: 'dietas'
            }
        ]
    }), // sync todos collection from Firestore into redux
    connect((state, props) => ({
        empresa: state.firestore.data.empresa,
        usuarios: state.firestore.data.usuarios,
        cargas: state.firestore.data.cargas,
        descargas: state.firestore.data.descargasSeguimiento,
        mixers: state.firestore.data.mixers,
        corrales: state.firestore.data.corrales,
        dietas: state.firestore.data.dietas,
    }))
)(WrappedComponent)


class SeguimientoDobleWrapeed extends Component {
    constructor(props){
        super(props);
        this.state = {
            fechaInicio: props.fechaInicio
        }
    }

    componentWillReceiveProps = (nextProps) => {
      this.setState({
        fechaInicio: nextProps.filtrosSeguimiento.fechaInicio
      })
    }
    
    render(){
        return <FireConnect fechaInicio={this.state.fechaInicio} perfil={this.props.perfil} />
    }
}

export default connect(mapStateToProps)(SeguimientoDobleWrapeed)