import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'


class UsuarioEdit extends Component {
  
  constructor (props) {
    super(props);
    this.perfil = props.perfil;
    this.id = props.id;
    this.db = props.db;
    this.mixers = props.mixers;

    this.error=[];

    this.options =  [
        {"key": "admin", "text": "Administrador", "value": "admin"},
        {"key": "operador", "text": "Operador", "value": "operador"}
    ];

    this.state = {
      tiempo: 15,
      cargando: false,
      modalOpen: false,
      alerta: false,
      nombre: props.usuario.nombre,
      mixer: props.usuario.mixer,
      oldmixer: props.usuario.mixer,
      apellido: props.usuario.apellido,
      empresa: props.usuario.empresa,
      rol: props.usuario.rol,
      email: props.usuario.email,
      activo: props.usuario.activo,
      password: props.usuario.password,
      repassword: props.usuario.password,
      oldnombre: props.usuario.nombre,
      oldapellido: props.usuario.apellido,
      oldempresa: props.usuario.empresa,
      oldrol: props.usuario.rol,
      oldemail: props.usuario.email,
      oldactivo: props.usuario.disabled,
      oldpassword: props.usuario.password,
      oldrepassword: props.usuario.password,
      disabled: !props.usuario.activo,
      olddisabled: !props.usuario.activo,
    };

  }

  componentWillReceiveProps = (nextProps) => {
    this.mixers = nextProps.mixers;

    this.setState({cargando: false})
    clearInterval(this.refreshIntervalId);

    if(nextProps.usuario) this.setState({ 
      nombre: nextProps.usuario.nombre,
      apellido: nextProps.usuario.apellido,
      empresa: nextProps.usuario.empresa,
      rol: nextProps.usuario.rol,
      email: nextProps.usuario.email,
      activo: nextProps.usuario.activo,
      password: nextProps.usuario.password,
      repassword: nextProps.usuario.password,
      oldnombre: nextProps.usuario.nombre,
      oldapellido: nextProps.usuario.apellido,
      oldempresa: nextProps.usuario.empresa,
      oldrol: nextProps.usuario.rol,
      oldemail: nextProps.usuario.email,
      oldactivo: nextProps.usuario.disabled,
      oldpassword: nextProps.usuario.password,
      oldrepassword: nextProps.usuario.password,
      disabled: !nextProps.usuario.activo,
      olddisabled: !nextProps.usuario.activo,
    });

  }

  componentWillUpdate() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  fixBody() {
    const anotherModal = document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }
  
  validateMail = (email) => {
      var resultado = false;
    
      var patron=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
      if(email.search(patron)===0)
      {
          resultado = true;
      }
      
      return resultado;
  }

  save = () => {

    this.setState({tiempo: 15});
    
    this.error = [];
    
    if(this.state.email.trim().length > 0 && !this.validateMail(this.state.email)) this.error.push('El correo ingresado no es valido');
    if((this.state.nombre.trim().length === 0)) this.error.push('El nombre es obligatorio.');
    if((this.state.apellido.trim().length === 0)) this.error.push('El apellido es obligatorio.');
    if((this.state.email.trim().length === 0)) this.error.push('El correo de usuario es obligatorio.');
    if((this.state.password.trim().length === 0)) this.error.push('La contraseña es obligatoria.');
    if((this.state.rol.trim().length === 0)) this.error.push('El Rol es obligatorio.');
    if((this.state.password.trim() !== this.state.repassword.trim())) this.error.push('El campo contraseña y su verificacion no coinciden.');
    if(this.state.password.trim().length < 6 ) this.error.push('La contraseña debe tener como minimo 6 caracteres');
    if((this.state.mixer.trim().length === 0)) this.error.push('El Mixer es obligatorio.');

    if(this.error.length === 0){

      var _this = this;
      this.setState({cargando: true});

      var usuarioRef = this.db.collection("usuarios").doc(this.id);
      
      // Set the "capital" field of the city 'DC'
      return usuarioRef.update({
        "empresa": this.perfil.empresa,
        "nombre": this.state.nombre,
        "apellido": this.state.apellido,
        "activo": this.state.activo,
        "email": this.state.email,
        "rol": this.state.rol,
        "mixer": this.state.mixer,
        "password": this.state.password,
        "disabled": this.state.disabled
      })
      .then(function() {
        _this.setState({ 
          cargando: false,
          alerta: false, 
          modalOpen: false, 
          oldnombre: _this.state.nombre, 
          oldapellido: _this.state.apellido, 
          oldrol: _this.state.rol, 
          oldactivo: _this.state.activo, 
          oldempresa: _this.state.empresa, 
          oldemail: _this.state.email,
          oldmixer: _this.state.mixer,
          error: "" });  
        _this.error=[];
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          _this.setState({ cargando: false, alerta: true, error: error,
            nombre: _this.state.oldnombre,
            apellido: _this.state.oldapellido,
            empresa: _this.state.oldempresa,
            rol: _this.state.oldrol,
            email: _this.state.oldemail,
            activo: _this.state.oldactivo,
            password: _this.state.oldpassword,
            repassword: _this.state.oldrepassword
          })
      })

    }else{this.setState({ alerta: true });}
  }


  render () {

    var optionsMixers  =  []
    
        this.mixers.forEach(function(element) {
          optionsMixers.push({"key": element.id, "text": element.nombre, "value": element.id})
        }, this);

    if(this.state.cargando){
      return( 
       <Modal 
         closeOnEscape={false} 
         closeOnDimmerClick={false} 
         open={this.state.cargando}
         size={'small'}
         >
 
         <Modal.Header>Espere un momento por favor...</Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <Segment>
               <Dimmer active inverted>
                 <Loader inverted>Loading {this.state.tiempo}</Loader>
               </Dimmer>
             </Segment>
           </Modal.Description>
         </Modal.Content>
       </Modal>
      )
     }

    return(
      <Modal 
        closeOnEscape={false} 
        closeOnDimmerClick={false} 
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'small'}
        trigger={<Button floated='right' 
        onClick={() => this.setState({ modalOpen: true })} color='teal' size={'small'} 
        icon="edit" className="ui center aligned segment"/> }
        >

        <Modal.Header>Editar Usuario</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Form>
                  <Form.Field>
                    <Form.Input label="Nombre" id="nombre" type="text" placeholder="Nombre" value={this.state.nombre} onChange={(e, { value }) => this.setState({ nombre: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Apellido" id="apellido" type="text" placeholder="Apellido" value={this.state.apellido} onChange={(e, { value }) => this.setState({ apellido: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Email" id="email" type="email" placeholder="Email" value={this.state.email} onChange={(e, { value }) => this.setState({ email: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Contraseña" id="passworde" type="password" placeholder="Contraseña" value={this.state.password} onChange={(e, { value }) => this.setState({ password: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input label="Repetir Contraseña" id="repassword" type="password" placeholder="Repetir Contraseña" value={this.state.repassword} onChange={(e, { value }) => this.setState({ repassword: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Rol" options={this.options} placeholder='Seleccionar Rol' value={this.state.rol} onChange={(e, { value }) => this.setState({ rol: value })} />
                  </Form.Field>
                  <Form.Field>
                    <Form.Select label="Mixer" options={optionsMixers} placeholder='Seleccionar Mixer' value={this.state.mixer} onChange={(e, { value }) => this.setState({ mixer: value })} />
                  </Form.Field>
                  <Form.Field >
                      <Form.Checkbox label="Activo" toggle checked={this.state.activo} onChange={(e, { value }) => this.setState({ activo: !this.state.activo, disabled: !this.state.disabled })}/>
                  </Form.Field>
                </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={(e, value) => this.setState({ modalOpen: false, apellido: this.state.oldapellido, activo: true, nombre: this.state.oldnombre, empresa: this.state.oldempresa, rol: this.state.oldrol, email: this.state.oldemail, mixer: this.state.oldmixer })} inverted>
              Cancelar
          </Button>
          <Button color='green' onClick={this.save} inverted>
            <Icon name='checkmark' /> Guardar
          </Button>
          {this.state.alerta &&
            <Message
              error
              header={this.state.error}
              list={ this.error }
            />
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

export default UsuarioEdit;