import React from "react";
import ReactExport from "react-export-excel";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import format from "date-format";
import numeral from "numeral";

import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const calcularMovimientos = (movimientos) => {
  let existencia = {
    etiqueta: "Existencias",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let altas = {
    etiqueta: "Altas de tropa",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let compra = {
    etiqueta: "Entrada por compras",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let encierre = {
    etiqueta: "Entrada por encierre",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let venta = {
    etiqueta: "Salida por ventas",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let transferenciasOutput = {
    etiqueta: "Salida por transferencias",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let transferenciasInput = {
    etiqueta: "Entrada por transferencias",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let salidaCampo = {
    etiqueta: "Salida a campo",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let ajustePositivo = {
    etiqueta: "Ajuste positivo",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let ajusteNegativo = {
    etiqueta: "Ajuste negativo",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let muertes = {
    etiqueta: "Muertes",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let entradas = {
    etiqueta: "Total Entradas",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };
  let salidas = {
    etiqueta: "Total Salidas",
    cantidad: 0,
    kilosTotales: 0,
    monetizacion: 0,
  };

  let resultado = [];

  movimientos.forEach((movimiento) => {
    if (movimiento.concepto.toLowerCase() === "entrada") {
      if (movimiento.detalle === "Alta de rodeo") {
        altas.cantidad += movimiento.cabezas;
        altas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        altas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Transferencia") {
        transferenciasInput.cantidad += movimiento.cabezas;
        transferenciasInput.kilosTotales +=
          movimiento.cabezas * movimiento.kilos;
        transferenciasInput.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Encierre") {
        encierre.cantidad += movimiento.cabezas;
        encierre.kilosTotales += movimiento.cabezas * movimiento.kilos;
        encierre.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Entrada por Recuento") {
        ajustePositivo.cantidad += movimiento.cabezas;
        // ajustePositivo.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // ajustePositivo.monetizacion -=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        // entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // entradas.monetizacion -=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Compra") {
        compra.cantidad += movimiento.cabezas;
        compra.kilosTotales += movimiento.cabezas * movimiento.kilos;
        compra.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        entradas.cantidad += movimiento.cabezas;
        entradas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        entradas.monetizacion -=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
    }

    if (movimiento.concepto.toLowerCase() === "salida") {
      if (movimiento.detalle === "A fecha final de reporte") {
        existencia.cantidad += movimiento.cabezas;
        existencia.kilosTotales += movimiento.cabezas * movimiento.kilos;
        existencia.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Generar Rodeo") {
        transferenciasOutput.cantidad += movimiento.cabezas;
        transferenciasOutput.kilosTotales +=
          movimiento.cabezas * movimiento.kilos;
        transferenciasOutput.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Transferencia") {
        transferenciasOutput.cantidad += movimiento.cabezas;
        transferenciasOutput.kilosTotales +=
          movimiento.cabezas * movimiento.kilos;
        transferenciasOutput.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Salida a Campo") {
        salidaCampo.cantidad += movimiento.cabezas;
        salidaCampo.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidaCampo.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Salida por Recuento") {
        ajusteNegativo.cantidad += movimiento.cabezas;
        // ajusteNegativo.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // ajusteNegativo.monetizacion +=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        // salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        // salidas.monetizacion +=
        //   movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
      if (movimiento.detalle === "Venta") {
        venta.cantidad += movimiento.cabezas;
        venta.kilosTotales += movimiento.cabezas * movimiento.kilos;
        venta.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;

        salidas.cantidad += movimiento.cabezas;
        salidas.kilosTotales += movimiento.cabezas * movimiento.kilos;
        salidas.monetizacion +=
          movimiento.cabezas * movimiento.precio * movimiento.kilos;
      }
    }

    if (movimiento.concepto.toLowerCase() === "muerte") {
      muertes.cantidad += movimiento.cabezas;
    }
  });

  resultado.push(altas);
  resultado.push(compra);
  resultado.push(encierre);
  resultado.push(transferenciasInput);
  resultado.push(ajustePositivo);
  resultado.push(venta);
  resultado.push(salidaCampo);
  resultado.push(transferenciasOutput);
  resultado.push(ajusteNegativo);
  resultado.push(muertes);
  resultado.push(existencia);
  resultado.push(entradas);
  resultado.push(salidas);

  return resultado;
};

export const ExelReporteTropas = ({ data }) => {
  let resumen = calcularMovimientos(data.movimientos);

  const produccion = data.resultados.salidas - data.resultados.entradas;
  const ventas = data.resultados.ventas;
  const compras = data.resultados.compras;
  const hoteleria = data.hoteleria;
  const sanidad = data.sanidad;
  const raciones = data.raciones;
  const ms = data.ms;
  const conversion = produccion > 0 ? ms / produccion : 0;
  const entradaAbsoluta = data.resultados.cabAcumuladas;
  const mcal = data.mcal;
  const prot = data.prot;
  const { movimientos, rodeos } = data;

  let fechaInicial = movimientos[0].fecha;
  let fechaFinal = movimientos[movimientos.length - 1].fecha;

  fechaInicial = fechaInicial.toDate
    ? fechaInicial.toDate()
    : new Date(fechaInicial);
  fechaFinal = fechaFinal.toDate ? fechaFinal.toDate() : new Date(fechaFinal);

  const diasTotales = moment(fechaFinal).diff(moment(fechaInicial), "days") + 1;

  let costoAlimentacion = 0;
  Object.keys(data.productos).map((c) => {
    costoAlimentacion += data.productos[c].kilos * data.productos[c].precio;
  });

  let adpv = 0;
  if (raciones > 0) {
    adpv = produccion / raciones;
  }

  let conversioncalc = 0;
  if (produccion > 0) conversioncalc = ms / produccion;

  let diasEnc = 0;
  if (entradaAbsoluta > 0) {
    diasEnc = Number(raciones) / entradaAbsoluta;
  }

  let existenciaPromedio = 0;
  if (diasTotales > 0) {
    existenciaPromedio = raciones / diasTotales;
  }

  let consumo = 0;
  if (raciones > 0) {
    consumo = ms / raciones;
  }
  let mcalgdpv = 0;
  if (adpv > 0) {
    mcalgdpv = mcal / adpv;
  }
  let protgdpv = 0;
  if (adpv > 0) {
    protgdpv = prot / adpv;
  }

  let costokiloms = 0;
  if (consumo > 0 && raciones > 0) {
    costokiloms = costoAlimentacion / raciones / consumo;
  }

  let ingreso_neto_por_kg = 0;
  if (produccion > 0) {
    ingreso_neto_por_kg = (ventas - compras) / produccion;
  }

  let ingreso_por_dia_por_cabeza = ingreso_neto_por_kg * adpv;

  let gast_alim_por_racion = 0;
  if (raciones > 0) {
    gast_alim_por_racion = costoAlimentacion / raciones;
  }

  let sanitario_por_cabeza_por_dia = 0;
  if (diasEnc > 0) {
    sanitario_por_cabeza_por_dia = sanidad / diasEnc;
  }

  let alimimentacion_x_kg = 0;
  if (produccion > 0) {
    alimimentacion_x_kg = costoAlimentacion / produccion;
  }

  let sanitario_x_kg = 0;
  if (adpv > 0) {
    sanitario_x_kg = sanitario_por_cabeza_por_dia / adpv;
  }

  let hoteleria_x_kg = 0;
  if (adpv > 0) {
    hoteleria_x_kg = hoteleria / adpv;
  }

  const dataSetConsumos = data.productos.map((m) => {
    return {
      producto: m.nombre,
      talcual: Number(m.kilos),
      ms: Number(m.materiaseca),
      costo: Number(m.costo),
    };
  });

  const dataSetMovimientos = data.movimientos.map((m) => {
    let detalle = m.detalle;

    if (
      m.concepto.toLowerCase() === "entrada" &&
      m.origen &&
      m.origen.length > 0
    ) {
      detalle = `${detalle} - de ${
        data.tropas[m.origen] ? data.tropas[m.origen].nombre : ""
      }`;
    }
    if (
      m.concepto.toLowerCase() === "salida" &&
      m.destino &&
      m.destino.length > 0
    ) {
      detalle = `${detalle} - a ${
        data.tropas[m.destino] ? data.tropas[m.destino].nombre : ""
      }`;
    }

    return {
      fecha: format("dd/MM/yyyy", new Date(m.fecha)),
      tropa: m.nombre,
      concepto: m.concepto,
      detalle,
      cabezas: Number(m.cabezas),
      kilos:
        m.concepto.toLowerCase() === "muerte" ||
        m.detalle.toLowerCase() === "entrada por recuento" ||
        m.detalle.toLowerCase() === "salida por recuento"
          ? 0
          : Number(m.kilos),
      precio:
        m.concepto.toLowerCase() === "muerte" ||
        m.detalle.toLowerCase() === "entrada por recuento" ||
        m.detalle.toLowerCase() === "salida por recuento"
          ? 0
          : Number(m.precio),
      totalPrecio:
        m.concepto.toLowerCase() === "muerte" ||
        m.detalle.toLowerCase() === "entrada por recuento" ||
        m.detalle.toLowerCase() === "salida por recuento"
          ? 0
          : Number(m.precio) * m.kilos * m.cabezas,
    };
  });

  const dataSetResumenMovimientos = resumen.map((m) => {
    return {
      concepto: m.etiqueta,
      cabezas: Number(m.cantidad),
      kgpromedio: m.cantidad > 0 ? Number(m.kilosTotales / m.cantidad) : 0,
      kgtotales: Number(m.kilosTotales),
      precioPromedio: Math.abs(
        m.kilosTotales > 0 ? m.monetizacion / m.kilosTotales : 0
      ),
      precioTotal: Number(m.monetizacion),
    };
  });

  return (
    <ExcelFile
      element={
        <button
          className="ui green small right floated button"
          style={{ marginLeft: "5px" }}
        >
          Exportar a Excel
        </button>
      }
    >
      <ExcelSheet data={dataSetMovimientos} name="Movimientos">
        <ExcelColumn label="Fecha" value="fecha" />
        <ExcelColumn label="Tropa" value="tropa" />
        <ExcelColumn label="Concepto" value="concepto" />
        <ExcelColumn label="Detalle" value="detalle" />
        <ExcelColumn label="Cabezas" value="cabezas" />
        <ExcelColumn label="Peso" value="kilos" />
        <ExcelColumn label="Precio" value="precio" />
        <ExcelColumn label="Total precio" value="totalPrecio" />
      </ExcelSheet>

      <ExcelSheet data={dataSetResumenMovimientos} name="Resumen Movimientos">
        <ExcelColumn label="Concepto" value="concepto" />
        <ExcelColumn label="Cabezas" value="cabezas" />
        <ExcelColumn label="Kilos promedios" value="kgpromedio" />
        <ExcelColumn label="Kilos totales" value="kgtotales" />
        <ExcelColumn label="Precio promedio" value="precioPromedio" />
        <ExcelColumn label="Monetizacion total" value="precioTotal" />
      </ExcelSheet>

      <ExcelSheet data={dataSetConsumos} name="Consumos">
        <ExcelColumn label="Producto" value="producto" />
        <ExcelColumn label="Kilos tal cual" value="talcual" />
        <ExcelColumn label="Materia Seca" value="ms" />
        <ExcelColumn label="Costo" value="costo" />
      </ExcelSheet>
    </ExcelFile>
  );
};
