import React, { Component } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEmpresa } from '../actions';

class NombreEmpresa extends Component {
  
  static isPrivate = true;

  constructor(props){
    super(props);
    this.buscarEmpresa = props.actions.fetchEmpresa;
    this.perfil = props.perfil;
    this.state = ({ empresa: props.empresa || null });
  }

  componentWillMount = () => {
    this.buscarEmpresa(this.perfil);
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.empresa) this.setState({ empresa: nextProps.empresa});
  }

  render() {
    if (!this.state.empresa){
      return (
        <div>
        <Segment>
          <Dimmer active>
            <Loader>Loading</Loader>
          </Dimmer>
        </Segment>
      </div>
      )
    }
    
    return (
        <span>{this.state.empresa.nombre}</span>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    empresa: state.personalStore.empresa,
  };
}

function mapDispatchToProps(dispatch, props) {

  const actions = {
      fetchEmpresa: bindActionCreators(fetchEmpresa, dispatch),
  };
  
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NombreEmpresa);