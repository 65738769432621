import React from 'react';
import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase'
import { Button, Modal, Icon, Grid, Dimmer, Loader } from 'semantic-ui-react'
import { compose } from 'redux'

import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, Bar } from 'recharts';

import TooltipMessage from '../components/TooltipMessage';

//import ComponentGraficaConsumo from './../components/ComponentGraficoConsumo'
class GraficoConsumo extends React.Component {

    constructor(props) {
        super(props);

        this.firestore = props.firestore;

        this.tropa = props.tropa;
        this.dietas = props.dietas || [];
        this.clima = props.clima || [];
        this.perfil = props.perfil

        this.rodeo = props.rodeo

        this.descargas = props.descargas;

        this.categoria = props.categoria;

        this.state = {
            modalOpen: false,
            width: 450,
            height: 304
        };
    }

    componentWillReceiveProps = (nextProps) => {
        this.tropa = nextProps.tropa;
        this.dietas = nextProps.dietas || [];
        this.clima = nextProps.clima || [];
        this.perfil = nextProps.perfil
        this.rodeo = nextProps.rodeo

        this.categoria = nextProps.categoria;
        this.descargas = nextProps.descargas;
    }

    updateDimensions = () => {
        if (window.innerWidth < 500) {
            this.setState({ width: 450, height: 302 });
        } else {
            let update_width = window.innerWidth - 100;
            let update_height = Math.round(update_width / 3);
            this.setState({ width: update_width, height: update_height });
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.updateDimensions();

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleClose = () => {
        this.setState({ modalOpen: false })
        this.firestore.unsetListener({
            collection: 'empresas',
            doc: this.props.perfil.empresa,
            subcollections: [{ collection: 'descargas' }],
            orderBy: [['fecha', 'asc']],
            where: [['rodeo', '==', this.props.rodeo]],
            storeAs: `descargas${this.props.rodeo}`
        })
    }


    fechaCorta = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }

    dia = (date) => {
        var months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        return date.getDate() + ' ' + months[date.getMonth()];
    }

    render() {

        let lista = {}
        if (this.descargas !== undefined && this.descargas !== null) {

            lista = Object.keys(this.descargas).reduce((descarga, item) => {
                let dieta = this.dietas.find(d => d.id === item.dieta);
                let val = this.descargas[item].rodeo + this.fechaCorta(this.descargas[item].fecha.toDate ? this.descargas[item].fecha.toDate() : new Date(this.descargas[item].fecha));  // ( this.descargas[item].rodeo + this.fechaCorta(this.descargas[item].fecha.toDate()) );
                descarga[val] = descarga[val] || { detalle: this.descargas[item], dieta: dieta ? dieta.nombre : "Sin Definir", calidad: this.descargas[item].mcal, ingredientes: [], cabezas: this.descargas[item].cabezas, kilos: 0, costo: 0, materiaseca: 0, remanentehoy: 0, remanentefuturo: 0, fecha: this.fechaCorta(this.descargas[item].fecha.toDate ? this.descargas[item].fecha.toDate() : new Date(this.descargas[item].fecha)), dia: this.dia(this.descargas[item].fecha.toDate ? this.descargas[item].fecha.toDate() : new Date(this.descargas[item].fecha)) };
                if (this.descargas[item].cabezas > descarga[val].cabezas) {
                    descarga[val].cabezas = this.descargas[item].cabezas;
                }
                descarga[val].materiaseca += this.descargas[item].materiaseca;
                descarga[val].kilos += this.descargas[item].kilos;
                descarga[val].remanentehoy += this.descargas[item].remanentehoy;
                descarga[val].remanentefuturo += this.descargas[item].remanentefuturo;
                descarga[val].costo += this.descargas[item].costo;
                descarga[val].ingredientes = descarga[val].ingredientes.concat(this.descargas[item].ingredientes);
                return descarga;
            }, {});
        }


        let fecha = (new Date());
        fecha.setHours(0);
        fecha.setMinutes(0);



        let data = [];


        Object.keys(lista).map((d, i) => {

            let tiempo = this.clima.filter(c => {
                return this.fechaCorta(c.fecha.toDate ? c.fecha.toDate() : new Date(c.fecha)) === lista[d].fecha;
            })

            if ((lista[d].detalle.fecha.toDate ? lista[d].detalle.fecha.toDate() : new Date(lista[d].detalle.fecha)) < fecha) {
                data.push({
                    dia: lista[d].dia,
                    clima: (tiempo && tiempo.length > 0) ? true : false,
                    condition: (tiempo && tiempo.length > 0) ? tiempo[0].condition : false,
                    precipitacion: (tiempo && tiempo.length > 0 && tiempo[0].precipitacion) ? tiempo[0].precipitacion : 0,
                    icon: (tiempo && tiempo.length > 0) ? tiempo[0].icon : false,
                    temp_c: (tiempo && tiempo.length > 0) ? tiempo[0].temp_c : false,
                    humidity: (tiempo && tiempo.length > 0) ? tiempo[0].humidity : false,
                    ITH: (tiempo && tiempo.length > 0) ? ((((1.8 * tiempo[0].temp_c) + 32)) - ((0.55 - (0.55 * (tiempo[0].humidity / 100))) * ((1.8 * tiempo[0].temp_c) - 26))) : 0,
                    name: lista[d].dia,
                    Cabezas: lista[d].cabezas,
                    dieta: lista[d].dieta,
                    calidad: lista[d].calidad,
                    Materia_Seca: ((Number(lista[d].remanentehoy / lista[d].detalle.talcual) + Number(lista[d].materiaseca) - Number(lista[d].remanentefuturo / lista[d].detalle.talcual)) / lista[d].cabezas),
                    Megacalorias: Number(((((lista[d].remanentehoy / lista[d].detalle.talcual) + Number(lista[d].materiaseca) - Number(lista[d].remanentefuturo / lista[d].detalle.talcual)) / lista[d].cabezas) * lista[d].detalle.mcal))
                })
            }

        });


        return (
            <Modal
                closeOnEscape={true}
                closeOnDimmerClick={true}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                basic
                size={'fullscreen'}
                trigger={< Icon name='chart line' style={{ cursor: "zoom-in" }} onClick={() => {
                    this.setState({ modalOpen: true });
                    this.firestore.setListener({
                        collection: 'empresas',
                        doc: this.props.perfil.empresa,
                        subcollections: [{ collection: 'descargas' }],
                        orderBy: [['fecha', 'asc']],
                        where: [['rodeo', '==', this.props.rodeo]],
                        storeAs: `descargas${this.props.rodeo}`
                    })

                    

                }} color={"green"} />}
            >

                <Modal.Header>{`Grafica de Consumo de Tropa: ${this.tropa}    Categoria: ${this.categoria}`} </Modal.Header>
                <Modal.Content>

                    {this.descargas === undefined &&
                        (<Dimmer active><Loader /></Dimmer>)
                    }

                    {this.descargas === null &&
                        (<div>No se registran descargas para este lote</div>)
                    }
                    {this.descargas !== undefined && this.descargas !== null &&
                        (<Grid.Row centered className="fondo-grafico-consumo">

                            {/* <div style={{ backgroundImage: `url('/assets/consumo.jpg')`, overflow: "scroll", height: `${(this.state.height)}px;`, width: `${this.state.width}px;` }}> */}
                            <ComposedChart width={this.state.width} height={(this.state.height)} data={data} className="lineChart"
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <CartesianGrid strokeDasharray="3 3" color="white" stroke='#f5f5f5' />
                                <XAxis dataKey="name" tick={{ stroke: '#fff', strokeWidth: 2 }} />
                                <YAxis yAxisId="left" tick={{ stroke: '#fff', strokeWidth: 2 }} />
                                <YAxis yAxisId="right" scale="sqrt" orientation="right" tick={{ stroke: '#fff', strokeWidth: 2 }} />
                                {/* scale="sqrt"  */}
                                {/* { console.log({ name, props }); return this.formatoNumero(value, 2); } */}
                                <Tooltip content={<TooltipMessage />} />
                                <Legend verticalAlign='top' iconType={'diamond'} iconSize="30" />
                                <Bar yAxisId="right" dataKey="precipitacion" fill="#01A9DB" />
                                <Line yAxisId="right" strokeWidth="4" type="monotone" dataKey="ITH" stroke="#FA0000" />
                                <Line yAxisId="left" strokeWidth="4" type="monotone" dataKey="Materia_Seca" stroke="#12F307" activeDot={{ r: 8 }} />
                                <Line yAxisId="left" strokeWidth="4" type="monotone" dataKey="Megacalorias" stroke="#FEFE08" />
                                <Brush startIndex={data.length > 30 ? data.length - 30 : 0} endIndex={data.length - 1} stroke="#0b3c29" tickFormatter={(i) => data[i].dia} />
                            </ComposedChart>
                            {/* LineChart */}
                            {/* </div> */}
                        </Grid.Row>)
                    }
                    {/* {this.state.modalOpen &&
                        <ComponentGraficaConsumo clima={this.clima} rodeo={this.rodeo} perfil={this.perfil} dietas={this.dietas} height={this.state.height} width={this.state.width} />
                    } */}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={() => this.setState({ modalOpen: false })} inverted>
                        <Icon name='checkmark' /> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal >
        )
    }
}

// export default connect((state, props) => ({
//     descargas: state.firestore.data[`descargas${props.rodeo}`]
// }))(GraficoConsumo)

export default compose(
    withFirestore,
    connect((state, props) =>
        ({
            descargas: state.firestore.data[`descargas${props.rodeo}`]
        })

    )
)(GraficoConsumo)