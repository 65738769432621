const initialState = {  
    fechaInicio: null,
    fechaFinal: null,
    allProductos: true,
    listaProductos: [],
    filtros: []
};

const filtrosStock = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTROS_STOCK':
            return Object.assign({}, state, {
            ...state,
            ...action
            });
        default:
            return state
    }
}

export default filtrosStock;